<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Estilo">
          <!-- Dimensões -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Dimensões</label>

            <!-- Largura da linha -->
            <div>
              <div class="label-help">
                <label class="sub" for="widhtPage">Largura</label>
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Linhas com largura completa envolvem a página de ponta a ponta. Linhas fixas teram a largura pré-definida."
                />
              </div>
              <!-- Largura da linha -->
              <multiselect
                id="widhtPage"
                v-model="editaItem.propsRow.width_type"
                :options="['Fixa', 'Largura Completa']"
                :searchable="false"
                :show-labels="false"
                placeholder="Largura da página"
                @input="updateWidth()"
                :allow-empty="false"
              >
              </multiselect>
              <div class="space"></div>

              <!-- Se a largura for fixa-->
              <div v-if="editaItem.propsRow.width_type == 'Fixa'">
                <!-- px -->
                <b-form-group
                  v-if="editaItem.propsRow.width_fixed_und == 'px'"
                  id="Largura fixa"
                  class="und_form"
                >
                  <div class="und_form">
                    <b-form-input
                      id="Largura-fixa"
                      v-model="editaItem.propsRow.width_fixed"
                      type="number"
                      min="0"
                      @input="updateWidth()"
                    ></b-form-input>
                    <span
                      @click="
                        (editaItem.propsRow.width_fixed_und = '%'),
                          updateWidth()
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      :title="`Largura em pixel`"
                      >{{ editaItem.propsRow.width_fixed_und }}</span
                    >
                    <div class="space"></div>
                  </div>
                </b-form-group>

                <!-- % -->
                <b-form-group
                  v-if="editaItem.propsRow.width_fixed_und != 'px'"
                  id="Largura fixa"
                  label-for="Largura-fixa"
                  class="und_form"
                >
                  <div class="und_form">
                    <b-form-input
                      id="Largura-fixa"
                      v-model="editaItem.propsRow.width_fixed_number"
                      type="number"
                      min="0"
                      @input="updateWidth()"
                    ></b-form-input>
                    <span
                      @click="
                        (editaItem.propsRow.width_fixed_und = 'px'),
                          updateWidth()
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      :title="`Largura em porcentagem`"
                      >{{ editaItem.propsRow.width_fixed_und }}</span
                    >
                    <div class="space"></div>
                  </div>
                </b-form-group>
              </div>

              <!-- Se a largura for completa -->
              <div v-if="editaItem.propsRow.width_type == 'Largura Completa'">
                <!-- Largura do conteúdo -->
                <div>
                  <div class="label-help">
                    <label class="sub" for="widhtPage"
                      >Largura do Conteúdo</label
                    >
                    <img
                      width="17px"
                      height="17px"
                      src="@/assets/editor/help-circle.svg"
                      alt="icon"
                      v-b-tooltip.hover
                      title="Conteúdos com largura completa envolvem a página de ponta a ponta. Conteúdos com larguras fixas são limitados"
                    />
                  </div>
                  <multiselect
                    id="widhtPageContent"
                    v-model="editaItem.propsRow.width_fixed_content_type"
                    :options="['Fixa', 'Largura Completa']"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    placeholder="Largura da página"
                  ></multiselect>
                  <div class="space"></div>
                </div>

                <!-- Se a largura do conteúdo for fixa -->
                <div
                  v-if="editaItem.propsRow.width_fixed_content_type == 'Fixa'"
                >
                  <div class="label-icon">
                    <label class="subtitulo">Largura fixa</label>
                    <div style="margin-top: 7px">
                      <img
                        src="@/assets/editor/globe.svg"
                        alt="monitor"
                        style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = !global)"
                        v-if="global"
                      />
                      <img
                        src="@/assets/editor/monitor.svg"
                        alt="monitor"
                        style="filter: invert(50%) !important"
                        @click="nextIcon('tablet'), (global = false)"
                        v-if="typeView === 'desktop' && !global"
                      />
                      <img
                        src="@/assets/editor/tablet.svg"
                        alt="tablet"
                        style="filter: invert(50%) !important"
                        @click="nextIcon('mobile'), (global = false)"
                        v-if="typeView === 'tablet'"
                      />
                      <img
                        src="@/assets/editor/phone.svg"
                        alt="phone"
                        style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = true)"
                        v-if="typeView === 'mobile'"
                      />
                    </div>
                  </div>
                  <!-- Largura do conteúdo fixa px -->
                  <b-form-group
                    v-if="editaItem.propsRow.content[typeView].und == 'px'"
                    id="Largura fixa"
                    label-for="Largura-fixa"
                    class="und_form"
                  >
                    <div class="und_form">
                      <b-form-input
                        id="Largura-fixa"
                        v-model="editaItem.propsRow.content[typeView].width"
                        type="number"
                        min="0"
                      ></b-form-input>
                      <span
                        @click="next_und_width_content"
                        class="und text validClick"
                        v-b-tooltip.hover
                        title="Largura em pixel"
                        >{{ editaItem.propsRow.content[typeView].und }}</span
                      >
                      <div class="space"></div>
                    </div>
                  </b-form-group>

                  <!-- Largura do conteúdo fixa % -->
                  <b-form-group
                    v-else
                    id="Largura fixa"
                    label-for="Largura-fixa"
                    class="und_form"
                  >
                    <div class="und_form">
                      <b-form-input
                        id="Largura-fixa"
                        v-model="
                          editaItem.propsRow.content[typeView].width_number
                        "
                        type="number"
                        max="100"
                        min="0"
                      ></b-form-input>
                      <span
                        @click="next_und_width_content"
                        class="und text validClick"
                        v-b-tooltip.hover
                        title="Largura em porcentagem"
                        >{{ editaItem.propsRow.content[typeView].und }}</span
                      >
                      <div class="space"></div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>

            <!-- Altura -->
            <div>
              <div class="label-help">
                <label class="sub" for="widhtPage">Altura </label>
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Linhas com altura completa envolvem a página de ponta a ponta."
                />
              </div>

              <!-- Selecionar altura -->
              <div>
                <multiselect
                  v-model="editaItem.propsRow.height_und"
                  id="widhtPage"
                  :options="[
                    'Altura padrão',
                    'Altura completa',
                    'Altura mínima',
                  ]"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  placeholder="Altura da página"
                ></multiselect>
                <div class="space"></div>
              </div>

              <!-- Se for altura mínima -->
              <div v-if="editaItem.propsRow.height_und === 'Altura mínima'">
                <!-- Linhas com altura mínima responsiva -->
                <div class="label-icon">
                  <label class="subtitulo">Altura mínima</label>
                  <div style="margin-top: 7px">
                    <img
                      src="@/assets/editor/globe.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)"
                      v-if="global"
                    />
                    <img
                      src="@/assets/editor/monitor.svg"
                      alt="monitor"
                      style="filter: invert(50%) !important"
                      @click="nextIcon('tablet'), (global = false)"
                      v-if="typeView === 'desktop' && !global"
                    />
                    <img
                      src="@/assets/editor/tablet.svg"
                      alt="tablet"
                      style="filter: invert(50%) !important"
                      @click="nextIcon('mobile'), (global = false)"
                      v-if="typeView === 'tablet'"
                    />
                    <img
                      src="@/assets/editor/phone.svg"
                      alt="phone"
                      style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = true)"
                      v-if="typeView === 'mobile'"
                    />
                  </div>
                </div>

                <!-- Altura mínima em px -->
                <b-form-group
                  v-if="
                    editaItem.propsRow.min_height[typeView].height_min_und ===
                    'px'
                  "
                  id="Altura mínima"
                  class="und_form"
                >
                  <div class="und_form">
                    <b-form-input
                      id="Largura-fixa"
                      v-model="
                        editaItem.propsRow.min_height[typeView].height_min
                      "
                      type="number"
                      min="0"
                    ></b-form-input>
                    <span
                      @click="next_und_height2"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                      >px</span
                    >
                  </div>
                </b-form-group>

                <!-- Altura mínima em vh -->
                <b-form-group
                  v-if="
                    editaItem.propsRow.min_height[typeView].height_min_und ===
                    'vh'
                  "
                  id="Altura mínima"
                  label-for="Largura-fixa"
                  class="und_form"
                >
                  <div class="und_form">
                    <b-form-input
                      id="Largura-fixa"
                      v-model="
                        editaItem.propsRow.min_height[typeView]
                          .height_min_number
                      "
                      type="number"
                      min="0"
                    ></b-form-input>
                    <span
                      @click="next_und_height2"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura da viewport"
                      >vh</span
                    >
                  </div>
                </b-form-group>
                <div class="space"></div>
              </div>

              <div
                v-if="
                  editaItem.propsRow.height_und == 'Altura completa' ||
                  editaItem.propsRow.height_und == 'Altura mínima'
                "
              >
                <label class="sub" for="align_vetical"
                  >Alinhamento Vertical</label
                >
                <multiselect
                  :allow-empty="false"
                  v-model="editaItem.propsRow.height_align_vertical"
                  id="align_vetical"
                  :options="['Topo ', 'Centralizado', 'Base']"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Alinhamento do conteúdo"
                ></multiselect>
              </div>
            </div>
          </div>

          <!-- fundo -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Fundo</label>
            <label class="sub">Tipo do fundo</label>

            <!-- Seleção do tipo de fundo -->
            <div>
              <multiselect
                v-model="editaItem.propsRow.backgd_und"
                :options="['Nenhum', 'Cor sólida', 'Gradiente', 'Imagem']"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                placeholder="Tipo do fundo"
                @input="trocarUndBackground(), updateOverlap()"
              ></multiselect>
            </div>
            <div
              v-show="editaItem.propsRow.backgd_und != 'Gradiente'"
              class="space"
            ></div>

            <!-- Cor sólida -->
            <div v-if="editaItem.propsRow.backgd_und == 'Cor sólida'">
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsRow.backgd_solid"
                @input="backgroundSolid($event)"
                :colors="palette"
              ></LvColorpicker>
            </div>

            <!-- Gradiente -->
            <div v-if="editaItem.propsRow.backgd_und == 'Gradiente'">
              <!-- Pickers -->
              <div class="pickers">
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradient()"
                    id="LvColorpicker"
                    v-model="editaItem.propsRow.backgd_gradient_c1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradient()"
                    id="LvColorpicker"
                    v-model="editaItem.propsRow.backgd_gradient_c2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input
                  type="number"
                  v-model="editaItem.propsRow.backgd_gradient_deg"
                  @change="backgroundGradient()"
                />
              </div>
              <b-form-input
                v-model="editaItem.propsRow.backgd_gradient_deg"
                @change="backgroundGradient()"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>

            <!-- Imagem -->
            <div v-show="editaItem.propsRow.backgd_und == 'Imagem'">
              <div class="select-image">
                <div @click="openModal('Upload-Editor')">
                  <img
                    :src="
                      editaItem.propsRow.backgd_image
                        ? editaItem.propsRow.backgd_image
                        : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                    "
                    alt="Imagem preview"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    v-model="editaItem.propsRow.backgd_image"
                    @input="backgroundImage(editaItem.propsRow.backgd_image)"
                  />
                  <span @click="openModal('Upload-Editor')">{{
                    editaItem.propsRow.backgd_image
                      ? "Alterar imagem"
                      : "Selecionar imagem"
                  }}</span>
                </div>
              </div>
              <div class="space"></div>

              <label class="sub" for="multiselect-repeat-img">Repetir</label>
              <multiselect
                id="multiselect-repeat-img"
                v-model="editaItem.propsRow.backgd_image_repeat_und"
                :options="['Nenhuma', 'Ladrilhos', 'Horizontal', 'Vertical']"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                placeholder="Tipo do fundo"
                @input="imageRepeat($event)"
              ></multiselect>
              <div class="space"></div>

              <label class="sub" for="multiselect-position-img">Posição</label>
              <multiselect
                id="multiselect-position-img"
                v-model="editaItem.propsRow.backgd_image_position_und"
                :options="[
                  'Topo à esquerda',
                  'Meio à esquerda',
                  'Base à esquerda',
                  'Topo à direita',
                  'Meio à direita',
                  'Base à direita',
                  'Topo ao centro',
                  'Centralizado',
                  'Base ao centro',
                ]"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                placeholder="Tipo do fundo"
                @input="imagePosition($event)"
              >
              </multiselect>
              <div class="space"></div>

              <label class="sub" for="multiselect-anexação-img"
                >Anexação ao Fundo</label
              >
              <multiselect
                :allow-empty="false"
                id="multiselect-anexação-img"
                v-model="editaItem.propsRow.backgd_anexacao_und"
                :options="['Deslizar', 'Fixa']"
                :searchable="false"
                :show-labels="false"
                placeholder="Tipo do fundo"
                @input="imageAnexacao($event)"
              ></multiselect>
              <div v-if="editaItem.propsRow.backgd_anexacao_und == 'Fixa'" style="padding: 7px; border: 1px solid #db2; border-radius: 10px; background-color: #db21; margin-top: 5px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#db2" style="display: inline-block; margin-right: 5px;" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg> 
                <span style="color: #db2; display: inline; font-size: 14px;">Incompatível na versão mobile</span>
              </div>
              <div class="space"></div>

              <label class="sub" for="multiselect-escaca-img">Escaça</label>
              <multiselect
                id="multiselect-escaca-img"
                v-model="editaItem.propsRow.backgd_escaca_und"
                :options="['Nenhuma', 'Ajustar', 'Preencher']"
                :searchable="false"
                :show-labels="false"
                placeholder="Tipo do fundo"
                @input="imageEscaca($event)"
                :allow-empty="false"
              ></multiselect>
              <div class="space"></div>

              <label class="sub" for="multiselect-Sobrepocição-img"
                >Sobrepocição de Fundo
              </label>
              <multiselect
                id="multiselect-Sobrepocição-img"
                v-model="editaItem.propsRow.backgd_overlap_und"
                :options="['Nenhuma', 'Cor', 'Gradiente']"
                :searchable="false"
                :show-labels="false"
                placeholder="Tipo de Sobrepocição"
                :allow-empty="false"
                @input="updateOverlap"
              ></multiselect>
              <div class="space"></div>

              <div v-if="editaItem.propsRow.backgd_overlap_und == 'Cor'">
                <label class="sub">Cor da sobreposição</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsRow.backgd_overlap_cor"
                  @input="updateOverlap"
                  :colors="palette"
                ></LvColorpicker>
                <div class="space"></div>
              </div>

              <div v-if="editaItem.propsRow.backgd_overlap_und == 'Gradiente'">
                <!-- Pickers -->
                <div class="pickers pb-2">
                  <div class="picker">
                    <LvColorpicker
                      id="LvColorpicker"
                      v-model="editaItem.propsRow.backgd_overlap_c1"
                      @input="updateOverlap"
                      :colors="palette"
                    ></LvColorpicker>
                  </div>
                  <div class="picker">
                    <LvColorpicker
                      id="LvColorpicker"
                      v-model="editaItem.propsRow.backgd_overlap_c2"
                      @input="updateOverlap"
                      :colors="palette"
                    ></LvColorpicker>
                  </div>
                </div>
                <div class="space"></div>
                <!-- Direção -->
                <div class="label-range">
                  <label class="subtitulo">Direção do gradiente</label>
                  <input
                    type="number"
                    v-model="editaItem.propsRow.backgd_overlap_deg"
                    @input="updateOverlap"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsRow.backgd_overlap_deg"
                  type="range"
                  min="0"
                  max="360"
                  @input="updateOverlap"
                  step="1"
                ></b-form-input>
              </div>
            </div>
          </div>

          <!-- Borda -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Bordas</label>
            <label class="sub" for="borderType">Estilo</label>
            <multiselect
              id="borderType"
              v-model="editaItem.propsRow.border_und"
              :options="[
                'Nenhuma',
                'Sólido',
                'Tracejadas',
                'Pontilhado',
                'Dobro',
                'Groove',
                'Ridge',
                'Inset',
                'Outset',
              ]"
              :searchable="false"
              :show-labels="false"
              placeholder="Tipo do fundo"
              @input="borderAlter($event)"
              :allow-empty="false"
            >
            </multiselect>
            <div
              class="space"
              v-if="editaItem.propsRow.border_und != 'Nenhuma'"
            ></div>
            <div v-show="editaItem.propsRow.border_und != 'Nenhuma'">
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsRow.border_color"
                @input="borderColor($event, editaItem.propsRow)"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <div
              class="space"
              v-if="editaItem.propsRow.border_und != 'Nenhuma'"
            ></div>
            <div v-show="editaItem.propsRow.border_und != 'Nenhuma'">
              <div class="label-help">
                <label class="sub" for="container-border">Largura</label>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  v-if="linkBorderHeight"
                  class="links"
                  @click="linkBorderHeight = !linkBorderHeight"
                />
                <img
                  src="@/assets/editor/link.svg"
                  v-else
                  alt="Link"
                  class="links"
                  @click="linkBorderHeight = !linkBorderHeight"
                />
              </div>
              <div
                class="container-border"
                id="container-border"
                v-if="linkBorderHeight"
                style="display: flex; gap: 5px; align-items: center"
              >
                <div class="borderx">
                  <img
                    src="@/assets/icons/quadrado.svg"
                    alt="quadrado"
                    :style="`transform:rotate(${rotate_border}deg); transition:all .3s`"
                  />
                </div>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    id="email"
                    v-model="editaItem.propsRow.border_px_top"
                    @click="border_rotate('-180')"
                    @input="borderPxLink($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_right"
                    @click="border_rotate('-90')"
                    @input="borderPxLink($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_bottom"
                    @click="border_rotate('0')"
                    @input="borderPxLink($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_left"
                    @click="border_rotate('90')"
                    @input="borderPxLink($event)"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="container-border"
                id="container-border"
                v-else
                style="display: flex; gap: 5px; align-items: center"
              >
                <div class="borderx">
                  <img
                    src="@/assets/icons/quadrado.svg"
                    alt="quadrado"
                    :style="`transform:rotate(${rotate_border}deg); transition:all .3s`"
                  />
                </div>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    id="email"
                    v-model="editaItem.propsRow.border_px_top"
                    @click="border_rotate('-180')"
                    @input="borderPx()"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_right"
                    @click="border_rotate('-90')"
                    @input="borderPx()"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_bottom"
                    @click="border_rotate('0')"
                    @input="borderPx()"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_px_left"
                    @click="border_rotate('90')"
                    @input="borderPx()"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div
              v-if="editaItem.propsRow.border_und != 'Nenhuma'"
              class="space"
            ></div>
            <div v-if="editaItem.propsRow.border_und != 'Nenhuma'">
              <div class="label-help">
                <label class="sub" for="container-border">Raio</label>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  v-if="linkBorderRaio"
                  class="links"
                  @click="linkBorderRaio = !linkBorderRaio"
                />
                <img
                  src="@/assets/editor/link.svg"
                  v-else
                  alt="Link"
                  class="links"
                  @click="linkBorderRaio = !linkBorderRaio"
                />
              </div>
              <div
                class="container-border"
                id="container-border"
                v-if="linkBorderRaio"
                style="display: flex; gap: 5px; align-items: center"
              >
                <div class="borderx">
                  <img
                    src="@/assets/icons/quadrado.svg"
                    alt="quadrado"
                    :style="`transform:rotate(${shadow_border}deg); transition:all .3s`"
                  />
                </div>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    id="email"
                    v-model="editaItem.propsRow.border_radius_top"
                    @click="shadow_rotate('-180')"
                    @input="linkBorderRadius($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_right"
                    @click="shadow_rotate('-90')"
                    @input="linkBorderRadius($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_bottom"
                    @click="shadow_rotate('0')"
                    @input="linkBorderRadius($event)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_left"
                    @click="shadow_rotate('90')"
                    @input="linkBorderRadius($event)"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="container-border"
                id="container-border"
                v-else
                style="display: flex; gap: 5px; align-items: center"
              >
                <div class="borderx">
                  <img
                    src="@/assets/icons/quadrado.svg"
                    alt="quadrado"
                    :style="`transform:rotate(${shadow_border}deg); transition:all .3s`"
                  />
                </div>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    id="email"
                    v-model="editaItem.propsRow.border_radius_top"
                    @click="shadow_rotate('-180')"
                    @input="borderRadius(editaItem.propsRow)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_right"
                    @click="shadow_rotate('-90')"
                    @input="borderRadius(editaItem.propsRow)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_bottom"
                    @click="shadow_rotate('0')"
                    @input="borderRadius(editaItem.propsRow)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    type="number"
                    v-model="editaItem.propsRow.border_radius_left"
                    @click="shadow_rotate('90')"
                    @input="borderRadius(editaItem.propsRow)"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>

          <!-- Sombra -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Sombras</label>
            <div>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsRow.border_shadow_color"
                @input="borderShadowColor($event)"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <div class="space"></div>
            <label class="sub" for="container-sombra">Sombra</label>
            <div
              class="container-border"
              id="container-sombra"
              style="display: flex; gap: 5px; align-items: center"
            >
              <b-form-group class="form_shadow">
                <b-form-input
                  type="number"
                  id="shadow_column"
                  v-model="editaItem.propsRow.border_shadow_x"
                  @input="borderShadow()"
                ></b-form-input>
                <p>X</p>
              </b-form-group>
              <b-form-group class="form_shadow">
                <b-form-input
                  id="shadow_column"
                  type="number"
                  v-model="editaItem.propsRow.border_shadow_y"
                  @input="borderShadow()"
                ></b-form-input>
                <p>Y</p>
              </b-form-group>
              <b-form-group class="form_shadow">
                <b-form-input
                  id="shadow_column"
                  type="number"
                  v-model="editaItem.propsRow.border_shadow_blur"
                  @input="borderShadow()"
                ></b-form-input>
                <p>Borrão</p>
              </b-form-group>
              <b-form-group class="form_shadow">
                <b-form-input
                  id="shadow_column"
                  type="number"
                  v-model="editaItem.propsRow.border_shadow_spread"
                  @input="borderShadow()"
                ></b-form-input>
                <p>Spread</p>
              </b-form-group>
            </div>
            <div class="space"></div>
            <div class="space"></div>
          </div>
        </b-tab>

        <b-tab title="Avançado">
          <!-- Componente de Espaçamento -->
          <div class="separador"></div>
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="editaItem.propsRow.spacing[typeView].margin_left"
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                      :disabled="editaItem.propsRow.width_type == 'Fixa'"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsRow.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsRow.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsRow.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsRow.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsRow.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsRow.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsRow.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                      :disabled="editaItem.propsRow.width_type == 'Fixa'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Visilibilidade -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="display: flex !important" class="sub">
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsRow.monitor"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="display: flex !important" class="sub">
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsRow.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="display: flex !important" class="sub">
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsRow.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <!-- Classe e ID -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub">
              <div style="display: flex; gap: 5px">
                IDﾠ<img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
                /></div
            ></label>
            <input
              v-model="editaItem.id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub">
              <div style="display: flex; gap: 5px">
                Classeﾠ<img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
                /></div
            ></label>
            <input
              v-model="editaItem.propsRow.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import Upload from "../Upload.vue";
import _ from "lodash";
export default {
  components: {
    LvColorpicker: LvColorpicker,
    Multiselect,
    Upload,
  },
  props: ["editaItem", "typeView", "palette"],
  data() {
    return {
        propsRow: {
          backgd_und: 'Cor sólida',
          backgd_solid: "" 
        },
      und_height: 1,
      rotate_border: 0,
      shadow_border: 0,
      linkBorderHeight: false,
      linkBorderRaio: false,
      linkMargin: true,
      linkPadding: true,
      global: false,
    };
  },
  methods: {
    updateOverlap: _.debounce(function (value) {
      this.$emit("updateOverlap")
    }, 300),
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    setImg(img) {
      var props = this.editaItem.propsRow;
      props.backgd_image = img;
      props.backgd = `
        background-image:url(${img});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsRow.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
      props.desktop.padding = `
      padding:${props.desktop.padding_top}px ${props.desktop.padding_right}px ${props.desktop.padding_bottom}px ${props.desktop.padding_left}px`;
      props.tablet.padding = `
      padding:${props.tablet.padding_top}px ${props.tablet.padding_right}px ${props.tablet.padding_bottom}px ${props.tablet.padding_left}px`;
      props.mobile.padding = `
      padding:${props.mobile.padding_top}px ${props.mobile.padding_right}px ${props.mobile.padding_bottom}px ${props.mobile.padding_left}px`;
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsRow.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
      props.desktop.margin = `
      margin:${props.desktop.margin_top}px ${props.desktop.margin_right}px ${props.desktop.margin_bottom}px ${props.desktop.margin_left}px`;
      props.tablet.margin = `
      margin:${props.tablet.margin_top}px ${props.tablet.margin_right}px ${props.tablet.margin_bottom}px ${props.tablet.margin_left}px`;
      props.mobile.margin = `
      margin:${props.mobile.margin_top}px ${props.mobile.margin_right}px ${props.mobile.margin_bottom}px ${props.mobile.margin_left}px`;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    linkBorderRadius(px) {
      var props = this.editaItem.propsRow;
      const {
        border_shadow_color,
        border_shadow_x,
        border_shadow_y,
        border_shadow_blur,
        border_shadow_spread,
      } = props;
      props.border_radius_top = px;
      props.border_radius_right = px;
      props.border_radius_bottom = px;
      props.border_radius_left = px;

      props.border = `
       border: ${props.border_px_top}px ${props.border_type} ${props.border_color}
                ${props.border_px_right}px ${props.border_type} ${props.border_color}
                ${props.border_px_bottom}px ${props.border_type} ${props.border_color}
                ${props.border_px_left}px ${props.border_type} ${props.border_color};
    border-radius:${px}px ${px}px ${px}px ${px}px;
    box-shadow:${border_shadow_color} ${border_shadow_x}px ${border_shadow_y}px ${border_shadow_blur}px ${border_shadow_spread}px;`;
    },
    borderPxLink(px) {
      var props = this.editaItem.propsRow;
      const {
        border_radius_top,
        border_radius_right,
        border_radius_bottom,
        border_radius_left,
        border_shadow_color,
        border_shadow_x,
        border_shadow_y,
        border_shadow_blur,
        border_shadow_spread,
      } = props;
      props.border_px_top = px;
      props.border_px_right = px;
      props.border_px_bottom = px;
      props.border_px_left = px;

      props.border = `
      border: ${props.border_px_top}px ${props.border_type} ${props.border_color}
                ${props.border_px_right}px ${props.border_type} ${props.border_color}
                ${props.border_px_bottom}px ${props.border_type} ${props.border_color}
                ${props.border_px_left}px ${props.border_type} ${props.border_color};
    border-radius:${border_radius_top}px ${border_radius_right}px ${border_radius_bottom}px ${border_radius_left}px;
    box-shadow:${border_shadow_color} ${border_shadow_x}px ${border_shadow_y}px ${border_shadow_blur}px ${border_shadow_spread}px;`;
    },
    borderShadowColor(cor) {
      var props = this.editaItem.propsRow;
      props.border_shadow_color = cor;
      props.border = `
        border: ${props.border_px_top}px ${props.border_type} ${props.border_color}
                ${props.border_px_right}px ${props.border_type} ${props.border_color}
                ${props.border_px_bottom}px ${props.border_type} ${props.border_color}
                ${props.border_px_left}px ${props.border_type} ${props.border_color};
        border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
        box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
  `;
    },
    borderShadow() {
      var props = this.editaItem.propsRow;

      const borderProps = {
        top: `${props.border_px_top}px ${props.border_type} ${props.border_color}`,
        right: `${props.border_px_right}px ${props.border_type} ${props.border_color}`,
        bottom: `${props.border_px_bottom}px ${props.border_type} ${props.border_color}`,
        left: `${props.border_px_left}px ${props.border_type} ${props.border_color}`,
        radius: `${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px`,
      };

      const shadowProps = {
        color: props.border_shadow_color,
        x: `${props.border_shadow_x}px`,
        y: `${props.border_shadow_y}px`,
        blur: `${props.border_shadow_blur}px`,
        spread: `${props.border_shadow_spread}px`,
      };

      props.border = `
        border-top: ${borderProps.top};
        border-right: ${borderProps.right};
        border-bottom: ${borderProps.bottom};
        border-left: ${borderProps.left};
        border-radius: ${borderProps.radius};
        box-shadow: ${shadowProps.color} ${shadowProps.x} ${shadowProps.y} ${shadowProps.blur} ${shadowProps.spread};
  `;
    },
    borderRadius() {
      var props = this.editaItem.propsRow;
      props.border = `
          border-top: ${props.border_px_top}px ${props.border_type} ${props.border_color};
          border-right: ${props.border_px_right}px ${props.border_type} ${props.border_color};
          border-bottom: ${props.border_px_bottom}px ${props.border_type} ${props.border_color};
          border-left: ${props.border_px_left}px ${props.border_type} ${props.border_color};
          border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
          box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
        `;
    },
    borderPx() {
      var props = this.editaItem.propsRow;
      props.border = `
          border-top: ${props.border_px_top}px ${props.border_type} ${props.border_color};
          border-right: ${props.border_px_right}px ${props.border_type} ${props.border_color};
          border-bottom: ${props.border_px_bottom}px ${props.border_type} ${props.border_color};
          border-left: ${props.border_px_left}px ${props.border_type} ${props.border_color};
          border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
          box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
        `;
    },
    borderColor(cor) {
      var props = this.editaItem.propsRow;
      const {
        border_px_top,
        border_px_right,
        border_px_bottom,
        border_px_left,
        border_type,
        border_radius_top,
        border_radius_right,
        border_radius_bottom,
        border_radius_left,
        border_shadow_color,
        border_shadow_x,
        border_shadow_y,
        border_shadow_blur,
        border_shadow_spread,
      } = props;
      const border = `
          border-top: ${border_px_top}px ${border_type} ${cor};
          border-right: ${border_px_right}px ${border_type} ${cor};
          border-bottom: ${border_px_bottom}px ${border_type} ${cor};
          border-left: ${border_px_left}px ${border_type} ${cor};
          border-radius:${border_radius_top}px ${border_radius_right}px ${border_radius_bottom}px ${border_radius_left}px;
          box-shadow:${border_shadow_color} ${border_shadow_x}px ${border_shadow_y}px ${border_shadow_blur}px ${border_shadow_spread}px
      `;
      props.border = border;
    },

    borderAlter() {
      var props = this.editaItem.propsRow;
      const bUnd = props.border_und;
      let border_type = "";
      let border_style = "";

      switch (bUnd) {
        case "Nenhuma":
          props.border = "border:none";
          break;
        case "Sólido":
          border_type = "solid";
          break;
        case "Tracejadas":
          border_type = "dashed";
          break;
        case "Pontilhado":
          border_type = "dotted";
          break;
        case "Dobro":
          border_type = "double";
          break;
        case "Groove":
          border_type = "groove";
          break;
        case "Ridge":
          border_type = "ridge";
          break;
        case "Inset":
          border_type = "inset";
          break;
        case "Outset":
          border_type = "outset";
          break;
        default:
          break;
      }

      if (bUnd !== "Nenhuma") {
        this.editaItem.propsRow.default_border = false;
      } else {
        this.editaItem.propsRow.default_border = true;
      }

      if (border_type) {
        props.border_type = border_type;
        props.border = `
      border-top: ${props.border_px_top}px ${border_type} ${props.border_color} ;
      border-right: ${props.border_px_right}px ${border_type} ${props.border_color} ;
      border-bottom: ${props.border_px_bottom}px ${border_type} ${props.border_color} ;
      border-left: ${props.border_px_left}px ${border_type} ${props.border_color} ;
      border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px ;
      box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
    `;
      }

      if (border_style) {
        props.border_style = border_style;
        props.border = `
      border-top-style: ${border_style};
      border-right-style: ${border_style};
      border-bottom-style: ${border_style};
      border-left-style: ${border_style};
      border-top-color: ${props.border_color};
      border-right-color: ${props.border_color};
      border-bottom-color: ${props.border_color};
      border-left-color: ${props.border_color};
      border-top-width: ${props.border_px_top}px;
      border-right-width: ${props.border_px_right}px;
      border-bottom-width: ${props.border_px_bottom}px;
      border-left-width: ${props.border_px_left}px;
      border-radius:${props.border_radius_top}px ${props.border_radius_right}px ${props.border_radius_bottom}px ${props.border_radius_left}px;
      box-shadow:${props.border_shadow_color} ${props.border_shadow_x}px ${props.border_shadow_y}px ${props.border_shadow_blur}px ${props.border_shadow_spread}px
    `;
      }
    },
    imageEscaca(escaca) {
      const props = this.editaItem.propsRow;
      const escacaValues = {
        Nenhuma: "auto",
        Ajustar: "contain",
        Preencher: "cover",
      };
      props.backgd_escaca_type = escacaValues[escaca] || "auto";
      props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
      `;
    },
    imageAnexacao(anexacao) {
      var props = this.editaItem.propsRow;
      if (anexacao === "Deslizar") {
        props.backgd_anexacao = "scroll";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
        `;
      } else {
        props.backgd_anexacao = "fixed";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
        `;
      }
    },
    imagePosition(position) {
      const props = this.editaItem.propsRow;
      const positionTypes = {
        "Topo à esquerda": "left top",
        "Meio à esquerda": "left center",
        "Base à esquerda": "left bottom",
        "Topo à direita": "right top",
        "Meio à direita": "right center",
        "Base à direita": "right bottom",
        "Topo ao centro": "center top",
        Centralizado: "center center",
        "Base ao centro": "center bottom",
      };

      props.backgd_image_position_type =
        positionTypes[position] || "center center";
      props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
      `;
    },
    imageRepeat(repeat) {
      var props = this.editaItem.propsRow;
      if (repeat === "Nenhuma") {
        props.backgd_image_repeat_type = "no-repeat";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
      } else if (repeat === "Ladrilhos") {
        props.backgd_image_repeat_type = "repeat";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
      } else if (repeat === "Horizontal") {
        props.backgd_image_repeat_type = "repeat-x";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
      } else if (repeat === "Vertical") {
        props.backgd_image_repeat_type = "repeat-y";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
      } else {
        props.backgd_image_repeat_type = "no-repeat";
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-repeat:${props.backgd_image_repeat_type};
        background-size: ${props.backgd_escaca_type};
        background-position:${props.backgd_image_position_type}
          `;
      }
    },

    backgroundImage(url) {
      var props = this.editaItem.propsRow;
      props.backgd_image = url;
      props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-size: ${props.backgd_escaca_type};
        background-repeat:${props.backgd_image_repeat_type};
        background-position:${props.backgd_image_position_type}
        `;
    },
    backgroundGradient() {
      var props = this.editaItem.propsRow;
      var gradient = `${props.backgd_gradient_type}(${props.backgd_gradient_deg}deg, ${props.backgd_gradient_c1}, ${props.backgd_gradient_c2})`;
      props.backgd = `background:${gradient}`;
    },
    backgroundSolid(cor) {
      var props = this.editaItem.propsRow;
      props.backgd = `
       background:${cor}
      `;
    },
    trocarUndBackground() {
      var props = this.editaItem.propsRow;
      if (props.backgd_und === "Nenhum") {
        props.backgd = "background:#FFF";
      } else if (props.backgd_und === "Cor sólida") {
        props.backgd = `background:${props.backgd_solid}`;
      } else if (props.backgd_und === "Gradiente") {
        props.backgd = `background:${props.backgd_gradient_type}(${props.backgd_gradient_deg}deg, ${props.backgd_gradient_c1}, ${props.backgd_gradient_c2})`;
      } else if (props.backgd_und === "Imagem") {
        props.backgd = `
        background-image:url(${props.backgd_image});
        background-attachment: ${props.backgd_anexacao};
        background-size: ${props.backgd_escaca_type};
        background-repeat:${props.backgd_image_repeat_type};
        background-position:${props.backgd_image_position_type}
          `;
      } else {
        props.backgd = "background:#FFF";
      }
    },
    updateWidth() {
      if (this.editaItem.propsRow.width_type == "Largura Completa") {
        this.editaItem.propsRow.width = `width: 100%`;
        return;
      }
      if (this.editaItem.propsRow.width_fixed_und == "px") {
        this.editaItem.propsRow.width = `
        width:${this.editaItem.propsRow.width_fixed}px
      `;
      } else {
        this.editaItem.propsRow.width = `
        width:${this.editaItem.propsRow.width_fixed_number}%
      `;
      }
    },
    next_und_height2() {
      var props = this.editaItem.propsRow.min_height[this.typeView];
      if (props.height_min_und == "vh") {
        props.height_min_und = "px";
      } else {
        props.height_min_und = "vh";
      }
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsRow.min_height.tablet =
          this.editaItem.propsRow.min_height.desktop;
        this.editaItem.propsRow.min_height.mobile =
          this.editaItem.propsRow.min_height.desktop;
      }
    },
    next_und_width_content() {
      var props = this.editaItem.propsRow.content;
      if (props[this.typeView].und == "%") {
        props[this.typeView].und = "px";
      } else {
        props[this.typeView].und = "%";
      }
      if (this.global && this.typeView == "desktop") {
        props.tablet.und == props.desktop.und;
        props.mobile.und == props.desktop.und;
      }
      this.updateWidth();
    },
    border_rotate(valor) {
      this.rotate_border = valor;
    },
    shadow_rotate(valor) {
      this.shadow_border = valor;
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.links-advanced {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 0rem !important;
}

.links {
  cursor: pointer;
}

// .form_shadow:nth-child(4) {
//   p {
//     left: -15px;
//   }
// }

// .form_shadow:nth-child(3) {
//   p {
//     left: -10px;
//   }
// }

.form_shadow {
  position: relative;

  p {
    position: absolute;
    color: #747474;
    font-size: 12px;
    top: 45px;
    left: 5px;
  }
}

.und_form {
  position: relative;

  .und {
    position: absolute;
    top: 12px !important;
    right: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
}

.label-help {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  label {
    margin: 0 !important;
  }
}

#SideRightTabs {
  width: 100%;
  padding-left: 15px;

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: var(--greenn) !important;
  }

  a {
    color: var(--greenn) !important;
  }
}
</style>
