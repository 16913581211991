<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Editor" @click="editMode = false">
          <div class="separador"></div>
          <div id="app" class="text-component">
            <!-- Controlador do texto -->
            <div class="content">
              <div class="content-links">
                <div class="half">
                  <label class="titulo">Controle do texto</label>
                  <div class="contain-buttons" style="margin-top: -15px">
                    <button class="btn button-add-produto" @click="addtext()"></button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="separador"></div>
              <div v-for="(item, idx) in editaItem.propsData.text" :key="idx">
                <div class="p-2" style="position: relative; ;">
                  <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                  <label class="subtitulo component-group-title">Texto {{ idx + 1 }}</label>
                  <div style="display: flex; justify-content: space-between;">
                    <div class="select" >
                      <input type="text"  v-model="editaItem.propsData.text[idx]">
                    </div>
                    <div class="image-control">
                    <div>
                      <img
                        :class="{ 'disabled-arrow' : idx == 0}"
                        class="first-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx == 0 ? '' : moveUp(idx)"
                        title="Mover para cima"
                        style="margin-top: 0px;"
                      />
                      <button
                        class="button-remove-produto"
                        @click="removetextIndex(idx)"
                      ></button>
                      <img
                        :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.text.length}"
                        class="last-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx + 1 == editaItem.propsData.text.length ? '' : moveDown(idx)"
                        title="Mover para baixo"
                        style=""
                      />
                    </div>
                  </div>


                  </div>

                </div>
              </div>
            </div>

          </div>

        </b-tab>

        <b-tab title="Estilo" v-if="editaItem.propsData.styles.hasOwnProperty('line_height')">
          <!--Comportamento do texto-->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Comportamento</label>
            <multiselect v-model="editaItem.propsData.textloop.loop"
                :options="['Ficar Rodando', 'Parar na última frase',]" :searchable="false" :show-labels="false" placeholder=""
                :hide-selected="true" :allow-empty="false">
                  
                </multiselect>
                <div class="space"></div>
                <label class="subtitulo"> Cor da barra </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.hover_bar" label="Cor da barra"
              :colors="palette"></LvColorpicker>
                <div>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Tempo entre as frases</label>
                <input type="number" @input="debouncetypeSpeed()" v-model="texttype">
              </div>
              <b-form-input v-model="editaItem.propsData.textloop.typingSpeed" type="range" min="100" max="500"
                step="50"></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
          <div class="content">
            <!-- Cores -->
            <label class="titulo"> Cores </label>
            <label class="subtitulo"> Cor do texto </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.color_text" label="Cor do texto"
              :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo"> Fundo </label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.background_color_text"
              label="Cor do texto" :colors="palette"></LvColorpicker>
          </div>

          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Tipografia</label>
            <div class="label-icon">
              <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho</label>
              <div>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>
            </div>
            <div class="mt-2" style="
                display: flex;
                gap: 10px;
                width: 100%;
                gap: 10px;
                margin-bottom: -1rem;
              ">
              <b-button-group>
                <b-button :disabled="editaItem.propsData.styles[typeView].size == 0
                  " size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="editaItem.propsData.styles[typeView].size--, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"><img
                    src="@/assets/editor/minus3.svg" alt="align" /></b-button>
                <b-form-input @input="globalInput($event, 'styles', 'size')" class="text-right"
                  v-model="editaItem.propsData.styles[typeView].size" style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                  "></b-form-input>
                <b-button
                  @click="editaItem.propsData.styles[typeView].size++, globalInput(editaItem.propsData.styles[typeView].size, 'styles', 'size')"
                  size="sm" style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
              </b-button-group>

              <div>
                <multiselect v-model="editaItem.propsData.styles[typeView].font_measure"
                  :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                  :hide-selected="true" :allow-empty="false"
                  @input="globalInput(editaItem.propsData.styles[typeView].font_measure, 'styles', 'font_measure')">
                </multiselect>
              </div>
            </div>
            <div class="space"></div>
            <div class="space"></div>

            <!-- Fonte e cor -->
            <label class="subtitulo">Fonte</label>

            <div class="max-multi">
              
              <multiselect 
                v-model="editaItem.propsData.styles.font_family" 
                :options="fontOptions" 
                :searchable="true"
                :show-labels="false" 
                placeholder="Fonte"
                @input="$emit('callCharge',$event)"  
                :hide-selected="true" 
                :allow-empty="false">
              </multiselect>
            </div>

            <div class="space"></div>

            <!-- Alinhamento -->
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0px 0 !important;">Alinhamento</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <b-button-group size="sm" style="margin-top: 5px">
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'left', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-left.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'center', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-center.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'right', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-right.svg" /></b-button>
                <b-button
                  @click="editaItem.propsData.styles[typeView].align = 'justify', globalInput(editaItem.propsData.styles[typeView].align, 'styles', 'align')"><img
                    src="@/assets/editor/align-justify.svg" /></b-button>
              </b-button-group>
            </div>
            <!-- Posicionamento -->
            <div class="space"></div>
            <label class="subtitulo">Posição do bloco de texto</label>
            <div style="display: flex; width: 100%;">
              <div style="display: flex; width: 50%; transition: all 0.3s"
                v-if="editaItem.propsData.styles.hasOwnProperty('justify_vertical')">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'start' }"
                    @click="globalJustify('start', true)"><img src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'center' }"
                    @click="globalJustify('center', true)"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.justify_vertical[typeView] == 'end' }"
                    style="z-index: 2" @click="globalJustify('end', true)"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
            <!-- Espessura -->
            <div>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte</label>
                <input type="number" v-model="editaItem.propsData.styles.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

            <!-- Espaçamento entre letras -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espaço entre letras</label>
                <input type="number" v-model="editaItem.propsData.styles.letter_space" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.letter_space" type="range" min="-15" max="15"
                step="0.1"></b-form-input>
            </div>

            <!-- Altura da linha -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Altura da linha</label>
                <input type="number" v-model="editaItem.propsData.styles.line_height" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.line_height" type="range" min="0" max="5"
                step="0.1"></b-form-input>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Largura máxima -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <div class="label-range">
              <label class="subtitulo">Largura máxima</label>
              <input type="number" v-model="editaItem.propsData.styles.max_width[typeView]"
                @input="globalMeasure($event)" />
            </div>
            <b-form-input id="range-1" v-model="editaItem.propsData.styles.max_width[typeView]" type="range" min="10"
              max="2000" @input="globalMeasure($event)"></b-form-input>

            <div>
              <label for="form-altura" class="sub">Altura mínima</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input id="form-altura" v-model="editaItem.propsColumn.height[typeView].height" type="number"
                    min="0"></b-form-input>
                  <span @click="next_und_height" class="und text" v-b-tooltip.hover title="Altura em pixel">{{
                    editaItem.propsColumn.height[typeView].height_und }}</span>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'" @click="linkMargin = !linkMargin" />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_left
                      " @input="attrLinkMargin($event, 'left')" min="0" max="100" />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_top
                          " @input="attrLinkMargin($event, 'top')" min="0" max="100" />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'" @click="linkPadding = !linkPadding" />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_left
                                " @input="paddingLink($event, 'left')" min="0" max="100" />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                    .padding_top
                                    " @input="paddingLink($event, 'top')" min="0" max="100" />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                  .padding_bottom
                                  " @input="paddingLink($event, 'bottom')" min="0" max="100" />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_right
                                " @input="paddingLink($event, 'right')" min="0" max="100" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_bottom
                          " @input="attrLinkMargin($event, 'bottom')" min="0" max="100" />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_right
                      " @input="attrLinkMargin($event, 'right')" min="0" max="100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visilibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important">
                  Dispositivos grandesﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor" @change="$emit('fixWidth')" name="is_main" size="lg"
                  switch>
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important">
                  Dispositivos médiosﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet" name="is_main" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important">
                  Dispositivos pequenosﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone" name="is_main" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Nenhuma'
                  }" @click="editaItem.propsColumn
  .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Gradual Increase'
                  }"
                    @click="editaItem.propsColumn
                      .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Fade Up'
                  }" @click="editaItem.propsColumn
  .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                    <div class="simulated-item simu3">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Fade Right'
                  }" @click="editaItem.propsColumn
  .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Fade Down'
                  }" @click="editaItem.propsColumn
  .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item" :class="{
                    'selected-animation': editaItem.propsColumn
                      .attribute_und == 'Fade Left'
                  }" @click="editaItem.propsColumn
  .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo"
                      :class="{ 'labelOff': editaItem.propsColumn.attribute_und == 'Nenhuma' }">Atraso</label>
                    <input type="number" :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      v-model="editaItem.propsColumn.delay" />
                  </div>
                  <b-form-input v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'" type="range" min="0" max="10"
                    step="1"></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo"
                      :class="{ 'labelOff': editaItem.propsColumn.attribute_und == 'Nenhuma' }">Duração</label>
                    <input :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'" type="number"
                      v-model="editaItem.propsColumn.duration" />
                  </div>
                  <b-form-input v-model="editaItem.propsColumn.duration" type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'" min="0" max="10" step="1"></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub">IDﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID" /></label>
            <input v-model="editaItem.propsData.custom.c_id" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: id_unico" />
            <label class="sub">Classeﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes." /></label>
            <input v-model="editaItem.propsData.custom.c_class" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: classe_1 classe_2" />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import _ from "lodash";



export default {
  props: ["editaItem", "openSideRight", "fontOptions", "typeView", "divWidth", "palette"],
  components: {
    Multiselect,
    LvInput,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      texttype:"",
      expandCkButton: false,
      isBlack: false,
      editMode: false,
      linkPadding: true,
      linkMargin: true,
      content: true,
      global: false,
      editorConfig: {
        uiColor: "#eeeeee",
        allowedContent: true,
        toolbar: [
          ["Maximize"],
          [
            "Bold",
            "Italic",
            "Strike",
          ],
          ["TextColor", "BGColor"],
          ["Source"],
          ["FontSize"],
          ["Undo", "Redo"],
        ],
        extraPlugins:
          "justify, colorbutton, colordialog, font, lineutils, richcombo, uicolor,divarea",
        removePlugins:
          "about, blockquote, image, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
      },
    };
  },
  mounted(){
    this.texttype = this.editaItem.propsData.text
  },
  computed: {
    dynamicPosition() {
      if (this.divWidth > 265) {
        return `top: 75px`;
      } else if (this.divWidth > 290) {
        return `top: 108px`;
      } else {
        return `top: 140px`;
      }
    },
  },
  watch: {
    editaItem: {
      handler: function (newValue) {
        this.debounceSetProps();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    updatatext(idx){
      this.editaItem.propsData.text.updata(idx)
    },

    updataSpeedtype(value){
      this.editaItem.propsData.textloop.typingSpeed.updata(value)
    },

    limiteText() {
      this.$bvToast.toast("Limite de textos atingido", {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    addtext() {
      if (this.editaItem.propsData.text.length < 8) {
        this.editaItem.propsData.text.push("Quero Aproveitar Agora")
      }
      if(this.editaItem.propsData.text.length == 8){
        this.limiteText()
      }
    },
    removetextIndex(index) {
      this.editaItem.propsData.text.splice(index, 1);
    },

    moveDown(idx) {
        var moving = this.editaItem.propsData.text[idx]
        this.editaItem.propsData.text.splice(idx, 1);
        this.editaItem.propsData.text.splice(idx + 1, 0, moving);
      },

      moveUp(idx) {
        var moving = this.editaItem.propsData.text[idx]
        this.editaItem.propsData.text.splice(idx, 1);
        this.editaItem.propsData.text.splice(idx - 1, 0, moving);
      },

    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    globalInput(px, place1, place2) {
      if (this.global && this.typeView == 'desktop') {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        props[place1].tablet[place2] = pixel;
        props[place1].mobile[place2] = pixel;
      }
    },

    debounceSetProps: _.debounce(function () {
      this.setProps();
    }, 200),
    setProps() {

    },

    globalJustify(value, isVertical) {
      var props = this.editaItem.propsData.styles.justify;
      if (isVertical) {
        props = this.editaItem.propsData.styles.justify_vertical;
      }
      props[this.typeView] = value;
      if (this.global && this.typeView == 'desktop') {
        props.tablet = value;
        props.mobile = value;
      }
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.styles.max_width;
        props.tablet = px;
        props.mobile = px;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    // getGoogleFonts() {
    //   minhaVariavel.valor = this.fontOptions;
    // },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
    this.setProps()
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
.text-component .cke_toolbar_last {
  padding-right: 63px !important;
}

.button-expand {
  z-index: 9999999999;
  top: 7px !important;
  right: -775px !important;
}

// .ck-button-theme {
//   border: 1px solid #ccc !important;
//   border-radius: 10px;
//   color: #000;
//   transition: all 0.3s;

//   &:hover {
//     color: #000;
//   }
// }

// .button-black {
//   background: #000000 !important;
//   color: #fff;

//   &:hover {
//     color: #fff;
//   }
// }

// .button-white {
//   background: #fff !important;
// }

#SideRightTabs {
  position: relative;
}

.ck-theme {
  position: absolute;
  top: 140px;
  right: 10px;
}

.cke_wysiwyg_div {
  padding-top: 1em !important;
}

textarea.cke_source {
  min-height: 100% !important;
  border: 0px solid #000000 !important;
}

.cke_source:hover {
  border: 0px solid #000000 !important;
}

.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}

.container-mg {
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 20px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;

  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }

  .form-group {
    margin: 0 !important;
  }

  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    border: 0.5px solid var(--greenn);

    .borderx {
      border-right: 0.5px solid var(--greenn);
    }
  }
}

.cke_wysiwyg_div * {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  text-align: inherit;
  text-shadow: inherit;
  text-transform: inherit;
}

a.cke_button_off:hover,
a.cke_button_off:focus,
a.cke_button_off:active {
  background: #e5e5e5;
  padding: 4px 6px !important;
}

.cke_toolbar_start+.cke_combo_off a.cke_combo_button:hover,
.cke_toolbar_start+.cke_combo_off a.cke_combo_button:focus,
.cke_toolbar_start+.cke_combo_off a.cke_combo_button:active {
  padding: 1px !important;
  margin-left: 0px !important;
}

.text-component .cke_reset {
  min-height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

a.cke_button_expandable.cke_button_off:hover,
a.cke_button_expandable.cke_button_off:focus,
a.cke_button_expandable.cke_button_off:active {
  padding: 4px 5px !important;
}

.cke_combo_on a.cke_combo_button,
.cke_combo_off a.cke_combo_button:hover,
.cke_combo_off a.cke_combo_button:focus,
.cke_combo_off a.cke_combo_button:active {
  background: #e5e5e5;
  padding: 1px !important;
  margin-left: 0px !important;
}

.cke_resizer {
  display: none !important;
}

a.cke_dialog_ui_button {
  border-radius: 10px !important;
  display: inline-block;
  padding: 4px 1px;
  margin: 0;
  text-align: center;
  color: #484848;
  vertical-align: middle;
  cursor: pointer;
  border: 0px solid #bcbcbc !important;
  background: #f8f8f8;
  letter-spacing: 0.3px;
  line-height: 18px;
  box-sizing: border-box;
  transition: all 0.3s !important;
}

.cke_dialog_footer_buttons a.cke_dialog_ui_button span {
  color: inherit;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 0 12px;
  font-family: montserrat;
}

a.cke_button_on {
  background: rgb(255, 255, 255);
  padding: 4px 6px !important;
}
</style>
