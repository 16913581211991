let propsRow = {
  custom: {
    c_class: "",
    c_id: "",
  },
  equalize: false,

  // Largura da linha
  width_type: "Largura Completa",
  width_fixed_und: "px",
  width_fixed: 1200,
  width_fixed_number: 100,

  // Largura do conteúdo
  width_fixed_content_type: "Fixa",
  content: {
    desktop: {
      und: "px",
      width: 1200,
      width_number: 80,
    },
    tablet: {
      und: "px",
      width: 600,
      width_number: 80,
    },
    mobile: {
      und: "px",
      width: 320,
      width_number: 80,
    },
  },

  // ALTURA
  height_und: "Altura padrão",
  height_type: "",

  // ALTURA MINIMA
  min_height: {
    desktop: {
      height_min_und: "px",
      height_min: 500,
      height_min_number: 50,
    },
    tablet: {
      height_min_und: "px",
      height_min: 500,
      height_min_number: 50,
    },
    mobile: {
      height_min_und: "px",
      height_min: 500,
      height_min_number: 50,
    },
  },
  // height_min_und: "px",
  // height_min: 500,
  // height_min_number: 50,

  // ALINHAMENTO VERTICAL
  height_align_vertical: "Centralizado",

  // BACKGROUND
  backgd_und: "Nenhum",
  backgd: "background:#0000",
  // BACKGROUND SOLID
  backgd_solid: "#ffffff",
  // BACKGROUND GRADIENT
  backgd_gradient_type: "linear-gradient",
  backgd_gradient_deg: "0",
  backgd_gradient_c1: "#FFFFFF",
  backgd_gradient_c2: "#FFFFFF",
  // BACKGROUND IMAGE
  backgd_image:
    "https://gdigital.s3.amazonaws.com/gdigital/1/background%20%281%29.webp",
  backgd_image_repeat_und: "Nenhuma",
  backgd_image_repeat_type: "no-repeat",
  backgd_image_position_und: "Centralizado",
  backgd_image_position_type: "center center",
  backgd_anexacao_und: "Deslizar",
  backgd_anexacao: "scroll",
  backgd_escaca_und: "Preencher",
  backgd_escaca_type: "cover",
  // SOBREPOSICAO DE BACKGROUND IMAGE
  backgd_overlap_und: "Nenhuma",
  backgd_overlap_cor: "#00000088",
  ///// BACKGRODUNd
  backgd_overlap_deg: "0",
  backgd_overlap_c1: "#00000088",
  backgd_overlap_c2: "#ffffff88",
  //////
  // BORDER
  border_und: "Nenhuma",
  border_type: "",
  border: "border:none",
  border_color: "#FFFFFF",
  border_px_top: 0,
  border_px_right: 0,
  border_px_bottom: 0,
  border_px_left: 0,
  // BORDER RADIUS
  border_radius_top: 0,
  border_radius_right: 0,
  border_radius_bottom: 0,
  border_radius_left: 0,
  // SHADOW
  border_shadow_color: "#FFFFFF",
  border_shadow_x: 0,
  border_shadow_y: 0,
  border_shadow_blur: 0,
  border_shadow_spread: 0,
  // ESPAÇAMENTO
  mg_top: 0,
  mg_right: 0,
  mg_bottom: 0,
  mg_left: 0,
  pd_top: 0,
  pd_right: 0,
  pd_bottom: 0,
  pd_left: 0,
  // VISIBILIDADE
  monitor: true,
  tablet: true,
  phone: true,
  default_border: true,
  spacing: {
    desktop: {
      margin: "0px",
      padding: "",
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    tablet: {
      margin: "0px",
      padding: "",
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
    mobile: {
      margin: "0px",
      padding: "",
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
    },
  },
};

export default propsRow;
