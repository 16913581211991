<template>
  <div class="components">
    <div
      class="item subitem"
      :class="{ itemActive: selected == 'paginas' }"
      @click="selected = 'paginas', showHover('paginas')"
    >
      <Tooltip
        :text="
          this.$store.getters.user.user.level == 'templater'
            ? 'Templates prontos'
            : 'Páginas prontas'
        "
      >
        <img
          src="@/assets/editor/layout.svg"
          alt="Páginas prontas"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div
      class="item subitem"
      :class="{ itemActive: selected == 'itens' }"
      @click="selected = 'itens', showHover('itens')"
    >
      <Tooltip text="Linhas">
        <img src="@/assets/editor/row.svg" alt="Itens" class="img_click" />
      </Tooltip>
    </div>

    <div
      class="item subitem"
      :class="{ itemActive: selected == 'componentes' }"
      @click="selected = 'componentes', showHover('componentes')"
    >
      <Tooltip text="Componentes">
        <img
          src="@/assets/editor/box.svg"
          alt="Componentes"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div
      class="item subitem"
      :class="{ itemActive: selected == 'config' }"
      @click="selected = 'config', showHover('config')"
    >
      <Tooltip text="Configurações">
        <img
          src="@/assets/editor/tool.svg"
          alt="configurações"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div
      class="item subitem"
      :class="{ itemActive: selected == 'palette' }"
      @click="selected = 'palette', showHover('palette')"
    >
      <Tooltip text="Paleta de cores">
        <img
          style="width: 30px"
          src="@/assets/editor/palette.svg"
          alt="paleta de cores"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div
      class="item subitem"
      @click="enterFullscreen"
      v-if="fullScreen === false"
    >
      <Tooltip text="Abrir tela cheia">
        <img
          style="width: 30px"
          src="@/assets/editor/maximize.svg"
          alt="tela cheia"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div class="item subitem" @click="exitFullscreen" v-else>
      <Tooltip text="Fechar tela cheia">
        <img
          style="width: 30px"
          alt="fechar tela cheia"
          src="@/assets/editor/minimize.svg"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div class="item subitem" @click="moreAndLess()" v-if="showMore">
      <Tooltip text="Menos opções">
        <img
          :src="require(`@/assets/editor/${svgFont}.svg`)"
          class="img_click"
        />
      </Tooltip>
    </div>

    <div class="item subitem" @click="moreAndLess()" v-else>
      <Tooltip text="Mais opções">
        <img
          :src="require(`@/assets/editor/${svgFont}.svg`)"
          class="img_click"
        />
      </Tooltip>
    </div>

    <transition name="subitem" v-if="showMore">
      <div class="valid-hover">
        <div
          class="item subitem"
          :class="{ itemActive: selected == 'duplicar' }"
          @click="
            (count = 1),
              templater
                ? cloneTemplate($route.params.page_id)
                : clonePage($route.params.page_id)
          "
        >
          <Tooltip :text="templater ? 'Duplicar template' : 'Duplicar página'">
            <img
              src="@/assets/editor/copy.svg"
              alt="copiar página"
              class="img_click"
            />
          </Tooltip>
        </div>
        <div
          v-if="this.$store.getters.user.user.level != 'templater'"
          class="item subitem"
          :class="{ itemActive: selected == 'codigo' }"
          @click="selected = 'codigo', showHover('codigo')"
        >
          <Tooltip text="JavaScript e CSS">
            <img
              src="@/assets/editor/code2.svg"
              alt="Adicionar código"
              class="img_click"
            />
          </Tooltip>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

import Vue from "vue";

import Tooltip from "./Tooltip.vue";

import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();

import TemplateService from "@/services/resources/TemplateService";
const serviceTemplate = TemplateService.build();
export default {
  props: ["hoverSideLeft", "item_selecionado"],
  components: {
    Tooltip,
  },
  data() {
    return {
      fullScreen: false,
      showMore: false,
      svgFont: "plus2",
      title: "Mais opções",
      active: "",
      selected: "componentes",
      templater: false,
      count: 0,
    };
  },
  watch: {
    hoverSideLeft() {
      this.selected = "null";
    },
    selected() {
      if (this.selected != "null") {
        this.$emit("itemSelected", this.selected);
      }
    },
  },
  methods: {
    enterFullscreen() {
      this.fullScreen = true;
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },

    exitFullscreen() {
      this.fullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    confirmAndRedirect() {
      Vue.swal({
        title: "Editor",
        text: `Deseja sair do editor?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // EventBus.$emit("setStyleCss");
          this.$router.push("/dynamicRoute/home").then(() => {
            location.reload();
          });
        }
      });
    },
    showHover(id) {
      const idToSelectedMap = {
        paginas: "paginas",
        itens: "itens",
        componentes: "componentes",
        config: "config",
        ia: "ia",
        historico: "historico",
        codigo: "codigo",
        palette: "palette",
        duplicar: () => {
          if (this.$route.params.page_id) {
            return "duplicar";
          } else {
            this.$bvToast.toast("Por favor, salve sua página primeiro", {
              title: "Editor",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return null;
          }
        },
      };
      const selected = idToSelectedMap[id];

      if (typeof selected === "function") {
        const result = selected();
        if (result !== null) {
          this.selected = result;
        }
      } else if (selected !== undefined) {
        this.selected = selected;
      }
    },
    moreAndLess() {
      if (this.showMore) {
        this.showMore = !this.showMore;
        this.svgFont = "plus2";
        this.title = "Mais opções";

        return;
      } else {
        this.showMore = !this.showMore;
        this.svgFont = "minus2";
        this.title = "Menos opções";
        return;
      }
    },
    clonePage(id) {
      if (id === undefined) {
        this.$bvToast.toast("Por favor, salve a sua página primeiro", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      Vue.swal({
        title: "Editor",
        text: `Deseja duplicar a página atual?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Duplicar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          servicePage.createId({ id: `clone/${id}` }).then((resp) => {
            this.loading = false;
            if (this.count == 1) {
              this.$bvToast.toast("Página duplicada com sucesso", {
                title: "Editor",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.count = 0;
            }
            setTimeout(() => {
              this.$router.push(`/editor/${resp.id}`);
              this.$emit("sendData", resp);
            }, 2000);
          });
        }
      });
    },
    cloneTemplate(id) {
      if (id == undefined) {
        this.$bvToast.toast("Por favor, salve o seu template primeiro", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      Vue.swal({
        title: "Editor",
        text: `Deseja duplicar o template atual?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Duplicar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceTemplate.createId({ id: `clone/${id}` }).then((resp) => {
            this.loading = false;
            if (this.count == 1) {
              this.$bvToast.toast("Template duplicado com sucesso", {
                title: "Editor",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });

              this.count = 0;
            }
            setTimeout(() => {
              // console.log(resp);
              this.$router.push(`/editor/${resp.id}`);
              this.$emit("sendData", resp);
            }, 2000);
          });
        }
      });
    },
    // keyHandler(event) {
    //   if (
    //     event.target.closest("Upload-Editor___BV_modal_content_") ||
    //     (event.target.tagName === "INPUT" && event.target.type === "text") ||
    //     event.target.tagName === "TEXTAREA" ||
    //     event.target.tagName === "DIV" ||
    //     event.target.closest(".quill-editor")
    //   ) {
    //     // O evento ocorreu em um campo de entrada, não faz nada
    //     return;
    //   }
    //   // O evento ocorreu na pagina
    //   if (event.key === "t" || event.keyCode === 84) {
    //     this.selected = "paginas";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "l" || event.keyCode === 76) {
    //     this.selected = "itens";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "m" || event.keyCode === 77) {
    //     this.selected = "componentes";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "u" || event.keyCode === 85) {
    //     this.selected = "uploads";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "g" || event.keyCode === 71) {
    //     this.showMore = true;
    //     this.svgFont = "plus2";
    //     this.selected = "ia";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "c" || event.keyCode === 67) {
    //     this.showMore = true;
    //     this.svgFont = "plus2";
    //     this.selected = "config";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "h" || event.keyCode === 72) {
    //     this.showMore = true;
    //     this.svgFont = "plus2";
    //     this.selected = "historico";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "d" || event.keyCode === 68) {
    //     if (this.$store.getters.user.user.level === "templater") {
    //       this.cloneTemplate(this.$route.params.page_id);
    //       return;
    //     }
    //     this.clonePage(this.$route.params.page_id);
    //     this.count += 1;

    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "j" || event.keyCode === 74) {
    //     this.showMore = true;
    //     this.svgFont = "plus2";
    //     this.selected = "codigo";
    //     this.$emit("itemSelected", this.selected);
    //   } else if (event.key === "+" || event.keyCode === 107) {
    //     this.showMore = true;
    //     this.svgFont = "plus2";
    //   } else if (event.key === "-" || event.keyCode === 109) {
    //     this.showMore = false;
    //     this.svgFont = "minus2";
    //   }
    // },
  },
  mounted() {
    document.addEventListener("keydown", this.keyHandler);
    if (this.$store.getters.user.user.level === "templater") {
      this.templater = true;
    }
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.keyHandler);
  },
};
</script>

<style lang="scss" scoped>
* {
  user-select: none;
  -webkit-user-drag: none;
}
.home img {
  cursor: pointer;
  width: 40px;
  margin-bottom: 22px;
}
.components {
  border-right: 1px solid #ededf0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98px;
  height: 100%;
  background-color: white;
  z-index: 9;
    position: relative;
  .item {
    color: #00a070;
    margin-top: 5vh;
    cursor: pointer;
    user-select: none;
  }
  .item:hover img:hover {
    color: var(--greenn) !important;
  }

  .item img {
    filter: invert(50%) brightness(120%);
    transition: all 0.3s ease;
  }
  .item:hover img {
    filter: invert(50%) brightness(0%);
  }
}

.itemActive > * > img {
  filter: invert(0%) !important;
}

.subitem {
  animation-name: example;
  animation-duration: 0.2s;
  user-select: none;
}

@keyframes example {
  0% {
    margin-left: -160px;
  }
  100% {
    margin-left: 0;
  }
}

.subitem-enter-active {
  animation: example 0.5s;
  animation-timing-function: ease-out;
}
.subitem-leave-active {
  animation: example 0.5s reverse;
  animation-timing-function: ease-in;
}
</style>
