<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active>
        <div class="b-tab-content">
          <!-- Conteúdo -->
          <div class="separador" />
          <div class="content">
            <label class="titulo">Conteúdo</label>
            <label class="subtitulo">Texto</label>
            <textarea
              v-focus
              v-model="editaItem.propsData.button.content_button"
              rows="2"
              class="w-100"
            ></textarea>
            <div class="space"></div>
            <div
              class="select-image"
              v-if="editaItem.propsData.icon.svg.hasOwnProperty('name')"
            >
              <div @click="openModal('Modal-Icons-svg')">
                <span
                  class="material-icons icon-preview"
                  v-html="editaItem.propsData.icon.svg.code"
                ></span>
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.icon.svg.name"
                />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon.svg
                      ? "Alterar thumbnail"
                      : "Selecionar thumbnail"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
            <div class="select-image" v-else>
              <div @click="openModal('Modal-Icons-svg')">
                <span class="material-icons icon-preview">{{
                  editaItem.propsData.icon.svg
                }}</span>
              </div>
              <div>
                <input type="text" v-model="editaItem.propsData.icon.svg" />
                <div class="with-trash">
                  <span @click="openModal('Modal-Icons-svg')">{{
                    editaItem.propsData.icon.svg
                      ? "Alterar ícone"
                      : "Selecionar ícone"
                  }}</span>
                  <img
                    v-show="editaItem.propsData.icon.svg"
                    title="Remover thumbnail"
                    @click="editaItem.propsData.icon.svg = ''"
                    class="trash"
                    src="@/assets/editor/trash-2.svg"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Link -->
          <div class="separador"></div>
          <div
            class="content"
            v-if="editaItem.propsData.button.hasOwnProperty('type')"
          >
            <label class="titulo">Link</label>
            <label class="subtitulo">Tipo de link</label>
            <multiselect
              :options="optionsType"
              v-model="editaItem.propsData.button.type"
              :searchable="false"
              :show-labels="false"
              :hide-selected="true"
              :allow-empty="false"
              placeholder="Tipo de link"
              label="text"
              track-by="value"
            ></multiselect>
            <div class="space" />

            <!-- externo -->
            <div v-if="editaItem.propsData.button.type.value == 'externo'">
              <label class="sub">Link para redirecionar</label>
              <input
                placeholder="https://seulink"
                class="w-100"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.button.href_button"
              />
              <div class="space"></div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Redirecionar para nova guia</label>
                <b-form-checkbox
                  style="
                    margin-top: -5px;
                    margin-left: auto;
                    margin-right: 0;
                    z-index: 0;
                  "
                  v-model="editaItem.propsData.button.blank"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>

            <!-- interno -->
            <div v-if="editaItem.propsData.button.type.value == 'interno'">
              <label class="sub"
                >ID do elemento de destino
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title='Nessa opção, o usuário é levado até a posição da página onde se encontra o elemento de destino. O ID do elemento pode ser configurado nas aba "avançado" ao editar um componente ou linha.'
              /></label>
              <input
                placeholder="exemplo_de_id"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.button.href_button"
              />
            </div>

            <!-- copartilhar -->
            <div v-if="editaItem.propsData.button.type.value == 'compartilhar'">
              <label class="sub"
                >Escreva um texto curto para ser compartilhado
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Esse texto será automaticamente posto no campo de mensagens do whatsapp do usuário para que ele envie para outras pessoas"
              /></label>
              <textarea
                placeholder="Dê uma olhada nesse produto incrível!..."
                v-model="editaItem.propsData.button.share_text"
                rows="2"
                class="w-100 mb-1"
                style="
                  height: min-content !important;
                  min-height: 45px !important;
                "
              ></textarea>
            </div>

            <!-- chamar -->
            <div v-if="editaItem.propsData.button.type.value == 'chamar'">
              <label class="sub">Escreva um texto curto para ser chamado</label>
              <textarea
                placeholder="Olá, tudo bem? Gostei da oferta.."
                v-model="editaItem.propsData.button.share_text"
                rows="2"
                class="w-100 mb-1"
                style="
                  height: min-content !important;
                  min-height: 45px !important;
                "
              ></textarea>
              <label class="sub"
                >Insira o número de telefone
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Digite apenas números e inclua o 55 e o código de área. Por exemplo: 5544912345678"
              /></label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-model="editaItem.propsData.button.href_button"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              />
            </div>

            <!-- telefone -->
            <div v-if="editaItem.propsData.button.type.value == 'telefone'">
              <label class="sub">Insira o número de telefone</label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
                v-model="editaItem.propsData.button.href_button"
              />
            </div>
          </div>
          <div v-else>
            <label class="sub">Link para redirecionar</label>
            <input
              placeholder="https://seulink"
              class="w-100"
              type="text"
              name="text_1"
              id="text_1"
              v-model="editaItem.propsData.button.href_button"
            />
          </div>

          <!-- Temporarizador -->
          <!-- <div class="separador"></div>
          <div class="content">
            <div style="display: flex; width: 100%">
              <label :class="{ labelOff: temp == false }" class="titulo"
                >Temporarizador</label
              >
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="temp"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label class="sub" :class="{ labelOff: temp == false }"
              >Ação ao esgotar o tempo</label
            >
            <multiselect
              v-model="tempType"
              :options="['Desabilitar', 'Desaparecer']"
              :searchable="false"
              :show-labels="false"
              placeholder="Selecione uma das opções"
              :disabled="!temp"
            ></multiselect>
            <div class="space"></div>
            <label class="sub" :class="{ labelOff: temp == false }"
              >Tempo (em segundos)</label
            >
            <input
              class="w-100 mb-3"
              type="number"
              v-model="editaItem.propsData.button.href_button"
              :disabled="!temp"
            />
          </div> -->

          <!-- Linha -->
          <div class="separador"></div>
        </div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="b-tab-content">
          <!-- Corpo -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Fundo</label>
            <label class="sub">Tipo do fundo</label>

            <!-- Seleção do tipo de fundo -->
            <multiselect
              v-model="editaItem.propsData.styles.back_selected"
              :options="['Cor sólida', 'Gradiente']"
              :searchable="false"
              :show-labels="false"
              placeholder="Tipo do fundo"
              @click.capture="backgroundCreate()"
              @input="delayedBackgroundCreate()"
              :hide-selected="true"
              :allow-empty="false"
            ></multiselect>
            <!-- Espaço -->
            <div
              v-show="
                !editaItem.propsData.styles.back_selected ||
                editaItem.propsData.styles.back_selected == 'Imagem' ||
                editaItem.propsData.styles.back_selected == 'Cor sólida'
              "
              style="height: 1rem"
            ></div>

            <!-- Cor sólida -->
            <div
              v-show="editaItem.propsData.styles.back_selected == 'Cor sólida'"
            >
              <label class="subtitulo">Cor padrão</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                @input="backgroundCreate()"
                v-model="editaItem.propsData.styles.back_content"
                :colors="palette"
              ></LvColorpicker>
              <div
                v-if="editaItem.propsData.styles.hasOwnProperty('hover_color')"
              >
                <div class="space"></div>
                <label class="subtitulo">Cor ao passar o mouse</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.hover_color"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>

            <!-- Gradiente -->
            <div
              v-show="editaItem.propsData.styles.back_selected == 'Gradiente'"
            >
              <!-- Pickers -->
              <div class="pickers pb-2">
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.grad_color1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.grad_color2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input
                  type="number"
                  @input="backgroundCreate()"
                  v-model="editaItem.propsData.styles.grad_angle"
                />
              </div>
              <b-form-input
                @input="backgroundCreate()"
                v-model="editaItem.propsData.styles.grad_angle"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>
          </div>

          <!-- Tipografia -->
          <div class="separador"></div>
          <div class="content max-multi">
            <div class="label-icon">
              <label class="titulo">Tipografia</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <div
              class="mt-2"
              style="
                display: flex;
                gap: 10px;
                width: 100%;
                justify-content: space-between;
                margin-bottom: -1rem;
              "
            >
              <b-button-group>
                <b-button
                  :disabled="
                    editaItem.propsData.button_size[typeView].font_size == 0
                  "
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="
                    editaItem.propsData.button_size[typeView].font_size--,
                      globalInput(
                        editaItem.propsData.button_size[typeView].font_size,
                        'button_size',
                        'font_size',
                        false
                      )
                  "
                  ><img src="@/assets/editor/minus3.svg" alt="align"
                /></b-button>
                <b-form-input
                  @input="
                    globalInput($event, 'button_size', 'font_size', false)
                  "
                  class="text-right"
                  v-model="editaItem.propsData.button_size[typeView].font_size"
                  style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                  "
                ></b-form-input>
                <b-button
                  @click="
                    editaItem.propsData.button_size[typeView].font_size++,
                      globalInput(
                        editaItem.propsData.button_size[typeView].font_size,
                        'button_size',
                        'font_size',
                        false
                      )
                  "
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  ><img src="@/assets/editor/plus.svg" alt="align"
                /></b-button>
              </b-button-group>

              <div style="width: 30%">
                <multiselect
                  v-model="editaItem.propsData.button_size.textFontMeasure"
                  :options="['px', 'rem', '%', 'em']"
                  :searchable="false"
                  :show-labels="false"
                  placeholder=" "
                  :hide-selected="true"
                  :allow-empty="false"
                ></multiselect>
              </div>

              <div>
                <b-button
                  size="sm"
                  @click="trocarType"
                  style="
                    height: min-content !important;
                    padding: 10.5px 10px !important;
                  "
                  ><img src="@/assets/editor/type.svg" alt="align"
                /></b-button>
              </div>
            </div>

            <div class="space" />

            <div class="space" />
            <!-- Fonte e cor -->
            <multiselect
              v-model="editaItem.propsData.styles.font_family"
              :options="fontOptions"
              :searchable="true"
              :show-labels="false"
              placeholder="Fonte"
              :hide-selected="true"
              @input="$emit('callCharge',$event)"
              :allow-empty="false"
            ></multiselect>
            <div class="space" />
            <div
              v-if="editaItem.propsData.styles.hasOwnProperty('hover_font')"
              class="half"
            >
              <div>
                <label class="sub">Cor</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.color_button"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div>
                <label class="sub">Sob o mouse</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.hover_font"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
            <LvColorpicker
              v-else
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_button"
              :colors="palette"
            ></LvColorpicker>

            <div class="space"></div>

            <!-- Texto -->
            <textarea
              v-model="editaItem.propsData.button.content_button"
              rows="2"
              class="w-100"
            ></textarea>

            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Espessura da fonte</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.font_weight"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.font_weight"
              type="range"
              min="100"
              max="900"
              step="100"
            ></b-form-input>

            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Espaço entre letras</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.letter_space"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.letter_space"
              type="range"
              min="-15"
              max="15"
              step="0.25"
            ></b-form-input>
          </div>

          <!-- Dimensões -->
          <div class="separador"></div>
          <div class="content">
            <!-- Label dimensões -->
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>

            <!-- dimensões automáticas -->
            <div
              style="display: flex; width: 100%; transition: all 0.3s"
              v-if="editaItem.propsData.size[typeView].hasOwnProperty('isAuto')"
            >
              <label class="subtitulo"
                >Dimensões automáticas
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Dimensões automáticas respeitarão as dimensões do conteúdo do botão e suas margens internas"
              /></label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.size[typeView].isAuto"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <!-- largura  100%-->
            <div class="d-flex">
              <button class="edit-modal" @click="largeFull()">Aplicar largura completa</button>
            </div>
            <!-- Altura e largura -->
            <div class="half">
              <div>
                <label
                  class="subtitulo"
                  :class="{
                    labelOff:
                      editaItem.propsData.size[typeView].hasOwnProperty(
                        'isAuto'
                      ) && editaItem.propsData.size[typeView].isAuto == true,
                  }"
                  >Altura</label
                >
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      :disabled="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                      "
                      id="Largura-fixa"
                      v-model="editaItem.propsData.size[typeView].height"
                      type="number"
                      @input="globalInput($event, 'size', 'height', false)"
                      min="0"
                    />
                    <span
                      @click="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                          ? ''
                          : next_und('und_height')
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_height }}</span
                    >
                  </section>
                </section>
              </div>

              <div>
                <label
                  class="subtitulo"
                  :class="{
                    labelOff:
                      editaItem.propsData.size[typeView].hasOwnProperty(
                        'isAuto'
                      ) && editaItem.propsData.size[typeView].isAuto == true,
                  }"
                  >Largura</label
                >
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      :disabled="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                      "
                      id="Largura-fixa"
                      v-model="editaItem.propsData.size[typeView].width"
                      type="number"
                      @input="globalInput($event, 'size', 'width', false)"
                      min="0"
                    />
                    <span
                      @click="
                        editaItem.propsData.size[typeView].hasOwnProperty(
                          'isAuto'
                        ) && editaItem.propsData.size[typeView].isAuto == true
                          ? ''
                          : next_und('und_width')
                      "
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_width }}</span
                    >
                  </section>
                </section>
              </div>
            </div>
            <div class="space"></div>

            <!-- Posição -->
            <div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'justify')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'center',
                      }"
                      @click="globalJustify('center', 'justify')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'justify')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'start',
                      }"
                      @click="globalJustify('start', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'center',
                      }"
                      @click="globalJustify('center', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'end',
                      }"
                      @click="globalJustify('end', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
            <div>
              <label for="form-altura" class="sub"
                >Altura mínima da coluna</label
              >
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{
                      editaItem.propsColumn.height[typeView].height_und
                    }}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <!-- Borda -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Bordas</label>
            <div>
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="changeBorderLink()"
                />
              </div>
              <div class="container-mg">
                <b-form-group label-for="email">
                  <b-form-input
                    style="text-align: center ; border: 0.5px solid var(--white-medium) !important;"
                    type="number"
                    v-model="editaItem.propsData.border.border_radius_top"
                    @update="linked('border_radius_top')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_right"
                    @update="linked('border_radius_right')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_bottom"
                    @update="linked('border_radius_bottom')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-for="email">
                  <b-form-input
                    type="number"
                    style="text-align: center; border: 0.5px solid var(--white-medium) !important;"
                    v-model="editaItem.propsData.border.border_radius_left"
                    @update="linked('border_radius_left')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="space"></div>
            </div>

            <!-- Bordas visíveis e cor -->
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="subtitulo">Bordas visíveis</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="hasBorder"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div v-if="hasBorder">
              <div class="space"></div>
              <div
                v-if="editaItem.propsData.styles.hasOwnProperty('hover_border')"
                class="half"
              >
                <div>
                  <label class="sub">Cor</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="borderColor"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div>
                  <label class="sub">Sob o mouse</label>
                  <LvColorpicker
                    id="LvColorpicker"
                    v-model="editaItem.propsData.styles.hover_border"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div v-else>
                <label class="sub">Cor da borda</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="borderColor"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div class="space"></div>
              <!-- Espessura e traço -->
              <div class="label-range">
                <label class="subtitulo">Espessura</label>
                <input type="number" v-model="borderThickness" />
              </div>
              <b-form-input
                id="range-1"
                v-model="borderThickness"
                type="range"
                min="0"
                max="10"
                step="1"
              ></b-form-input>
              <div class="space"></div>
              <label class="subtitulo">Traço</label>
              <multiselect
                v-model="borderStyle"
                :options="[
                  '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                  '━━━━━━━━━━━━━━━━━━',
                  '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ',
                  '══════════════════',
                ]"
                :searchable="false"
                :show-labels="false"
                placeholder="Traço"
              ></multiselect>
            </div>
          </div>

          <!-- Sombra -->
          <div class="separador"></div>
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Sombra</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="shadow"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <!-- Cor, espessura e traço -->
            <div v-if="shadow">
              <label class="subtitulo">Cor da sombra</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="shadowColor"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <div class="three">
                <div>
                  <label class="subtitulo">Eixo X</label>
                  <input type="number" v-model="shadowX" />
                </div>
                <div>
                  <label class="subtitulo">Eixo Y</label>
                  <input type="number" v-model="shadowY" />
                </div>
                <div>
                  <label class="subtitulo">Borrão</label>
                  <input type="number" v-model="shadowBlur" />
                </div>
              </div>
            </div>
          </div>

          <div class="separador" v-if="editaItem.propsData.icon.svg"></div>
          <div class="content" v-if="editaItem.propsData.icon.svg">
            <div class="label-icon">
              <label class="titulo">Ícone</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <label class="sub">Cor do ícone</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.icon.color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>

            <div
              style="display: flex; width: 100%; transition: all 0.3s"
              v-if="editaItem.propsData.icon.hasOwnProperty('isRight')"
            >
              <label
                class="subtitulo"
                :class="{ labelOff: !editaItem.propsData.icon }"
                >Ícone à direita</label
              >
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.icon.isRight"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div
              class="space"
              v-if="editaItem.propsData.icon.hasOwnProperty('isRight')"
            ></div>

            <div class="label-range">
              <label class="subtitulo">Tamanho</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon.size[typeView].font_size"
                @input="globalInput($event, 'size', 'font_size', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon.size[typeView].font_size"
              @input="globalInput($event, 'size', 'font_size', true)"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div
              class="space"
              v-if="editaItem.propsData.button.content_button.length"
            ></div>
            <div
              class="label-range"
              v-if="editaItem.propsData.button.content_button.length"
            >
              <label class="subtitulo">Margem lateral</label>
              <input
                type="number"
                v-model="editaItem.propsData.icon.size[typeView].margin"
                @input="globalInput($event, 'size', 'margin', true)"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.icon.size[typeView].margin"
              @input="globalInput($event, 'size', 'margin', true)"
              v-if="editaItem.propsData.button.content_button.length"
              type="range"
              min="0"
              max="25"
              step="1"
            ></b-form-input>
          </div>
        </div>
        -
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <div class="separador"></div>

        <!-- Componente de Espaçamento -->
        <div class="content">
          <!-- Ícones -->
          <div class="label-icon">
            <label class="titulo">Espaçamento</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="global"
            />
            <img
              src="@/assets/editor/monitor.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('tablet')"
              v-if="typeView === 'desktop' && !global"
            />
            <img
              src="@/assets/editor/tablet.svg"
              alt="tablet"
              style="filter: invert(50%) !important"
              @click="nextIcon('mobile')"
              v-if="typeView === 'tablet' && !global"
            />
            <img
              src="@/assets/editor/phone.svg"
              alt="phone"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="typeView === 'mobile' && !global"
            />
          </div>
          <!-- Inputs -->
          <div class="container-spacing" style="padding-bottom: 15px">
            <div class="border-dash">
              <span class="txt-mg">MARGEM EXTERNA</span>
              <img
                src="@/assets/editor/link-click.svg"
                alt="Link"
                class="links-advanced"
                :class="linkMargin ? '' : 'link-disabled'"
                @click="linkMargin = !linkMargin"
              />
              <div class="alinhamento">
                <!-- MARGIN LEFT -->
                <div class="item-1">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_left"
                    @input="attrLinkMargin($event, 'left')"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="item-2">
                  <div class="alinhamento-2">
                    <!-- MARGIN TOP -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_top
                        "
                        @input="attrLinkMargin($event, 'top')"
                        min="0"
                        max="100"
                      />
                    </div>
                    <div>
                      <div class="contain-borders">
                        <span class="txt-mg">MARGEM INTERNA</span>
                        <img
                          src="@/assets/editor/link-click.svg"
                          alt="Link"
                          class="links-advanced"
                          :class="linkPadding ? '' : 'link-disabled'"
                          @click="linkPadding = !linkPadding"
                        />
                        <div class="alinhamento">
                          <!-- PADDING LEFT -->
                          <div class="item-1">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_left
                              "
                              @input="paddingLink($event, 'left')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div class="item-2">
                            <div class="alinhamento-2">
                              <!-- PADDING TOP -->
                              <div>
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_top
                                  "
                                  @input="paddingLink($event, 'top')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                              <div>
                                <div class="border-final"></div>
                              </div>
                              <!-- PADDING BOTTOM -->
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_bottom
                                "
                                @input="paddingLink($event, 'bottom')"
                                min="0"
                                max="100"
                              />
                              <div></div>
                            </div>
                          </div>
                          <!-- PADDING RIGHT -->
                          <div class="item-3">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_right
                              "
                              @input="paddingLink($event, 'right')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN BOTTOM -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_bottom
                        "
                        @input="attrLinkMargin($event, 'bottom')"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
                <!-- MARGIN RIGHT -->
                <div class="item-3">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_right"
                    @input="attrLinkMargin($event, 'right')"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Visilibilidade -->
        <div class="content">
          <label class="titulo"> Visibilidade </label>

          <!-- Dispositivos grandes -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos grandesﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Maiores que 768 pixels de largura, como laptops e computadores"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.monitor"
                name="is_main"
                @change="$emit('fixWidth')"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos médios -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos médiosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Entre 480 a 768 pixels de largura, como tablets"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.tablet"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos pequenosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Menores que 480 pixels de largura, como celulares"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.phone"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Animação -->
        <div class="content columnAnimate">
          <label class="titulo">Animação</label>
          <div class="select-animation">
            <div class="animation-container">
              <div>
                <p>Nenhum</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Nenhuma',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                      (editaItem.propsColumn.attribute_animation = '')
                  "
                >
                  <div class="simulated-item simu1"></div>
                </div>
              </div>
              <div>
                <p>Zoom</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Gradual Increase',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Gradual Increase'),
                      (editaItem.propsColumn.attribute_animation =
                        'gradual-increase')
                  "
                >
                  <div class="simulated-item simu2">
                    <img src="@/assets/editor/square-logo.svg" alt="setinha" />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Up</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Up',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Up'),
                      (editaItem.propsColumn.attribute_animation = 'top')
                  "
                >
                  <div class="simulated-item simu3">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Right</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Right',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Right'),
                      (editaItem.propsColumn.attribute_animation = 'right')
                  "
                >
                  <div class="simulated-item simu4">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Down</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Down',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Down'),
                      (editaItem.propsColumn.attribute_animation = 'bottom')
                  "
                >
                  <div class="simulated-item simu5">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Left</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Left',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Left'),
                      (editaItem.propsColumn.attribute_animation = 'left')
                  "
                >
                  <div class="simulated-item simu6">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div class="half-animation">
              <div>
                <div class="label-range">
                  <label
                    class="subtitulo"
                    :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    >Atraso</label
                  >
                  <input
                    type="number"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    v-model="editaItem.propsColumn.delay"
                    style="margin: 0 !important"
                    max="5"
                    min="0"
                    step="0.5"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.delay"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  type="range"
                  max="5"
                  min="0"
                  step="0.5"
                ></b-form-input>
              </div>
              <div>
                <div class="label-range">
                  <label
                    class="subtitulo"
                    :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    >Duração</label
                  >
                  <input
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="number"
                    v-model="editaItem.propsColumn.duration"
                    style="margin: 0 !important"
                    max="5"
                    min="0"
                    step="0.5"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.duration"
                  type="range"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  max="5"
                  min="0"
                  step="0.5"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Classe e ID -->
        <div class="content">
          <label class="titulo">Classe e ID</label>
          <label class="sub"
            >IDﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_id"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: id_unico"
          />
          <label class="sub"
            >Classeﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_class"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: classe_1 classe_2"
          />
        </div>
        <div class="separador"></div>
      </b-tab>
    </b-tabs>

    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
// import ModalIcons from "../../ModalSvg.vue";
import ModalIcons from "@/components/Editor/ModalSvg";

export default {
  props: ["editaItem", "fontOptions", "typeView", "palette"],
  components: {
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      lastColor: "",
      linkType: "Externo",
      tempType: "",
      options: [
        "Externo",
        "Na própria página",
        "Compartilhar no WhatsApp",
        "Chamar no WhatsApp",
        "Número de telefone",
        "Fechar pop-up",
        "Abrir pop-up",
      ],
      content: true,
      temp: false,
      gradientType: "linear-gradient",
      borderThickness: "",
      borderColor: "",
      borderStyle: "",
      textFont: "",
      textFontMeasure: "px",
      iconSize: "",
      iconML: "",
      shadow: false,
      letterSpace: "",
      lineSpace: "",
      shadowBlur: "",
      shadowColor: "",
      shadowX: "",
      shadowY: "",
      type: "none",
      hasBorder: "",
      borderLink: true,
      width100: false,
      lastWidth: "",
      global: false,
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
        { value: "compartilhar", text: "Compartilhar no WhatsApp" },
        { value: "chamar", text: "Chamar no WhatsApp" },
        { value: "telefone", text: "Número de telefone" },
      ],
    };
  },
  watch: {
    font_family: function (newFont, oldFont) {
      this.editaItem.propsData.styles.font_family = newFont;
    },
    width100(valor) {
      this.widthButton();
    },
    borderThickness(valor) {
      this.borderCreate();
    },
    borderColor(valor) {
      this.borderCreate();
    },
    borderStyle(valor) {
      this.borderCreate();
    },
    hasBorder(valor) {
      this.borderCreate();
    },
    shadow(valor) {
      this.shadowCreate();
    },
    shadowX(valor) {
      this.shadowCreate();
    },
    shadowY(valor) {
      this.shadowCreate();
    },
    shadowBlur(valor) {
      this.shadowCreate();
    },
    shadowColor(valor) {
      this.shadowCreate();
    },
  },
  methods: {
    largeFull(){
      this.editaItem.propsData.size[this.typeView].width =  100;
      this.editaItem.propsData.size[this.typeView].und_width = '%';
      this.editaItem.propsData.size[this.typeView].isAuto = false;
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsData.size.tablet.width =  100;
        this.editaItem.propsData.size.tablet.und_width =  '%';
        this.editaItem.propsData.size.tablet.isAuto = false;
        this.editaItem.propsData.size.mobile.width =  100;
        this.editaItem.propsData.size.mobile.und_width =  '%';
        this.editaItem.propsData.size.mobile.isAuto = false;
      }
    },
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und =
        props[this.typeView].height_und == "px"
          ? "vh"
          : props[this.typeView].height_und == "vh"
          ? "vw"
          : "px";
      if (this.global && this.typeView == "desktop") {
        props.tablet.height_und = props.desktop.height_und;
        props.mobile.height_und = props.desktop.height_und;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "%";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "%";
          props.size.mobile[place] = "%";
        }
        return;
      }
      if (props.size[this.typeView][place] == "%") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },

    globalInput(px, place1, place2, icon) {
      if (this.editaItem.propsData.size[this.typeView].und_width == '%') {
        let widthPercentage = this.editaItem.propsData.size[this.typeView].width;
        const limitedWidthPercentage = Math.min(widthPercentage, 100);
        if (widthPercentage > 100) {
          this.$bvToast.toast("A largura máxima permitida é 100%", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.editaItem.propsData.size[this.typeView].width = 100;
        }
      }
      if (this.global && this.typeView == "desktop") {
        var pixel = px.target ? px.target.value : px;
        var props = this.editaItem.propsData;
        if (icon) {
          props = this.editaItem.propsData.icon;
        }
        props[place1].tablet[place2] = pixel;
        props[place1].mobile[place2] = pixel;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    iconSelect(item) {
      this.editaItem.propsData.icon.svg = item;
    },
    delayedBackgroundCreate() {
      if (this.editaItem.propsData.styles.back_selected == "Cor sólida") {
        if (this.lastColor) {
          this.editaItem.propsData.styles.back_content = this.lastColor;
        } else {
          this.editaItem.propsData.styles.back_content =
            this.editaItem.propsData.styles.grad_color1;
        }
      } else {
        this.lastColor = this.editaItem.propsData.styles.back_content;
      }
      setTimeout(() => {
        this.backgroundCreate();
      }, 100);
    },
    backgroundCreate() {
      if (this.editaItem.propsData.styles.back_selected == "Cor sólida") {
        this.editaItem.propsData.styles.back_type = "color";
        return;
      }
      if (this.editaItem.propsData.styles.back_selected == "Gradiente") {
        this.editaItem.propsData.styles.back_type = "image";
        this.editaItem.propsData.styles.back_content = `${this.gradientType}(${this.editaItem.propsData.styles.grad_angle}deg, ${this.editaItem.propsData.styles.grad_color2}, ${this.editaItem.propsData.styles.grad_color1})`;
        return;
      }
      // if (this.editaItem.propsData.styles.back_selected == "Imagem") {
      //   this.editaItem.propsData.styles.back_type = "image";
      //   return;
      // }
    },
    borderCreate() {
      this.editaItem.propsData.border.border_style = this.borderStyle;
      this.editaItem.propsData.border.border_thickness = this.borderThickness;
      this.editaItem.propsData.border.border_color = this.borderColor;
      if (this.hasBorder == true) {
        this.editaItem.propsData.border.hasBorder = true;
        var styleName = "";
        if (this.borderStyle == "● ● ● ● ● ● ● ● ● ● ● ● ● ●") {
          styleName = "dotted";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "━━━━━━━━━━━━━━━━━━━━━") {
          styleName = "solid";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━") {
          styleName = "dashed";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
        if (this.borderStyle == "══════════════════") {
          styleName = "double";
          this.editaItem.propsData.border.border = `${this.borderThickness}px ${styleName} ${this.borderColor}`;
          return;
        }
      }
      if (this.hasBorder == false) {
        this.editaItem.propsData.border.hasBorder = false;
        this.editaItem.propsData.border.border = "none";
      }
    },
    shadowCreate() {
      this.editaItem.propsData.shadow.hasShadow = this.shadow;
      this.editaItem.propsData.shadow.shadow_x = this.shadowX;
      this.editaItem.propsData.shadow.shadow_y = this.shadowY;
      this.editaItem.propsData.shadow.shadow_blur = this.shadowBlur;
      this.editaItem.propsData.shadow.shadow_color = this.shadowColor;
      if (this.shadow == true) {
        this.editaItem.propsData.shadow.shadow = `${this.shadowX}px ${this.shadowY}px ${this.shadowBlur}px ${this.shadowColor}`;
        return;
      }
      if (this.shadow == false) {
        this.editaItem.propsData.shadow.hasShadow = false;
        this.editaItem.propsData.shadow.shadow = "none";
      }
    },
    trocarType() {
      if (this.type == "none") {
        this.type = "uppercase";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
      if (this.type == "uppercase") {
        this.type = "none";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
    },
    changeBorderLink() {
      this.borderLink = !this.borderLink;
      if (this.borderLink) {
        this.editaItem.propsData.border.borderright =
          this.editaItem.propsData.border.bordertop;
        this.editaItem.propsData.border.borderleft =
          this.editaItem.propsData.border.bordertop;
        this.editaItem.propsData.border.borderbottom =
          this.editaItem.propsData.border.bordertop;
      }
    },
    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },
    linked(place) {
      if (this.borderLink) {
        var border = this.editaItem.propsData.border;
        border.border_radius_top = border[place];
        border.border_radius_left = border[place];
        border.border_radius_bottom = border[place];
        border.border_radius_right = border[place];
      }
    },
  },
  created() {
    // console.log(this.editaItem.propsData.styles.back_content);
    if (this.typeView == "desktop") {
      this.global = true;
    }
    this.hasBorder = this.editaItem.propsData.border.hasBorder;
    this.borderStyle = this.editaItem.propsData.border.border_style;
    this.borderThickness = this.editaItem.propsData.border.border_thickness;
    this.borderColor = this.editaItem.propsData.border.border_color;
    this.shadow = this.editaItem.propsData.shadow.hasShadow;
    this.shadowX = this.editaItem.propsData.shadow.shadow_x;
    this.shadowY = this.editaItem.propsData.shadow.shadow_y;
    this.shadowBlur = this.editaItem.propsData.shadow.shadow_blur;
    this.shadowColor = this.editaItem.propsData.shadow.shadow_color;
    this.borderLink = this.editaItem.propsData.border.borderLinked;
  },
};
</script>

<style scoped lang="scss">
.edit-modal{
    width: 100%;
    cursor: pointer !important;
    padding: 5px !important ;
    margin-top: 5px !important;
    margin-bottom: 12px;
    border:  1px solid #ececec !important;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
    font-size: 14px;
}
.edit-modal:hover{
  border:  1px solid var(--greenn) !important;
  color:  var(--greenn) !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: rgb(0, 0, 0);
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #545b6200 !important;
}

input:disabled {
  background-color: transparent !important;
}

.form-control:disabled,
.form-control[readonly] {
  color: #dadada !important;
}

.lv-input__group .lv-input__field .lv-input__element {
  padding: 15px 0 15px 20px !important;
}

.lv-input__group .lv-input__field {
  padding: 0 12px 0 0 !important;
}

.pickers {
  width: 99.5%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.picker {
  height: 100%;
  width: 47.5% !important;
}

.selectedDiv {
  background-color: var(--greenn) 50 !important;
}

.gridDirection {
  margin: 1rem 0px;
  justify-content: space-between;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2px;

  div {
    transition: all 0.3s;
    border-radius: 10px;
    background-color: #f0f0f0;
    max-width: 36px;

    img {
      stroke: var(--greenn);
      padding: 6px;
    }
  }
}

.lv-input__group .lv-input__field {
  font-size: 14px !important;
  padding: 0 12px;
  border: 0.5px solid #ededf0 !important;
  height: 45px !important;
  background-color: transparent !important;
  margin-top: 0px !important;
}

.btn-secondary {
  transition: all 0.5s !important;
}

.btn-secondary:hover {
  background-color: var(--greenn) 30;
}

.multiselect__tags {
  border: 0.5px solid #ededf0 !important;
  min-height: 45px !important;
  height: fit-content !important;
  padding: 5px 40px 5px 8px !important;
}

.b-tab-content {
  overflow-y: auto;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus,
.multiselect__tags:focus,
.multiselect__tags:hover {
  border-color: var(--greenn) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style lang="scss" scoped>
* {
  overflow-x: visible !important;
}

.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
button {
  border: none;
}

button:disabled,
.disabled {
  background-color: transparent !important;
}

#LvColorpicker {
  width: 100%;
}

.more-options {
  padding: 10px;
  background-color: transparent;
  width: 100%;
}

*:disabled {
  color: #dadada !important;
}
</style>
