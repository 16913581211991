<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <!-- Geral -->
        <b-tab
          title="Conteúdo"
          active
        >
          <!-- Imagem -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Imagem </label>
            <div class="select-image">
              <div @click="openModal('Upload-Editor')">
                <img
                  :src="
                    editaItem.propsData.image.img_logo
                      ? editaItem.propsData.image.img_logo
                      : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                  "
                  alt="Imagem preview"
                />
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.image.img_logo"
                />
                <span @click="openModal('Upload-Editor')">{{
                  editaItem.propsData.image.img_logo
                    ? "Alterar imagem"
                    : "Selecionar imagem"
                }}</span>
              </div>
            </div>
            <div class="space"></div>
            <label class="subtitulo"> Link (opcional)</label>
            <input
              placeholder="https://seulink"
              v-model="editaItem.propsData.styles.link"
            />
          </div>

          <div class="separador"></div>
          <div class="content">
            <div class="half">
              <label class="titulo">Legendas</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button
                  class="btn button-add-produto"
                  @click="addLink"
                ></button>
              </div>
            </div>
            <div v-for="(link, idx) in editaItem.propsData.links" :key="idx">
              <div class="p-2" style="position: relative">
                <div
                  class="separador"
                  style="position: absolute; top: 17px; left: -3px"
                ></div>
                <label class="subtitulo component-group-title"
                  >Legenda {{ idx + 1 }}</label
                >
              </div>
              <div style="display: flex">
                <div
                  style="
                    width: calc(100% - 35px);
                    display: flex;
                    align-items: center;
                  "
                >
                  <input
                    id="menu"
                    placeholder="https://seulink"
                    v-model="editaItem.propsData.links[idx].value"
                  />
                </div>
                <div class="image-control" style="height: 45px">
                  <div>
                    <img
                      :class="{ 'disabled-arrow': idx == 0 }"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{
                        'disabled-arrow':
                          idx + 1 == editaItem.propsData.links.length,
                      }"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="
                        idx + 1 == editaItem.propsData.links.length
                          ? ''
                          : moveDown(idx)
                      "
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
        </b-tab>

        <!-- Estilo -->
        <b-tab title="Estilo">
          <div>
            <div
              class="separador"
              v-if="editaItem.propsData.hasOwnProperty('alignment')"
            ></div>
            <div
              class="content"
              v-if="editaItem.propsData.hasOwnProperty('alignment')"
            >
              <div class="label-icon">
                <label class="titulo">Posição</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
              </div>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'justify')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'center',
                      }"
                      @click="globalJustify('center', 'justify')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'justify')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'start',
                      }"
                      @click="globalJustify('start', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'center',
                      }"
                      @click="globalJustify('center', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'end',
                      }"
                      @click="globalJustify('end', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="separador"></div>

            <!-- Logo -->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Imagem</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>

              <!-- largura e altura -->
              <div class="half">
                <div>
                  <label class="sub">Altura</label>
                  <input
                    id="menu"
                    placeholder="50"
                    v-model="editaItem.propsData.size[typeView].height"
                    type="number"
                    @input="globalInput($event, 'height')"
                  />
                </div>
                <div>
                  <label class="sub">Largura</label>
                  <input
                    id="menu"
                    placeholder="50"
                    v-model="editaItem.propsData.size[typeView].width"
                    type="number"
                    @input="globalInput($event, 'width')"
                  />
                </div>
              </div>
              <div class="space"></div>

              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLink = !borderLink"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.top"
                  @input="linked('top', 'border_radius', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.right"
                  @input="linked('right', 'border_radius', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.bottom"
                  @input="linked('bottom', 'border_radius', 'borderLink')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius.left"
                  @input="linked('left', 'border_radius', 'borderLink')"
                />
              </div>
            </div>
            <div class="separador"></div>

            <!-- Legendas -->
            <div class="content">
              <label class="titulo">Itens</label>

              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLinkItens === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLinkItens = !borderLinkItens"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.top"
                  @input="
                    linked('top', 'border_radius_item', 'borderLinkItens')
                  "
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.right"
                  @input="
                    linked('right', 'border_radius_item', 'borderLinkItens')
                  "
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.bottom"
                  @input="
                    linked('bottom', 'border_radius_item', 'borderLinkItens')
                  "
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border_radius_item.left"
                  @input="
                    linked('left', 'border_radius_item', 'borderLinkItens')
                  "
                />
              </div>
              <div class="space"></div>

              <label class="sub">Fundo</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.background_header"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>

              <label class="sub">Links</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.color_itens"
                label="Cor dos Links"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>

              <!-- <label class="subtitulo">Espaçamento entre links</label>
              <input
                id="menu"
                placeholder="Seu Texto"
                v-model="editaItem.propsData.size[typeView].gap_itens"
                type="number"
                @input="globalInput($event, 'gap_itens')"
              /> -->
            </div>
            <div class="separador"></div>
          </div>
        </b-tab>

        <!-- Avançado -->
        <b-tab title="Avançado">
          <div class="separador"></div>
          <!-- Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  @change="$emit('fixWidth')"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";

export default {
  props: ["editaItem", "typeView", "palette"],

  components: {
    Upload,
    BaseButton,
    LvInput,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      borderLink: true,
      borderLinkItens: true,
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.links[idx];
      this.editaItem.propsData.links.splice(idx, 1);
      this.editaItem.propsData.links.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.links[idx];
      this.editaItem.propsData.links.splice(idx, 1);
      this.editaItem.propsData.links.splice(idx + 1, 0, moving);
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    linked(position, model, vari) {
      if (this[vari]) {
        var border = this.editaItem.propsData[model];
        border.top = border[position];
        border.right = border[position];
        border.left = border[position];
        border.bottom = border[position];
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalInput(px, place) {
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        var value = px.target.value;
        props.tablet[place] = value;
        props.mobile[place] = value;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        props[this.typeView].margin_top = value;
        props[this.typeView].margin_right = value;
        props[this.typeView].margin_bottom = value;
        props[this.typeView].margin_left = value;
        if (this.global && this.typeView == "desktop") {
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    setImg(img) {
      this.editaItem.propsData.image.img_logo = img;
    },
    removeImgIndex(index) {
      this.editaItem.propsData.links.splice(index, 1);
    },
    addLink() {
      this.editaItem.propsData.links.push({
        name: "Novo link",
        link: "https://greenn.com.br/",
      });
    },
    openModal(data) {
      // console.log('modal ?',data)
      this.$bvModal.show(data);
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
</style>
