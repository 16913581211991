import { render, staticRenderFns } from "./ConfigPage.vue?vue&type=template&id=dc5463fa&scoped=true&"
import script from "./ConfigPage.vue?vue&type=script&lang=js&"
export * from "./ConfigPage.vue?vue&type=script&lang=js&"
import style0 from "./ConfigPage.vue?vue&type=style&index=0&id=dc5463fa&prod&lang=css&"
import style1 from "./ConfigPage.vue?vue&type=style&index=1&id=dc5463fa&prod&scoped=true&lang=scss&"
import style2 from "./ConfigPage.vue?vue&type=style&index=2&id=dc5463fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc5463fa",
  null
  
)

export default component.exports