<template>
  <div class="menu">
    <div class="menu-left">
      <div @click="confirmAndRedirect" class="arrow home">
        <img v-if="!isGreenn" style="height: 45px; width: 98px; object-fit: contain;" src="@/assets/img/G-digital.png" alt="voltar" />
        <img v-else style="height: 45px; width: 98px; object-fit: contain;" src="@/assets/greenn_sales/SIMBOLO_.png" alt="voltar" />
      </div>
      <div @click="$emit('openLeft')" class="open-left-side" :style="openSideLeft ? 'background-color: var(--greenn-transparent)' : ''">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4ea934" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM40,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24ZM216,180H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z"></path></svg>
      </div>
      <div class="separator"></div>
      <div class="types-resposives">
        <img
          :class="{ active_responsive: typeView === 'desktop' }"
          @click="$emit('view', 'desktop')"
          src="@/assets/editor/monitor3.svg"
          alt="monitor"
        />
        <img
          :class="{ active_responsive: typeView === 'tablet' }"
          @click="$emit('view', 'tablet')"
          src="@/assets/editor/tablet3.svg"
          alt="tablet"
        />
        <img
          :class="{ active_responsive: typeView === 'mobile' }"
          @click="$emit('view', 'mobile')"
          src="@/assets/editor/phone3.svg"
          alt="phone"
        />
      </div>
      <div class="separator"></div>
      <div style="position: relative; width: 16px; height: 16px;" v-if="this.page_id">
        <Tooltip text="Desfazer" style="position: absolute;">
          <img
            class="undo-redo"
            id="undo"
            src="@/assets/editor/undo.svg"
            alt="desfazer"
            :style="currentPage == totalPages || (statusHist != 'save' && statusHist != 'none') ? 'filter: grayscale(1) brightness(1.5); cursor: not-allowed;' : ''"
            @click="currentPage == totalPages || (statusHist != 'save' && statusHist != 'none') ? '' : prevHistory()"
          />
        </Tooltip>
      </div>
      <div style="position: relative; width: 16px; height: 16px;" v-if="this.page_id">
        <Tooltip class="tool" text="Refazer">
          <img
            class="undo-redo"
            id="redo"
            src="@/assets/editor/redo.svg"
            alt="refazer"
            @click="currentPage == 1 || (statusHist != 'save' && statusHist != 'none') ? '' : nextHistory()"
            :style="currentPage == 1 || (statusHist != 'save' && statusHist != 'none') ? 'filter: grayscale(1) brightness(1.5); cursor: not-allowed;' : ''"
          />
        </Tooltip>
      </div>
      <div class="separator" v-if="this.page_id"></div>
      <div class="clouds" v-if="this.page_id">
        <svg v-if="statusHist == 'none'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Z"></path></svg>
        <svg v-else-if="statusHist == 'load'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
        <svg v-else-if="statusHist == 'error'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm-8-72V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,172,164Z"></path></svg>
        <svg v-else-if="statusHist == 'save'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm37.66-93.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L144,148.69l42.34-42.35A8,8,0,0,1,197.66,106.34Z"></path></svg>
        <svg v-else-if="statusHist == 'undo'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#4ea934" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-69.66,42.34L160,188.69V128a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path></svg>
      </div>
    </div>

    <div class="actions">
      <Tooltip
        :text="showHidden ? 'Esconder itens ocultos' : 'Mostrar itens ocultos'"
      >
        <div style="cursor: pointer">
          <img
            @click="$emit('show', false)"
            v-if="showHidden"
            src="@/assets/editor/eye.svg"
            alt="monitor"
          />
          <img
            @click="$emit('show', true)"
            v-else
            src="@/assets/editor/eye-off.svg"
            alt="monitor"
          />
        </div>
      </Tooltip>
      <div class="separator"></div>

      <button class="preview" @click="$emit('resetarPreview', true)">
        Preview
      </button>
      <button
        class="salvar"
        variant="primary"
        @click="
          $emit('saveHistory', true),
          isSaving ? '' : level === 'templater' ? saveTemplate() : savePage()
        "
        :style="isSaving ? 'background-color: #555' : ''"
      >
        Publicar
      </button>
    </div>
    <ModalSave :page="dataPage" :path="path"/>
    <ModalBeforeSave
      @emitTitle="emitTitle"
      @saveTemplate="saveTemplate"
      @savePage="savePage"
      :level="level"
    />
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import Vue from "vue";
import Tooltip from "./SideBar/TooltipHistory.vue";
import ModalSave from "./ModalSave.vue";
import ModalBeforeSave from "./ModalBeforeSave.vue";

import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();

import TemplateService from "@/services/resources/TemplateService";
const serviceTemplate = TemplateService.build();

import PixelService from "@/services/resources/PixelService";
const servicePixel = PixelService.build();

import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

export default { 
  props: [
    "typeView",
    "showHidden",
    "page",
    "page_data",
    "current_data",
    "preview",
    "history",
    "palette",
    'openSideLeft',
    'statusHist',
    'currentPage',
    'totalPages'
  ],
  components: {
    Tooltip,
    ModalSave,
    ModalBeforeSave,
  },
  data() {
    return {
      dataPage:[],
      level: this.$store.getters.user.user.level,
      page_id: this.$route.params.page_id,
      loading_history: false,
      fontFamilies: [],
      overlaps: [],
      isSaving: false,
      page_data2: [],
      path: '',
    };
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    userLevel() {
      return this.$store.getters.user.user.level;
    },
  },
  methods: {
    updateMeta(key, newValue){ 
      let meta = this.page_data.metas.find(meta=> meta.meta_key == key)
      if(meta){
        meta.meta_value = newValue
      }else{
        this.page_data.metas.push(
          {
            meta_key: key,
            meta_value: newValue
          }
        )
      }
    },
    emitTitle(value) {
      this.$emit("toChangePageTitle", value);
    },
    saveHistoryNewPage(id, type) {
      var data = {
        id: `/save-historic`,
        historic:
          this.history.length === 0
            ? JSON.stringify(this.history)
            : JSON.parse(this.history[this.history.length - 1]),
      };
      switch (type) {
        case "page":
          data.page_id = id;
          servicePage
            .createId(data)
            .then((resp) => {
              this.$parent.apiGetHistoryPage(id);
            })
            .catch((error) => {});
          break;
        case "template":
          data.template_id = id;
          serviceTemplate
            .createId(data)
            .then((resp) => {
              this.$parent.apiGetHistoryPage(id);
            })
            .catch((error) => {});
      }
    },
    async getAllFontFamilies(template, isSavestring) {
      // Essa função é responsável por percorrrer todo o array da page e criar uma array com todas as fontes usadas no page
       let fontFamilies = [];
      let jsonPage =[]
      if(template){
        jsonPage = template
      }else{
        jsonPage = this.page
      }
      for (let x = 0; x < jsonPage.length; x++) {
        let row = jsonPage[x];
        for (let w = 0; w < row.length; w++) {
          const coluns = row[w].coluns;
          for (let y = 0; y < coluns.length; y++) {
            let column = coluns[y];
            for (let z = 0; z < column.length; z++) {
              let isEmpty = column[z].empty;
              if (!isEmpty) {
                let propsData = column[z].propsData;
                if (
                  (propsData &&
                    propsData.styles &&
                    (propsData.styles.font_family ||
                      propsData.styles.font ||
                      propsData.styles.font_label)) ||
                  (propsData && propsData.text && propsData.text.content_text)
                ) {
                  if (propsData.text) {
                    let fontFamily = propsData.styles.font_family;
                    if (!fontFamilies.includes(fontFamily)) {
                      fontFamilies.push(fontFamily);
                    }
                    const regex = /font-family:\s*([^;]+)/g;
                    let match;
                    while (
                      (match = regex.exec(propsData.text.content_text)) !== null
                    ) {
                      let fontFamily = match[1].trim();
                      fontFamilies.push(fontFamily);
                    }
                  } else if (propsData.styles.font_label) {
                    if (
                      !fontFamilies.includes(propsData.styles.font_label)
                    ) {
                      fontFamilies.push(propsData.styles.font_label);
                    }
                    if (
                      !fontFamilies.includes(propsData.styles.font_number)
                    ) {
                      fontFamilies.push(propsData.styles.font_number);
                    }
                  } else {
                    let fontFamily;
                    if (propsData.styles.font_family) {
                      fontFamily = propsData.styles.font_family;
                    } else {
                      fontFamily = propsData.styles.font;
                    }
                    if (!fontFamilies.includes(fontFamily)) {
                      fontFamilies.push(fontFamily);
                    }
                  }
                }
              }
            }
          }
        }
      }
      if(isSavestring){
        return JSON.stringify(fontFamilies);
      }
      return fontFamilies
    },
    getAllOverlaps() {
      this.overlaps = "";
      for (let x = 0; x < this.page.length; x++) {
        let row = this.page[x][0];
        if (
          row.propsRow.backgd_und == "Imagem" &&
          row.propsRow.backgd_overlap_und != "Nenhuma"
        ) {
          if (row.propsRow.backgd_overlap_und == "Cor") {
            this.overlaps += `.overlap_${row.id}::after {content: url(); position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: ${row.propsRow.backgd_overlap_cor}; } `;
          } else {
            this.overlaps += ` .overlap_${row.id}::after { content: url(); position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(${row.propsRow.backgd_overlap_deg}deg, ${row.propsRow.backgd_overlap_c1}, ${row.propsRow.backgd_overlap_c2}); }`;
          }
        }
      }
    },
    confirmAndRedirect() {
      Vue.swal({
        title: "Editor",
        text: `Deseja voltar  ${
          this.userLevel !== "templater"
            ? "para o funil"
            : "à lista de Templates"
        } ?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // EventBus.$emit("setStyleCss");
          if (this.userLevel !== "templater") {
            this.$router
              .push({
                name: "CampanhasListaUnica",
                params: {
                  dynamicRoute: this.$store.getters.tenantSubdomain,
                  id: this.$route.params.campaign_id,
                },
                query: { tab: "paginas" },
              })
              .then(() => {
                location.reload();
              });
            for (var chave in sessionStorage) {
              if (chave.includes("prevHistory")) {
                sessionStorage.removeItem(chave);
              }
            }
            for (var chave in localStorage) {
              if (
                chave.includes("historyEditor") ||
                chave.includes("prevHistory")
              ) {
                localStorage.removeItem(chave);
                sessionStorage.removeItem(chave);
              }
            }
          } else {
            this.$router
              .push(`/${this.$store.getters.tenantSubdomain}/templates`)
              .then(() => {
                location.reload();
              });
            for (var chave in sessionStorage) {
              if (chave.includes("prevHistory")) {
                sessionStorage.removeItem(chave);
              }
            }
            for (var chave in localStorage) {
              if (
                chave.includes("historyEditor") ||
                chave.includes("prevHistory")
              ) {
                localStorage.removeItem(chave);
                sessionStorage.removeItem(chave);
              }
            }
          }
        }
      });
    },
    nextHistory() {
      this.$emit("nextHistory");
    },
    prevHistory() {
      this.$emit("prevHistory");
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    removeAccents(str) {
      // Converte para caracteres comuns
      str = str
        .toLowerCase()
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éèêë]/gi, "e")
        .replace(/[íìîï]/gi, "i")
        .replace(/[óòõôö]/gi, "o")
        .replace(/[úùûü]/gi, "u")
        .replace(/[ç]/gi, "c")
        .replace(/[ñ]/gi, "n")
        .replace(/\s+/g, "-");
      // Remove caracteres especiais
      str = str.replace(/(?!-)[^a-z0-9]/gi, "");
      str = str.replace(/-+/gi, "-");
      str = str.replace(/  ?/gi, "");
      return str;
    },
    async savePage() {
      this.isSaving = true;
      if (!this.page_data.title) {
        this.$bvModal.show("Modal-before-save");
        this.isSaving = false;
        return;
      }
      if (/  /g.test(this.page_data.title)) {
        this.$bvToast.toast("Espaçamentos inválidos no título!", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        this.isSaving = false;

        return;
      }
      
      this.getAllOverlaps();

      var link = this.removeAccents(
        this.page_data.link
          ? this.page_data.link
          : this.page_data.path_name
          ? this.page_data.path_name
          : this.page_data.title ?? ""
      );
      this.path = link
      let data = {
        title: this.page_data.title,
        status:
          !this.page_data.publicar && this.page_data.publicar != undefined
            ? "draft"
            : "publish",
        json: this.page ? this.page : [],
        path_name: link,
        metas: {
          favicon: this.page_data?.favicon,
          search_title: this.page_data?.search_title,
          description: this.page_data?.description,
          lgpd: this.page_data.lgpd,
          allowCopy: this.page_data.allowCopy,
          marca: this.page_data.marca,
          page_domain: this.page_data.domain
            ? this.page_data.domain
            : this.getMeta(this.page_data.metas, "page_domain") ?? "",
          html_header_custom: this.page_data.html_header_custom,
          html_foot_custom: this.page_data.html_foot_custom,
          time_redirect_switch: this.page_data.time,
          isNew: false,
          time_redirect: this.page_data.time_redirect
            ? this.page_data.time_redirect
            : "",
          redirect_page: this.page_data.redirect_page,
          redirect_page_date_inactive: this.page_data.date,
          page_date_switch: this.page_data.date_switch,
          dynamic_css: this.getMeta(this.page_data.metas, "dynamic_css") ?? "",
          dynamic_js: this.getMeta(this.page_data.metas, "dynamic_js") ?? "",
          palette: this.palette ? JSON.stringify(this.palette) : "",
          thumb: this.page_data.thumb
            ? this.page_data.thumb
            : false,
          font_family: await  this.getAllFontFamilies(false, true),
          overlap: this.overlaps ? this.overlaps : "",
        },
        campaign_id: this.$route.params.campaign_id,
      };
      if (this.page_id != undefined) {
        if (
          this.current_data.status === "publish" &&
          this.page_data.open_config === undefined
        ) {
          data.status = "publish";
        }
        data.id = `edit/${this.page_id}`;
        servicePage
          .update(data)
          .then((resp) => {
            this.page_data2 = resp.page;
            this.dataPage = data
            this.$bvModal.show("ModalSavePage");
            this.$bvToast.toast(resp.message, {
              title: "Editor",
              variant:
                resp.message == "Página atualizada com sucesso!"
                  ? "info"
                  : "warning",
              autoHideDelay: 5000,
              appendToast: true,
            });

            if (this.page_data.homepage) {
              this.savePageDefault();
            }
            // sessionStorage.removeItem(`historyEditor${this.page_id}`);
            for (var chave in localStorage) {
              if (
                chave.includes("historyEditor") ||
                chave.includes("prevHistory")
              ) {
                localStorage.removeItem(chave);
                sessionStorage.removeItem(chave);
              }
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.savePixels(this.page_id);
            // if (this.isTemplater) {
            //   this.$router.push({
            //     name: "TemplaterEditorPage",
            //     params: { id: this.page_id },
            //   });
            // } else {
            //   this.$router.push({
            //     name: "CampanhasListaUnica",
            //     params: { id: this.$route.params.campaign_id },
            //   });
            // }
            this.isSaving = false;
          });
        return;
      }
      var saveResponse = null;

      servicePage
        .create(data)
        .then((resp) => {
          saveResponse = resp;
          this.page_data2 = resp.page;
          this.dataPage = data
          this.$bvModal.show("ModalSavePage");
          this.saveHistoryNewPage(resp.page.id, "page");
          this.page_id = resp.page.id;
          this.$bvToast.toast(resp.message, {
            title: "Editor",
            variant:
              resp.message == "Página criada com sucesso!" ? "info" : "warning",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$router.push(
            `/${this.$store.getters.tenantSubdomain}/funis/${this.$route.params.campaign_id}/editor/${resp.page.id}`
          );

          this.savePixels(resp.page.id);
          if (this.page_data.homepage) {
            this.savePageDefault();
          }

          if (resp.page.id) {
            this.$emit("getNewPage", resp.page.id);
          }

          sessionStorage.removeItem(`historyEditorUnsave`);
          for (var chave in localStorage) {
            if (
              chave.includes("historyEditorUnsave") ||
              chave.includes("prevHistoryUnsaved")
            ) {
              localStorage.removeItem(chave);
              sessionStorage.removeItem(chave);
            }
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    savePageDefault() {
      serviceDomain.update({
        id: " ",
        domain_id: this.page_data.domain,
        page_id_default: this.$route.params.page_id,
        tenant_id: this.$store.getters.user.user.tenant_id,
      });
      this.isSaving = false;
    },
    getMeta(metas, chave) {
      if (!metas || !chave) {
        return;
      }
      var result = metas.find((x) => x.meta_key == chave);
      if (!result) {
        return "";
      }
      return result.meta_value;
    },
    saveTemplate() {
      this.isSaving = true;
      if (!this.page_data.title) {
        this.$bvModal.show("Modal-before-save");
        this.isSaving = false;
        return;
      }

      var link = this.removeAccents(
        this.page_data.link ? this.page_data.link : this.page_data.title
      );

      let data = {
        title: this.page_data.title,
        category_id: this.page_data.category_id,
        status:
          !this.page_data.publicar || this.page_data.publicar === undefined
            ? "draft"
            : "publish",
        json: this.page ? this.page : [],
        path_name: link,
        metas: {
          palette: this.palette ? JSON.stringify(this.palette) : "",
          image: this.page_data.image,
        },
      };

      if (this.page_id != undefined) {
        if (
          this.current_data.status === "publish" &&
          this.page_data.status == null
        ) {
          data.status = "publish";
        }
        data.id = `edit/${this.page_id}`;
        serviceTemplate
          .update(data)
          .then(() => {
            this.$emit("updateTemplate", this.page_id);

            this.$bvToast.toast("Template salvo com sucesso", {
              title: "Editor",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            // this.$router.push("/dynamicRoute/templates");
            sessionStorage.removeItem(`historyEditor${this.page_id}`);
          })
          .finally(() => {
            this.$emit("updateTemplate", this.page_id);
          });
        this.isSaving = false;
        return;
      }

      serviceTemplate.create(data).then((resp) => {
        this.$bvToast.toast("Template criado com sucesso", {
          title: "Editor",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.page_id = resp.id;
        this.$emit("updateTemplate", resp.id);
        this.saveHistoryNewPage(resp.id, "template");

        sessionStorage.removeItem(`historyEditorUnsaved`);
        this.$router.push(
          `/${this.$store.getters.tenantSubdomain}/editor/${resp.id}`
        );

        // this.$router.push({
        //   name: "CampanhasListaUnica",
        //   params: { id: this.$route.params.campaign_id },
        // });
      });
      this.isSaving = false;
    },
    savePixels(id) {
      if (!this.page_data.pixels || !this.page_data.pixels.length) {
        return;
      }

      if (this.page_data.pixels[0].hasOwnProperty('type')) {
        var pixels = this.page_data.pixels.map((item) => {
          return {
            id: item.id, 
            conversion_label: item.conversion_label
          }
        });
      } else {
        var pixels = this.page_data.pixels.map((item) => {
          return {
            id: item.pixel_id, 
            conversion_label: item.conversion_label
          }
        });
      }

      var data = {
        id: "assoc",
        pixel_id: pixels,
        page_id: id,
      };

      servicePixel.createId(data);
    },
  },
};
</script>

<style scoped lang="scss">
.clouds {
  opacity: 0.5;
}
.separator {
  width: 1px;
  height: 20px;
  background-color: var(--white-medium);
}
.open-left-side {
  padding: 10px;
  transition: background-color 0.3s;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    background-color: var(--greenn2);
  }
}
#page-title {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600;
  font-size: 16px !important;
  color: var(--gray01);
  height: min-content !important;
}

* {
  user-select: none;
}
.home {
  cursor: pointer;
}
#separador {
  position: absolute;
  width: 1px;
  height: 21px;
  right: 327px;
  top: 33px;
  background: #ededf0;
}

.undo-redo {
  height: 16px;
  margin: 0;
}

#undo {
  cursor: pointer;
}

#redo {
  cursor: pointer;
}
.infos {
  position: absolute;
  left: 74px;
  top: 23px;
  width: 450px;
  h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}

.actions {
  display: flex;
  gap: 20px;
  height: 100%;
  align-items: center;
  button {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
  }
  .preview {
    color: var(--greenn);
    background: var(--greenn2);
    transition: all 0.3s;
    &:hover {
      transform: scale(1.07);
    }
  }
  .salvar {
    color: #ffffff;
    background: var(--greenn);
    transition: all 0.3s;

    &:hover {
      transform: scale(1.07);
    }
  }
}
.types-resposives img {
  padding: 12px;
  height: 45px;
  filter: invert(50%);
  transition: all 0.3s;
}
.active_responsive {
  background-color: var(--greenn2) !important;
  border-radius: 10px;
  filter: invert(0%) !important;
}
.flex {
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    cursor: pointer;
  }
}
.menu-left {
  display: flex;
  gap: 20px;
  align-items: center;
}
.menu {
  padding: 0px 30px 0 0;
  user-select: none;
  -webkit-user-drag: none;
  position: fixed;
  left: 0;
  top: 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  position: fixed;
  width: 100vw;
  z-index: 20;
  height: 74px;
  justify-content: space-between;
  * {
    user-select: none;
    -webkit-user-drag: none;
  }
  .types-resposives {
    display: flex;
    gap: 15px;
    img {
      cursor: pointer;
    }
  }
}
</style>

<style>
  .menu-left .tooltip {
    top: 30px;
    right: unset;
  }
</style>
