<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs
        pills
        justified
        small
        style="width: 100%"
        v-if="typeof idxTable != 'number'"
      >
        <b-tab title="Conteúdo" active>
          <!-- Templates -->
          <div class="separador"></div>
          <div class="content">
            <div class="grid-templates">
              <div
                class="template1 templates"
                @click="editaItem.propsData.styles.template = 0"
                :class="
                  editaItem.propsData.styles.template == 0
                    ? 'template-selected'
                    : ''
                "
              >
                <div class="head-template">
                  <div class="t-title">Título</div>
                  <div class="t-value">$$</div>
                  <div class="t-time">/mês</div>
                  <div class="t-button">Saiba mais!</div>
                </div>
                <p
                  v-for="n in 5"
                  :key="n"
                  class="t-resources"
                  :class="{
                    't-separator': editaItem.propsData.styles.hasSeparator,
                    't-stripe': editaItem.propsData.styles.hasStripe,
                  }"
                >
                  {{
                    editaItem.propsData.styles.icons.type == "Nenhum" ? "" : "•"
                  }}
                  Recurso do item
                </p>
              </div>
              <div
                class="template2 templates"
                @click="editaItem.propsData.styles.template = 1"
                :class="
                  editaItem.propsData.styles.template == 1
                    ? 'template-selected'
                    : ''
                "
              >
                <div class="head-template">
                  <div class="t-title">Título</div>
                </div>
                <div class="t-value">$$</div>
                <div class="t-time">por mês</div>
                <p
                  v-for="n in 5"
                  :key="n"
                  class="t-resources"
                  :class="{
                    't-separator': editaItem.propsData.styles.hasSeparator,
                    't-stripe': editaItem.propsData.styles.hasStripe,
                  }"
                >
                  {{
                    editaItem.propsData.styles.icons.type == "Nenhum" ? "" : "•"
                  }}
                  Recurso do item
                </p>
                <div class="t-button">Saiba mais!</div>
              </div>
              <div
                class="template3 templates"
                @click="editaItem.propsData.styles.template = 2"
                :class="
                  editaItem.propsData.styles.template == 2
                    ? 'template-selected'
                    : ''
                "
              >
                <div class="head-template">
                  <div class="t-title">Título</div>
                </div>
                <div class="t-div">
                  <div class="t-value">$$</div>
                  <div class="t-time">por mês</div>
                </div>
                <p
                  v-for="n in 5"
                  :key="n"
                  class="t-resources"
                  :class="{
                    't-separator': editaItem.propsData.styles.hasSeparator,
                    't-stripe': editaItem.propsData.styles.hasStripe,
                  }"
                >
                  {{
                    editaItem.propsData.styles.icons.type == "Nenhum" ? "" : "•"
                  }}
                  Recurso do item
                </p>
                <div class="t-button">Saiba mais!</div>
              </div>
              <div
                class="template4 templates"
                @click="editaItem.propsData.styles.template = 3"
                :class="
                  editaItem.propsData.styles.template == 3
                    ? 'template-selected'
                    : ''
                "
              >
                <div class="head-template"></div>
                <div class="t-title">Título</div>
                <div class="t-value">$$</div>
                <div class="t-time">por mês</div>
                <p
                  v-for="n in 5"
                  :key="n"
                  class="t-resources"
                  :class="{
                    't-separator': editaItem.propsData.styles.hasSeparator,
                    't-stripe': editaItem.propsData.styles.hasStripe,
                  }"
                >
                  {{
                    editaItem.propsData.styles.icons.type == "Nenhum" ? "" : "•"
                  }}
                  Recurso do item
                </p>
                <div class="t-button">Saiba mais!</div>
              </div>
              <!-- <div class="template5 templates"></div>
              <div class="template6 templates"></div> -->
            </div>
            <div class="half">
              <div
                style="
                  display: flex;
                  width: 100%;
                  transition: all 0.3s;
                  margin-top: 10px;
                "
              >
                <label class="subtitulo" style="margin-top: 10px !important"
                  >Separador</label
                >
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.hasSeparator"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
              <div
                style="
                  display: flex;
                  width: 100%;
                  transition: all 0.3s;
                  margin-top: 10px;
                "
              >
                <label class="subtitulo" style="margin-top: 10px !important"
                  >Listra</label
                >
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.hasStripe"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <!-- Itens -->
          <div class="separador"></div>
          <div class="content">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              "
            >
              <label class="titulo">Itens</label>
              <button
                class="btn button-add-produto"
                @click="addItems(false)"
              ></button>
            </div>
            <div v-for="(row, index) in editaItem.propsData.rows" :key="index">
              <div style="display: flex">
                <div class="select-image" style="width: calc(100% - 35px)">
                  <div>
                    <input
                      type="text"
                      v-model="editaItem.propsData.rows[index].title"
                    />
                    <div class="with-trash">
                      <span @click="idxTable = index">Editar elemento</span>
                      <div style="display: flex; gap: 5px">
                        <img
                          @click="editaItem.propsData.styles.emphasis = null"
                          class="trash"
                          src="@/assets/editor/star3.svg"
                          v-show="index == editaItem.propsData.styles.emphasis"
                        />
                        <img
                          @click="editaItem.propsData.styles.emphasis = index"
                          v-show="index != editaItem.propsData.styles.emphasis"
                          class="trash"
                          src="@/assets/editor/star2.svg"
                        />
                        <img
                          @click="double(index)"
                          class="trash"
                          src="@/assets/editor/duplicate2.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow': index == 0 }"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="index == 0 ? '' : moveUp(index)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeItems(index)"
                    ></button>
                    <img
                      :class="{
                        'disabled-arrow':
                          index + 1 == editaItem.propsData.rows.length,
                      }"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="
                        index + 1 == editaItem.propsData.rows.length
                          ? ''
                          : moveDown(index)
                      "
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <!-- Cores -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Cores</label>
            <div class="half">
              <div>
                <label class="sub">Cor destaque</label>
                <LvColorpicker
                  v-model="editaItem.propsData.styles.color1"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div>
                <label class="sub">Cor de fundo</label>
                <LvColorpicker
                  v-model="editaItem.propsData.styles.color2"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
          </div>

          <!-- Tamanho -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Tamanho</label>
            <div class="label-range">
              <div class="label-icon">
                <label class="subtitulo">Largura</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global && typeView === 'desktop'"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].width"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size[typeView].width"
              type="range"
              min="200"
              max="400"
              step="1"
            ></b-form-input>
            <!-- <div
                style="
                  display: flex;
                  width: 100%;
                  transition: all 0.3s;
                "
              >
                <label class="subtitulo"
                  >Equalizar alturas</label
                >
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.equalize"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div> -->
          </div>

          <!-- Tipografia -->
          <div class="separador"></div>
          <div class="content max-multi">
            <label class="titulo">Textos</label>
            <multiselect
              v-model="editaItem.propsData.styles.font_family"
              :options="fontOptions"
              :searchable="true"
              :show-labels="false"
              placeholder="Fonte"
              @input="$emit('callCharge',$event)"
              :hide-selected="true"
              :allow-empty="false"
            ></multiselect>
            <div class="space"></div>
            <div class="label-range">
              <div class="label-icon">
                <label class="subtitulo">Tamanho dos textos</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global && typeView === 'desktop'"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].font_size"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size[typeView].font_size"
              type="range"
              min="16"
              max="40"
              step="1"
            ></b-form-input>
            <label class="subtitulo">Ícones ao lado</label>
            <multiselect
              v-model="editaItem.propsData.styles.icons.type"
              :options="[
                'Nenhum',
                'Um ícone para cada',
                'Ícone de sim e não',
                'Um ícone para todos',
              ]"
              :searchable="false"
              :show-labels="false"
              :hide-selected="true"
              :allow-empty="false"
            ></multiselect>
            <div
              v-if="
                editaItem.propsData.styles.icons.type == 'Um ícone para todos'
              "
            >
              <div class="space"></div>
              <div class="select-image">
                <div @click="openModal('Modal-Icons-svg', false)">
                  <span class="material-icons icon-preview">{{
                    editaItem.propsData.styles.icons.icon
                  }}</span>
                </div>
                <div>
                  <input
                    type="text"
                    v-model="editaItem.propsData.styles.icons.icon"
                  />
                  <span @click="openModal('Modal-Icons-svg', false)">{{
                    editaItem.propsData.styles.icons.icon
                      ? "Alterar ícone"
                      : "Selecionar ícone"
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Posição -->
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Posição</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global && typeView === 'desktop'"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet'"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile'"
              />
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'flex-start',
                    }"
                    @click="globalJustify('flex-start', 'justify')"
                  >
                    <img src="@/assets/editor/align-left-new.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'center',
                    }"
                    @click="globalJustify('center', 'justify')"
                  >
                    <img src="@/assets/editor/align-center-horizontal.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].justify ==
                        'flex-end',
                    }"
                    @click="globalJustify('flex-end', 'justify')"
                  >
                    <img src="@/assets/editor/align-right-new.svg" />
                  </button>
                </div>
              </div>

              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'start',
                    }"
                    @click="globalJustify('start', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-top.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'center',
                    }"
                    @click="globalJustify('center', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-center-vertical.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'end',
                    }"
                    @click="globalJustify('end', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-bottom.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Bordas -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Bordas</label>
            <div class="label-range">
              <label class="subtitulo">Arredondar bordas</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.table_radius"
              />
            </div>
            <b-form-input
              style="margin-bottom: 0 !important"
              v-model="editaItem.propsData.styles.table_radius"
              type="range"
              min="0"
              max="80"
              step="1"
            ></b-form-input>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Espaçamento geral</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global && typeView === 'desktop'"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet'"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile'"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <!-- Visilibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  size="lg"
                  switch
                  @change="$emit('fixWidth')"
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content columnAnimate">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>

      <b-tabs pills justified small style="width: 100%" v-else>
        <b-tab title="Voltar" @click="idxTable = ''"> </b-tab>

        <b-tab title="Conteúdo" active>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Textos</label>
            <label class="sub">Título</label>
            <input
              type="text"
              v-model="editaItem.propsData.rows[idxTable].title"
              placeholder="Plano padrão"
            />
            <label class="sub">Preço</label>
            <input
              type="text"
              v-model="editaItem.propsData.rows[idxTable].price"
              placeholder="249,90"
            />
            <label class="sub">Tempo</label>
            <input
              type="text"
              v-model="editaItem.propsData.rows[idxTable].time"
              placeholder="por mês"
            />
            <label class="sub">Botão</label>
            <input
              type="text"
              v-model="editaItem.propsData.rows[idxTable].button.content_button"
              placeholder="Comprar plano"
            />
          </div>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Link do botão</label>
            <label class="subtitulo">Tipo de link</label>
            <multiselect
              :options="optionsType"
              v-model="editaItem.propsData.rows[idxTable].button.type"
              :searchable="false"
              :show-labels="false"
              :hide-selected="true"
              :allow-empty="false"
              placeholder="Tipo de link"
              label="text"
              track-by="value"
            ></multiselect>
            <div class="space" />

            <!-- externo -->
            <div
              v-if="
                editaItem.propsData.rows[idxTable].button.type.value ==
                'externo'
              "
            >
              <label class="sub">Link para redirecionar</label>
              <input
                placeholder="https://seulink"
                class="w-100"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.rows[idxTable].button.href_button"
              />
              <div class="space"></div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Redirecionar para nova guia</label>
                <b-form-checkbox
                  style="
                    margin-top: -5px;
                    margin-left: auto;
                    margin-right: 0;
                    z-index: 0;
                  "
                  v-model="editaItem.propsData.rows[idxTable].button.blank"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>

            <!-- interno -->
            <div
              v-if="
                editaItem.propsData.rows[idxTable].button.type.value ==
                'interno'
              "
            >
              <label class="sub"
                >ID do elemento de destino
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title='Nessa opção, o usuário é levado até a posição da página onde se encontra o elemento de destino. O ID do elemento pode ser configurado nas aba "avançado" ao editar um componente ou linha.'
              /></label>
              <input
                placeholder="exemplo_de_id"
                type="text"
                name="text_1"
                id="text_1"
                v-model="editaItem.propsData.rows[idxTable].button.href_button"
              />
            </div>

            <!-- copartilhar -->
            <div
              v-if="
                editaItem.propsData.rows[idxTable].button.type.value ==
                'compartilhar'
              "
            >
              <label class="sub"
                >Escreva um texto curto para ser compartilhado
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Esse texto será automaticamente posto no campo de mensagens do whatsapp do usuário para que ele envie para outras pessoas"
              /></label>
              <textarea
                placeholder="Dê uma olhada nesse produto incrível!..."
                v-model="editaItem.propsData.rows[idxTable].button.share_text"
                rows="2"
                class="w-100 mb-1"
                style="
                  height: min-content !important;
                  min-height: 45px !important;
                "
              ></textarea>
            </div>

            <!-- chamar -->
            <div
              v-if="
                editaItem.propsData.rows[idxTable].button.type.value == 'chamar'
              "
            >
              <label class="sub">Escreva um texto curto para ser chamado</label>
              <textarea
                placeholder="Olá, tudo bem? Gostei da oferta.."
                v-model="editaItem.propsData.rows[idxTable].button.share_text"
                rows="2"
                class="w-100 mb-1"
                style="
                  height: min-content !important;
                  min-height: 45px !important;
                "
              ></textarea>
              <label class="sub"
                >Insira o número de telefone
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Digite apenas números e inclua o 55 e o código de área. Por exemplo: 5544912345678"
              /></label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-model="editaItem.propsData.rows[idxTable].button.href_button"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
              />
            </div>

            <!-- telefone -->
            <div
              v-if="
                editaItem.propsData.rows[idxTable].button.type.value ==
                'telefone'
              "
            >
              <label class="sub">Insira o número de telefone</label>
              <input
                placeholder="5544XXXXXXXXX"
                class="w-100"
                type="text"
                v-mask="['+##(##) ####-####', '+##(##) #####-####']"
                v-model="editaItem.propsData.rows[idxTable].button.href_button"
              />
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Recursos">
          <div class="separador"></div>
          <div
            class="content"
            style="
              padding-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
          >
            <label class="titulo">Recursos</label>
            <button
              class="btn button-add-produto"
              @click="addItems(true)"
            ></button>
          </div>

          <div class="content" style="padding-top: 10px">
            <div
              v-for="(row, index) in editaItem.propsData.rows[idxTable]
                .resource"
              :key="index"
            >
              <div style="display: flex">
                <div style="width: calc(100% - 35px); display: flex; gap: 10px">
                  <div
                    v-if="
                      editaItem.propsData.styles.icons.type ==
                      'Ícone de sim e não'
                    "
                    style="
                      height: 45px;
                      width: 45px;
                      border-radius: 10px;
                      border: 0.5px solid #ededf0;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                      cursor: pointer;
                    "
                    @click="
                      editaItem.propsData.rows[idxTable].resource[
                        index
                      ].iconTrue =
                        !editaItem.propsData.rows[idxTable].resource[index]
                          .iconTrue
                    "
                  >
                    <span class="material-icons">{{
                      editaItem.propsData.rows[idxTable].resource[index]
                        .iconTrue
                        ? "check"
                        : "close"
                    }}</span>
                  </div>
                  <div
                    v-if="
                      editaItem.propsData.styles.icons.type ==
                      'Um ícone para cada'
                    "
                    style="
                      height: 45px;
                      width: 45px;
                      border-radius: 10px;
                      border: 0.5px solid #ededf0;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                      cursor: pointer;
                    "
                    @click="openModal('Modal-Icons-svg', index)"
                  >
                    <span class="material-icons">{{
                      editaItem.propsData.rows[idxTable].resource[index].icon
                    }}</span>
                  </div>
                  <input
                    :style="
                      editaItem.propsData.styles.icons.type == 'Nenhum' ||
                      editaItem.propsData.styles.icons.type ==
                        'Um ícone para todos'
                        ? ''
                        : 'width: calc( 100% - 55px ) !important;'
                    "
                    type="text"
                    v-model="
                      editaItem.propsData.rows[idxTable].resource[index].text
                    "
                  />
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow': index == 0 }"
                      class="first-arrow"
                      style="margin-top: -15px"
                      src="@/assets/editor/chevron-down.svg"
                      @click="index == 0 ? '' : moveUp(index, true)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeItems(index, true)"
                    ></button>
                    <img
                      :class="{
                        'disabled-arrow':
                          index + 1 ==
                          editaItem.propsData.rows[idxTable].resource.length,
                      }"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="
                        index + 1 ==
                        editaItem.propsData.rows[idxTable].resource.length
                          ? ''
                          : moveDown(index, true)
                      "
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <ModalIcons @iconSelect="iconSelect" :noBrands="true"></ModalIcons>
  </div>
</template>

<script>
import LvInput from "lightvue/input";
import Multiselect from "vue-multiselect";
import ModalIcons from "@/components/Editor/ModalSvg";
import LvColorpicker from "lightvue/color-picker";

export default {
  props: ["editaItem", "typeView", "fontOptions", "divWidth", "palette"],
  components: {
    ModalIcons,
    LvInput,
    Multiselect,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      idxTable: null,
      linkPadding: true,
      linkMargin: true,
      global: false,
      selectedResource: null,
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
        { value: "compartilhar", text: "Compartilhar no WhatsApp" },
        { value: "chamar", text: "Chamar no WhatsApp" },
        { value: "telefone", text: "Número de telefone" },
      ],
    };
  },
  computed: {},
  methods: {
    iconSelect(item) {
      if (typeof this.idxTable != "number") {
        this.editaItem.propsData.styles.icons.icon = item;
        return;
      }
      this.editaItem.propsData.rows[this.idxTable].resource[
        this.selectedResource
      ].icon = item;
    },
    moveUp(idx, isResource) {
      if (isResource) {
        var moving = this.editaItem.propsData.rows[this.idxTable].resource[idx];
        this.editaItem.propsData.rows[this.idxTable].resource.splice(idx, 1);
        this.editaItem.propsData.rows[this.idxTable].resource.splice(
          idx - 1,
          0,
          moving
        );
        return;
      }
      var moving = this.editaItem.propsData.rows[idx];
      this.editaItem.propsData.rows.splice(idx, 1);
      this.editaItem.propsData.rows.splice(idx - 1, 0, moving);
    },
    moveDown(idx, isResource) {
      if (isResource) {
        var moving = this.editaItem.propsData.rows[this.idxTable].resource[idx];
        this.editaItem.propsData.rows[this.idxTable].resource.splice(idx, 1);
        this.editaItem.propsData.rows[this.idxTable].resource.splice(
          idx + 1,
          0,
          moving
        );
        return;
      }
      var moving = this.editaItem.propsData.rows[idx];
      this.editaItem.propsData.rows.splice(idx, 1);
      this.editaItem.propsData.rows.splice(idx + 1, 0, moving);
    },
    addItems(isResource) {
      if (isResource) {
        this.editaItem.propsData.rows[this.idxTable].resource.push({
          text: "Recurso fantástico",
          icon: "emoji_flags",
          iconTrue: true,
        });
        return;
      }
      if (this.editaItem.propsData.rows.length <= 4) {
        this.editaItem.propsData.rows.push({
          title: "Plano novo!",
          price: "249,90",
          time: "Por mês",
          resource: [
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
            { text: "Recurso fantástico", icon: "emoji_flags", iconTrue: true },
          ],
          button: {
            href_button: "",
            blank: false,
            share_text: "",
            content_button: "Clique aqui",
            type: { value: "externo", text: "Externo" },
          },
        });
      } else {
        this.mostrarMensagem();
      }
    },
    double(idx) {
      if (this.editaItem.propsData.rows.length <= 4) {
        let copy = JSON.parse(
          JSON.stringify(this.editaItem.propsData.rows[idx])
        );
        this.editaItem.propsData.rows.splice(idx, 0, copy);
      } else {
        this.mostrarMensagem();
      }
    },
    removeItems(index, isResource) {
      if (isResource) {
        if (this.editaItem.propsData.rows[this.idxTable].resource.length > 1) {
          this.editaItem.propsData.rows[this.idxTable].resource.splice(
            index,
            1
          );
        } else {
          this.mostrarMensagem();
        }
        return;
      }
      if (this.editaItem.propsData.rows.length > 1) {
        this.editaItem.propsData.rows.splice(index, 1);
      } else {
        this.mostrarMensagem();
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    mostrarMensagem() {
      this.$bvToast.toast(`Limite de itens atingido`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    openModal(data, idx) {
      this.$bvModal.show(data);
      if (idx) {
        this.selectedResource = idx;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.grid-templates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.templates {
  border: 1px solid #c0c0c0 !important;
  border-radius: 10px;
  font-size: 9px;
  text-align: center;
  line-height: 1;
  border-radius: 7px;
  background-color: #f1f1f1;
  width: 100%;
  padding: 15px 0;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: #e7e7e7;
  }
  .t-resources {
    padding: 2px;
    position: relative;
    font-size: 9px;
  }

  .t-separator::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 1px;
    background-color: #3334;
  }

  .t-separator:last-of-type::after {
    display: none;
  }

  .t-stripe:nth-child(odd) {
    background-color: #aaaaaa36;
  }

  .t-button {
    padding: 3px;
    color: #aaaaaa;
    background-color: #333333;
    border-radius: 3px;
    margin: 5px 20px 0;
  }

  .t-value {
    font-weight: 800;
    font-size: 22px;
  }
}
.template-selected {
  background-color: #d4d4d4 !important;
  * {
    color: #414040 !important;
  }
}
.template1 {
  padding-top: 95px;
  .head-template {
    font-size: 9px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 1;
    background-color: #aaaaaa99;
    .t-title {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .t-value {
      font-weight: 800;
      font-size: 12px;
    }
  }
}
.template2 {
  padding-top: 35px;
  .head-template {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 1;
    background-color: #aaaaaa99;
    .t-title {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  .t-time {
    margin-bottom: 5px;
  }
}
.template3 {
  padding: 30px 0 10px 0;
  .head-template {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    display: flex;
    padding-top: 10px;
    line-height: 1;
    background-color: #aaaaaa99;
    .t-title {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      width: 100%;
    }
  }
  .t-div {
    border-radius: 5px;
    padding: 5px 5px 2px;
    margin: 0 25px 5px;
    background-color: #e7e7e7;
    z-index: 1;
    position: relative;
  }
  .t-time {
    margin-bottom: 5px;
    font-size: 6px;
  }
  .t-button {
    margin-top: 10px;
  }
}
.template4 {
  padding-top: 10px;
  .head-template {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #aaaaaa49;
  }
  .t-title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0px 0 15px;
  }
  .t-value {
    position: relative;
    z-index: 1;
  }
  .t-time {
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
  }
}
</style>