<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <!-- Geral -->
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo"> Logo </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.image.has_logo"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="select-image">
              <div @click="openModal('Upload-Editor')">
                <img
                  :src="
                    editaItem.propsData.image.img_logo
                      ? editaItem.propsData.image.img_logo
                      : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                  "
                  alt="Imagem preview"
                />
              </div>
              <div>
                <input
                  type="text"
                  v-model="editaItem.propsData.image.img_logo"
                />
                <span @click="openModal('Upload-Editor')">{{
                  editaItem.propsData.image.img_logo
                    ? "Alterar imagem"
                    : "Selecionar imagem"
                }}</span>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="half">
              <label class="titulo">Links</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button
                  class="btn button-add-produto"
                  @click="addLink"
                ></button>
                <button
                  v-if="editaItem.propsData.links.length > 1"
                  class="btn button-remove-produto"
                  @click="removeItem"
                ></button>
                <button
                  v-else
                  class="btn button-remove-produto"
                  @click="mostrarMensagem"
                ></button>
              </div>
            </div>
            <div v-for="(link, idx) in editaItem.propsData.links" :key="idx">
              <div class="label-range">
                <label class="subtitulo" style="margin-top: 10px !important"
                  >Link {{ idx + 1 }}</label
                >
                <button
                  v-if="editaItem.propsData.links.length > 1"
                  class="btn button-remove-produto"
                  @click="removeImgIndex(idx)"
                ></button>
                <button
                  v-else
                  class="btn button-remove-produto"
                  @click="mostrarMensagem"
                ></button>
              </div>
              <div style="padding: 0px 5px">
                <input
                  id="menu"
                  placeholder="Sobre nós"
                  v-model="editaItem.propsData.links[idx].name"
                />
                <div class="space"></div>
                <input
                  id="menu"
                  placeholder="https://seulink"
                  v-model="editaItem.propsData.links[idx].link"
                />
                <div class="space"></div>
                <div
                  class="half-menu"
                  v-if="editaItem.propsData.links[0].hasOwnProperty('blank')"
                >
                  <div class="child">
                    <multiselect
                      :options="optionsType"
                      v-model="editaItem.propsData.links[idx].type"
                      :searchable="false"
                      :show-labels="false"
                      :allow-empty="false"
                      placeholder="Tipo de link"
                      label="text"
                      track-by="value"
                    ></multiselect>
                  </div>
                  <div
                    class="child"
                    style="display: flex; width: 50%; transition: all 0.3s"
                  >
                    <label class="subtitulo" style="margin: 0 !important"
                      >Abrir em nova guia</label
                    >
                    <b-form-checkbox
                      style="
                        margin-top: -5px;
                        margin-left: auto;
                        margin-right: 0;
                        z-index: 0;
                      "
                      v-model="editaItem.propsData.links[idx].blank"
                      name="is_main"
                      size="lg"
                      switch
                    >
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <!-- Estilo -->
        <b-tab title="Estilo">
          <div>
            <div class="separador"></div>

            <!-- Cores -->
            <div class="content">
              <label class="titulo">Cores</label>
              <label class="sub">Fundo</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.background_header"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Links</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.color_itens"
                label="Cor dos Links"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <div class="separador"></div>

            <!-- Logo -->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Logo</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>
              <div class="half">
                <div>
                  <label class="sub">Altura</label>
                  <input
                    id="menu"
                    placeholder="0"
                    v-model="editaItem.propsData.size[typeView].height"
                    type="number"
                    @input="globalInput($event, 'height')"
                  />
                </div>
                <div>
                  <label class="sub">Largura</label>
                  <input
                    id="menu"
                    placeholder="0"
                    v-model="editaItem.propsData.size[typeView].width"
                    type="number"
                    @input="globalInput($event, 'width')"
                  />
                </div>
              </div>
            </div>
            <div class="separador"></div>

            <!-- Dimensões -->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>
              <label class="sub">Altura mínima</label>
              <input
                id="menu"
                placeholder="00"
                v-model="editaItem.propsData.size[typeView].min_height"
                type="number"
                @input="globalInput($event, 'min_height')"
              />
            </div>
            <div class="separador"></div>

            <!--  Espaçamento entre os links-->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Espaçamento</label>
                <div>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet'"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile'"
                  />
                </div>
              </div>

              <label class="sub">Espaçamento entre links</label>
              <input
                id="menu"
                placeholder="00"
                v-model="editaItem.propsData.size[typeView].gap_itens"
                type="number"
                @input="globalInput($event, 'gap_itens')"
              />
            </div>
            <div class="separador"></div>

            <!-- Fonte -->
            <div
              class="content max-multi"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            >
              <label class="titulo">Fonte</label>
              <multiselect
                v-model="editaItem.propsData.styles.font"
                :options="fontOptions"
                :searchable="true"
                :show-labels="false"
                placeholder="Fonte"
                :hide-selected="true"
                :allow-empty="false"
                @input="$emit('callCharge',$event)"
              ></multiselect>
            </div>
            <div
              class="separador"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            ></div>

            <div class="content"></div>
          </div>
        </b-tab>

        <!-- Avançado -->
        <b-tab title="Avançado">
          <div class="separador"></div>
          <!-- Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  @change="$emit('fixWidth')"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],

  components: {
    Multiselect,
    Upload,
    BaseButton,
    LvInput,
    LvColorpicker: LvColorpicker,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
      ],
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalInput(px, place) {
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        var value = px.target.value;
        props.tablet[place] = value;
        props.mobile[place] = value;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    setImg(img) {
      this.editaItem.propsData.image.img_logo = img;
    },
    mostrarMensagem() {
      this.$bvToast.toast(`Necessário ao menos um link`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    removeImgIndex(index) {
      this.editaItem.propsData.links.splice(index, 1);
    },
    addLink() {
      this.editaItem.propsData.links.push({
        blank: false,
        link: "https://greenn.com.br/",
        name: "Novo link",
        type: {
          text: "Externo",
          value: "externo",
        },
      });
    },
    removeItem() {
      this.editaItem.propsData.links.pop();
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    mg_logo(valor) {
      this.block_rotate_logo = valor;
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}

.half-menu {
  display: flex;
  max-width: 100% !important;
  gap: 10px;
  .child {
    width: 50% !important;
    align-items: center;
    justify-content: center;
  }
}
</style>
