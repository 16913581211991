<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Layout do card de depoimento -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Layout</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Foto</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.hasPicture" size="lg" switch>
                </b-form-checkbox>
              </div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="subtitulo">Estrelas</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0;"
                  v-model="editaItem.propsData.styles.hasStar" size="lg" switch id="input-max-width">
                </b-form-checkbox>
              </div>
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <!-- <div style="display: flex; width: 100%; transition: all 0.3s; margin-top: 5px;">
                <label class="subtitulo">Carrossel</label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.styles.isCarousel" size="lg" switch>
                </b-form-checkbox>
              </div> -->
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'left' }"
                    @click="editaItem.propsData.styles.align = 'left'"><img
                      src="@/assets/editor/align-left.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'center' }"
                    @click="editaItem.propsData.styles.align = 'center'"><img
                      src="@/assets/editor/align-center.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.styles.align == 'right' }" style="z-index: 2"
                    @click="editaItem.propsData.styles.align = 'right'"><img
                      src="@/assets/editor/align-right.svg" /></button>
                </div>
              </div>
            </div>

            <div class="card-type" :class="{
              'card-selected': editaItem.propsData.styles.layout == 1,
              'card-center': editaItem.propsData.styles.align == 'center', 'card-right': editaItem.propsData.styles.align == 'right'
            }" @click="editaItem.propsData.styles.layout = 1">
              <span v-if="editaItem.propsData.styles.hasPicture" class="material-icons card-icon">account_circle</span>
              <p class="card-title">
                Nome do usuário
              </p>
              <div v-if="editaItem.propsData.styles.hasStar">
                <span class="material-icons card-stars" v-for="n in 5" :key="n">
                  star
                </span>
              </div>
              <p class="card-content">
                Exemplo de depoimento que pode ser adicionado à página para dar mais credibilidade ao produto ofertado. É
                recomendado que as avaliações sejam verídicas.
              </p>
            </div>

            <div class="card-type card-2"
              :class="{ 'card-selected': editaItem.propsData.styles.layout == 2, 'card-inverse': editaItem.propsData.styles.align == 'right', 'card-right': editaItem.propsData.styles.align == 'right', 'card-center': editaItem.propsData.styles.align == 'center', }"
              @click="editaItem.propsData.styles.layout = 2">
              <div v-if="editaItem.propsData.styles.hasPicture">
                <div>
                  <span class="material-icons card-icon">account_circle</span>
                </div>
                <div v-if="editaItem.propsData.styles.hasStar">
                  <span class="material-icons card-stars" v-for="n in 4" :key="n">
                    star
                  </span>
                </div>
              </div>
              <div style="margin-top: -55px; margin-right: -10px;">
                <span class="material-icons"
                  :style="editaItem.propsData.styles.align == 'right' ? 'color: #838383;' : 'transform: rotate(180deg); color: #838383;'">
                  format_quote
                </span>
              </div>
              <div>
                <p class="card-content">
                  Exemplo de depoimento que pode ser adicionado à página para dar mais credibilidade ao produto ofertado.
                  É recomendado que as avaliações sejam verídicas.
                </p>
                <div v-if="editaItem.propsData.styles.hasStar && !editaItem.propsData.styles.hasPicture">
                  <span class="material-icons card-stars" v-for="n in 4" :key="n">
                    star
                  </span>
                </div>
                <p class="card-name">
                  - Nome do usuário
                </p>
              </div>
            </div>
          </div>

          <!-- Depoimentos -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Depoimentos</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button class="btn button-add-produto" @click="addImg"></button>
                </div>
              </div>
            </div>
            <div v-for="(slide, idx) in editaItem.propsData.slides" :key="idx">
              <div class="p-2" style="position: relative; display: flex; justify-content: space-between;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Depoimento {{ idx + 1 }}</label>
                <div class="image-control2">
                  <img :class="{ 'disabled-arrow': idx == 0 }" class="first-arrow" src="@/assets/editor/chevron-down.svg"
                    @click="idx == 0 ? '' : moveUp(idx)" title="Mover para cima" />
                  <button :disabled="editaItem.propsData.slides.length == 1" class="button-remove-produto"
                    @click="removeImgIndex(idx)"></button>
                  <img :class="{ 'disabled-arrow': idx + 1 == editaItem.propsData.slides.length }" class="last-arrow"
                    src="@/assets/editor/chevron-down.svg"
                    @click="idx + 1 == editaItem.propsData.slides.length ? '' : moveDown(idx)" title="Mover para baixo" />
                </div>
              </div>
              <div class="select-image" v-if="editaItem.propsData.styles.hasPicture">
                <div @click="openModal('Upload-Editor', idx)">
                  <img :src="slide.imagem ? slide.imagem : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview" style="border-radius: 1000px;">
                </div>
                <div>
                  <input type="text" v-model="slide.imagem">
                  <span @click="openModal('Upload-Editor', idx)">{{ slide.imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                </div>
              </div>
              <div v-if="editaItem.propsData.styles.hasPicture" class="space"></div>
              <input type="text" v-model="slide.name" placeholder="Nome do usuário">
              <div class="space"></div>
              <div class="rating" v-if="editaItem.propsData.styles.hasStar">
                <span class="material-icons" v-for="n in 5" :key="n" style="position: relative; font-size: 24px; cursor: pointer;"
                  @click.self="slide.rating = n">
                  {{ slide.rating >= n ? 'star' : (slide.rating > n - 1) ? 'star_half' : 'star_border' }}
                  <div style="width: 50%; height: 100%; position: absolute; top: 0; left: 0;"
                    @click.self="slide.rating = n - 0.5"></div>
                </span>
              </div>
              <div class="space" v-if="editaItem.propsData.styles.hasStar"></div>
              <textarea placeholder="Depoimento" v-model="slide.text" name="Depoimento" id="" cols="30"
                rows="5"></textarea>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>

          <!-- Fundo -->
          <div class="content">
            <!-- Tipo de fundo -->
            <label class="titulo">Fundo</label>
            <multiselect 
              v-model="editaItem.propsData.styles.card.background.type" 
              :options="['Cor sólida', 'Gradiente']"
              :searchable="false" 
              :show-labels="false" 
              placeholder="Fonte" 
              :hide-selected="true" 
              :allow-empty="false">
            </multiselect>
            <div class="space"></div>

            <!-- Fundo Gradiente -->
            <div class="half" v-if="editaItem.propsData.styles.card.background.type == 'Gradiente'">
              <div>
                <label class="subtitulo"> Cor 1 </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.grad1"
                  label="Cor do texto" :colors="palette"></LvColorpicker>
              </div>
              <div>
                <label class="subtitulo"> Cor 2 </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.grad2"
                  label="Cor do texto" :colors="palette"></LvColorpicker>
              </div>
            </div>

            <div v-if="editaItem.propsData.styles.card.background.type == 'Gradiente'">
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Angulo do gradiente</label>
                <input type="number" v-model="editaItem.propsData.styles.card.background.gradAngle" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.card.background.gradAngle" type="range" min="0" max="360"
                step="1"></b-form-input>
            </div>

            <!-- Fundo sólido -->
            <div v-else>
              <label class="subtitulo"> Cor do fundo</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
              <div class="space"></div>
              <label class="subtitulo"> Cor do fundo ao passar o mouse</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.card.background.hover_color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
            </div>
            <div class="space"></div>
            <label class="sub">Posicionamento</label>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Mídia -->
          <div v-if="editaItem.propsData.styles.hasPicture || editaItem.propsData.styles.hasStar" class="content">
            <label class="titulo">Mídias</label>
            <div v-if="editaItem.propsData.styles.hasPicture">
              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Tamanho da foto (px)</label>
                  <div>
                    <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                    <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                      @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                    <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                      @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                    <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                      @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                  </div>
                </div>
                <input
                  type="number"
                  v-model="editaItem.propsData.styles.picture.size[typeView]"
                />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.picture.size[typeView]" type="range" min="20" max="350"
                step="10"></b-form-input>
            </div>
            <div v-if="editaItem.propsData.styles.hasStar">
              <div>
                <div class="label-range">
                  <div class="label-icon">
                    <label class="subtitulo">Tamanho das estrelas</label>
                    <div>
                      <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                      <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                        @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                      <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                        @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                      <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                        @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                    </div>
                  </div>
                  <input
                    type="number"
                    v-model="editaItem.propsData.styles.stars.size[typeView]"
                  />
                </div>
                <b-form-input v-model="editaItem.propsData.styles.stars.size[typeView]" type="range" min="4" max="60"
                  step="2"></b-form-input>
              </div>

              <label class="subtitulo"> Cor das estrelas</label>
              <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.stars.color"
                label="Cor do texto" :colors="palette"></LvColorpicker>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Textos -->
          <div class="content">
            <label class="titulo">Textos</label>

            <!-- Cores -->
            <div class="half">
              <div>
                <label class="subtitulo"> Cor do texto </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.text.color" label="Cor do texto"
                  :colors="palette"></LvColorpicker>
              </div>
              <div>
                <label class="subtitulo"> Cor do nome </label>
                <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.name.color" label="Cor do texto"
                  :colors="palette"></LvColorpicker>
              </div>
            </div>

            <!-- Tamanho do texto-->
            <div class="space"></div>
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho do texto</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <div class="mt-2" style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  ">
                <b-button-group>
                  <b-button :disabled="editaItem.propsData.styles.text.size[typeView] == 0
                    " size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                    @click="editaItem.propsData.styles.text.size[typeView]--, globalInput(editaItem.propsData.styles.text.size[typeView], 'styles', 'size')"><img
                      src="@/assets/editor/minus3.svg" alt="align" /></b-button>
                  <b-form-input @input="globalInput($event, 'styles', 'size')" class="text-right"
                    v-model="editaItem.propsData.styles.text.size[typeView]" style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                      "></b-form-input>
                  <b-button
                    @click="editaItem.propsData.styles.text.size[typeView]++, globalInput(editaItem.propsData.styles.text.size[typeView], 'styles', 'size')"
                    size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
                </b-button-group>

                <div>
                  <multiselect v-model="editaItem.propsData.styles.text.font_measure[typeView]"
                    :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                    :hide-selected="true" :allow-empty="false"
                    @input="globalInput(editaItem.propsData.styles.text.font_measure[typeView], 'styles', 'font_measure')">
                  </multiselect>
                </div>
              </div>
            </div>

            <!-- Tamanho do nome -->
            <div class="space"></div>
            <div class="space"></div>
            <div>
              <div class="label-icon">
                <label class="subtitulo" style="margin: 5px 0 0 0 !important;">Tamanho do nome</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important; margin: 0"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <div class="mt-2" style="
                    display: flex;
                    gap: 10px;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: -1rem;
                  ">
                <b-button-group>
                  <b-button :disabled="editaItem.propsData.styles.name.size[typeView] <= 1
                    " size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "
                    @click="editaItem.propsData.styles.name.size[typeView]--, globalInput(editaItem.propsData.styles.name.size[typeView], 'styles', 'size')"><img
                      src="@/assets/editor/minus3.svg" alt="align" /></b-button>
                  <b-form-input @input="globalInput($event, 'styles', 'size')" class="name-right"
                    v-model="editaItem.propsData.styles.name.size[typeView]" style="
                        width: 44px;
                        text-align: center !important;
                        border: none !important;
                        padding: 0 !important;
                      "></b-form-input>
                  <b-button
                    @click="editaItem.propsData.styles.name.size[typeView]++, globalInput(editaItem.propsData.styles.name.size[typeView], 'styles', 'size')"
                    size="sm" style="
                        height: min-content !important;
                        padding: 10.5px 7px !important;
                      "><img src="@/assets/editor/plus.svg" alt="align" /></b-button>
                </b-button-group>

                <div>
                  <multiselect v-model="editaItem.propsData.styles.name.font_measure[typeView]"
                    :options="['px', 'rem', '%', 'em']" :searchable="false" :show-labels="false" placeholder=" "
                    :hide-selected="true" :allow-empty="false"
                    @input="globalInput(editaItem.propsData.styles.name.font_measure[typeView], 'styles', 'font_measure')">
                  </multiselect>
                </div>
              </div>
            </div>

            <!-- Fonte -->
            <div class="space"></div>
            <div class="space"></div>
            <div class="max-multi">
              <label class="subtitulo">Fonte</label>
              <multiselect 
                v-model="editaItem.propsData.styles.text.font" 
                :options="fontOptions"
                @input="$emit('callCharge',$event)" 
                :searchable="true"
                :show-labels="false"
                placeholder="Fonte" 
                :hide-selected="true" 
                :allow-empty="false">
              </multiselect>
              <div class="space"></div>
            </div>

            <!-- Espessura do texto -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte (texto)</label>
                <input type="number" v-model="editaItem.propsData.styles.text.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.text.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

            <!-- Espessura do nome -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Espessura da fonte (nome)</label>
                <input type="number" v-model="editaItem.propsData.styles.name.font_weight" />
              </div>
              <b-form-input v-model="editaItem.propsData.styles.name.font_weight" type="range" min="100" max="900"
                step="100"></b-form-input>
            </div>

          </div>
          <div class="separador"></div>

          <!-- Tempo -->
          <div class="content" v-if="editaItem.propsData.styles.isCarousel">
            <label class="titulo">Temporarizador</label>
            <label class="subtitulo">Selecione o tempo em segundos para os depoimentos mudarem</label>
            <div class="space"></div>
            <div class="focus-control">
              <button :disabled="editaItem.propsData.styles.timer <= 1" style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                " @click="
                  editaItem.propsData.styles.timer--
                  ">
                -
              </button>
              <input type="number" style="
                  height: 40px !important;
                  border-radius: 0px !important;
                  text-align: center;
                " min="1" @input="validTimer()" v-model="editaItem.propsData.styles.timer" />
              <button style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                " @click="
                  editaItem.propsData.styles.timer++
                  ">
                +
              </button>
            </div>
          </div>
          <div class="separador" v-if="editaItem.propsData.styles.isCarousel"></div>

          <!-- Bordas -->
          <div class="content">
            <label class="titulo">Bordas</label>
            <div class="label-range">
              <label class="subtitulo">Arredondar bordas</label>
              <input type="number" v-model="editaItem.propsData.styles.card.border.radius" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles.card.border.radius" type="range" min="0" max="75"
              step="1"></b-form-input>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }"
                class="subtitulo">Bordas
                visíveis</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.card.border.hasBorder" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <div class="space"></div>
            <label class="subtitulo" :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }"> Cor
              da
              borda </label>
            <LvColorpicker :class="{ colorDisabled: editaItem.propsData.styles.card.border.hasBorder == false }"
              :disabled="editaItem.propsData.styles.card.border.hasBorder == false" id="LvColorpicker"
              v-model="editaItem.propsData.styles.card.border.color" label="Cor do texto" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.border.hasBorder == false }">Espessura</label>
              <input :disabled="editaItem.propsData.styles.card.border.hasBorder == false" type="number"
                v-model="editaItem.propsData.styles.card.border.width" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.border.hasBorder == false"
              v-model="editaItem.propsData.styles.card.border.width" type="range" min="0" max="5"
              step="0.1"></b-form-input>
          </div>
          <div class="separador"></div>

          <!-- Sombras -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }"
                class="titulo">Sombra</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.card.shadow.hasShadow" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <div class="space"></div>
            <label class="subtitulo" :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }"> Cor
              da
              Sombra </label>
            <LvColorpicker :class="{ colorDisabled: editaItem.propsData.styles.card.shadow.hasShadow == false }"
              :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" id="LvColorpicker"
              v-model="editaItem.propsData.styles.card.shadow.color" label="Cor do texto" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Borrão</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.blur" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.blur" type="range" min="0" max="10"
              step="0.5"></b-form-input>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Posição
                Horizontal</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.x" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.x" type="range" min="-50" max="50" step="1"></b-form-input>
            <div class="label-range">
              <label class="subtitulo"
                :class="{ labelOff: editaItem.propsData.styles.card.shadow.hasShadow == false }">Posição
                Vertical</label>
              <input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false" type="number"
                v-model="editaItem.propsData.styles.card.shadow.y" />
            </div>
            <b-form-input :disabled="editaItem.propsData.styles.card.shadow.hasShadow == false"
              v-model="editaItem.propsData.styles.card.shadow.y" type="range" min="-50" max="50" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'" @click="linkMargin = !linkMargin" />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_left
                      " @input="attrLinkMargin($event, 'left')" min="0" max="100" />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_top
                          " @input="attrLinkMargin($event, 'top')" min="0" max="100" />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'" @click="linkPadding = !linkPadding" />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_left
                                " @input="paddingLink($event, 'left')" min="0" max="100" />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                    .padding_top
                                    " @input="paddingLink($event, 'top')" min="0" max="100" />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                  .padding_bottom
                                  " @input="paddingLink($event, 'bottom')" min="0" max="100" />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_right
                                " @input="paddingLink($event, 'right')" min="0" max="100" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_bottom
                          " @input="attrLinkMargin($event, 'bottom')" min="0" max="100" />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_right
                      " @input="attrLinkMargin($event, 'right')" min="0" max="100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>
            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos grandesﾠ </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor" size="lg" @change="$emit('fixWidth')" switch>
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos médiosﾠ </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>

            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos pequenosﾠ </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                    .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                    .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                    <div class="simulated-item simu3"> 
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                    <input
                      type="number"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                    <input
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub">IDﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID" /></label>
            <input v-model="editaItem.propsData.custom.c_id" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: id_unico" />
            <label class="sub">Classeﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes." /></label>
            <input v-model="editaItem.propsData.custom.c_class" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: classe_1 classe_2" />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],

  components: {
    Upload,
    BaseButton,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      block_rotate_logo: 0,
      img_carousel_index: null,
      proportion: "Personalizado",
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx + 1, 0, moving);
    },
    validTimer() {
      if (this.editaItem.propsData.styles.carousel.timer < 1) {
        this.editaItem.propsData.styles.carousel.timer = 1
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet.colorIndex = px;
        props.mobile.colorIndex = px;
      }
    },
    globalWidth(place, pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    addImg() {
      this.editaItem.propsData.slides.push({
        imagem: "https://gdigital.s3.amazonaws.com/gdigital/1/user-undefined.webp",
        name: "",
        rating: 5,
        text: "",
      });
    },
    dimensions() {
      if (this.editaItem.propsData.visibility.compleat) {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.compleat_padding_bottom = 100;
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.compleat_padding_bottom = 50;
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.compleat_padding_bottom = 56.25;
          return;
        }
        if (this.proportion == "Personalizado") {
          this.editaItem.propsData.compleat_padding_bottom = 56.25;
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.compleat_padding_bottom = 125;
          return;
        }
      } else {
        this.editaItem.propsData.compleat_padding_bottom = 0;
      }
    },
    removeImgIndex(index) {
      this.editaItem.propsData.slides.splice(index, 1);
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.slides[this.img_carousel_index].imagem = img;
    },
    mg_logo(valor) {
      this.block_rotate_logo = valor;
    },
    proportions(item) {
      if (item == "largura") {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width
          );
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width / 2
          );
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 1080) * 608
          );
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 4) * 5
          );
          return;
        }
        return;
      }
      if (item == "altura") {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          return;
        }
        return;
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.rating {
  display: flex;
  justify-content: center;
  border: 1px solid #ededf0 !important;
  height: 45px;
  border-radius: 10px;
  align-items: center;
  transition: all 0.5s;

  &:hover {
    border-color: var(--greenn) !important;
  }
}

.focus-control {
  display: flex;

  button {
    height: 40px;
    width: 40px;
    background-color: #00000000;
    border: 1px solid #eee !important;
    font-size: 20px;
    transition: all 0.3s;
  }

  input {
    width: 40px !important;
    background-color: #00000000 !important;
    border: 1px solid #eee !important;
    font-size: 20px !important;
    padding: 15px 0px !important;
  }
}

.card-type {
  border-radius: 7px;
  background-color: #f1f1f1;
  border: 1px dotted #c0c0c0;
  width: 100%;
  padding: 20px;
  margin-top: 1rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #e7e7e7;
  }

  .card-icon {
    font-size: 48px;
    transition: all 0.3s;
    color: #838383;
  }

  .card-stars {
    font-size: 12px;
    transition: all 0.3s;
    color: #838383;
  }

  .card-title {
    transition: all 0.3s;
    color: #838383;
    font-size: 12px;
    margin: 0 !important;
  }

  .card-content {
    transition: all 0.3s;
    color: #838383;
    font-size: 8px;
  }

  .card-name {
    transition: all 0.3s;
    color: #838383;
    font-size: 8px;
    text-align: end;
  }
}

.card-center {
  justify-content: center;
  text-align: center;
}

.card-right {
  justify-content: end;
  text-align: right;
}

.card-inverse {
  flex-direction: row-reverse;
}

.card-2 {
  display: flex;
  gap: 15px;
  align-items: center;
}

.card-3 {
  display: flex;
  gap: 15px;
  align-items: center;

  .card-title {
    line-height: 1;
    margin: 0;
  }
}
</style>
