<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active>
        <div>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Área de código</label>
            <textarea
              v-focus
              v-model="editaItem.propsData.content.htmlContent"
              class="codigo"
              name="html"
              id="html"
              cols="30"
              rows="20"
            ></textarea>
          </div>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <div class="separador"></div>

        <!-- Componente de Espaçamento -->
        <div class="content">
          <!-- Ícones -->
          <div class="label-icon">
            <label class="titulo">Espaçamento</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="global"
            />
            <img
              src="@/assets/editor/monitor.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('tablet')"
              v-if="typeView === 'desktop' && !global"
            />
            <img
              src="@/assets/editor/tablet.svg"
              alt="tablet"
              style="filter: invert(50%) !important"
              @click="nextIcon('mobile')"
              v-if="typeView === 'tablet' && !global"
            />
            <img
              src="@/assets/editor/phone.svg"
              alt="phone"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="typeView === 'mobile' && !global"
            />
          </div>
          <!-- Inputs -->
          <div class="container-spacing" style="padding-bottom: 15px">
            <div class="border-dash">
              <span class="txt-mg">MARGEM EXTERNA</span>
              <img
                src="@/assets/editor/link-click.svg"
                alt="Link"
                class="links-advanced"
                :class="linkMargin ? '' : 'link-disabled'"
                @click="linkMargin = !linkMargin"
              />
              <div class="alinhamento">
                <!-- MARGIN LEFT -->
                <div class="item-1">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_left"
                    @input="attrLinkMargin($event, 'left')"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="item-2">
                  <div class="alinhamento-2">
                    <!-- MARGIN TOP -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_top
                        "
                        @input="attrLinkMargin($event, 'top')"
                        min="0"
                        max="100"
                      />
                    </div>
                    <div>
                      <div class="contain-borders">
                        <span class="txt-mg">MARGEM INTERNA</span>
                        <img
                          src="@/assets/editor/link-click.svg"
                          alt="Link"
                          class="links-advanced"
                          :class="linkPadding ? '' : 'link-disabled'"
                          @click="linkPadding = !linkPadding"
                        />
                        <div class="alinhamento">
                          <!-- PADDING LEFT -->
                          <div class="item-1">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_left
                              "
                              @input="paddingLink($event, 'left')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div class="item-2">
                            <div class="alinhamento-2">
                              <!-- PADDING TOP -->
                              <div>
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_top
                                  "
                                  @input="paddingLink($event, 'top')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                              <div>
                                <div class="border-final"></div>
                              </div>
                              <!-- PADDING BOTTOM -->
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_bottom
                                "
                                @input="paddingLink($event, 'bottom')"
                                min="0"
                                max="100"
                              />
                              <div></div>
                            </div>
                          </div>
                          <!-- PADDING RIGHT -->
                          <div class="item-3">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_right
                              "
                              @input="paddingLink($event, 'right')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN BOTTOM -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_bottom
                        "
                        @input="attrLinkMargin($event, 'bottom')"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
                <!-- MARGIN RIGHT -->
                <div class="item-3">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_right"
                    @input="attrLinkMargin($event, 'right')"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Visilibilidade -->
        <div class="content">
          <label class="titulo"> Visibilidade </label>

          <!-- Dispositivos grandes -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos grandesﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Maiores que 768 pixels de largura, como laptops e computadores"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.monitor"
                @change="$emit('fixWidth')"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos médios -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos médiosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Entre 480 a 768 pixels de largura, como tablets"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.tablet"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos pequenosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Menores que 480 pixels de largura, como celulares"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.phone"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Animação -->
        <div class="content">
          <label class="titulo">Animação</label>
          <div class="select-animation">
            <div class="animation-container">
              <div>
                <p>Nenhum</p>
                <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                  .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                  <div class="simulated-item simu1"></div>
                </div>
              </div>
              <div>
                <p>Zoom</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                  .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                  <div class="simulated-item simu2">
                    <img src="@/assets/editor/square-logo.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Up</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                  <div class="simulated-item simu3"> 
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Right</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                  <div class="simulated-item simu4">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Down</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                  <div class="simulated-item simu5">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Left</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                  <div class="simulated-item simu6">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div class="half-animation">
              <div>
                <div class="label-range">
                  <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                  <input
                    type="number"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    v-model="editaItem.propsColumn.delay"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.delay"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  type="range"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
              <div>
                <div class="label-range">
                  <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                  <input
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="number"
                    v-model="editaItem.propsColumn.duration"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.duration"
                  type="range"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>
        <div class="content">
          <label class="titulo"> Scripts Externos</label>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Priorizar carregamentoﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Prioriza o carregamento do script sem estar visivel"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.scriptRender"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: ["editaItem", "typeView"],
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    if (!this.editaItem.propsData.scriptRender) {
      this.editaItem.propsData.scriptRender = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.codigo {
  display: block;
  font-family: monospace;
  white-space: pre;
}

textarea {
  padding: 6px 0 !important;
  height: auto !important;
}
</style>
