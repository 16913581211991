<template>
    <div id="SideRightTabs">
      <b-card no-body>
        <b-tabs pills justified small style="width: 100%">
          <b-tab title="Conteúdo" active>
            <!-- Controle de imagens -->
            <div class="separador"></div>
            <div class="content">
              <div class="container-links">
                <div class="half">
                  <label class="titulo">Imagens</label>
                  <div class="contain-buttons" style="margin-top: -15px">
                    <button
                      class="btn button-add-produto"
                      @click="addImg"
                    ></button>
                  </div>
                </div>  
              </div>
              <div v-for="(item, idx) in editaItem.propsData.gallery" :key="idx">
                <div class="p-2" style="position: relative;">
                  <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                  <label class="subtitulo component-group-title">Imagem {{ idx + 1 }}</label>
                </div>
                <div style="display: flex">
                  <div class="select-image" style="width: calc( 100% - 35px )">
                    <div @click="openModal('Upload-Editor', idx)">
                      <img :src="item.imagem ? item.imagem : 'https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp'" alt="Imagem preview">
                    </div>
                    <div>
                      <input type="text" v-model="editaItem.propsData.gallery[idx].imagem">
                      <span @click="openModal('Upload-Editor', idx)">{{ item.imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                    </div>
                  </div>
                  <div class="image-control">
                    <div>
                      <img
                        :class="{ 'disabled-arrow' : idx == 0}"
                        class="first-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx == 0 ? '' : moveUp(idx)"
                        title="Mover para cima"
                      />
                      <button
                        class="button-remove-produto"
                        @click="removeImgIndex(idx)"
                      ></button>
                      <img
                        :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.gallery.length}"
                        class="last-arrow"
                        src="@/assets/editor/chevron-down.svg"
                        @click="idx + 1 == editaItem.propsData.gallery.length ? '' : moveDown(idx)"
                        title="Mover para baixo"
                      />
                    </div>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>
            <div class="separador"></div>
          </b-tab>
  
          <b-tab title="Estilo">
            <div class="separador"></div>
            <!--Dimensões-->
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>

              <div class="label-range">
                <label
                  class="subtitulo"
                  >Quantidade de colunas</label
                >
                <input
                  type="number"
                  v-model="editaItem.propsData.styles.size[typeView]"
                  @input="globalize('size')"
                />
              </div>
              <b-form-input
                @input="globalize('size')"
                id="range-1"
                v-model="editaItem.propsData.styles.size[typeView]"
                type="range"
                min="1"
                max="8"
                step="1"
              ></b-form-input>

              <div class="label-range">
                <label
                  class="subtitulo"
                  >Espaçamento entre as fotos</label
                >
                <input
                  type="number"
                  @input="globalize('gap')"
                  v-model="editaItem.propsData.styles.gap[typeView]"
                />
              </div>
              <b-form-input
                id="range-1"
                v-model="editaItem.propsData.styles.gap[typeView]"
                @input="globalize('gap')"
                type="range"
                min="0"
                max="40"
                step="1"
              ></b-form-input>

              <label
                class="subtitulo"
                >Alinhamento vertical</label
              >
              <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.styles.align[typeView] == 'start',
                      }"
                      @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'start'"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.styles.align[typeView] == 'center',
                      }"
                      @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'center'"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.styles.align[typeView] == 'end',
                      }"
                      @click="globalize('align'), editaItem.propsData.styles.align[typeView] = 'end'"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
            </div>
            <div class="separador"></div>
            <!-- Sombra -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Sombra</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.shadow.hasShadow"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
              class="subtitulo"
              >Cor da sombra</label
            >
            <LvColorpicker
              id="LvColorpicker"
              :class="{ colorDisabled: editaItem.propsData.shadow.hasShadow == false, }"
              :disabled="editaItem.propsData.shadow.hasShadow == false"
              v-model="editaItem.propsData.shadow.shadowColor"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <div class="three">
              <div>
                <label
                  :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                  class="subtitulo"
                  >Eixo X</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowX"
                />
              </div>
              <div>
                <label
                  :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                  class="subtitulo"
                  >Eixo Y</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowY"
                />
              </div>
              <div>
                <label
                  :class="{ labelOff: editaItem.propsData.shadow.hasShadow == false }"
                  class="subtitulo"
                  >Borrão</label
                >
                <input
                  type="number"
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  v-model="editaItem.propsData.shadow.shadowBlur"
                />
              </div>
            </div>
          </div>
          <div class="separador"></div>
            <!-- Bordas -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s"> 
              <label class="titulo"  >Bordas</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.border"
                size="lg"
                switch>

              </b-form-checkbox>
            </div>
            <!-- Arredondar borda -->
            <div class="label-range">
              <label
                class="subtitulo"
                >Arredondar bordas</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.border.radius"
              />
            </div>
            <b-form-input
              id="range-1"
              v-model="editaItem.propsData.border.radius"
              type="range"
              min="0"
              max="100"
              step="1"
            ></b-form-input>
            <div class="space"></div>
            <div>
              <label
              :class="{ labelOff: editaItem.propsData.border.border == false }"
              class="subtitulo"
              >Estilo da borda</label>
              <multiselect
              
                :options="[
                  'Sólido',
                  'Tracejada',
                  'Pontilhada',
                  'Dupla',
                ]"
                :allow-empty="false"
                :searchable="false"
                :show-labels="false"
                v-model="editaItem.propsData.border.borderStyle"
                
                :disabled="editaItem.propsData.border.border == false"
              ></multiselect>
                <div class="space"></div>
                <div class="label-range">
              <label
                :class="{
                  labelOff: editaItem.propsData.border.border == false,
                }"
                class="subtitulo"
                >Espessura</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.border.borderSize"
                :disabled="editaItem.propsData.border.border == false"
              />
            </div>
            <b-form-input
              :disabled="editaItem.propsData.border.border == false"
              id="range-1"
              v-model="editaItem.propsData.border.borderSize"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
              
            <div class="space"></div>
            <label
              :class="{ labelOff: editaItem.propsData.border.border == false }"
              class="subtitulo"
            >Cor da borda</label>
            <LvColorpicker
              id="LvColorpicker"
              :disabled="editaItem.propsData.border.border == false"
              :class="{ colorDisabled: editaItem.propsData.border.border== false, }"
              
              v-model="editaItem.propsData.border.borderColor"
              :colors="palette"
            ></LvColorpicker>
            </div>
          </div>
          </b-tab>
  
          <b-tab title="Avançado">
            <!-- Espaçamento -->
            <div class="separador"></div>
            <!-- Componente de Espaçamento -->
            <div class="content">
              <!-- Ícones -->
              <div class="label-icon">
                <label class="titulo">Espaçamento</label>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
              <!-- Inputs -->
              <div class="container-spacing" style="padding-bottom: 15px">
                <div class="border-dash">
                  <span class="txt-mg">MARGEM EXTERNA</span>
                  <img
                    src="@/assets/editor/link-click.svg"
                    alt="Link"
                    class="links-advanced"
                    :class="linkMargin ? '' : 'link-disabled'"
                    @click="linkMargin = !linkMargin"
                  />
                  <div class="alinhamento">
                    <!-- MARGIN LEFT -->
                    <div class="item-1">
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_left
                        "
                        @input="attrLinkMargin($event, 'left')"
                        min="0"
                        max="100"
                      />
                    </div>
                    <div class="item-2">
                      <div class="alinhamento-2">
                        <!-- MARGIN TOP -->
                        <div>
                          <input
                            type="number"
                            v-model="
                              editaItem.propsData.spacing[typeView].margin_top
                            "
                            @input="attrLinkMargin($event, 'top')"
                            min="0"
                            max="100"
                          />
                        </div>
                        <div>
                          <div class="contain-borders">
                            <span class="txt-mg">MARGEM INTERNA</span>
                            <img
                              src="@/assets/editor/link-click.svg"
                              alt="Link"
                              class="links-advanced"
                              :class="linkPadding ? '' : 'link-disabled'"
                              @click="linkPadding = !linkPadding"
                            />
                            <div class="alinhamento">
                              <!-- PADDING LEFT -->
                              <div class="item-1">
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_left
                                  "
                                  @input="paddingLink($event, 'left')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                              <div class="item-2">
                                <div class="alinhamento-2">
                                  <!-- PADDING TOP -->
                                  <div>
                                    <input
                                      type="number"
                                      v-model="
                                        editaItem.propsData.spacing[typeView]
                                          .padding_top
                                      "
                                      @input="paddingLink($event, 'top')"
                                      min="0"
                                      max="100"
                                    />
                                  </div>
                                  <div>
                                    <div class="border-final"></div>
                                  </div>
                                  <!-- PADDING BOTTOM -->
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_bottom
                                    "
                                    @input="paddingLink($event, 'bottom')"
                                    min="0"
                                    max="100"
                                  />
                                  <div></div>
                                </div>
                              </div>
                              <!-- PADDING RIGHT -->
                              <div class="item-3">
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_right
                                  "
                                  @input="paddingLink($event, 'right')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- MARGIN BOTTOM -->
                        <div>
                          <input
                            type="number"
                            v-model="
                              editaItem.propsData.spacing[typeView].margin_bottom
                            "
                            @input="attrLinkMargin($event, 'bottom')"
                            min="0"
                            max="100"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN RIGHT -->
                    <div class="item-3">
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_right
                        "
                        @input="attrLinkMargin($event, 'right')"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Visibilidade -->
            <div class="separador"></div>
            <div class="content">
              <label class="titulo"> Visibilidade </label>
              <!-- Dispositivos grandes -->
              <div>
                <div style="display: flex; width: 100%; transition: all 0.3s">
                  <label class="sub"> Dispositivos grandesﾠ </label>
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: auto; margin-right: 0"
                    v-model="editaItem.propsData.visibility.monitor"
                    size="lg"
                    @change="$emit('fixWidth')" 
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <!-- Dispositivos médios -->
              <div>
                <div style="display: flex; width: 100%; transition: all 0.3s">
                  <label class="sub"> Dispositivos médiosﾠ </label>
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: auto; margin-right: 0"
                    v-model="editaItem.propsData.visibility.tablet"
                    size="lg"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
  
              <!-- Dispositivos pequenos -->
              <div>
                <div style="display: flex; width: 100%; transition: all 0.3s">
                  <label class="sub"> Dispositivos pequenosﾠ </label>
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: auto; margin-right: 0"
                    v-model="editaItem.propsData.visibility.phone"
                    size="lg"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="separador"></div>
  
            <!-- Animação -->
            <div class="content columnAnimate">
              <label class="titulo">Animação</label>
              <div class="select-animation">
                <div class="animation-container">
                  <div>
                    <p>Nenhum</p>
                    <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                      .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                      <div class="simulated-item simu1"></div>
                    </div>
                  </div>
                  <div>
                    <p>Zoom</p>
                    <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                      .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                      <div class="simulated-item simu2">
                        <img src="@/assets/editor/square-logo.svg" alt="setinha">
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Fade Up</p>
                    <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                      .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                      <div class="simulated-item simu3"> 
                        <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Fade Right</p>
                    <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                      .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                      <div class="simulated-item simu4">
                        <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Fade Down</p>
                    <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                      .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                      <div class="simulated-item simu5">
                        <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Fade Left</p>
                    <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                      .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                      .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                      <div class="simulated-item simu6">
                        <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="space"></div>
                <div class="half-animation">
                  <div>
                    <div class="label-range">
                      <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                      <input
                        type="number"
                        :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                        v-model="editaItem.propsColumn.delay"
                      />
                    </div>
                    <b-form-input
                      v-model="editaItem.propsColumn.delay"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      type="range"
                      min="0"
                      max="10"
                      step="1"
                    ></b-form-input>
                  </div>
                  <div>
                    <div class="label-range">
                      <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                      <input
                        :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                        type="number"
                        v-model="editaItem.propsColumn.duration"
                      />
                    </div>
                    <b-form-input
                      v-model="editaItem.propsColumn.duration"
                      type="range"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      min="0"
                      max="10"
                      step="1"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="separador"></div>
  
            <!-- Classe e ID -->
            <div class="content">
              <label class="titulo">Classe e ID</label>
              <label class="sub"
                >IDﾠ<img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
              /></label>
              <input
                v-model="editaItem.propsData.custom.c_id"
                class="w-100 mb-3"
                type="text"
                name="text_1"
                id="text_1"
                placeholder="exemplo: id_unico"
              />
              <label class="sub"
                >Classeﾠ<img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
              /></label>
              <input
                v-model="editaItem.propsData.custom.c_class"
                class="w-100 mb-3"
                type="text"
                name="text_1"
                id="text_1"
                placeholder="exemplo: classe_1 classe_2"
              />
            </div>
            <div class="separador"></div>
          </b-tab>
        </b-tabs>
      </b-card>
      <Upload @setImg="setImg" />
    </div>
  </template>
  
  <script>
  import Upload from "../../Upload.vue";
  import BaseButton from "@/components/BaseButton";
  import LvColorpicker from "lightvue/color-picker";
  import Multiselect from "vue-multiselect";
  
  export default {
    props: ["editaItem", "typeView", "palette"],
  
    components: {
      Upload,
      BaseButton,
      LvColorpicker: LvColorpicker,
      Multiselect,
    },
    data() {
      return {
        linkPadding: true,
        linkMargin: true,
        global: false,
        block_rotate_logo: 0,
        img_carousel_index: null,
        proportion:"Colagem",
      borderLink: true,
      };
    },
  

    methods: {
      moveUp(idx) {
        var moving = this.editaItem.propsData.gallery[idx]
        this.editaItem.propsData.gallery.splice(idx, 1);
        this.editaItem.propsData.gallery.splice(idx - 1, 0, moving);
      },
      moveDown(idx) {
        var moving = this.editaItem.propsData.gallery[idx]
        this.editaItem.propsData.gallery.splice(idx, 1);
        this.editaItem.propsData.gallery.splice(idx + 1, 0, moving);
      },
      nextIcon(type) {
        this.$emit("attIconView", type);
      },

      linked(place) {
        if (this.borderLink) {
          var border = this.editaItem.propsData.border;
          border.radius = border[place];
          
        }
      },
      paddingLink(px, position) {
        var props = this.editaItem.propsData.spacing;
        var value = px.target.value;
        if (this.linkPadding) {
          if (this.global && this.typeView == 'desktop') {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          } else {
            if (this.typeView === "desktop") {
              props.desktop.padding_top = value;
              props.desktop.padding_right = value;
              props.desktop.padding_bottom = value;
              props.desktop.padding_left = value;
            } else if (this.typeView === "tablet") {
              props.tablet.padding_top = value;
              props.tablet.padding_right = value;
              props.tablet.padding_bottom = value;
              props.tablet.padding_left = value;
            } else {
              props.mobile.padding_top = value;
              props.mobile.padding_right = value;
              props.mobile.padding_bottom = value;
              props.mobile.padding_left = value;
            }
          }
        } else if (this.global && this.typeView == 'desktop') {
          if (position == "top") {
            props.tablet.padding_top = value;
            props.mobile.padding_top = value;
            return;
          } else if (position == "right") {
            props.tablet.padding_right = value;
            props.mobile.padding_right = value;
            return;
          } else if (position == "bottom") {
            props.tablet.padding_bottom = value;
            props.mobile.padding_bottom = value;
            return;
          } else if (position == "left") {
            props.tablet.padding_left = value;
            props.mobile.padding_left = value;
          }
        }
      },
      globalJsutify() {
        if (this.global && this.typeView == 'desktop') {
          this.editaItem.propsData.alignment.tablet.justify = this.editaItem.propsData.alignment.desktop.justify
          this.editaItem.propsData.alignment.mobile.justify = this.editaItem.propsData.alignment.desktop.justify
        }
      },
      attrLinkMargin(px, position) {
        var props = this.editaItem.propsData.spacing;
        var value = px.target.value;
        if (this.linkMargin) {
          if (this.global && this.typeView == 'desktop') {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          } else {
            if (this.typeView === "desktop") {
              props.desktop.margin_top = value;
              props.desktop.margin_right = value;
              props.desktop.margin_bottom = value;
              props.desktop.margin_left = value;
            } else if (this.typeView === "tablet") {
              props.tablet.margin_top = value;
              props.tablet.margin_right = value;
              props.tablet.margin_bottom = value;
              props.tablet.margin_left = value;
            } else {
              props.mobile.margin_top = value;
              props.mobile.margin_right = value;
              props.mobile.margin_bottom = value;
              props.mobile.margin_left = value;
            }
          }
        } else if (this.global && this.typeView == 'desktop') {
          if (position == "top") {
            props.tablet.margin_top = value;
            props.mobile.margin_top = value;
            return;
          } else if (position == "right") {
            props.tablet.margin_right = value;
            props.mobile.margin_right = value;
            return;
          } else if (position == "bottom") {
            props.tablet.margin_bottom = value;
            props.mobile.margin_bottom = value;
            return;
          } else if (position == "left") {
            props.tablet.margin_left = value;
            props.mobile.margin_left = value;
          }
        }
      },
      mostrarMensagem() {
        this.$bvToast.toast(`Necessário ao menos uma imagem`, {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      },
      addImg() {
        const idx = this.editaItem.propsData.gallery.length > 0 ? this.editaItem.propsData.gallery[this.editaItem.propsData.gallery.length - 1].id + 1 : 1;
        this.editaItem.propsData.gallery.push({
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia3%20%281%29.webp",
        ratio: 1620 / 911,
      },);
      },
 
      removeImgIndex(index) {
        this.editaItem.propsData.gallery.splice(index, 1);
      },
      removeImg() {
        this.editaItem.propsData.gallery.pop();
      },
      openModal(data, index) {
        this.img_carousel_index = index;
        this.$bvModal.show(data);
      },
      setImg(img) {
        this.editaItem.propsData.gallery[this.img_carousel_index].imagem = img;
        const newImg = new Image();
        newImg.onload = () => {
          this.editaItem.propsData.gallery[this.img_carousel_index].ratio = newImg.width / newImg.height
        };
        newImg.src = img;
      },
      globalize(place) {
        if (this.global && this.typeView == 'desktop') {
          this.editaItem.propsData.styles[place].tablet = this.editaItem.propsData.styles[place].desktop
          this.editaItem.propsData.styles[place].mobile = this.editaItem.propsData.styles[place].desktop
        }
      }
    },
    created() {
      if (this.typeView == "desktop") {
        this.global = true;
      }
    },
  };
  </script>
  
  <style scoped lang="scss">

  </style>
  