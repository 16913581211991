import { render, staticRenderFns } from "./EditTestimony.vue?vue&type=template&id=074c537b&scoped=true&"
import script from "./EditTestimony.vue?vue&type=script&lang=js&"
export * from "./EditTestimony.vue?vue&type=script&lang=js&"
import style0 from "./EditTestimony.vue?vue&type=style&index=0&id=074c537b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074c537b",
  null
  
)

export default component.exports