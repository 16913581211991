<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Geral</label>
            <label class="sub">Tipo de vídeo</label>
            <multiselect
              :options="['YouTube', 'Incorporar']"
              v-model="editaItem.propsData.video.type"
              :searchable="false"
              :show-labels="false"
              placeholder="Tipo de video"
              :allow-empty="false"
            ></multiselect>
            <div class="space"></div>
            <div v-if="editaItem.propsData.video.type == 'YouTube' || showConfig">
              <label class="sub">URL</label>
              <input
                placeholder="https://seulink"
                class="w-100 mb-2"
                type="text"
                name="name"
                id="name"
                v-model="editaItem.propsData.video.url"
                @input="convertURL"
              />
              <div class="space"></div>
              <label class="sub">Thumbnail (opcional)                   
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Caso não exista uma thumbnail selecionada, será mostrada a thumbnail padrão. Se o vídeo for não listado, a thumbnail personalizada é recomendada."
                /></label>
              <div class="select-image">
                <div @click="openModal('Upload-Editor')">
                  <img :src="editaItem.propsData.video.thumb ? editaItem.propsData.video.thumb : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'"  alt="Imagem preview">
                </div>
                <div>
                  <input type="text" v-model="editaItem.propsData.video.thumb">
                  <div class="with-trash">
                    <span @click="openModal('Upload-Editor')">{{ editaItem.propsData.video.thumb ? 'Alterar thumbnail' : 'Selecionar thumbnail'}}</span>
                    <img title="Remover thumbnail" @click="editaItem.propsData.video.thumb = ''" class="trash" src="@/assets/editor/trash-2.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <label class="sub">Código para incorporar</label>
              <textarea
                placeholder="<iframe src=''https://seulink.com'''> ... </iframe>"
                class="w-100 mb-2"
                type="text"
                name="code"
                id="code"
                v-model="editaItem.propsData.video.code"
                rows="10"
                style="min-height: 100px !important; max-height: fit-content !important;"
              ></textarea>
            </div>
          </div>
          <div class="separador"></div>
          <div class="content">
            <!-- Label typeView -->
            <div class="label-icon">
              <label class="titulo">Dimensões</label>
              <div>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>
            </div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="subtitulo">Largura da coluna</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.size[typeView].compleate"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <b-collapse v-model="editaItem.propsData.size[typeView].compleate">
              <div class="space"></div>
              <label class="subtitulo">Proporções</label>
              <multiselect
                :options="[
                  'Paisagem (16:9)',
                  'Vertical (9:16)',
                  'Personalizado',
                  'Quadrado (1:1)',
                  'Retrato (4:5)',
                  'Panorama (2:1)',
                ]"
                v-model="proportion"
                :searchable="false"
                :show-labels="false"
                placeholder=" "
                @input="dimensions()"
              ></multiselect>
            </b-collapse>
            <div class="space"></div>
            <!-- Altura e largura -->
            <div class="half" v-if="editaItem.propsData.video.type == 'YouTube' || showConfig">
              <div>
                <label class="subtitulo" :class="{ labelOff: editaItem.propsData.size[typeView].compleate }">Altura</label>
                <input
                  :disabled="editaItem.propsData.size[typeView].compleate"
                  type="number"
                  v-model="editaItem.propsData.size[typeView].height"
                  @input="globalProp($event, 'altura')"
                />
              </div>
              <div>
                <label class="subtitulo" :class="{ labelOff: editaItem.propsData.size[typeView].compleate }">Largura</label>
                <input
                  :disabled="editaItem.propsData.size[typeView].compleate"
                  type="number"
                  v-model="editaItem.propsData.size[typeView].width"
                  @input="globalProp($event, 'largura')"
                />
              </div>
            </div>
            <div class="space"></div>

            <div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-start' }"
                    @click="globalJustify('flex-start', 'justify')"><img
                        src="@/assets/editor/align-left-new.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'center' }"
                    @click="globalJustify('center', 'justify')"><img
                        src="@/assets/editor/align-center-horizontal.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-end' }"
                    @click="globalJustify('flex-end', 'justify')"><img
                        src="@/assets/editor/align-right-new.svg" /></button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                      @click="globalJustify('start', 'justify_vertical')"><img
                        src="@/assets/editor/align-top.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                      @click="globalJustify('center', 'justify_vertical')"><img
                        src="@/assets/editor/align-center-vertical.svg" /></button>
                    <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                      @click="globalJustify('end', 'justify_vertical')"><img
                        src="@/assets/editor/align-bottom.svg" /></button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="space"></div>
              <label for="form-altura" class="sub">Altura mínima da coluna</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{editaItem.propsColumn.height[typeView].height_und}}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visilibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  @change="$emit('fixWidth')"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                    .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                    .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                    <div class="simulated-item simu3"> 
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                    <input
                      type="number"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                    <input
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input v-model="editaItem.propsData.custom.c_id" class="w-100 mb-3" type="text" name="text_1" id="text_1" placeholder="exemplo: id_unico" />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input v-model="editaItem.propsData.custom.c_class" class="w-100 mb-3" type="text" name="text_1" id="text_1" placeholder="exemplo: classe_1 classe_2"/>
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Upload,
    Multiselect,
    LvColorpicker,
  },
  props: ["editaItem", "typeView"],
  computed: {
    theThumb() {
      return this.editaItem.propsData.video.thumb
    },
  },
  watch: {
    theThumb(newVal) {
      this.updateThumb(this.editaItem.propsData.video.type);
    },
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      videoId: "",
      global: false,
      showConfig: false,
      proportion: 'Paisagem (16:9)'
    };
  },
  methods: {
    dimensions() {
      if (this.proportion.includes("Personalizado")) {
        this.editaItem.propsData.size[this.typeView].proportion = 0;
        this.editaItem.propsData.size.desktop.compleate = false
        this.editaItem.propsData.size.tablet.compleate = false
        this.editaItem.propsData.size.mobile.compleate = false
      } else {
        this.editaItem.propsData.size.desktop.compleate = true
        this.editaItem.propsData.size.tablet.compleate = true
        this.editaItem.propsData.size.mobile.compleate = true
        if (this.proportion.includes("Quadrado")) {
          this.editaItem.propsData.size[this.typeView].proportion = '1';
        } else if (this.proportion.includes("Panorama")) {
          this.editaItem.propsData.size[this.typeView].proportion = '2';
        } else if (this.proportion.includes("Paisagem")) {
          this.editaItem.propsData.size[this.typeView].proportion = '16/9';
        } else if (this.proportion.includes("Vertical")) {
          this.editaItem.propsData.size[this.typeView].proportion = '9/16';
        } else if (this.proportion.includes("Retrato")) {
          this.editaItem.propsData.size[this.typeView].proportion = '4/5';
        }
      }
    },
    updateThumb(value) {
      this.showConfig = true
      this.editaItem.propsData.video.type = 'YouTube'
      setTimeout(() => {
        this.editaItem.propsData.video.type = value
        this.showConfig = false
      }, 100);
    },
    setImg(img) {
      this.editaItem.propsData.video.thumb = img;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    switch_global() {
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        // console.log(props.desktop.compleate)
        props.tablet.compleate = props.desktop.compleate
        props.mobile.compleate = props.desktop.compleate
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalProp(value, prop) {
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        var px = value.target.value;
        if (prop == "altura") {
          props.tablet.height = px;
          props.mobile.height = px;
        } else {
          props.tablet.width = px;
          props.mobile.width = px;
        }
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    convertURL() {
      var url = this.editaItem.propsData.video.url;
      const regex = /https:\/\/(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|)([a-zA-Z0-9_-]+)/;
      const match = url.match(regex);
      var base_url = "https://www.youtube.com/embed/";
      if (match) {
        this.editaItem.propsData.video.url_converted = base_url + match[4];
      } else {
        this.editaItem.propsData.video.url_converted = "invalid";
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }

    if (!this.editaItem.propsData.size.desktop.hasOwnProperty('proportion')) {
      this.editaItem.propsData.size.desktop.proportion = '9/16'
    }

    switch (this.editaItem.propsData.size.desktop.proportion) {
      case '1':
        this.proportion = 'Quadrado (1:1)';
        break;
      case '2':
        this.proportion = 'Panorama (2:1)';
        break;
      case '16/9':
        this.proportion = 'Paisagem (16:9)';
        break;
      case '9/16':
        this.proportion = 'Vertical (9:16)';
        break;
      case '4/5':
        this.proportion = 'Retrato (4:5)';
        break;
      default:
        this.proportion = 'Personalizado';
    }
  },
};
</script>

<style lang="scss">
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.container-mg {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 20px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;

  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }

  .form-group {
    margin: 0 !important;
  }

  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    border: 0.5px solid var(--greenn);

    .borderx {
      border-right: 0.5px solid var(--greenn);
    }
  }
}
</style>
