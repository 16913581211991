<template>
  <div 
    @mouseenter="disablePoiterEvent(true)"
    @mouseleave="disablePoiterEvent(false)"
    v-show="editaItem && editaItem != 'null'"
    class="side-right"
    :style="`top:${sideRightStyles.top}px;left:${sideRightStyles.left}px;width:${sideRightStyles.width}px;height:${sideRightStyles.height}px;`"
    v-if="editaItem && editaItem != 'null'">
    <div v-if="editaItem && editaItem != 'null'" class="conteudo-side">
      <div class="header-side" @mousedown="startMove" @mouseup="stopMove">
        <div style="display: flex; gap: 10px; align-items: center">
          <div class="move"></div>
          <span class="side-title">{{
            editaItem.component_type === "row"
              ? "Linha"
              : editaItem.component_type === "column"
              ? "Coluna"
              : editaItem.name
          }}</span>
        </div>
        <svg style="cursor: pointer" width="32" height="30" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg" @click="close_side_right('x');">
          <path d="M24.8462 14.1282L15.1026 23.8718M15.1026 14.1282L24.8462 23.8718"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div
        v-for="(n, index) in 4"
        :key="index"
        class="corners"
        :style="cornerStyle(n)"
        @mousedown="startResize(n, $event)"
        @mouseup="stopResize"
      ></div>
      <div v-if="renderEditaItem" style="height: calc(100% - 75px); position: relative">
        <EditRow
          v-if="editaItem.component_type === 'row' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :palette="palette"
          @attIconView="attIconView"
          @updateOverlap="updateOverlap"
        />
        <EditItemColumn
          v-if="editaItem.component_type === 'column' && !rerender"
          :editaItem="editaItem"
          :index_page="index_page"
          :indexColumn="indexColumn"
          :indexComponent="indexComponent"
          :indexLine="indexLine"
          :typeView="typeView"
          @coluns2="changeColumn"
          @fixWidth="fixWidthColumn"
          @equalize="changeEqualize"
          @emitColumn="changeEmitColumn"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditAccordionList
          v-if="editaItem.type === 'AccordionList' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          :divWidth="sideRightStyles.width"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditPrices
          v-if="editaItem.type === 'Prices' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          :divWidth="sideRightStyles.width"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditImage
          v-if="editaItem.type === 'Imagem' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @disabledSave="disabledSave"
        />
        <EditSeparador
          v-if="editaItem.type === 'Separador' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditButton
          v-if="editaItem.type === 'Button' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditTextoConteudo
          v-if="editaItem.type === 'TextoConteudo' && !rerender"
          ref="TextoConteudo"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          :divWidth="sideRightStyles.width"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditTextDynamic
          v-if="editaItem.type === 'TextDynamic' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          :divWidth="sideRightStyles.width"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditCard
          v-if="editaItem.type === 'Card' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          :divWidth="sideRightStyles.width"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditCarousel
          v-if="editaItem.type === 'Carousel' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditCarouselVertical
          v-if="editaItem.type === 'CarouselVertical' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditMenu
          v-if="editaItem.type === 'MenuHorizontal' && !rerender"
          :editaItem="editaItem"
          :fontOptions="fontOptions"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditMenuFlutuante
          v-if="editaItem.type === 'MenuFlutuante' && !rerender"
          :fontOptions="fontOptions"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditMenuVertical
          v-if="editaItem.type === 'MenuVertical' && !rerender"
          :fontOptions="fontOptions"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditTextVideo
          v-if="editaItem.type === 'TextoVideo' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditGallery
          v-if="editaItem.type === 'Gallery' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditMaps
          v-if="editaItem.type === 'Maps'"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditPopUp
          v-if="editaItem.type === 'PopUp' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
          @openClosePopUP="currentPopUp"
        />
        <EditCountDown
          v-if="editaItem.type === 'CountDown' && !rerender"
          :editaItem="editaItem"
          :fontOptions="fontOptions"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditIcon
          v-if="editaItem.type === 'Icon' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditForm
          v-if="editaItem.type === 'Form' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditStepsForm
          v-if="editaItem.type === 'StepsForm' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditCalendar
          v-if="editaItem.type === 'Calendar' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          :fontOptions="fontOptions"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          @callCharge="callCharge"
        />
        <EditHtml
          v-if="editaItem.type === 'Html' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
        <EditTestimony
          v-if="editaItem.type === 'Testimony' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
          :fontOptions="fontOptions"
          @callCharge="callCharge"
        />
        <EditWhatsFloating
          v-if="editaItem.type === 'WhatsFloating' && !rerender"
          :editaItem="editaItem"
          :typeView="typeView"
          @fixWidth="fixWidth"
          :palette="palette"
          @attIconView="attIconView"
        />
      </div>
      <div class="footer-side">
        <button @click="$emit('cancel')">Cancelar</button>
        <button :disabled="btnDisabled" @click="close_side_right('save',editaItem)">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
import EditItemColumn from "@/components/Editor/Components/EditColumn.vue";
import EditRow from "@/components/Editor/Components/EditRow.vue";
import EditAccordionList from "@/components/Editor/Components/AccordionList/EditAccordionList.vue";
import EditPrices from "@/components/Editor/Components/Prices/EditPrices.vue";
import EditSeparador from "@/components/Editor/Components/Separador/EditSeparador.vue";
import EditButton from "@/components/Editor/Components/Button/EditButton.vue";
import EditTextoConteudo from "@/components/Editor/Components/TextoConteudo/EditTextoConteudo.vue";
import EditCarousel from "@/components/Editor/Components/Carousel/EditCarousel.vue";
import EditCarouselVertical from "@/components/Editor/Components/CarouselVertical/EditCarouselVertical.vue";
import EditMenu from "@/components/Editor/Components/MenuHorizontal/EditMenu.vue";
import EditMenuFlutuante from "@/components/Editor/Components/MenuFlutuante/EditMenu.vue";
import EditMenuVertical from "@/components/Editor/Components/MenuVertical/EditMenu.vue";
import EditTextVideo from "@/components/Editor/Components/TextoVideo/EditTextVideo.vue";
import EditGallery from "@/components/Editor/Components/Gallery/EditGallery.vue";
import EditPopUp from "@/components/Editor/Components/PopUp/EditPopUp.vue";
import EditCountDown from "@/components/Editor/Components/CountDown/EditCountDown.vue";
import EditImage from "@/components/Editor/Components/Image/EditImage.vue";
import EditIcon from "@/components/Editor/Components/Icon/EditIcon.vue";
import EditForm from "@/components/Editor/Components/Form/EditForm.vue";
import EditStepsForm from "@/components/Editor/Components/StepsForm/EditStepsForm.vue";
import EditCalendar from "@/components/Editor/Components/Calendar/EditCalendar.vue";
import EditHtml from "@/components/Editor/Components/Html/EditHtml.vue";
import EditCard from "@/components/Editor/Components/Card/EditCard.vue";
import EditTestimony from "@/components/Editor/Components/Testimony/EditTestimony.vue";
import EditTextDynamic from "@/components/Editor/Components/TextoDinamico/EditTextDynamic.vue";
import EditMaps from "@/components/Editor/Components/Maps/EditMaps.vue";
import EditWhatsFloating from "../Components/WhatsappFloating/EditWhatsFloating.vue";
import _ from "lodash";


export default {
  props: [
    "indexComponent",
    "indexLine",
    "indexColumn",
    "fontOptions",
    "index_page",
    "editaItem",
    "typeView",
    "palette",
  ],
  components: {
    EditStepsForm,
    EditCarouselVertical,
    EditAccordionList,
    EditMenuFlutuante,
    EditTextoConteudo,
    EditMenuVertical,
    EditTextDynamic,
    EditItemColumn,
    EditTextVideo,
    EditSeparador,
    EditCountDown,
    EditTestimony,
    EditCalendar,
    EditCarousel,
    EditGallery,
    EditButton,
    EditPrices,
    EditImage,
    EditPopUp,
    EditMenu,
    EditForm,
    EditHtml,
    EditMaps,
    EditIcon,
    EditCard,
    EditRow,
    EditWhatsFloating
  },
  data() {
    return {
      renderEditaItem: false,
      resizing: false,
      moving: false,
      startX: 0,
      startY: 0,
      rerender: false,
      valueX: 1,
      valueY: 1,
      sideRightStyles: {
        height: 600,
        width: 350,
        top: 200,
        left: 700,
      },
      historicSideRightStyles: {
        height: 600,
        width: 350,
        top: 200,
        left: 700,
      },
      btnDisabled: false,
    };
  },
  methods: {
    currentPopUp(bool,props){
      this.$emit('openClosePopUP',bool, props)
    },  
    disablePoiterEvent(boolea){
      this.$emit('disablePoiter',boolea)
    },  
    callCharge(call){
      this.$emit('chargeFont', [`${call}`])
    },
    disabledSave(status){
      this.btnDisabled = status;
    },
    cornerStyle(n) {
      switch (n) {
        case 1:
          return `top: -${10 * Math.sqrt(2)}px; left: -${
            10 * Math.sqrt(2)
          }px; transform: rotate(45deg); cursor: nwse-resize;`;
        case 2:
          return `top: -${10 * Math.sqrt(2)}px; right: -${
            10 * Math.sqrt(2)
          }px; transform: rotate(135deg); cursor: nesw-resize;`;
        case 3:
          return `bottom: -${10 * Math.sqrt(2)}px; right: -${
            10 * Math.sqrt(2)
          }px; transform: rotate(225deg); cursor: nwse-resize;`;
        case 4:
          return `bottom: -${10 * Math.sqrt(2)}px; left: -${
            10 * Math.sqrt(2)
          }px; transform: rotate(315deg); cursor: nesw-resize;`;
      }
    },
    rerenderSide() {
      this.rerender = true;
      this.$nextTick(() => {
        this.rerender = false;
      });
    },
    fixWidth() {
      this.$emit(
        "fixWidth",
        this.indexLine,
        this.index_page,
        this.index_column
      );
    },
    fixWidthColumn() {
      this.$emit("fixWidth", this.indexLine, this.index_page);
    },
    startResize(n, event) {
      switch (n) {
        case 1:
          this.valueX = -1;
          this.valueY = -1;
          break;
        case 2:
          this.valueX = 1;
          this.valueY = -1;
          break;
        case 3:
          this.valueX = 1;
          this.valueY = 1;
          break;
        case 4:
          this.valueX = -1;
          this.valueY = 1;
          break;
      }
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.resizing = true;
    },
    stopResize() {
      this.resizing = false;
      this.historicSideRightStyles.width = this.sideRightStyles.width;
      this.historicSideRightStyles.height = this.sideRightStyles.height;
      this.historicSideRightStyles.left = this.sideRightStyles.left;
      this.historicSideRightStyles.top = this.sideRightStyles.top;
    },
    handleResize(event) {
      if (this.resizing) {
        const movementX = event.clientX - this.startX;
        const movementY = event.clientY - this.startY;
        this.sideRightStyles.width =
          this.historicSideRightStyles.width + movementX * this.valueX;
        this.sideRightStyles.height =
          this.historicSideRightStyles.height + movementY * this.valueY;
        if (this.valueX == -1) {
          this.sideRightStyles.left =
            this.historicSideRightStyles.left + movementX;
        }
        if (this.valueY == -1) {
          this.sideRightStyles.top =
            this.historicSideRightStyles.top + movementY;
          if (this.sideRightStyles.top < 74) {
            this.sideRightStyles.top = 80;
            this.stopResize();
          }
        }
        if (this.sideRightStyles.width < 190) {
          this.sideRightStyles.width = 200;
          this.stopResize();
        }
        if (this.sideRightStyles.height < 190) {
          this.sideRightStyles.height = 200;
          this.stopResize();
        }
      }
    },
    startMove(event) {
      
      this.moving = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    stopMove() {
      this.moving = false;
      this.historicSideRightStyles.left = this.sideRightStyles.left;
      this.historicSideRightStyles.top = this.sideRightStyles.top;
    },
    handleMove(event) {
      if (this.moving) {
        const movementX = event.clientX - this.startX;
        const movementY = event.clientY - this.startY;
        this.sideRightStyles.left =
          this.historicSideRightStyles.left + movementX;
        this.sideRightStyles.top = this.historicSideRightStyles.top + movementY;
        if (this.sideRightStyles.top < 74) {
          this.sideRightStyles.top = 80;
          this.stopMove();
        }
      }
    },
    attIconView(type) {
      this.$emit("emitTypeIcon", type);
    },
    changeEmitColumn(data) {
      this.$emit("emitColumn", data);
    },
    changeEqualize(equalize) {
      this.$emit("equalize", equalize);
    },
    updateOverlap() {
      this.$emit("updateOverlap");
    },
    changeColumn(coluns) {
      this.$emit("coluns2", coluns);
    },
    emitTextSave(isGaping){
      if(isGaping === 'TextoConteudo' ){
        this.$refs[isGaping].saveText()
      }
    },
    close_side_right(exit, saveProps) {
      if(exit !== 'x'){
        this.$emit("editIframe",exit, saveProps)
        this.emitTextSave(this.editaItem.type)
      }
      this.$emit("exit_right");
    },
    debouncesavePage: _.debounce(function(){
      this.$emit('editIframe')
    },200)
  },
  mounted() {
    window.addEventListener("mousemove", this.handleResize);
    window.addEventListener("mousemove", this.handleMove);
    var sideRezise = localStorage.getItem("sideRezise");
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleResize);
    window.removeEventListener("mousemove", this.handleMove);
  },
  watch:{
    editaItem:{
      handler:function(){
        this.debouncesavePage()
      },
      deep:true
    }
  }
};
</script>

<style lang="scss">
.side-right {
  position: fixed;
  z-index: 8;
  background-color: #ffffff;
  padding: 0px;
  filter: drop-shadow(-1px 4px 4px rgba(0, 0, 0, 0.13));
  user-select: none;
  border-radius: 10px;
  overflow: hidden;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.colorDisabled
  .lv-input__group
  .lv-input__field
  .lv-input__append
  .lv-colorpicker__colorblock {
  background-color: #dadada !important;
}

.component-group-title {
  font-weight: 400;
  margin: 0 !important;
  z-index: 100;
  background-color: white;
  position: relative;
  max-width: fit-content;
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.lv-colorpicker__colorblock,
.lv-input__field {
  transition: all 0.3s;
}

.borderLinkActive {
  filter: invert(0%) !important;
}

.lv-input__group label {
  display: none !important;
}

.form-group {
  margin: 0 !important;
}

.side-right input {
  padding: 12px 20px;
  height: 45px !important;
  border: 0.5px solid #ededf0;
  appearance: none !important;
  font-size: 14px !important;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s !important;
  border-radius: 10px !important;
}

.side-right input[type="range"] {
  padding: 12px 0px !important;
  height: 45px !important;
  border: 0.5px solid #ededf0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: 14px !important;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
  border-radius: 10px !important;
}

.side-right .label-range {
  input {
    text-align: right;
    margin-right: 10px;
  }
}

input,
select {
  padding: 12px 20px;
  height: 45px !important;
  border: 0.5px solid #ededf0 !important;
  appearance: none !important;
  font-size: 14px !important;
  transition: 0.3s !important;
  border-radius: 10px !important;
  box-sizing: border-box;
}
textarea {
  width: 100%;
  padding: 12px 20px;
  border: 0.5px solid #ededf0 !important;
  appearance: none !important;
  font-size: 14px !important;
  transition: border 0.3s !important;
  border-radius: 10px !important;
  box-sizing: border-box;
  min-height: 45px;
}

#LvColorpicker .lv-input__group .lv-input__field .lv-input__element {
  width: 100%;
}

.lv-input__group .lv-input__field {
  border-radius: 10px !important;
}

.lv-input__element {
  border: 0px solid black !important;
}

.lv-overlaypanel {
  z-index: 99999999999999999999999999999999999999 !important;
}

.custom-range,
.custom-range:hover {
  border: none !important;
  margin-top: -10px;
}

// #range-1::-webkit-slider-thumb,
// #range-1::-moz-range-thumb {
//   background-color: var(--greenn) !important;
// }

input[type="range"]::-webkit-slider-thumb {
  background: var(--greenn) !important;
}
input[type="range"]:disabled::-webkit-slider-thumb {
  background: #dadada !important;
}

.label-range {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label-range input {
  border: none !important;
  width: 15% !important;
  padding: 0 !important;
  height: auto !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 15px;
}

.container-mg {
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 0px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;

  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }

  .form-group {
    margin: 0 !important;
  }

  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    border: 0.5px solid var(--greenn);

    .borderx {
      border-right: 0.5px solid var(--greenn);
    }
  }
}

.txt-pd {
  position: absolute;
  font-size: 8px;
  bottom: 4px;
  left: 8px;
  display: flex;
}

.txt-mg {
  position: absolute;
  font-size: 8px;
  left: 10px;
  top: 5px;
}

.border-final {
  background: #e5e7eb;
  width: 2.5rem;
  height: 1.25rem;
  border-radius: 10px;
}

.alinhamento-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alinhamento {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contain-borders {
  border-radius: 10px;
  padding: 18px;
  border: 2px solid var(--greenn) !important;
  position: relative;
}

.separador {
  width: 100%;
  height: 1px;
  background-color: #ededf0;
}

.content {
  padding: 30px 35px;
}

.titulo {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--gray01);
  display: block;
  // margin: 0 0 1em 0 !important;
  padding: 0 !important;
  transition: all 0.3s;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem var(--greenn) 00 !important;
  box-shadow: 0 0 0 0.2rem var(--greenn) 00 !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #adb5bd;
}
.subtitulo,
.sub {
  display: flex !important;
  gap: 5px;
  font-family: "Montserrat";
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--gray01);
  // background: var(--greenn);
  margin: 0 0 0.5em 0 !important;
  padding: 0 !important;
  transition: all 0.3s;
}
.border-dash {
  border: 2px dashed #e5e7eb !important;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  display: flex;
  position: relative;
}

.item-1,
.item-2,
.item-3 {
  input {
    font-size: 0.8rem !important;
    line-height: 1.25rem !important;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    text-align: center !important;
    width: 2rem !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 1rem !important;
    border: none !important;
  }
}

.container-spacing {
  display: flex;
  justify-content: center;
}
.inputs-group {
  display: flex;
  gap: 10px;
  input {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.header-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 10px;
  cursor: move;
  path {
    stroke: var(--greenn);
  }
  .move {
    width: 7px;
    height: 30px;
    background-image: linear-gradient(
      0deg,
      transparent 0px,
      transparent 1.5px,
      var(--greenn) 1.5px,
      var(--greenn) 4.5px,
      transparent 4.5px,
      transparent 6px
    );
    background-size: 6px 6px;
  }
  .side-title {
    font-size: 16px;
    color: var(--greenn);
    font-weight: 600;
  }
}

.footer-side {
  display: flex;
  padding: 0 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 45px;
  border-top: 1px solid var(--white-medium);
  button {
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 10px;
    border: none;
    height: fit-content;
    transition: all 0.3s;
    &:hover {
      filter: brightness(0.95);
    }
  }
  button:last-child {
    background-color: var(--greenn);
    color: var(--white-light);
    font-weight: 500;
  }
}

.corners {
  // opacity: 0;
  width: 30px;
  height: 30px;
  aspect-ratio: 1;
  background-color: var(--greenn);
  transition: opacity 0.3s;
  position: absolute;
  z-index: 500;
  opacity: 0;
  border-top-left-radius: 10px;
  &:hover {
    opacity: 1;
  }
}
#SideRightTabs {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  .multiselect--disabled {
    background: #00000000 !important;
    pointer-events: none;
  }
  .chevron {
    transition: all 0.3s;
    margin-top: 1px;
    height: 20px;
  }
  .select-animation {
    .label-range input {
      height: fit-content !important;
    }
    .animation-container {
      p {
        font-size: 10px;
        line-height: 1;
        text-transform: uppercase;
        margin-top: 0;
      }
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 50);
      gap: 10px;
      .selected-animation {
        border-color: #13cc9e !important;
      }
      .animation-item {
        animation-play-state: paused;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #dadada;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        .simulated-item {
          animation-play-state: inherit;
          border-radius: 3px;
          background-color: #6d6d6d;
          height: 28px;
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .simu2 {
          background: radial-gradient(#00000000 0%, #6d6d6d 70%);
        }
        .simu3 {
          background: linear-gradient(0deg, #6d6d6d, #00000000);
        }
        .simu4 {
          background: linear-gradient(90deg, #6d6d6d, #00000000);
          img {
            transform: rotate(90deg);
          }
        }
        .simu5 {
          background: linear-gradient(180deg, #6d6d6d, #00000000);
          img {
            transform: rotate(180deg);
          }
        }
        .simu6 {
          background: linear-gradient(270deg, #6d6d6d, #00000000);
          img {
            transform: rotate(270deg);
          }
        }
        &:hover {
          animation-play-state: running;
          background-color: #eeeeee;
        }
      }
    }

    .half-animation {
      display: flex;
      gap: 15px;
      .label-range {
        max-height: 22px;
        input {
          margin: 0 5px 0 0;
        }
      }
    }
    .half-animation > div {
      width: 50%;
    }
  }

  #input-max-width {
    * {
      height: min-content !important;
    }
  }

  .select-align {
    img {
      height: 24px;
    }
    button:first-child {
      border-radius: 10px 0 0 10px;
      border-right: none;
    }
    button:last-child {
      border-left: none;
      border-radius: 0px 010px 010px 0px;
    }
    button {
      background: transparent;
      border: 1px solid #ececec;
      box-sizing: border-box;
      transition: all 0.3s;
    }
    button:hover {
      background: #e6e6e6;
    }
    .selected-align {
      background: #cccbcb;
    }
  }
  .select-image {
    display: flex;
    padding: 10px;
    border: 1px solid #ececec;
    border-radius: 10px;
    width: 100%;
    transition: all 0.3s;
    &:hover {
      border-color: var(--greenn) !important;
    }
    div:has(input) {
      width: -webkit-fill-available;
    }
    img {
      height: 60px;
      width: 60px;
      object-fit: cover;
      padding: 5px;
      cursor: pointer;
      border-radius: 10px;
    }
    .icon-preview {
      height: 60px;
      width: 60px;
      border-radius: 3px;
      background-color: #dadada;
      color: var(--gray01);
      font-size: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      text-transform: none;
      margin: 5px;
    }
    span {
      font-size: 10px;
      text-transform: uppercase;
      text-align: right;
      padding: 5px;
      padding-bottom: 0px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        color: var(--greenn);
      }
    }
    input {
      font-size: 12px !important;
      height: 30px !important;
      padding: 10px !important;
      margin-top: 5px;
      margin-bottom: 0px !important;
    }
    .with-trash {
      display: flex;
      justify-content: space-between;
      .trash {
        margin-top: 5px;
        margin-right: 5px;
        height: 17px;
        width: 17px;
        border-radius: 0;
        padding: 0;
        transition: all 0.3s;
        filter: invert(50%) brightness(40%);
        &:hover {
          filter: invert(0%);
        }
      }
    }
  }
  .image-control {
    height: auto;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      height: 24px;
      width: 24px;
      border-radius: 10px;
      transition: all 0.3s;
      margin: 0;
      padding: 0;
    }
    div {
      width: min-content;
    }
    .first-arrow {
      margin-top: 6px;
      rotate: 180deg;
      margin-bottom: -6px;
    }
    .last-arrow {
      margin-top: -12px;
    }
    img {
      cursor: pointer;
    }
    .disabled-arrow {
      filter: invert(50%);
      cursor: default !important;
    }
  }

  .image-control2 {
    height: 24px;
    display: flex;
    margin-top: -2px;
    z-index: 2;
    background-color: white;
    padding: 0 5px;
    button {
      height: 24px;
      width: 24px;
      border-radius: 10px;
      transition: all 0.3s;
      margin: 0;
      padding: 0;
    }
    div {
      width: min-content;
    }
    .first-arrow {
      rotate: 180deg;
    }
    img {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
    .disabled-arrow {
      filter: invert(50%);
      cursor: default !important;
    }
  }

  .chevron {
    transition: transform 0.3s ease-in-out;
  }

  .collapsed > .chevron {
    transform: rotate(0deg);
  }

  .not-collapsed > .chevron {
    transform: rotate(90deg);
  }

  .max-multi .multiselect__tags {
    max-height: 45px !important;
  }
  .multiselect__content-wrapper {
    z-index: 2 !important;
  }

  .columnAnimate .multiselect {
    z-index: 2 !important;
  }

  .monthselect,
  .yearselect,
  .hourselect,
  .minuteselect {
    background-color: transparent;
    text-align: center;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: var(--greenn);
    border-color: transparent;
    color: #fff;
  }

  * {
    border: none;
  }
  .space {
    height: 1em;
  }

  .half {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    .lv-input__element {
      padding-top: 15px !important;
      padding-right: 0 !important;
      padding-bottom: 15px !important;
      padding-left: 10px !important;
    }
    .lv-input__field {
      padding-right: 0 !important;
    }
  }
  .half > div {
    max-width: 50% !important;
    align-items: center;
  }
  .half > div > input {
    width: 100% !important;
  }

  .three {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    div {
      width: 33% !important;
      input {
        width: 100% !important;
      }
    }
  }
  .tabs,
  .card {
    height: 100%;
  }
  .nav {
    gap: 5px;
  }
  div.tabs > div:first-child {
    width: 100%;
    position: fixed;
    border-bottom: 1px solid var(--white-medium);
    border-top: 1px solid var(--white-medium);
  }
  .tab-content {
    position: relative;
    top: 68px;
    width: 100%;
    height: calc(100% - 67px);
    overflow-y: auto;
    margin-top: 0 !important;
  }
  .labelOff {
    color: rgb(185, 185, 185) !important;
  }
  .nav-pills {
    padding: 15px 20px;
  }
  .nav-pills .nav-link {
    font-weight: 500 !important;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
      background-color: var(--greenn2);
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--gray01) !important;
    background-color: var(--greenn-transparent) !important;
    border-radius: 10px;
    position: relative;
    transition: all 0.3s;
  }
  a {
    color: var(--gray01) !important;
    font-weight: 400 !important;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.conteudo-side {
  height: 100%;
  position: relative;
}
.p {
  color: aliceblue;
}
</style>
