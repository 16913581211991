<template>
  <div id="SideRightTabs">
    <b-tabs pills justified small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active style="max-height: 80vh">
        <div class="separador"></div>

        <!-- Formulário -->
        <div class="content">
          <label class="titulo">Formulário do calendário</label>
          <label class="subtitulo">Formulário</label>
          <div class="max-multi">
            <BaseSelect
              id="ajax"
              :selectModel="editaItem.propsData.form"
              :show-labels="false"
              placeholder="Selecione um formulário"
              :hide-selected="false"
              track-by="id"
              :allow-empty="false"
              :array="forms"
              trackname="title"
              :searchable="true"
              noResult="Nenhum formulário encontrado."
              noOptions="Pesquise pelo formulário"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @change="(editaItem.propsData.form = $event), setaForm($event)"
              @callback="getForms($event)"
            >
            </BaseSelect>
          </div>
          <div class="space"></div>
          <label class="subtitulo">Calendário referente</label>
          <div class="max-multi">
            <BaseSelect
              id="ajax"
              :selectModel="editaItem.propsData.calendar"
              :show-labels="false"
              placeholder="Selecione um calendário"
              :hide-selected="false"
              track-by="id"
              :allow-empty="false"
              :array="calendars"
              trackname="name"
              :searchable="true"
              noResult="Nenhum calendário encontrado."
              noOptions="Pesquise pelo calendário"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @change="setaCalendar($event)"
              @callback="getCalendars($event)"
            >
            </BaseSelect>
          </div>
          <div class="space"></div>

          <!-- Cabeçalho -->
          <div>
            <label class="subtitulo">Cabeçalho</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              placeholder="Cadastre-se aqui!"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.header.text"
            />
            <div class="space"></div>
          </div>

          <!-- Rodapé -->
          <!-- <div>
            <label class="subtitulo">Rodapé</label>
            <input
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              placeholder="Política de privacidade"
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer"
            />
            <div class="space"></div>
          </div> -->

          <!-- Link do rodapé -->
          <!-- <div v-if="editaItem.propsData.footer">
            <label class="subtitulo">Link do rodapé</label>
            <input
              placeholder="https://seulink"
              :disabled="
                !editaItem.propsData.form && !editaItem.propsData.form.id
              "
              @input="debounce('adicionais')"
              type="text"
              name="msg"
              id="msg"
              v-model="editaItem.propsData.footer_link"
            />
          </div> -->
        </div>
        <div class="separador"></div>

        <!-- Botão -->
        <div class="content">
          <label class="titulo">Botão</label>

          <label class="subtitulo">Texto do botão</label>
          <input
            type="text"
            name="btn-content"
            id="btn-content"
            v-model="editaItem.propsData.content_button"
          />
          <div class="space"></div>
          <!-- ICON -->
          <label class="subtitulo">Ícone</label>
          <div
            class="select-image"
            v-if="editaItem.propsData.icon_selected.hasOwnProperty('name')"
          >
            <div @click="openModal('Modal-Icons-svg', 'icon')">
              <span
                class="material-icons icon-preview"
                v-html="editaItem.propsData.icon_selected.code"
              ></span>
            </div>
            <div>
              <input
                type="text"
                v-model="editaItem.propsData.icon_selected.name"
              />
              <span @click="openModal('Modal-Icons-svg', 'icon')">{{
                editaItem.propsData.icon_selected
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
          <div class="select-image" v-else>
            <div @click="openModal('Modal-Icons-svg', 'icon')">
              <span class="material-icons icon-preview">{{
                editaItem.propsData.icon_selected
              }}</span>
            </div>
            <div>
              <input type="text" v-model="editaItem.propsData.icon_selected" />
              <span @click="openModal('Modal-Icons-svg', 'icon')">{{
                editaItem.propsData.icon_selected
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
        </div>

        <div class="separador"></div>
        <!-- ARROWS -->
        <div class="content">
          <label class="titulo">ARROWS</label>

          <!-- ICON 1-->
          <label class="subtitulo">Ícone 1</label>
          <div
            class="select-image"
            v-if="editaItem.propsData.arrows.top.hasOwnProperty('name')"
          >
            <div @click="openModal('Modal-Icons-svg', 'arrow-top')">
              <span
                class="material-icons icon-preview"
                v-html="editaItem.propsData.arrows.top.code"
              ></span>
            </div>
            <div>
              <input
                type="text"
                v-model="editaItem.propsData.arrows.top.name"
              />
              <span @click="openModal('Modal-Icons-svg', 'arrow-top')">{{
                editaItem.propsData.arrows.top
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
          <div class="select-image" v-else>
            <div @click="openModal('Modal-Icons-svg', 'arrow-top')">
              <span class="material-icons icon-preview">{{
                editaItem.propsData.arrows.top
              }}</span>
            </div>
            <div>
              <input type="text" v-model="editaItem.propsData.arrows.top" />
              <span @click="openModal('Modal-Icons-svg', 'arrow-top')">{{
                editaItem.propsData.arrows.top
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
          <!-- ICON 2-->
          <label class="subtitulo">Ícone 1</label>
          <div
            class="select-image"
            v-if="editaItem.propsData.arrows.bottom.hasOwnProperty('name')"
          >
            <div @click="openModal('Modal-Icons-svg', 'arrow-bottom')">
              <span
                class="material-icons icon-preview"
                v-html="editaItem.propsData.arrows.bottom.code"
              ></span>
            </div>
            <div>
              <input
                type="text"
                v-model="editaItem.propsData.arrows.bottom.name"
              />
              <span @click="openModal('Modal-Icons-svg', 'arrow-bottom')">{{
                editaItem.propsData.arrows.bottom
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
          <div class="select-image" v-else>
            <div @click="openModal('Modal-Icons-svg', 'arrow-bottom')">
              <span class="material-icons icon-preview">{{
                editaItem.propsData.arrows.bottom
              }}</span>
            </div>
            <div>
              <input type="text" v-model="editaItem.propsData.arrows.bottom" />
              <span @click="openModal('Modal-Icons-svg', 'arrow-bottom')">{{
                editaItem.propsData.arrows.bottom
                  ? "Alterar ícone"
                  : "Selecionar ícone"
              }}</span>
            </div>
          </div>
        </div>

        <div class="separador"></div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div
          v-if="
            editaItem.propsData.selected_calendar.id &&
            editaItem.propsData.form.id
          "
        >
          <div class="separador"></div>

          <!-- Formulário -->
          <div class="content">
            <!-- Largura -->
            <div class="und_form">
              <div class="label-icon">
                <label class="titulo">Formulário</label>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>

              <div class="label-range">
                <label class="subtitulo">Largura máxima (px)</label>
                <input
                  type="number"
                  v-model="editaItem.propsData.size[typeView].width_form"
                />
              </div>
              <b-form-input
                v-model="editaItem.propsData.size[typeView].width_form"
                type="range"
                min="200"
                max="1000"
                step="10"
              ></b-form-input>
            </div>
            <!-- Fundo -->
            <label class="subtitulo">Fundo</label>
            <div>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.back_form_content"
                :colors="palette"
              ></LvColorpicker>
            </div>

            <!-- Posição -->
            <div>
              <div class="space"></div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'justify')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'center',
                      }"
                      @click="globalJustify('center', 'justify')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'justify')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>

                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'start',
                      }"
                      @click="globalJustify('start', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'center',
                      }"
                      @click="globalJustify('center', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView]
                            .justify_vertical == 'end',
                      }"
                      @click="globalJustify('end', 'justify_vertical')"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
            <!-- Gap -->
            <div class="label-range">
              <label class="subtitulo">Espaçamento (px)</label>
              <input
                type="number"
                v-model="editaItem.propsData.alignment[typeView].gap"
                min="0"
                max="100"
                step="1"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.alignment[typeView].gap"
              type="range"
              min="0"
              max="100"
              step="1"
            ></b-form-input>
            <!-- Espaçamento -->
            <div class="label-range">
              <label class="subtitulo">Espaçamento header (px)</label>
              <input
                type="number"
                v-model="editaItem.propsData.alignment[typeView].spacing"
                min="0"
                max="100"
                step="1"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.alignment[typeView].spacing"
              type="range"
              min="0"
              max="100"
              step="1"
            ></b-form-input>
            <!-- Titulo -->
            <label class="subtitulo">Cor do titulo</label>
            <div>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.text_header_color"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <!-- Tipografia -->
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Tipografia (px)</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.text_font_size"
                min="0"
                max="50"
                step="1"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.text_font_size"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <!-- Espessura da fonte -->
            <div class="label-range">
              <label class="subtitulo">Espessura da fonte</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.text_font_weight"
                min="400"
                max="900"
                step="100"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.text_font_weight"
              type="range"
              min="400"
              max="900"
              step="100"
            ></b-form-input>
          </div>
          <div class="separador"></div>
          <!-- IMAGE -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Imagem</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.image.show"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <!-- Layout -->
            <div class="select-image">
              <div @click="openModal('Upload-Editor')">
                <img
                  :src="
                    editaItem.propsData.image.src
                      ? editaItem.propsData.image.src
                      : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                  "
                  alt="Imagem preview"
                />
              </div>
              <div>
                <input
                  @input="obterProporcoesOriginais()"
                  type="text"
                  v-model="editaItem.propsData.image.src"
                  :disabled="!editaItem.propsData.image.show"
                />
                <span @click="openModal('Upload-Editor')">{{
                  editaItem.propsData.image.src
                    ? "Alterar imagem"
                    : "Selecionar imagem"
                }}</span>
              </div>
            </div>
            <div>
              <div class="space"></div>
              <label class="subtitulo">Layout</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :disabled="!editaItem.propsData.image.show"
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].layout ==
                          'start',
                      }"
                      @click="
                        (editaItem.propsData.alignment['desktop'].layout =
                          'start'),
                          (editaItem.propsData.alignment['tablet'].layout =
                            'start'),
                          (editaItem.propsData.alignment['mobile'].layout =
                            'start')
                      "
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :disabled="!editaItem.propsData.image.show"
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].layout ==
                          'center',
                      }"
                      @click="
                        (editaItem.propsData.alignment['desktop'].layout =
                          'center'),
                          (editaItem.propsData.alignment['tablet'].layout =
                            'center'),
                          (editaItem.propsData.alignment['mobile'].layout =
                            'center')
                      "
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :disabled="!editaItem.propsData.image.show"
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].layout ==
                          'end',
                      }"
                      @click="
                        (editaItem.propsData.alignment['desktop'].layout =
                          'end'),
                          (editaItem.propsData.alignment['tablet'].layout =
                            'end'),
                          (editaItem.propsData.alignment['mobile'].layout =
                            'end')
                      "
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>

          <div class="separador"></div>
          <!-- CALENDARIO -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Calendário</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.calendar_component.show_editor"
                name="is_main"
                size="lg"
                switch
                @change="emitShowEditor"
              >
              </b-form-checkbox>
            </div>
            <div class="label-range"></div>
            <div>
              <label class="sub">Cor de fundo dos dias disponiveis</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.available
                    .background
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Cor dos dias disponiveis</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.available.color
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Cor de fundo dos dias indisponiveis</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.unavailable
                    .background
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Cor dos dias indisponiveis</label>

              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.unavailable.color
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <!--  -->
              <div class="space"></div>

              <label class="sub">Cor de fundo dos dias vazios</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.empty.background
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Cor dos dias vazios</label>

              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.calendar_component.days.empty.color
                "
                :colors="palette"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></LvColorpicker>
              <div class="space"></div>

              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Largura dos dias</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>
                <input
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  v-model="
                    editaItem.propsData.calendar_component.size[typeView].width
                  "
                  @input="attrWidthDaysCalendar"
                  :disabled="
                    !editaItem.propsData.calendar_component.show_editor
                  "
                />
              </div>
              <b-form-input
                v-model="
                  editaItem.propsData.calendar_component.size[typeView].width
                "
                type="range"
                min="0"
                max="100"
                step="1"
                @input="attrWidthDaysCalendar"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></b-form-input>
              <!-- TESTE -->
              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Altura dos dias</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>

                <input
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  v-model="
                    editaItem.propsData.calendar_component.size[typeView].height
                  "
                  @input="attrHeightDaysCalendar"
                  :disabled="
                    !editaItem.propsData.calendar_component.show_editor
                  "
                />
              </div>
              <b-form-input
                v-model="
                  editaItem.propsData.calendar_component.size[typeView].height
                "
                type="range"
                min="0"
                max="100"
                step="1"
                @input="attrHeightDaysCalendar"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></b-form-input>
              <!-- BORDER SIZE -->
              <div class="label-range">
                <label class="subtitulo">Arredondar Bordas</label>
                <input
                  type="number"
                  min="0"
                  max="50"
                  step="1"
                  v-model="
                    editaItem.propsData.calendar_component.styles.border_radius
                  "
                  :disabled="
                    !editaItem.propsData.calendar_component.show_editor
                  "
                />
              </div>
              <b-form-input
                v-model="
                  editaItem.propsData.calendar_component.styles.border_radius
                "
                type="range"
                min="0"
                max="50"
                step="1"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></b-form-input>
              <!-- FONT SIZE -->
              <div class="label-range">
                <div class="label-icon">
                  <label class="subtitulo">Tamanho da fonte (px)</label>
                  <img
                    src="@/assets/editor/globe.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="global"
                  />
                  <img
                    src="@/assets/editor/monitor.svg"
                    alt="monitor"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')"
                    v-if="typeView === 'desktop' && !global"
                  />
                  <img
                    src="@/assets/editor/tablet.svg"
                    alt="tablet"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')"
                    v-if="typeView === 'tablet' && !global"
                  />
                  <img
                    src="@/assets/editor/phone.svg"
                    alt="phone"
                    style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)"
                    v-if="typeView === 'mobile' && !global"
                  />
                </div>

                <input
                  type="number"
                  min="0"
                  max="50"
                  step="1"
                  v-model="
                    editaItem.propsData.calendar_component.size[typeView]
                      .font_size
                  "
                  @input="attrSizeCalendar"
                  :disabled="
                    !editaItem.propsData.calendar_component.show_editor
                  "
                />
              </div>
              <b-form-input
                v-model="
                  editaItem.propsData.calendar_component.size[typeView]
                    .font_size
                "
                type="range"
                min="0"
                max="50"
                step="1"
                @input="attrSizeCalendar"
                :disabled="!editaItem.propsData.calendar_component.show_editor"
              ></b-form-input>
            </div>
          </div>

          <div class="separador"></div>
          <!-- ProgressBar -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Barra de Progresso</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.progress_bar.show"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="label-range"></div>
            <div>
              <label class="sub">Cor de fundo da barra de progresso</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="
                  editaItem.propsData.progress_bar.background_color_container
                "
                :colors="palette"
                :disabled="!editaItem.propsData.progress_bar.show"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Cor da barra de progresso</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.progress_bar.background_color_bar"
                :colors="palette"
                :disabled="!editaItem.propsData.progress_bar.show"
              ></LvColorpicker>
              <div class="space"></div>
              <div class="label-range">
                <label class="subtitulo">Espessura da barra de progresso</label>
                <input
                  type="number"
                  min="0"
                  max="15"
                  step="1"
                  v-model="
                    editaItem.propsData.progress_bar.size[typeView].height
                  "
                  :disabled="!editaItem.propsData.progress_bar.show"
                />
              </div>
              <b-form-input
                v-model="editaItem.propsData.progress_bar.size[typeView].height"
                type="range"
                min="0"
                max="15"
                step="1"
                :disabled="!editaItem.propsData.progress_bar.show"
              ></b-form-input>
            </div>
          </div>

          <div class="separador"></div>

          <!-- Bordas -->
          <div class="content">
            <label class="titulo">Bordas</label>
            <div class="label-range">
              <label class="subtitulo">Arredondar bordas </label>
              <img
                :class="{ borderLinkActive: form_linked === true }"
                style="height: 18px; transition: all 0.3s; filter: invert(50%)"
                src="@/assets/editor/link-2.svg"
                alt="linkar"
                @click="changeFormLinked()"
              />
            </div>
            <div class="inputs-group">
              <input
                type="number"
                v-model="editaItem.propsData.styles.border_top_left_radius"
                @input="linked('top_left')"
              />
              <input
                type="number"
                v-model="editaItem.propsData.styles.border_top_right_radius"
                @input="linked('top_right')"
              />
              <input
                type="number"
                v-model="editaItem.propsData.styles.border_bottom_left_radius"
                @input="linked('bottom_left')"
              />
              <input
                type="number"
                v-model="editaItem.propsData.styles.border_bottom_right_radius"
                @input="linked('bottom_right')"
              />
            </div>
            <div>
              <div class="space"></div>
              <label class="sub">Cor da borda</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.form_border_color"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="subtitulo">Traço</label>
              <multiselect
                v-model="borderStyle"
                :options="[
                  '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                  '━━━━━━━━━━━━━━━━━━',
                  '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                  '══════════════════',
                ]"
                :searchable="false"
                :show-labels="false"
                placeholder="Traço"
              ></multiselect>
              <div class="space"></div>

              <div class="label-range">
                <label class="subtitulo">Espessura da borda</label>
                <input
                  type="number"
                  v-model="editaItem.propsData.styles.form_border_size"
                />
              </div>
              <b-form-input
                v-model="editaItem.propsData.styles.form_border_size"
                type="range"
                min="0"
                max="10"
                step="1"
              ></b-form-input>
            </div>
          </div>

          <div class="separador"></div>
          <div class="content">
            <div>
              <label class="titulo">Campos</label>

              <!-- <div class="space" />
              <label class="subtitulo">Cor do placeholder</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.placeholder_color"
                :colors="palette"
              ></LvColorpicker> 
              <div class="space"></div> -->
              <label class="subtitulo">Fundo</label>
              <LvColorpicker
                @input="setaInputStyles"
                v-model="editaItem.propsData.styles.input_back"
                id="LvColorpicker"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>

              <label class="subtitulo">Cor da borda</label>
              <LvColorpicker
                @input="setaInputStyles"
                v-model="editaItem.propsData.styles.input_border_color"
                id="LvColorpicker"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
            </div>
            <div>
              <label class="subtitulo">Arredondar bordas dos campos</label>
              <div class="und_form">
                <b-form-input
                  v-model="editaItem.propsData.styles.input_radius"
                  type="number"
                  min="0"
                  max="20"
                  @input="setaInputStyles"
                ></b-form-input>
              </div>
            </div>
            <!-- <div style="display: flex; gap: 10px">
              <div style="width: 50%">
                <label class="subtitulo">Largura</label>
                <div class="und_form">
                  <input
                    v-model="editaItem.propsData.styles.input_width"
                    type="number"
                    min="0"
                    max="100"
                    @input="setaInputStyles"
                  />
                  <span
                    class="und und2 text"
                    v-b-tooltip.hover
                    title="Largura em porcentagem"
                    >%</span
                  >
                </div>
              </div>
              <div style="width: 50%">
                <label class="subtitulo">Espaçamento</label>
                <div class="und_form">
                  <input
                    v-model="editaItem.propsData.spacing.desktop.input_gap"
                    type="number"
                    min="17"
                    max="30"
                    @input="setaInputStyles"
                  />
                  <span
                    class="und und2 text"
                    v-b-tooltip.hover
                    title="Largura em porcentagem"
                    >%</span
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="separador"></div>

          <div class="content">
            <label class="titulo">Botão</label>

            <label class="subtitulo">Tipografia</label>
            <div
              style="
                display: flex;
                gap: 10px;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 1rem;
              "
            >
              <b-button-group>
                <b-button
                  :disabled="
                    editaItem.propsData.size.desktop.font_size_button == 0
                  "
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  @click="editaItem.propsData.size.desktop.font_size_button--"
                  ><img src="@/assets/editor/minus3.svg" alt="align"
                /></b-button>
                <b-form-input
                  class="text-right"
                  v-model="editaItem.propsData.size.desktop.font_size_button"
                  style="
                    width: 44px;
                    text-align: center !important;
                    border: none !important;
                    padding: 0 !important;
                  "
                ></b-form-input>
                <b-button
                  @click="editaItem.propsData.size.desktop.font_size_button++"
                  size="sm"
                  style="
                    height: min-content !important;
                    padding: 10.5px 7px !important;
                  "
                  ><img src="@/assets/editor/plus.svg" alt="align"
                /></b-button>
              </b-button-group>

              <div style="width: 30%">
                <multiselect
                  v-model="editaItem.propsData.styles.textFontMeasure"
                  :options="['px', 'rem', '%', 'em']"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  placeholder=" "
                  :hide-selected="true"
                ></multiselect>
              </div>

              <div>
                <b-button
                  size="sm"
                  @click="trocarType"
                  style="
                    height: min-content !important;
                    padding: 10.5px 10px !important;
                  "
                  ><img src="@/assets/editor/type.svg" alt="align"
                /></b-button>
              </div>
            </div>
            <multiselect
              v-model="editaItem.propsData.styles.font_family"
              :options="fontOptions"
              @input="$emit('callCharge',$event)"
              :allow-empty="false"
              :hide-selected="true"
              :show-labels="false"
              placeholder="Fonte"
            ></multiselect>

            <div class="space" />
            <label class="subtitulo">Cor do conteúdo</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_button"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Espessura da fonte</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.font_weight"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.font_weight"
              type="range"
              min="100"
              max="1200"
              step="50"
            ></b-form-input>

            <div class="label-range">
              <label class="subtitulo">Espaço entre letras</label>
              <input
                type="number"
                v-model="editaItem.propsData.styles.letter_space"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.styles.letter_space"
              type="range"
              min="-5"
              max="15"
              step="0.25"
            ></b-form-input>
            <label>Tipo do fundo</label>
            <multiselect
              v-model="backgroundType"
              :options="['Cor sólida', 'Gradiente']"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              placeholder="Tipo do fundo"
              :hide-selected="false"
              @click.capture="backgroundCreate()"
            ></multiselect>

            <div
              v-show="!backgroundType || backgroundType == 'Cor sólida'"
              style="height: 1rem"
            ></div>

            <!-- Cor sólida -->
            <div v-show="backgroundType == 'Cor sólida'">
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.back_content"
                :colors="palette"
              ></LvColorpicker>
            </div>

            <!-- Gradiente -->
            <div v-show="backgroundType == 'Gradiente'">
              <!-- Pickers -->
              <div class="pickers pb-2">
                <div class="picker">
                  <LvColorpicker
                    @change="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="color1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @change="backgroundCreate()"
                    id="LvColorpicker"
                    v-model="color2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input type="number" v-model="selectedDegree" />
              </div>
              <b-form-input
                v-model="selectedDegree"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>
            <!-- ICON POSITION -->
            <div class="space"></div>
            <label class="subtitulo">Posicionamento</label>
            <div style="display: flex; width: 100%">
              <div
                style="
                  display: flex;
                  width: 100%;
                  transition: all 0.3s;
                  justify-content: space-between;
                "
              >
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].button ==
                        'flex-start',
                    }"
                    @click="globalJustify('flex-start', 'button')"
                  >
                    <img src="@/assets/editor/align-left-new.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].button ==
                        'center',
                    }"
                    @click="globalJustify('center', 'button')"
                  >
                    <img src="@/assets/editor/align-center-horizontal.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].button ==
                        'inherit',
                    }"
                    @click="globalJustify('inherit', 'button')"
                  >
                    <img src="@/assets/editor/align-center-horizontal.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView].button ==
                        'flex-end',
                    }"
                    @click="globalJustify('flex-end', 'button')"
                  >
                    <img src="@/assets/editor/align-right-new.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div
              style="
                display: flex;
                width: 100%;
                transition: all 0.3s;
                align-items: center;
              "
            >
              <label class="subtitulo">Texto ( ENTER ↵ )</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.text_enter_show"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- ICON -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Ícone</label>
            <label>Cor do ícone</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.icon_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Tamanho</label>
              <input
                type="number"
                v-model="editaItem.propsData.size.desktop.icon_size"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size.desktop.icon_size"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Margem lateral</label>
              <input
                type="number"
                v-model="editaItem.propsData.spacing.desktop.icon_margin"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.spacing.desktop.icon_margin"
              type="range"
              min="0"
              max="25"
              step="1"
            ></b-form-input>
            <!-- ICON DIRECTION -->
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label
                class="subtitulo"
                :class="{ labelOff: !editaItem.propsData.icon_is_right }"
                >Ícone à direita</label
              >
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.icon_is_right"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- ARROWS -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Setas</label>
            <label>Cor das setas</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.arrows_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>

            <div class="label-range">
              <label class="subtitulo">Tamanho</label>
              <input
                type="number"
                v-model="editaItem.propsData.size.desktop.arrows_size"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size.desktop.arrows_size"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div class="space"></div>
            <div class="label-range">
              <label class="subtitulo">Margem lateral</label>
              <input
                type="number"
                v-model="editaItem.propsData.spacing.desktop.arrows_margin"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.spacing.desktop.arrows_margin"
              type="range"
              min="0"
              max="25"
              step="1"
            ></b-form-input>
            <!-- ALIGN ARROWS -->
            <div>
              <div class="space"></div>
              <label class="subtitulo">Posicionamento</label>
              <div style="display: flex; width: 100%; gap: 10px">
                <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].arrows ==
                          'flex-start',
                      }"
                      @click="globalJustify('flex-start', 'arrows')"
                    >
                      <img src="@/assets/editor/align-left-new.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].arrows ==
                          'center',
                      }"
                      @click="globalJustify('center', 'arrows')"
                    >
                      <img src="@/assets/editor/align-center-horizontal.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].arrows ==
                          'flex-end',
                      }"
                      @click="globalJustify('flex-end', 'arrows')"
                    >
                      <img src="@/assets/editor/align-right-new.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </div>
        <div v-else>
          <div style="display: grid; fill: #0000">
            <svg
              style="align-self: center; margin: 0 auto"
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 24 24"
              fill="#0000"
              stroke="#666"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-alert-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
            <p style="font-size: 16px; text-align: center; margin-top: 20px">
              Selecione um
            </p>
            <p style="font-size: 16px; text-align: center">
              formulário e um calendario
            </p>
            <p style="font-size: 16px; text-align: center">
              para poder continuar
            </p>
            <p style="font-size: 16px; text-align: center">com a edição</p>
          </div>
        </div>
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <div
          v-if="
            editaItem.propsData.selected_calendar.id &&
            editaItem.propsData.form.id
          "
        >
          <!-- Espaçamento formulário -->
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Formulário</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                v-if="typeView === 'desktop'"
              />
            </div>
            <!-- Componente de Espaçamento -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  v-if="linkMargin"
                  class="links-advanced"
                  @click="linkMargin = !linkMargin"
                />
                <img
                  src="@/assets/editor/link.svg"
                  v-else
                  alt="Link"
                  class="links-advanced"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'form')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'form')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            v-if="linkPadding"
                            class="links-advanced"
                            @click="linkPadding = !linkPadding"
                          />
                          <img
                            src="@/assets/editor/link.svg"
                            v-else
                            alt="Link"
                            class="links-advanced"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'form')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'form')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'form')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'form')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'form')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'form')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Espaçamento botão -->
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Botão</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                v-if="typeView === 'desktop'"
              />
            </div>
            <!-- Componente de Espaçamento -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  v-if="linkMarginButton"
                  class="links-advanced"
                  @click="linkMarginButton = !linkMarginButton"
                />
                <img
                  src="@/assets/editor/link.svg"
                  v-else
                  alt="Link"
                  class="links-advanced"
                  @click="linkMarginButton = !linkMarginButton"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_button_left
                      "
                      @input="attrLinkMargin($event, 'button')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView]
                              .margin_button_top
                          "
                          @input="attrLinkMargin($event, 'button')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            v-if="linkPaddingButton"
                            class="links-advanced"
                            @click="linkPaddingButton = !linkPaddingButton"
                          />
                          <img
                            src="@/assets/editor/link.svg"
                            v-else
                            alt="Link"
                            class="links-advanced"
                            @click="linkPaddingButton = !linkPaddingButton"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_button_left
                                "
                                @input="paddingLink($event, 'button')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_button_top
                                    "
                                    @input="paddingLink($event, 'button')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_button_bottom
                                  "
                                  @input="paddingLink($event, 'button')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_button_right
                                "
                                @input="paddingLink($event, 'button')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView]
                              .margin_button_bottom
                          "
                          @input="attrLinkMargin($event, 'button')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView]
                          .margin_button_right
                      "
                      @input="attrLinkMargin($event, 'button')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Visibilidade -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub" style="display: flex !important">
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  @change="$emit('fixWidth')"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub" style="display: flex !important">
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub" style="display: flex !important">
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
        </div>
        <div v-else>
          <div style="display: grid; fill: #0000">
            <svg
              style="align-self: center; margin: 0 auto"
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 24 24"
              fill="#0000"
              stroke="#666"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-alert-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
            <p style="font-size: 16px; text-align: center; margin-top: 20px">
              Selecione um
            </p>
            <p style="font-size: 16px; text-align: center">
              formulário e um calendario
            </p>
            <p style="font-size: 16px; text-align: center">
              para poder continuar
            </p>
            <p style="font-size: 16px; text-align: center">com a edição</p>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
import ModalIcons from "@/components/Editor/ModalSvg";
import Upload from "../../Upload.vue";
//
import CalendarsService from "@/services/resources/CalendarService";
const serviceCalendar = CalendarsService.build();

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],
  components: {
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
    Upload,
  },
  data() {
    return {
      calendars: [],
      stypeIcon: "",
      stop: false,
      stop2: false,
      forms: [],
      pages: [],
      form_linked: true,
      linkPadding: true,
      linkPaddingButton: true,
      linkMargin: true,
      linkMarginButton: true,
      type: "none",
      backgroundType: "",
      selectedDegree: "",
      cor_fundo: "#000000",
      color1: "var(--greenn)",
      color2: "#093627",
      gradientType: "linear-gradient",
      borderThickness: "",
      borderStyle: "━━━━━━━━━━━━━━━━━━",
      global: false,
      // seeOnComputer: true,
      // seeOnTablet: true,
      // seeOnPhone: true,
    };
  },
  watch: {
    selectedDegree(valor) {
      this.backgroundCreate();
    },
    color1(valor) {
      this.backgroundCreate();
    },
    color2(valor) {
      this.backgroundCreate();
    },
    backgroundType(valor) {
      this.backgroundCreate();
    },
    cor_fundo(valor) {
      this.backgroundCreate();
    },
    borderThickness(valor) {
      this.borderCreate();
    },
    borderColor(valor) {
      this.borderCreate();
    },
    borderStyle(valor) {
      this.borderCreate();
    },
    hasBorder(valor) {
      this.borderCreate();
    },
  },
  computed: {
    inputStyle() {
      return {
        color: this.cor,
        backgroundColor: this.fundo,
        "::placeholder": {
          color: this.cor,
        },
        ":hover": {
          color: this.hoverCor,
          backgroundColor: this.hoverFundo,
        },
      };
    },
  },
  methods: {
    setImg(img) {
      this.editaItem.propsData.image.src = img;
    },
    obterProporcoesOriginais() {
      const img = new Image();
      img.onload = () => {
        const proporcoes = {
          largura: img.width,
          altura: img.height,
        };
        this.proporcoes = proporcoes;
      };
      img.src = this.editaItem.propsData.image.src;
    },
    attrHeightDaysCalendar(e) {
      var props = this.editaItem.propsData.calendar_component.size;

      if (this.global) {
        props["desktop"].height = e;
        props["tablet"].height = e;
        props["mobile"].height = e;
      }
    },
    attrWidthDaysCalendar(e) {
      var props = this.editaItem.propsData.calendar_component.size;

      if (this.global) {
        props["desktop"].width = e;
        props["tablet"].width = e;
        props["mobile"].width = e;
      }
    },
    attrSizeCalendar(e) {
      var props = this.editaItem.propsData.calendar_component.size;

      if (this.global) {
        props["desktop"].font_size = e;
        props["tablet"].font_size = e;
        props["mobile"].font_sizee = e;
      }
    },
    emitShowEditor(e) {
      this.$root.$emit("showCalendarEditor", e);
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalWidth(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        props.tablet.width = px;
        props.mobile.width = px;
      }
    },
    validateGlobal() {
      if (this.global && this.typeView == "desktop") {
        var prop = this.editaItem.propsData.size;
        prop.tablet.width_form = prop.desktop.width_form;
        prop.mobile.width_form = prop.desktop.width_form;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    getCalendars(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        page: page,
      };
      serviceCalendar.search(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop = true;
        }
        this.calendars = this.calendars.concat(resp.data);
      });
    },
    // getPages() {
    //   servicePage
    //     .read({ id: `all?campaign_id=${this.$route.params.campaign_id}` })
    //     .then((response) => {
    //       var pages = response;
    //       pages.map((page) => {
    //         page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
    //       });
    //       pages.push({ id: null, title: "Selecione a página" });
    //       this.pages = pages;
    //     });
    // },
    getForms(page = 1) {
      if (this.stop) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "active",
      };
      serviceForm.search(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop = true;
        }
        this.forms = this.forms.concat(resp.data);
      });
    },
    // getPages() {
    //   servicePage
    //     .read({ id: `all?campaign_id=${this.$route.params.campaign_id}` })
    //     .then((response) => {
    //       var pages = response;
    //       pages.map((page) => {
    //         page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
    //       });
    //       pages.push({ id: null, title: "Selecione a página" });
    //       this.pages = pages;
    //     });
    // },
    getPages(page = 1) {
      if (this.stop2) {
        return;
      }
      var data = {
        campaign_id: this.$route.params.campaign_id,
        page: page,
        status: "save",
      };

      PaginaListService.create(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stop2 = true;
        }
        var pages = resp.data;
        pages.map((page) => {
          page.path_name = `https://${this.$route.params.dynamicRoute}.gdigital.com.br/${page.path_name}`;
        });
        this.pages = this.pages.concat(pages);
      });
    },
    setaCalendar(e) {
      serviceCalendar
        .read({ id: e.id })
        .then((resp) => {
          // var find_schedule = e.metas.find((meta) => meta.meta_key === "schedule");
          // if (find_schedule) {
          //   var schedule = JSON.parse(find_schedule.meta_value);
          this.editaItem.propsData.selected_calendar = resp;
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setaForm(e) {
      e.fields.push(
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="tenant_id" value="${this.$store.getters.user.user.tenant_id}">`,
        },
        {
          id: 0,
          name_field: "hidden",
          form_field: `<input type="hidden" id="page_id" value="${this.$route.params.page_id}">`,
        }
      );
      this.editaItem.propsData.fields = e.fields;
      this.editaItem.propsData.tenant_id =
        this.$store.getters.user.user.tenant_id;
    },
    removeForm() {
      this.editaItem.propsData.form = {
        id: null,
        title: "Selecione um formulário",
      };
      this.editaItem.propsData.fields = [
        {
          id: 54,
          form_id: 51,
          name_field: "text",
          form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Nome</label><span class="required-asterisk" style="display: none;"> </span><input type="text" style="teste: 2px;" placeholder="Seu nome" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
          json: {
            name: "nome",
            type: "text",
            label: "Nome",
            mascara: "form-control",
            className: "form-control",
          },
          order: 0,
          created_at: "2023-03-26T03:32:54.000000Z",
          updated_at: "2023-03-26T03:32:54.000000Z",
        },
        {
          id: 55,
          form_id: 51,
          name_field: "text",
          form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">E-mail</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu e-mail" class="form-control" name="email-preview" mascara="form-control" id="email-preview"></div></li>`,
          json: {
            name: "email",
            type: "text",
            label: "Nome",
            mascara: "form-control",
            className: "form-control",
          },
          order: 0,
          created_at: "2023-03-26T03:32:54.000000Z",
          updated_at: "2023-03-26T03:32:54.000000Z",
        },
        {
          id: 56,
          form_id: 51,
          name_field: "text",
          form_field: `<li class="prev-holder" style=""><div class="fb-text form-group field-nome-preview"><label class="fb-text-label">Telefone</label><span class="required-asterisk" style="display: none;"> </span><input type="text" placeholder="Seu Telefone" class="form-control" name="nome-preview" mascara="form-control" id="nome-preview"></div></li>`,
          json: {
            name: "nome",
            type: "text",
            label: "Nome",
            mascara: "form-control",
            className: "form-control",
          },
          order: 0,
          created_at: "2023-03-26T03:32:54.000000Z",
          updated_at: "2023-03-26T03:32:54.000000Z",
        },
      ];
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    setaInputStyles() {
      var style = `style="border-radius:${
        this.editaItem.propsData.styles.input_radius
      }px !important; width:${
        this.editaItem.propsData.styles.input_width > 100
          ? 100
          : this.editaItem.propsData.styles.input_width
      }% !important; margin-bottom: ${
        this.editaItem.propsData.spacing.desktop.input_gap > 30
          ? 30
          : this.editaItem.propsData.spacing.desktop.input_gap
      }px; background-color: ${this.editaItem.propsData.styles.input_back};"`;

      var fields = this.editaItem.propsData.fields;
      for (let i = 0; i < fields.length; i++) {
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /style="([^"]*)"/gi,
            ""
          );
        this.editaItem.propsData.fields[i].form_field =
          this.editaItem.propsData.fields[i].form_field.replace(
            /input type="([^\s]+)"/,
            "$&" + style
          );
      }
    },
    trocarType() {
      if (this.type == "none") {
        this.type = "uppercase";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
      if (this.type == "uppercase") {
        this.type = "none";
        this.editaItem.propsData.styles.text_type = this.type;
        return;
      }
    },
    openModal(data, type) {
      this.stypeIcon = type;
      this.$bvModal.show(data);
    },
    iconSelect(item) {
      if (this.stypeIcon == "icon") {
        this.editaItem.propsData.icon_selected = item;
      } else if (this.stypeIcon == "arrow-top") {
        this.editaItem.propsData.arrows.top = item;
      } else if (this.stypeIcon == "arrow-bottom") {
        this.editaItem.propsData.arrows.bottom = item;
      } else {
        this.editaItem.propsData.icon_selected = item;
      }
    },
    saveOpt(opt) {
      if (!this.editaItem.propsData.form && !this.editaItem.propsData.form.id) {
        this.$bvToast.toast("Por favor, selecione um formulário", {
          title: "Editor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        return;
      }

      let data = {
        id: `insert-options/${this.editaItem.propsData.form.id}`,
        page_id: 1,
      };

      serviceForm.createId(data);
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    backgroundCreate() {
      if (this.backgroundType == "Cor sólida") {
        this.editaItem.propsData.styles.back_type = "color";
        this.editaItem.propsData.styles.back_content = `${
          this.editaItem.propsData.styles.back_content ?? this.cor_fundo
        }`;
        return;
      }
      if (this.backgroundType == "Gradiente") {
        this.editaItem.propsData.styles.back_type = "image";
        this.editaItem.propsData.styles.grad_angle = this.selectedDegree;
        this.editaItem.propsData.styles.grad_color1 = this.color2;
        this.editaItem.propsData.styles.grad_color2 = this.color1;
        this.editaItem.propsData.styles.back_content = `${this.gradientType}(${this.selectedDegree}deg, ${this.color2}, ${this.color1})`;
        return;
      }
      if (this.backgroundType == "Imagem") {
        this.editaItem.propsData.styles.back_type = "image";
        this.editaItem.propsData.styles.back_content = `${this.cor_fundo}`;
        return;
      }
    },
    paddingLink(px, type) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      var typeView = this.typeView;
      switch (type) {
        case "form":
          if (this.linkPadding) {
            if (this.typeView === "desktop") {
              props[typeView].padding_top = value;
              props[typeView].padding_right = value;
              props[typeView].padding_bottom = value;
              props[typeView].padding_left = value;
            }
          }
          break;
        case "button":
          if (this.linkPaddingButton) {
            if (this.typeView === "desktop") {
              props[typeView].padding_button_top = value;
              props[typeView].padding_button_right = value;
              props[typeView].padding_button_bottom = value;
              props[typeView].padding_button_left = value;
            }
          }
          break;
      }
    },
    attrLinkMargin(px, type) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      var typeView = this.typeView;

      switch (type) {
        case "form":
          if (this.typeView === "desktop" && this.linkMargin) {
            props[typeView].margin_top = value;
            props[typeView].margin_right = value;
            props[typeView].margin_bottom = value;
            props[typeView].margin_left = value;
          }
          break;
        case "button":
          if (this.typeView === "desktop" && this.linkMarginButton) {
            props[typeView].margin_button_top = value;
            props[typeView].margin_button_right = value;
            props[typeView].margin_button_bottom = value;
            props[typeView].margin_button_left = value;
          }
          break;
      }
    },
    changeFormLinked() {
      this.form_linked = !this.form_linked;
      if (this.form_linked) {
        this.editaItem.propsData.styles.border_top_right_radius =
          this.editaItem.propsData.styles.border_top_left_radius;
        this.editaItem.propsData.styles.border_bottom_right_radius =
          this.editaItem.propsData.styles.border_top_right_radius;
        this.editaItem.propsData.styles.border_bottom_left_radius =
          this.editaItem.propsData.styles.border_bottom_right_radius;
      }
    },
    linked(position) {
      if (this.form_linked == true) {
        switch (position) {
          case "top_left":
            this.editaItem.propsData.styles.border_top_right_radius =
              this.editaItem.propsData.styles.border_top_left_radius;
            this.editaItem.propsData.styles.border_bottom_right_radius =
              this.editaItem.propsData.styles.border_top_right_radius;
            this.editaItem.propsData.styles.border_bottom_left_radius =
              this.editaItem.propsData.styles.border_top_right_radius;
            break;
          case "top_right":
            this.editaItem.propsData.styles.border_top_left_radius =
              this.editaItem.propsData.styles.border_top_right_radius;
            this.editaItem.propsData.styles.border_bottom_right_radius =
              this.editaItem.propsData.styles.border_top_left_radius;
            this.editaItem.propsData.styles.border_bottom_left_radius =
              this.editaItem.propsData.styles.border_top_left_radius;
            break;
          case "bottom_right":
            this.editaItem.propsData.styles.border_top_left_radius =
              this.editaItem.propsData.styles.border_bottom_right_radius;
            this.editaItem.propsData.styles.border_top_right_radius =
              this.editaItem.propsData.styles.border_bottom_right_radius;
            this.editaItem.propsData.styles.border_bottom_left_radius =
              this.editaItem.propsData.styles.border_top_left_radius;
            break;
          case "bottom_left":
            this.editaItem.propsData.styles.border_top_left_radius =
              this.editaItem.propsData.styles.border_bottom_left_radius;
            this.editaItem.propsData.styles.border_top_right_radius =
              this.editaItem.propsData.styles.border_bottom_left_radius;
            this.editaItem.propsData.styles.border_bottom_right_radius =
              this.editaItem.propsData.styles.border_bottom_left_radius;
            break;
        }
      }
    },
    borderCreate() {
      // this.editaItem.propsData.styles.form_border_style = this.borderStyle;
      var styleName = "";
      if (this.borderStyle == "● ● ● ● ● ● ● ● ● ● ● ● ● ●") {
        styleName = "dotted";
        this.editaItem.propsData.styles.form_border_style = `${styleName}`;
        return;
      }
      if (this.borderStyle == "━━━━━━━━━━━━━━━━━━") {
        styleName = "solid";
        this.editaItem.propsData.styles.form_border_style = `${styleName}`;
        return;
      }
      if (this.borderStyle == "━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━") {
        styleName = "dashed";
        this.editaItem.propsData.styles.form_border_style = `${styleName}`;
        return;
      }
      if (this.borderStyle == "══════════════════") {
        styleName = "double";
        this.editaItem.propsData.styles.form_border_style = `${styleName}`;
        return;
      }
    },
    debounce: _.debounce(function (opt) {
      this.saveOpt(opt);
    }, 500),
  },
  mounted() {
    this.obterProporcoesOriginais();
  },
  created() {
    this.form_linked = this.editaItem.propsData.form_linked;
    this.hasBorder = this.editaItem.propsData.hasBorder;
    this.backgroundType = this.editaItem.propsData.styles.back_selected;
    this.selectedDegree = this.editaItem.propsData.styles.grad_angle;
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
// Está fazendo interferência com a classe content usada na side right
// .content {
//   padding: 10px 30px 35px 30px !important;
// }
.button-collapse-alpha {
  width: 100%;
  border-radius: 8px;
  background-color: var(--greenn) 25;
  border: none;
  margin-bottom: 4px;
  height: 40px;
}
.und_form {
  position: relative;
  .und {
    position: absolute;
    right: 8px;
    top: 43px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
  .und2 {
    top: 7px !important;
  }
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--greenn);
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--greenn) !important;
}

button {
  border: none;
}

button:disabled,
.disabled {
  background-color: transparent !important;
}

.more-options {
  padding: 10px;
  background-color: transparent;
  width: 100%;
}

#range-1::-webkit-slider-thumb,
#range-1::-moz-range-thumb {
  background-color: var(--greenn) !important;
}

*:disabled {
  color: #dadada !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem var(--greenn) 50 !important;
  box-shadow: 0 0 0 0.2rem var(--greenn) 50 !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: rgb(0, 0, 0);
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #545b6200 !important;
}

input:disabled {
  background-color: transparent !important;
}

.alinhamento-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alinhamento {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-1,
.item-2,
.item-3 {
  input {
    font-size: 0.8rem !important;
    line-height: 1.25rem !important;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    text-align: center !important;
    width: 2rem !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 1rem !important;
    border: none !important;
  }
}

.container-spacing {
  display: flex;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.labelOff {
  color: rgb(185, 185, 185) !important;
}
input {
  width: 100%;
}
</style>
