<template>
  <div id="SideRightTabs" class="countDown">
    <b-tabs pills justified small style="width: 100%">
      <b-tab title="Geral">
        <div class="separador"></div>
        <div class="content">
          <label class="titulo">Contagem regressiva</label>
          <label class="subtitulo">Data final</label>
          <date-range-picker
            ref="picker"
            :locale-data="localeData"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="false"
            :timePicker24Hour="true"
            :timePicker="true"
            v-model="dateRange1"
            :single-date-picker="true"
            @update="updateDate()"
            style="
              display: block;
              display: flex;
              height: 45px;
              align-items: center;
            "
          >
            <template
              v-if="dateRange1.startDate && dateRange1.endDate"
              v-slot:input="picker1"
            >
              {{ picker1.startDate | datetimem }}
            </template>
          </date-range-picker>
          <div class="space"></div>
          <label class="subtitulo">Tipo</label>
          <multiselect
            :options="['Círculo', 'Tradicional']"
            v-model="editaItem.propsData.styles.type"
            :hide-selected="true"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            placeholder=" "
          ></multiselect>
        </div>
        <div class="separador"></div>
      </b-tab>

      <b-tab title="Estilo">
        <div class="separador"></div>
        <!-- Dimensões -->
        <div class="content">
          <div class="label-icon">
            <label class="titulo">Tamanho</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="global"
            />
            <img
              src="@/assets/editor/monitor.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('tablet')"
              v-if="typeView === 'desktop' && !global"
            />
            <img
              src="@/assets/editor/tablet.svg"
              alt="tablet"
              style="filter: invert(50%) !important"
              @click="nextIcon('mobile')"
              v-if="typeView === 'tablet' && !global"
            />
            <img
              src="@/assets/editor/phone.svg"
              alt="phone"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="typeView === 'mobile' && !global"
            />
          </div>
          <div class="label-range">
            <label class="subtitulo">Fonte do CountDown</label>
            <input
              type="number"
              v-model="editaItem.propsData.count_size[typeView].count_size"
              @input="globalMeasure($event, 'count_size')"
            />
          </div>
          <b-form-input
            id="range-1"
            v-model="editaItem.propsData.count_size[typeView].count_size"
            type="range"
            min="20"
            max="100"
            @input="globalMeasure($event, 'count_size')"
          ></b-form-input>
          <div class="space"></div>
          <div class="label-range">
            <label class="subtitulo">Espaço entre os itens</label>
            <input
              type="number"
              v-model="editaItem.propsData.count_size[typeView].gap"
              @input="globalMeasure($event)"
            />
          </div>
          <b-form-input
            id="range-1"
            v-model="editaItem.propsData.count_size[typeView].gap"
            @input="globalMeasure($event)"
            type="range"
            min="0"
            max="1500"
          ></b-form-input>
          <div class="space"></div>
          <label class="subtitulo">Posicionamento</label>
          <div style="display: flex; width: 100%; gap: 10px">
            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'flex-start',
                  }"
                  @click="globalJustify('flex-start', 'justify')"
                >
                  <img src="@/assets/editor/align-left-new.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'center',
                  }"
                  @click="globalJustify('center', 'justify')"
                >
                  <img src="@/assets/editor/align-center-horizontal.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView].justify ==
                      'flex-end',
                  }"
                  style="z-index: 2"
                  @click="globalJustify('flex-end', 'justify')"
                >
                  <img src="@/assets/editor/align-right-new.svg" />
                </button>
              </div>
            </div>

            <div style="display: flex; width: 50%; transition: all 0.3s">
              <div class="select-align">
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'start',
                  }"
                  @click="globalJustify('start', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-top.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'center',
                  }"
                  @click="globalJustify('center', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-center-vertical.svg" />
                </button>
                <button
                  :class="{
                    'selected-align':
                      editaItem.propsData.alignment[typeView]
                        .justify_vertical == 'end',
                  }"
                  @click="globalJustify('end', 'justify_vertical')"
                >
                  <img src="@/assets/editor/align-bottom.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Cores -->
        <div class="content">
          <label class="titulo">Cores</label>
          <label class="subtitulo">Cor dos números</label>
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.number_color"
            label="Cor dos números"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <label
            v-if="editaItem.propsData.styles.type == 'Tradicional'"
            class="subtitulo"
            >Cor das legendas</label
          >
          <label
            v-if="editaItem.propsData.styles.type == 'Círculo'"
            class="subtitulo"
            >Cor do círculo de fundo</label
          >
          <LvColorpicker
            id="LvColorpicker"
            v-model="editaItem.propsData.styles.label_color"
            label="Cor de fundo"
            :colors="palette"
          ></LvColorpicker>
          <div class="space"></div>
          <div v-if="editaItem.propsData.styles.type == 'Círculo'">
            <label class="subtitulo">Cor do círculo</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.bar_color"
              label="Cor de fundo"
              :colors="palette"
            ></LvColorpicker>
          </div>
        </div>
        <div class="separador"></div>

        <div
          class="content max-multi"
          v-if="editaItem.propsData.styles.hasOwnProperty('font_number')"
        >
          <label class="titulo">Fontes</label>
          <label class="sub">Números</label>
          <multiselect
            v-model="editaItem.propsData.styles.font_number"
            :options="fontOptions"
            :searchable="true"
            :show-labels="false"
            :hide-selected="true"
            :allow-empty="false"
            @input="$emit('callCharge',$event)"
          ></multiselect>
          <div class="space"></div>
          <label class="sub">Legenda</label>
          <multiselect
            v-model="editaItem.propsData.styles.font_label"
            :options="fontOptions"
            :searchable="true"
            :show-labels="false"
            :hide-selected="true"
            :allow-empty="false"
            @input="$emit('callCharge',$event)"
          ></multiselect>
          <div
            class="separador"
            v-if="editaItem.propsData.styles.hasOwnProperty('font_number')"
          ></div>
        </div>
      </b-tab>

      <b-tab title="Avançado">
        <!-- Espaçamento -->
        <div class="separador"></div>
        <div class="content">
          <!-- Ícones -->
          <div class="label-icon">
            <label class="titulo">Espaçamento</label>
            <img
              src="@/assets/editor/globe.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="global"
            />
            <img
              src="@/assets/editor/monitor.svg"
              alt="monitor"
              style="filter: invert(50%) !important"
              @click="nextIcon('tablet')"
              v-if="typeView === 'desktop' && !global"
            />
            <img
              src="@/assets/editor/tablet.svg"
              alt="tablet"
              style="filter: invert(50%) !important"
              @click="nextIcon('mobile')"
              v-if="typeView === 'tablet' && !global"
            />
            <img
              src="@/assets/editor/phone.svg"
              alt="phone"
              style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)"
              v-if="typeView === 'mobile' && !global"
            />
          </div>
          <!-- Inputs -->
          <div class="container-spacing" style="padding-bottom: 15px">
            <div class="border-dash">
              <span class="txt-mg">MARGEM EXTERNA</span>
              <img
                src="@/assets/editor/link-click.svg"
                alt="Link"
                class="links-advanced"
                :class="linkMargin ? '' : 'link-disabled'"
                @click="linkMargin = !linkMargin"
              />
              <div class="alinhamento">
                <!-- MARGIN LEFT -->
                <div class="item-1">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_left"
                    @input="attrLinkMargin($event, 'left')"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="item-2">
                  <div class="alinhamento-2">
                    <!-- MARGIN TOP -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_top
                        "
                        @input="attrLinkMargin($event, 'top')"
                        min="0"
                        max="100"
                      />
                    </div>
                    <div>
                      <div class="contain-borders">
                        <span class="txt-mg">MARGEM INTERNA</span>
                        <img
                          src="@/assets/editor/link-click.svg"
                          alt="Link"
                          class="links-advanced"
                          :class="linkPadding ? '' : 'link-disabled'"
                          @click="linkPadding = !linkPadding"
                        />
                        <div class="alinhamento">
                          <!-- PADDING LEFT -->
                          <div class="item-1">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_left
                              "
                              @input="paddingLink($event, 'left')"
                              min="0"
                              max="100"
                            />
                          </div>
                          <div class="item-2">
                            <div class="alinhamento-2">
                              <!-- PADDING TOP -->
                              <div>
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_top
                                  "
                                  @input="paddingLink($event, 'top')"
                                  min="0"
                                  max="100"
                                />
                              </div>
                              <div>
                                <div class="border-final"></div>
                              </div>
                              <!-- PADDING BOTTOM -->
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_bottom
                                "
                                @input="paddingLink($event, 'bottom')"
                                min="0"
                                max="100"
                              />
                              <div></div>
                            </div>
                          </div>
                          <!-- PADDING RIGHT -->
                          <div class="item-3">
                            <input
                              type="number"
                              v-model="
                                editaItem.propsData.spacing[typeView]
                                  .padding_right
                              "
                              @input="paddingLink($event, 'right')"
                              min="0"
                              max="100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN BOTTOM -->
                    <div>
                      <input
                        type="number"
                        v-model="
                          editaItem.propsData.spacing[typeView].margin_bottom
                        "
                        @input="attrLinkMargin($event, 'bottom')"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                </div>
                <!-- MARGIN RIGHT -->
                <div class="item-3">
                  <input
                    type="number"
                    v-model="editaItem.propsData.spacing[typeView].margin_right"
                    @input="attrLinkMargin($event, 'right')"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Visibilidade -->
        <div class="separador"></div>
        <div class="content">
          <label class="titulo"> Visibilidade </label>
          <!-- Dispositivos grandes -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="sub"> Dispositivos grandesﾠ </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.monitor"
                @change="$emit('fixWidth')"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="space"></div>

          <!-- Dispositivos médios -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="sub"> Dispositivos médiosﾠ </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.tablet"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="space"></div>

          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="sub"> Dispositivos pequenosﾠ </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.phone"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Animação -->
        <div class="content">
          <label class="titulo">Animação</label>
          <div class="select-animation">
            <div class="animation-container">
              <div>
                <p>Nenhum</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Nenhuma',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                      (editaItem.propsColumn.attribute_animation = '')
                  "
                >
                  <div class="simulated-item simu1"></div>
                </div>
              </div>
              <div>
                <p>Zoom</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Gradual Increase',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Gradual Increase'),
                      (editaItem.propsColumn.attribute_animation =
                        'gradual-increase')
                  "
                >
                  <div class="simulated-item simu2">
                    <img src="@/assets/editor/square-logo.svg" alt="setinha" />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Up</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Up',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Up'),
                      (editaItem.propsColumn.attribute_animation = 'top')
                  "
                >
                  <div class="simulated-item simu3">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Right</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Right',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Right'),
                      (editaItem.propsColumn.attribute_animation = 'right')
                  "
                >
                  <div class="simulated-item simu4">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Down</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Down',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Down'),
                      (editaItem.propsColumn.attribute_animation = 'bottom')
                  "
                >
                  <div class="simulated-item simu5">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Left</p>
                <div
                  class="animation-item"
                  :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Left',
                  }"
                  @click="
                    (editaItem.propsColumn.attribute_und = 'Fade Left'),
                      (editaItem.propsColumn.attribute_animation = 'left')
                  "
                >
                  <div class="simulated-item simu6">
                    <img
                      src="@/assets/editor/arrow-fat-lines-down.svg"
                      alt="setinha"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div class="half-animation">
              <div>
                <div class="label-range">
                  <label
                    class="subtitulo"
                    :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    >Atraso</label
                  >
                  <input
                    type="number"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    v-model="editaItem.propsColumn.delay"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.delay"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  type="range"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
              <div>
                <div class="label-range">
                  <label
                    class="subtitulo"
                    :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    >Duração</label
                  >
                  <input
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="number"
                    v-model="editaItem.propsColumn.duration"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.duration"
                  type="range"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Classe e ID -->
        <div class="content">
          <label class="titulo">Classe e ID</label>
          <label class="sub"
            >IDﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_id"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: id_unico"
          />
          <label class="sub"
            >Classeﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_class"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: classe_1 classe_2"
          />
        </div>
        <div class="separador"></div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette", "fontOptions"],
  components: {
    Upload,
    BaseButton,
    LvInput,
    LvColorpicker: LvColorpicker,
    DateRangePicker,
    Multiselect,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      // BLOCO TODO
      rotate_margem: -180,
      rotate_padding: -180,
      content: true,
      dateRange1: {
        startDate: "",
        endDate: "",
      },
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
    };
  },
  methods: {
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalMeasure(pixel, place) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.count_size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    mg_componente(valor) {
      this.rotate_margem = valor;
    },
    pd_componente(valor) {
      this.rotate_padding = valor;
    },
    updateDate() {
      var data = moment(
        this.dateRange1.startDate ? this.dateRange1.startDate : ""
      ).unix();
      this.editaItem.propsData.count.date = data * 1000;
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    if (!this.editaItem.propsData.count.date) {
      const timeNow = new Date().getTime();
      this.editaItem.propsData.count.date = timeNow;
      this.dateRange1.startDate = timeNow;
    } else {
      this.dateRange1.startDate = this.editaItem.propsData.count.date;
    }
  },
};
</script>

<style lang="scss">
.countDown {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    border: 0.5px solid #ededf0 !important;
    z-index: 99999999999999 !important;
    top: 45px;
  }
}
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.sw {
  position: relative;

  .sw-content {
    position: absolute;
    top: 4px;
  }
}
</style>
