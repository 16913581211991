<template>
  <BaseModal
    name="ModalSavePage"
    id="ModalSavePage"
    idModal="ModalSavePage"
    size="md"
    title=""
    @shown="openModal"
  >
    <div class="successPage">
      <!-- <img src="@/assets/editor/savePage.svg" alt="Image" /> -->
      <h1>Parabéns</h1>
      <p>Sua página foi salva com sucesso!</p>
      <div class="qrcode">
        <p style="top: -15px;">Visualize no celular:</p>
        <img v-if="qrCode" :src="qrCode" alt="QR Code" style="
    width: 165px;
" />
        <div
          v-else
          style="margin: auto"
          class="d-flex flex-column justify-content-center mt-2"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="footer-qrcode">
          <a :href="qrCode" download="qrCodeToPage.png">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path
                d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"
              ></path>
            </svg>
          </a>
          <div class="export-qrcode" style="cursor: pointer;" @click="downloadQRCode">
            Exportar QR Code
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="loading2 && (page.status == 'publish' || page.publicar == true)"
      class="d-flex flex-column justify-content-center mt-lg-5"
    >
      <h6 style="text-align: center">Calculando métricas...</h6>
      <div
        style="margin: auto"
        class="d-flex flex-column justify-content-center mt-2"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

    <!-- <p style="text-align: center" v-if="!loading2">
        Tempo de carregamento médio
        <span style="font-weight: 800px; color: black">{{ time }}</span> segundos
      </p> -->
    <div v-if="!loading2">
      <div v-show="showProgress">
        <div class="pageSpeed">
          <img src="@/assets/editor/logoPageSpeed.svg" alt="Logo PageSpeed" />
        </div>
        <div class="flex-percentage">
          <div>
            <vue-ellipse-progress
              :progress="score_desktop"
              thickness="5%"
              emptyThickness="3%"
              color="#4ea934"
              fontSize="2.6rem"
              fontColor="#000"
              id="progressEllipse"
            >
            </vue-ellipse-progress>
            <h6 style="text-align: center">Desktop</h6>
          </div>

          <div>
            <vue-ellipse-progress
              :progress="score_mobile"
              thickness="5%"
              emptyThickness="3%"
              color="#4ea934"
              fontSize="2.6rem"
              fontColor="#000"
              id="progressEllipse"
            >
            </vue-ellipse-progress>
            <h6 style="text-align: center">Mobile</h6>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton
        v-if="page.status == 'publish' || page.publicar === true"
        variant="black"
        :disabled="loading"
        @click="viewPage"
      >
        Abrir pagina
      </BaseButton>
      <BaseButton
        v-else
        v-b-tooltip.hover
        title="A página deve ser publicada"
        variant="black2"
      >
        Abrir pagina
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>

import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();
export default {
  components: {},
  data() {
    return {
      loading: false,
      loading2: true,
      score_mobile: 0,
      score_desktop: 0,
      time: 0.1,
      showProgress: true,
      qrCode: true,
      page_data: [],
      downloadImg: "",
      domain:{
        subDoamin: true,
        link: '',
      }
    };
  },
  props: ["page", "path"],
  methods: {
    generateQRCode(link) {
      if (link) {
        const qrApiBaseUrl = "https://api.qrserver.com/v1/create-qr-code/";
        const imageSize = "240x240"; // Tamanho da imagem do QR code
        const qrCodeUrl = `${qrApiBaseUrl}?size=${imageSize}&data=${encodeURIComponent(
          link
        )}`;
        this.qrCode = qrCodeUrl;
      } else {
        this.qrCode = null;
      }
    },
    async downloadQRCode() {
      var imageUrl = this.qrCode;
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) throw new Error("Falha ao buscar a imagem");

        const imageBlob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(imageBlob);

        // Criar um elemento <a> temporário para realizar o download
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "qrcode.png"; // Você pode personalizar o nome do arquivo aqui
        document.body.appendChild(a);
        a.click();

        // Limpeza: remover o elemento <a> e liberar o objeto URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Erro ao baixar a imagem:", error);
      }
    },
    async openModal() {
      await this.getDomain()

      this.qrCode = null;
      this.loading = false;
      this.loading2 = true;
      this.score_mobile = 0;
      this.score_desktop = 0;
      this.time = 0.1;

      if (
        (this.page.status && this.page.status !== "publish") ||
        (this.page.publicar && this.page.publicar == false)
      ) {
        return;
      }
      var test = this;
      test.$bvToast.toast("Calculando métricas da página", {
        title: "Editor",
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });

      this.generateQRCode(this.domainRedirect);      
      const urlMobile = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://${this.$store.getters.tenantSubdomain}.${this.isGreenn ? process.env.GREENN_SALES_NUXT_DOMAIN : 'gdigital.com.br'}/${this.page.path_name}&strategy=mobile`;

      console.log(urlMobile);
      var that = this;
      fetch(urlMobile)
        .then((res) => res.json())
        .then((data) => {
          this.time = parseInt(
            data.lighthouseResult.timing.total * 1000
          ).toFixed(1);
          if (data.error) {
            that.loading2 = false;
            this.showProgress = false;
            that.$bvToast.toast("Não foi póssivel calcular o score", {
              title: "Editor",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          if (
            data.lighthouseResult &&
            data.lighthouseResult.categories &&
            data.lighthouseResult.categories.performance &&
            data.lighthouseResult.categories.performance.score
          ) {
            that.score_mobile = parseInt(
              data.lighthouseResult.categories.performance.score * 100
            );
          }
          that.loading2 = false;
        })
        .catch((error) => {
          console.error("Não foi póssivel calcular o score", error);
        });

      const urlDesktop = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://${this.$store.getters.tenantSubdomain}.${this.isGreenn ? process.env.GREENN_SALES_NUXT_DOMAIN : 'gdigital.com.br'}/${this.page.path_name}`;
      var that = this;
      fetch(urlDesktop)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            that.loading2 = false;
            this.showProgress = false;
            that.$bvToast.toast("Não foi póssivel calcular o score", {
              title: "Editor",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          if (
            data.lighthouseResult &&
            data.lighthouseResult.categories &&
            data.lighthouseResult.categories.performance &&
            data.lighthouseResult.categories.performance.score
          ) {
            that.score_desktop = parseInt(
              data.lighthouseResult.categories.performance.score * 100
            );
          }
          that.loading2 = false;
        })
        .catch((error) => {
          console.error("Não foi póssivel calcular o score", error);
          this.showProgress = false;
        });
    },

    viewPage() {
      var url = this.domainRedirect 
      window.open(url, "_blank");
    },
    async getDomain(){
      let id = parseInt(this.page.metas.page_domain)
      let defaultDomain = {
        link: '',
        subDoamin: true
      }
      if(id !== ""){        
        await serviceDomain.read(id).then((resp)=>{
          if(resp.status == 6 && resp.ssl_status == "ready"){
            this.domain = {
              link: `https://${resp.domain}/${this.page.path_name}`,
              subDoamin: false
            }
          }else{
            this.domain = defaultDomain
          }
        }).catch((err)=>{
          this.domain = defaultDomain
        })
      }else{
        this.domain = defaultDomain
      }
    }
  },
  computed:{
    domainRedirect(){
      if(this.domain.subDoamin){   
        return `https://${this.$store.getters.tenantSubdomain}.${this.isGreenn ? process.env.GREENN_SALES_NUXT_DOMAIN : 'gdigital.com.br'}/${this.page.path_name}`
      }else{
        return this.domain.link
      }
    },
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    }
  }
};
</script>

<style scoped lang="scss">
#ModalSavePage {
  button .close {
    position: absolute;
    z-index: 500;
  }
  .successPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-top: -85px;

    img {
      transform: scale(0.7);
    }

    h1 {
      font-size: 30px;
      font-weight: 600;
    }
  }

  #progressEllipse {
    margin-top: -50px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.65);
    font-weight: 600;
  }
}
.footer-qrcode {
  display: flex;
  position: absolute;
  bottom: 10px;
  align-items: flex-start;

  svg {
    width: 20px;
    margin-right: 5px;
    height: 20px;
  }
}
.flex-percentage {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}
.pageSpeed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 35px;
}

.qrcode {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 20px;
  right: 600px;
  z-index: 9999;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
  p {
    text-align: center;
    top: -15px;
    position: relative;
    z-index: 999999;
  }
  img {
    transform: scale(1) !important;
    top: -11px;
    position: relative;
  }
}
</style>
