<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Geral">
          <div>
            <!-- Seleção da imagem -->
            <div class="separador"></div>
            <div class="content">
              <label class="titulo">Endereço</label>
              <div class="select">
                <input type="text" placeholder="Seu endereço" v-model="editaItem.propsData.location.localStorage">
              </div>
            </div>

            <!-- Dimensões -->
            <div class="separador"></div>
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>


              <!-- Altura e largura -->
              <div class="half">
                <div style="max-width: 100% !important;width: 100% !important;">
                  <label class="subtitulo">Altura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input type="number" v-model="editaItem.propsData.size[typeView].height" @input="
                        proportions('altura'), globalWidth('height', $event)
                        " />
                      <span @click="next_und('und_height')" class="und text validClick" v-b-tooltip.hover
                        title="Largura em pixel">
                        {{
                          editaItem.propsData.size[typeView].und_height
                        }}</span>
                    </section>
                  </section>
                </div>
              </div>

            </div>
          </div>
        </b-tab>
        <b-tab title="Estilo">
          <div class="separador"></div>
          <!-- Bordas -->
          <div class="content">
            <label class="titulo" v-if="editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              ">Bordas</label>

            <!-- Arredondar borda -->
            <div v-if="editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              ">
              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img :class="{ borderLinkActive: borderLink === true }" style="
                      height: 18px;
                      transition: all 0.3s;
                      filter: invert(50%);
                      margin-right: 10px;
                    " src="@/assets/editor/link-2.svg" alt="linkar" @click="borderLink = !borderLink" />
              </div>
              <div class="inputs-group">
                <input type="number" v-model="editaItem.propsData.border.border_radius_top"
                  @input="linked('border_radius_top')" />
                <input type="number" v-model="editaItem.propsData.border.border_radius_right"
                  @input="linked('border_radius_right')" />
                <input type="number" v-model="editaItem.propsData.border.border_radius_bottom"
                  @input="linked('border_radius_bottom')" />
                <input type="number" v-model="editaItem.propsData.border.border_radius_left"
                  @input="linked('border_radius_left')" />
              </div>
              <div class="space"></div>
            </div>

            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Bordas visíveis</label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.hasBorder" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
            <label :class="{
              labelOff: editaItem.propsData.border.hasBorder == false,
            }" class="sub">Cor da borda</label>
            <LvColorpicker :class="{
              colorDisabled: editaItem.propsData.border.hasBorder == false,
            }" :disabled="!editaItem.propsData.border.hasBorder" id="LvColorpicker"
              v-model="editaItem.propsData.border.border_color" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <!-- Espessura e traço -->
            <div class="label-range">
              <label :class="{
                labelOff: editaItem.propsData.border.hasBorder == false,
              }" class="subtitulo">Espessura</label>
              <input type="number" v-model="editaItem.propsData.border.border_thickness"
                :disabled="!editaItem.propsData.border.hasBorder" />
            </div>
            <b-form-input :disabled="!editaItem.propsData.border.hasBorder" id="range-1"
              v-model="editaItem.propsData.border.border_thickness" type="range" min="0" max="100" step="1"></b-form-input>
            <div class="space"></div>
            <label
                :class="{ labelOff: editaItem.propsData.border.hasBorder == false }"
                class="subtitulo"
                >Traço</label
              >
              <div class="custom-multi">
                <multiselect
                  :disabled="!editaItem.propsData.border.hasBorder"
                  v-model="editaItem.propsData.border.border_style"
                  :options="[
                    '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                    '━━━━━━━━━━━━━━━━━━━━━',
                    '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                    '══════════════════',
                  ]"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Traço"
                ></multiselect>
              </div>
          </div>
        
        </b-tab>
        <b-tab title="Avançado">
          <!-- Componente de Espaçamento -->
          <div class="separador"></div>
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="global" />
              <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
              <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
              <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM INTERNA</span>
                <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                  :class="linkPadding ? '' : 'link-disabled'" @click="linkPadding = !linkPadding" />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].padding_left
                      " @input="paddingLink($event, 'left')" min="0" max="100" />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].padding_top
                          " @input="paddingLink($event, 'top')" min="0" max="100" />
                      </div>
                      <div>
                        <div class="contain-borders"></div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input type="number" v-model="editaItem.propsData.spacing[typeView].padding_bottom
                          " @input="paddingLink($event, 'bottom')" min="0" max="100" />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input type="number" v-model="editaItem.propsData.spacing[typeView].padding_right
                      " @input="paddingLink($event, 'right')" min="0" max="100" />
                  </div>
                </div>
              </div>
            </div>
            <label
                class="subtitulo"
                >Alinhamento vertical</label
              >
              <div style="display: flex; width: 50%; transition: all 0.3s">
                  <div class="select-align">
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify == 'flex-start',
                      }"
                      @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'flex-start'"
                    >
                      <img src="@/assets/editor/align-top.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify == 'center',
                      }"
                      @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'center'"
                    >
                      <img src="@/assets/editor/align-center-vertical.svg" />
                    </button>
                    <button
                      :class="{
                        'selected-align':
                          editaItem.propsData.alignment[typeView].justify == 'flex-end',
                      }"
                      @click="globalize('align'), editaItem.propsData.alignment[typeView].justify = 'flex-end'"
                    >
                      <img src="@/assets/editor/align-bottom.svg" />
                    </button>
                  </div>
                </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>
            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important" class="sub">
                  Dispositivos grandesﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor" @change="$emit('fixWidth')" name="is_main" size="lg"
                  switch>
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important" class="sub">
                  Dispositivos médiosﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet" name="is_main" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label style="font-size: 14px !important; display: flex !important" class="sub">
                  Dispositivos pequenosﾠ
                  <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares" />
                </label>
                <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone" name="is_main" size="lg" switch>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Nenhuma',
                  }" @click="
  (editaItem.propsColumn.attribute_und = 'Nenhuma'),
  (editaItem.propsColumn.attribute_animation = '')
  ">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und ==
                      'Gradual Increase',
                  }" @click="
(editaItem.propsColumn.attribute_und =
  'Gradual Increase'),
  (editaItem.propsColumn.attribute_animation =
    'gradual-increase')
  ">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Up',
                  }" @click="
  (editaItem.propsColumn.attribute_und = 'Fade Up'),
  (editaItem.propsColumn.attribute_animation = 'top')
  ">
                    <div class="simulated-item simu3">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Right',
                  }" @click="
  (editaItem.propsColumn.attribute_und = 'Fade Right'),
  (editaItem.propsColumn.attribute_animation = 'right')
  ">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Down',
                  }" @click="
  (editaItem.propsColumn.attribute_und = 'Fade Down'),
  (editaItem.propsColumn.attribute_animation = 'bottom')
  ">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha" />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item" :class="{
                    'selected-animation':
                      editaItem.propsColumn.attribute_und == 'Fade Left',
                  }" @click="
  (editaItem.propsColumn.attribute_und = 'Fade Left'),
  (editaItem.propsColumn.attribute_animation = 'left')
  ">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }">Atraso</label>
                    <input type="number" :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'
                      " v-model="editaItem.propsColumn.delay" />
                  </div>
                  <b-form-input v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'" type="range" min="0" max="10"
                    step="1"></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{
                      labelOff:
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }">Duração</label>
                    <input :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'
                      " type="number" v-model="editaItem.propsColumn.duration" />
                  </div>
                  <b-form-input v-model="editaItem.propsColumn.duration" type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'" min="0" max="10" step="1"></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub">IDﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID" /></label>
            <input v-model="editaItem.propsData.custom.c_id" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: id_unico" />
            <label class="sub">Classeﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes." /></label>
            <input v-model="editaItem.propsData.custom.c_class" class="w-100 mb-3" type="text" name="text_1" id="text_1"
              placeholder="exemplo: classe_1 classe_2" />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>

  </div>
</template>
  
<script>
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette"],

  components: {
    LvInput,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      fitOptions: [
        { value: "contain", text: "Conter" },
        { value: "fill", text: "Esticar" },
        { value: "cover", text: "Cobrir" },
      ],
      proportion: {
        desktop: "Nativo",
        tablet: "Nativo",
        mobile: "Nativo",
      },
      borderLink: true,
      linkPadding: true,
      proporcoes: "",
    };
  },
  computed: {

  },


  methods: {
    linked(place) {
      if (this.borderLink) {
        var border = this.editaItem.propsData.border;
        border.border_radius_top = border[place];
        border.border_radius_left = border[place];
        border.border_radius_bottom = border[place];
        border.border_radius_right = border[place];
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "%";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "%";
          props.size.mobile[place] = "%";
        }
        return;
      }
      if (props.size[this.typeView][place] == "%") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == "desktop") {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    proportions(item) {
      var proportion;
      proportion = this.editaItem.propsData.alignment[this.typeView].proportion;
      
      if (item == "altura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].width =
            Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height /
              this.proporcoes.altura) *
            this.proporcoes.largura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          this.globalize();
          return;
        }
        return;
      }
    },
    globalize() {
      if (this.global && this.typeView == "desktop") {
        var standardWidth = this.editaItem.propsData.size.desktop.width;
        var standardHeight = this.editaItem.propsData.size.desktop.height;
        var standardUndWidth = this.editaItem.propsData.size.desktop.und_width;
        var standardUndHeight =
          this.editaItem.propsData.size.desktop.und_height;
        this.editaItem.propsData.size.tablet.width = standardWidth;
        this.editaItem.propsData.size.mobile.width = standardWidth;
        this.editaItem.propsData.size.tablet.height = standardHeight;
        this.editaItem.propsData.size.mobile.height = standardHeight;
        this.editaItem.propsData.size.tablet.und_height = standardUndHeight;
        this.editaItem.propsData.size.mobile.und_height = standardUndHeight;
        this.editaItem.propsData.size.tablet.und_width = standardUndWidth;
        this.editaItem.propsData.size.mobile.und_width = standardUndWidth;
      }
    },
    globalWidth(place, pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
  
<style lang="scss" scoped>
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
  
<style lang="scss">
.custom-multi .multiselect__single {
  max-height: 20px !important;
  overflow: hidden !important;
}
</style>
  