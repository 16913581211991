<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Geral">
          <div>
            <!-- Seleção da imagem -->
            <div class="separador"></div>
            <div class="content">
              <label class="titulo">Imagem</label>
              <div class="select-image">
                <div @click="openModal('Upload-Editor')">
                  <img
                    :src="
                      editaItem.propsData.image.src
                        ? editaItem.propsData.image.src
                        : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                    "
                    alt="Imagem preview"
                  />
                </div>
                <div>
                  <input
                    @input="obterProporcoesOriginais()"
                    type="text"
                    v-model="editaItem.propsData.image.src"
                  />
                  <span @click="openModal('Upload-Editor')">{{
                    editaItem.propsData.image.src
                      ? "Alterar imagem"
                      : "Selecionar imagem"
                  }}</span>
                </div>
              </div>
              <div class="space"></div>
              <div v-if="editaItem.propsData.hasOwnProperty('redirectImage')">
              <label class="subtitulo">Link de redirecionamento</label>
                <input type="text" v-model="editaItem.propsData.redirectImage.link" placeholder="https://seulink" @change="checkLink"/>
                <div style="display:flex;align-items: center;justify-content: left ;transition: all 0.3s">
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: 0; margin-right: 0"
                    v-model="editaItem.propsData.redirectImage.isBlank"
                    size="lg"
                    switch
                    >
                  </b-form-checkbox>
                  <label class="subtitulo" style="margin:0 !important">Abrir em nova guia</label>
                </div>
              </div>
            </div>

            <!-- Dimensões -->
            <div class="separador"></div>
            <div class="content">
              <div class="label-icon">
                <label class="titulo">Dimensões</label>
                <img
                  src="@/assets/editor/globe.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="global"
                />
                <img
                  src="@/assets/editor/monitor.svg"
                  alt="monitor"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')"
                  v-if="typeView === 'desktop' && !global"
                />
                <img
                  src="@/assets/editor/tablet.svg"
                  alt="tablet"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')"
                  v-if="typeView === 'tablet' && !global"
                />
                <img
                  src="@/assets/editor/phone.svg"
                  alt="phone"
                  style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)"
                  v-if="typeView === 'mobile' && !global"
                />
              </div>

              <div
                v-if="
                  editaItem.propsData.alignment[typeView].proportion !=
                    'Respeitar coluna' &&
                  editaItem.propsData.alignment[typeView].proportion != 'Nativo'
                "
              >
                <label class="subtitulo">Conter ou cobrir</label>
                <multiselect
                  label="text"
                  :options="fitOptions"
                  :allow-empty="false"
                  :hide-selected="true"
                  :searchable="false"
                  :show-labels="false"
                  v-model="editaItem.propsData.alignment[typeView].fit"
                ></multiselect>
                <div class="space"></div>
              </div>

              <label class="subtitulo"
                >Proporções
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title='É necessário que as proporções sejam configuradas para "Personalizado" para usar as unidades %, vh e vw'
              /></label>
              <multiselect
                :options="[
                  'Respeitar coluna',
                  'Nativo',
                  'Personalizado',
                  'Círculo',
                  'Quadrado',
                  'Paisagem',
                  'Retrato',
                  'Panorama',
                ]"
                v-model="editaItem.propsData.alignment[typeView].proportion"
                :hide-selected="true"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
                placeholder=" "
                @input="proportions('altura')"
              ></multiselect>
              <div class="space"></div>

              <!-- Altura e largura -->
              <div
                class="half"
                v-if="
                  editaItem.propsData.alignment[typeView].proportion !=
                  'Respeitar coluna'
                "
              >
                <div>
                  <label class="subtitulo">Altura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input
                        type="number"
                        v-model="editaItem.propsData.size[typeView].height"
                        @input="
                          proportions('altura'), globalWidth('height', $event)
                        "
                      />
                      <span
                        @click="next_und('und_height')"
                        class="und text validClick"
                        v-b-tooltip.hover
                        :title="`Altura em ${undName[0]}`"
                      >
                        {{
                          editaItem.propsData.size[typeView].und_height
                        }}</span
                      >
                    </section>
                  </section>
                </div>

                <div>
                  <label class="subtitulo">Largura</label>
                  <section id="Altura mínima" class="und_form">
                    <section class="und_form">
                      <input
                        type="number"
                        v-model="editaItem.propsData.size[typeView].width"
                        @input="
                          proportions('largura'), globalWidth('width', $event)
                        "
                      />
                      <span
                        @click="next_und('und_width')"
                        class="und text validClick"
                        v-b-tooltip.hover
                        :title="`Largura em ${undName[1]}`"
                      >
                        {{ editaItem.propsData.size[typeView].und_width }}</span
                      >
                    </section>
                  </section>
                </div>
              </div>
              <div>
                <label class="subtitulo">Posicionamento</label>
                <div style="display: flex; width: 100%; gap: 10px">
                  <div style="display: flex; width: 50%; transition: all 0.3s">
                    <div class="select-align">
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-start',
                        }"
                        @click="globalJustify('flex-start', 'justify')"
                      >
                        <img src="@/assets/editor/align-left-new.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'center',
                        }"
                        @click="globalJustify('center', 'justify')"
                      >
                        <img
                          src="@/assets/editor/align-center-horizontal.svg"
                        />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-end',
                        }"
                        style="z-index: 2"
                        @click="globalJustify('flex-end', 'justify')"
                      >
                        <img src="@/assets/editor/align-right-new.svg" />
                      </button>
                    </div>
                  </div>

                  <div style="display: flex; width: 50%; transition: all 0.3s">
                    <div class="select-align">
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'start',
                        }"
                        @click="globalJustify('start', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-top.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'center',
                        }"
                        @click="globalJustify('center', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-center-vertical.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView]
                              .justify_vertical == 'end',
                        }"
                        @click="globalJustify('end', 'justify_vertical')"
                      >
                        <img src="@/assets/editor/align-bottom.svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="space"></div>
                </div>
              </div>
            </div>
            <div class="separador"></div>
          </div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>
          <!-- Sombra -->
          <div class="content">
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Sombra</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.shadow.hasShadow"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{
                labelOff: editaItem.propsData.shadow.hasShadow == false,
              }"
              class="subtitulo"
              >Cor da sombra</label
            >
            <LvColorpicker
              id="LvColorpicker"
              :class="{
                colorDisabled: editaItem.propsData.shadow.hasShadow == false,
              }"
              :disabled="editaItem.propsData.shadow.hasShadow == false"
              v-model="editaItem.propsData.shadow.shadowColor"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <div class="three">
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Eixo X</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowX"
                />
              </div>
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Eixo Y</label
                >
                <input
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  type="number"
                  v-model="editaItem.propsData.shadow.shadowY"
                />
              </div>
              <div>
                <label
                  :class="{
                    labelOff: editaItem.propsData.shadow.hasShadow == false,
                  }"
                  class="subtitulo"
                  >Borrão</label
                >
                <input
                  type="number"
                  :disabled="editaItem.propsData.shadow.hasShadow == false"
                  v-model="editaItem.propsData.shadow.shadowBlur"
                />
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Bordas -->
          <div class="content">
            <label
              class="titulo"
              v-if="
                editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              "
              >Bordas</label
            >

            <!-- Arredondar borda -->
            <div
              v-if="
                editaItem.propsData.alignment[typeView].proportion != 'Círculo'
              "
            >
              <!-- Arredondar bordas -->
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas </label>
                <img
                  :class="{ borderLinkActive: borderLink === true }"
                  style="
                    height: 18px;
                    transition: all 0.3s;
                    filter: invert(50%);
                    margin-right: 10px;
                  "
                  src="@/assets/editor/link-2.svg"
                  alt="linkar"
                  @click="borderLink = !borderLink"
                />
              </div>
              <div class="inputs-group">
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_top"
                  @input="linked('border_radius_top')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_right"
                  @input="linked('border_radius_right')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_bottom"
                  @input="linked('border_radius_bottom')"
                />
                <input
                  type="number"
                  v-model="editaItem.propsData.border.border_radius_left"
                  @input="linked('border_radius_left')"
                />
              </div>
              <div class="space"></div>
            </div>

            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Bordas visíveis</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.hasBorder"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{
                labelOff: editaItem.propsData.border.hasBorder == false,
              }"
              class="sub"
              >Cor da borda</label
            >
            <LvColorpicker
              :class="{
                colorDisabled: editaItem.propsData.border.hasBorder == false,
              }"
              :disabled="!editaItem.propsData.border.hasBorder"
              id="LvColorpicker"
              v-model="editaItem.propsData.border.border_color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <!-- Espessura e traço -->
            <div class="label-range">
              <label
                :class="{
                  labelOff: editaItem.propsData.border.hasBorder == false,
                }"
                class="subtitulo"
                >Espessura</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.border.border_thickness"
                :disabled="!editaItem.propsData.border.hasBorder"
              />
            </div>
            <b-form-input
              :disabled="!editaItem.propsData.border.hasBorder"
              id="range-1"
              v-model="editaItem.propsData.border.border_thickness"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
            <div class="space"></div>
            <!-- <label
              :class="{ labelOff: editaItem.propsData.border.hasBorder == false }"
              class="subtitulo"
              >Traço</label
            >
            <div class="custom-multi">
              <multiselect
                :disabled="!editaItem.propsData.border.hasBorder"
                v-model="editaItem.propsData.border.border_style"
                :options="[
                  '● ● ● ● ● ● ● ● ● ● ● ● ● ●',
                  '━━━━━━━━━━━━━━━━━━━━━',
                  '━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━ ━',
                  '══════════════════',
                ]"
                :searchable="false"
                :show-labels="false"
                placeholder="Traço"
              ></multiselect>
            </div> -->
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <!-- Componente de Espaçamento -->
          <div class="separador"></div>
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders"></div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>
            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important" class="sub"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  @change="$emit('fixWidth')"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important" class="sub"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"  class="sub"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>

    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette"],
  components: {
    Upload,
    LvInput,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  computed: {
    undName() {
      return [this.returnUndName(this.editaItem.propsData.size[this.typeView].und_height), this.returnUndName(this.editaItem.propsData.size[this.typeView].und_width)]
    },
  },
  data() {
    return {
      fitOptions: [
        { value: "contain", text: "Conter" },
        { value: "fill", text: "Esticar" },
        { value: "cover", text: "Cobrir" },
      ],
      linkPadding: true,
      linkMargin: true,
      global: false,
      animate: false,
      proportion: {
        desktop: "Nativo",
        tablet: "Nativo",
        mobile: "Nativo",
      },
      borderLink: true,
      proporcoes: "",
    };
  },
  watch: {
    'editaItem.propsData.image.src'() {
      this.obterProporcoesOriginais();
    },
    'proporcoes'(){
      this.proportions("altura");
        this.newHeight(
          this.editaItem.propsData.alignment[this.typeView].percWidth
        );
    },
  },
  mounted() {
    this.obterProporcoesOriginais();
  },
  methods: {
    checkLink() {
      let link;
      if (this.editaItem.propsData.redirectImage && this.editaItem.propsData.redirectImage.link) {
        link = this.editaItem.propsData.redirectImage.link;

        const urlPattern = /^(https?:\/\/)?([\w.-]+)(\.[\w\.-]+)+([\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=]+)?$/;
        if (!link.match(urlPattern)) {
          this.$bvToast.toast("URL de redirecionamento inválida", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('disabledSave', true);
          return;
        }
        if(!link.includes('http')){
          this.$bvToast.toast("URL precisa conter https://", {
            title: "Editor",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit('disabledSave', true);
          return;
        }
      }
      this.$emit('disabledSave', false);
    },
    returnUndName(und){
      switch (und) {
        case 'px':
          return 'pixel'
        case '%':
          return 'porcentagem'
        case 'vh':
          return 'viewer height'
        case 'vw':
          return 'viewer width'
        default:
          return 'pixel'
      }
    },
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und =
        props[this.typeView].height_und == "px"
          ? "vh"
          : props[this.typeView].height_und == "vh"
          ? "vw"
          : "px";
      if (this.global && this.typeView == "desktop") {
        props.tablet.height_und = props.desktop.height_und;
        props.mobile.height_und = props.desktop.height_und;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    newHeight(value) {
      this.editaItem.propsData.alignment[this.typeView].setHeight =
        (value * this.proporcoes.altura) / this.proporcoes.largura;
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsData.alignment.tablet.percWidth = value;
        this.editaItem.propsData.alignment.mobile.percWidth = value;
        this.editaItem.propsData.alignment.tablet.setHeight =
          this.editaItem.propsData.alignment.desktop.setHeight;
        this.editaItem.propsData.alignment.mobile.setHeight =
          this.editaItem.propsData.alignment.desktop.setHeight;
      }
    },
    obterProporcoesOriginais() {
      const img = new Image();
      img.onload = () => {
        const proporcoes = {
          largura: img.width,
          altura: img.height,
        };
        this.proporcoes = proporcoes;
      };
      img.src = this.editaItem.propsData.image.src;
    },
    next_und(place) {
      if (
        this.editaItem.propsData.alignment[this.typeView].proportion ==
        "Personalizado"
      ) {
        var props = this.editaItem.propsData;
        if (props.size[this.typeView][place] == "px") {
          props.size[this.typeView][place] = "%";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "%";
            props.size.mobile[place] = "%";
          }
          return;
        }
        if (props.size[this.typeView][place] == "%") {
          props.size[this.typeView][place] = "vh";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "vh";
            props.size.mobile[place] = "vh";
          }
          return;
        }
        if (props.size[this.typeView][place] == "vh") {
          props.size[this.typeView][place] = "vw";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "vw";
            props.size.mobile[place] = "vw";
          }
          return;
        }
        if (props.size[this.typeView][place] == "vw") {
          props.size[this.typeView][place] = "px";
          if (this.global && this.typeView == "desktop") {
            props.size.tablet[place] = "px";
            props.size.mobile[place] = "px";
          }
          return;
        }
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalWidth(place, pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    setImg(img) {
      this.editaItem.propsData.image.src = img;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    proportions(item) {
      var proportion;
      proportion = this.editaItem.propsData.alignment[this.typeView].proportion;
      if (proportion == "Personalizado") {
        this.editaItem.propsData.size[this.typeView].height =
          Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
        this.editaItem.propsData.size[this.typeView].height = Math.round(
          this.editaItem.propsData.size[this.typeView].height - 1
        );
        this.globalize();
        return;
      }
      this.editaItem.propsData.size.desktop.und_height = "px";
      this.editaItem.propsData.size.tablet.und_height = "px";
      this.editaItem.propsData.size.mobile.und_height = "px";
      this.editaItem.propsData.size.desktop.und_width = "px";
      this.editaItem.propsData.size.tablet.und_width = "px";
      this.editaItem.propsData.size.mobile.und_width = "px";
      if (item == "largura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].height =
            Math.round(this.editaItem.propsData.size[this.typeView].width) + 1;
          this.editaItem.propsData.size[this.typeView].height =
            Math.round(this.editaItem.propsData.size[this.typeView].width) - 1;
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width /
              this.proporcoes.largura) *
              this.proporcoes.altura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width / 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 1080) * 608
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 4) * 5
          );
          this.globalize();
          return;
        }
        return;
      }
      if (item == "altura") {
        if (proportion == "Quadrado" || proportion == "Círculo") {
          this.editaItem.propsData.size[this.typeView].width =
            Math.round(this.editaItem.propsData.size[this.typeView].height) + 1;
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          this.globalize();
          return;
        }
        if (proportion == "Nativo") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height /
              this.proporcoes.altura) *
              this.proporcoes.largura
          );
          this.globalize();
          return;
        }
        if (proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          this.globalize();
          return;
        }
        if (proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          this.globalize();
          return;
        }
        if (proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          this.globalize();
          return;
        }
        return;
      }
    },
    globalize() {
      if (this.global && this.typeView == "desktop") {
        var standardWidth = this.editaItem.propsData.size.desktop.width;
        var standardHeight = this.editaItem.propsData.size.desktop.height;
        var standardUndWidth = this.editaItem.propsData.size.desktop.und_width;
        var standardUndHeight =
          this.editaItem.propsData.size.desktop.und_height;
        this.editaItem.propsData.size.tablet.width = standardWidth;
        this.editaItem.propsData.size.mobile.width = standardWidth;
        this.editaItem.propsData.size.tablet.height = standardHeight;
        this.editaItem.propsData.size.mobile.height = standardHeight;
        this.editaItem.propsData.size.tablet.und_height = standardUndHeight;
        this.editaItem.propsData.size.mobile.und_height = standardUndHeight;
        this.editaItem.propsData.size.tablet.und_width = standardUndWidth;
        this.editaItem.propsData.size.mobile.und_width = standardUndWidth;
      }
    },
    linked(place) {
      if (this.borderLink) {
        var border = this.editaItem.propsData.border;
        border.border_radius_top = border[place];
        border.border_radius_left = border[place];
        border.border_radius_bottom = border[place];
        border.border_radius_right = border[place];
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    // Atualiza os componentes antigos
    if (!this.editaItem.propsData.hasOwnProperty('redirectImage')) {
      this.editaItem.propsData.redirectImage = {
        isBlank: false,
        link: ''
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.reload {
  height: 15px;
  margin-top: 2px;
}

.reloaded {
  animation: reloaded 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: rotate(0deg);
}
.redirectImage{
  display: flex;
  margin-top: 5px !important;
}

@keyframes reloaded {
  100% {
    transform: rotate(-360deg);
  }
}
</style>

<style lang="scss">
.custom-multi .multiselect__single {
  max-height: 20px !important;
  overflow: hidden !important;
}
</style>
