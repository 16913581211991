<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <!-- <div class="contain-buttons"> -->
          <div
            class="content"
            style="
              padding-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
          >
            <label style="margin: 0">Todos os itens</label>
            <button class="btn button-add-produto" @click="addItems"></button>
          </div>
          <!-- </div> -->
          <div v-for="(row, index) in dynamicLoop" :key="index">
            <div
              :id="'iconCard-' + index"
              v-b-toggle="'iconCard-' + index"
              @click="index == isOpen ? isOpen = null : isOpen = index"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
              "
              class="p-3"
            >
              <h1 class="titulo" style="margin: 0 !important">
                Item {{ index + 1 }}
              </h1>
              <div
                class="separador"
                style="position: absolute; top: 35px; left: 80px; width: calc(100% - 180px);"
              ></div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <button
                  v-if="editaItem.propsData.rows.length > 1"
                  class="btn button-remove-produto"
                  title="Remover item"
                  style="border-radius: 10px; z-index: 1; padding: 15px; margin-top: 5px;"
                  @click.stop="removeItems(index)"
                ></button>
                <button
                  v-else
                  class="btn button-remove-produto"
                  @click.stop="mostrarMensagem"
                ></button>
                <img
                  class="chevron icon mt-0"
                  src="@/assets/editor/chevron-right.svg"
                  style="transition: all 0.5s"
                  :style="{
                    transform: index == isOpen ? 'rotate(90deg)' : 'rotate(270deg)',
                  }"
                />
              </div>
            </div>
            <b-collapse
              :id="'iconCard-' + index"
              accordion="card-accordion"
              :visible="index === 0"
            >
              <div class="content-quill">
                <!-- Pergunta -->
                <label class="sub" :for="`question${index}`"
                  >Pergunta {{ index + 1 }}</label
                >
                <textarea
                  style="height: fit-content !important;"
                  :id="`question${index}`"
                  type="text"
                  v-model="editaItem.propsData.rows[index].question"
                  rows="4"
                />

                <!-- Tipo de resposta -->
                <div style="display: flex; width: 100%; transition: all 0.3s">
                  <label class="subtitulo">Resposta em imagem</label>
                  <b-form-checkbox
                    style="margin-top: -5px; margin-left: auto; margin-right: 0"
                    v-model="editaItem.propsData.rows[index].show_image"
                    size="lg"
                    switch
                  >
                  </b-form-checkbox>
                </div>
                <div class="space"></div>

                <!-- Resposta em texto -->
                <div v-if="editaItem.propsData.rows[index].show_image == false" :style="index + 1 == editaItem.propsData.rows.length ? 'margin-bottom: 40px;' : ''">
                  <div style="display: flex; justify-content: space-between;">
                    <label class="sub" :for="`resp${index}`">Resposta {{ index + 1 }}</label>
                    <div
                      class="ck-theme-accordion"
                      :class="{ 'button-expand': expandCkButton }"
                    >
                      <button
                        @click="themeCkEditor(index)"
                        class="ck-button-theme"
                        :class="{
                          'button-black': isBlack,
                          'button-white': !isBlack,
                        }"
                      >
                        Tema
                      </button>
                    </div>
                  </div>
                  <div style="border-radius: 10px 0 10px 10px; border: 1px solid #dadada;">
                    <ckeditor
                      :id="`resp${index}`"
                      :config="all_config_ck[index].config"
                      v-model="editaItem.propsData.rows[index].resp"
                    ></ckeditor>
                  </div>
                </div>

                <!-- Resposta em imagem -->
                <div v-else :style="index + 1 == editaItem.propsData.rows.length ? 'margin-bottom: 40px;' : ''">
                  <label class="sub">Imagem da resposta</label>
                  <div class="select-image">
                    <div @click="openModal('Upload-Editor')">
                      <img
                        :src="
                          editaItem.propsData.rows[index].image
                            ? editaItem.propsData.rows[index].image
                            : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                        "
                        alt="Imagem preview"
                      />
                    </div>
                    <div>
                      <input
                        @input="obterProporcoesOriginais(editaItem.propsData.rows[index].image)"
                        type="text"
                        v-model="editaItem.propsData.rows[index].image"
                      />
                      <span @click="openModal('Upload-Editor')">{{
                        editaItem.propsData.rows[index].image
                          ? "Alterar imagem"
                          : "Selecionar imagem"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- image -->
            </b-collapse>
            <!-- <div class="space"></div> -->
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Cores</label>
            <div class="half">
              <div>
                <label class="sub">Pergunta</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.color_question"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div>
                <label class="sub">Fundo</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.background_question"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
            <div class="space"></div>
            <div class="half">
              <div>
                <label class="sub">Resposta</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.color_resp"
                  :colors="palette"
                ></LvColorpicker>
              </div>
              <div>
                <label class="sub">Fundo</label>
                <LvColorpicker
                  id="LvColorpicker"
                  v-model="editaItem.propsData.styles.background_resp"
                  :colors="palette"
                ></LvColorpicker>
              </div>
            </div>
            <div class="space"></div>
          </div>
          <div class="separador"></div>
          <div class="content max-multi">
            <label class="titulo">Tipografia</label>
            <multiselect
              v-model="editaItem.propsData.styles.font_family"
              :options="fontOptions"
              @input="$emit('callCharge',$event)"
              :searchable="true"
              :show-labels="false"
              placeholder="Fonte"
              :hide-selected="true"
              :allow-empty="false"
            ></multiselect>
            <div class="space"></div>
            <div class="label-range">
              <div class="label-icon">
                <label class="subtitulo">Tamanho (pergunta)</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].font_size_question"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size[typeView].font_size_question"
              type="range"
              min="10"
              max="30"
              step="1"
            ></b-form-input>
            <div class="label-range">
              <div class="label-icon">
                <label class="subtitulo">Tamanho (resposta)</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input
                type="number"
                v-model="editaItem.propsData.size[typeView].font_size_resp"
              />
            </div>
            <b-form-input
              v-model="editaItem.propsData.size[typeView].font_size_resp"
              type="range"
              min="10"
              max="30"
              step="1"
            ></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Posição</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'start',
                    }"
                    @click="globalJustify('start', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-top.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'center',
                    }"
                    @click="globalJustify('center', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-center-vertical.svg" />
                  </button>
                  <button
                    :class="{
                      'selected-align':
                        editaItem.propsData.alignment[typeView]
                          .justify_vertical == 'end',
                    }"
                    @click="globalJustify('end', 'justify_vertical')"
                  >
                    <img src="@/assets/editor/align-bottom.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="editaItem.propsData.rows.some(item => item.show_image === true)">
            <div class="separador"></div>
            <div class="content">
              <label class="titulo">Imagem</label>
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas</label>
                <input
                  type="number"
                  v-model="editaItem.propsData.styles.image_radius"
                />
              </div>
              <b-form-input
                style="margin-bottom: 0 !important;"
                v-model="editaItem.propsData.styles.image_radius"
                type="range"
                min="0"
                max="50"
                step="1"
              ></b-form-input>
            </div>
          </div>
          
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Bordas</label>

            <!-- Arredondar borda -->
            <div>
              <div class="label-range">
                <label class="subtitulo">Arredondar bordas</label>
                <input
                  type="number"
                  v-model="editaItem.propsData.border.radius"
                />
              </div>
              <b-form-input
                style="margin-bottom: 0 !important;"
                v-model="editaItem.propsData.border.radius"
                type="range"
                min="0"
                max="50"
                step="1"
              ></b-form-input>
            </div>

            <div style="display: flex; width: 100%; transition: all 0.3s; margin-bottom: 10px;">
              <label class="subtitulo">Bordas visíveis</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.border.has"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <label
              :class="{
                labelOff: editaItem.propsData.border.has == false,
              }"
              class="sub"
              >Cor da borda</label
            >
            <LvColorpicker
              :class="{
                colorDisabled: editaItem.propsData.border.has == false,
              }"
              :disabled="!editaItem.propsData.border.has"
              id="LvColorpicker"
              v-model="editaItem.propsData.border.color"
              :colors="palette"
            ></LvColorpicker>
            <div class="space"></div>
            <!-- Espessura e traço -->
            <div class="label-range">
              <label
                :class="{ labelOff: editaItem.propsData.border.has == false }"
                class="subtitulo"
                >Espessura</label
              >
              <input
                type="number"
                v-model="editaItem.propsData.border.thickness"
                :disabled="!editaItem.propsData.border.has"
              />
            </div>
            <b-form-input
              :disabled="!editaItem.propsData.border.has"
              id="range-1"
              v-model="editaItem.propsData.border.thickness"
              type="range"
              min="0"
              max="10"
              step="1"
            ></b-form-input>
            <div class="space"></div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento geral</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMarginDl ? '' : 'link-disabled'"
                  @click="linkMarginDl = !linkMarginDl"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dl_margin_left
                      "
                      @input="attrLinkMarginDl($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].dl_margin_top
                          "
                          @input="attrLinkMarginDl($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPaddingDl ? '' : 'link-disabled'"
                            @click="linkPaddingDl = !linkPaddingDl"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dl_padding_left
                                "
                                @input="paddingLinkDl($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .dl_padding_top
                                    "
                                    @input="paddingLinkDl($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .dl_padding_bottom
                                  "
                                  @input="paddingLinkDl($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dl_padding_right
                                "
                                @input="paddingLinkDl($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView]
                              .dl_margin_bottom
                          "
                          @input="attrLinkMarginDl($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dl_margin_right
                      "
                      @input="attrLinkMarginDl($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento da pergunta</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMarginDt ? '' : 'link-disabled'"
                  @click="linkMarginDt = !linkMarginDt"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dt_margin_left
                      "
                      @input="attrLinkMarginDt($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].dt_margin_top
                          "
                          @input="attrLinkMarginDt($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPaddingDt ? '' : 'link-disabled'"
                            @click="linkPaddingDt = !linkPaddingDt"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dt_padding_left
                                "
                                @input="paddingLinkDt($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .dt_padding_top
                                    "
                                    @input="paddingLinkDt($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .dt_padding_bottom
                                  "
                                  @input="paddingLinkDt($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dt_padding_right
                                "
                                @input="paddingLinkDt($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView]
                              .dt_margin_bottom
                          "
                          @input="attrLinkMarginDt($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dt_margin_right
                      "
                      @input="attrLinkMarginDt($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento da resposta</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMarginDd ? '' : 'link-disabled'"
                  @click="linkMarginDd = !linkMarginDd"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dd_margin_left
                      "
                      @input="attrLinkMarginDd($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].dd_margin_top
                          "
                          @input="attrLinkMarginDd($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPaddingDd ? '' : 'link-disabled'"
                            @click="linkPaddingDd = !linkPaddingDd"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dd_padding_left
                                "
                                @input="paddingLinkDd($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .dd_padding_top
                                    "
                                    @input="paddingLinkDd($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .dd_padding_bottom
                                  "
                                  @input="paddingLinkDd($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .dd_padding_right
                                "
                                @input="paddingLinkDd($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView]
                              .dd_margin_bottom
                          "
                          @input="attrLinkMarginDd($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].dd_margin_right
                      "
                      @input="attrLinkMarginDd($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <!-- Visilibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  size="lg"
                  switch
                  @change="$emit('fixWidth')"
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  class="sub"
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content columnAnimate">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Vue from "vue";
//
import BaseButton from "@/components/BaseButton";
import LvInput from "lightvue/input";
import Multiselect from "vue-multiselect";
import LvColorpicker from "lightvue/color-picker";
//
// CK EDITOR
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);
//
import Upload from "../../Upload.vue";

export default {
  props: ["editaItem", "typeView", "fontOptions", "divWidth", "palette"],
  components: {
    BaseButton,
    LvInput,
    Multiselect,
    LvColorpicker: LvColorpicker,
    Upload,
  },
  data() {
    return {
      isOpen: 0,
      proporcoes: "",
      expandCkButton: false,
      isBlack: false,
      linkPaddingDl: true,
      linkMarginDl: true,
      linkPaddingDd: true,
      linkMarginDd: true,
      linkPaddingDt: true,
      linkMarginDt: true,
      global: false,
      und_width: 1,
      all_config_ck: [],
      editorConfig: {
        uiColor: "#eeeeee",
        allowedContent: true,
        toolbar: [
          ["Maximize"],
          ["Bold", "Italic", "Strike"],
          ["TextColor", "BGColor"],
          ["Source"],
          ["Format", "FontSize"],
          ["Undo", "Redo"],
        ],
        extraPlugins:
          "justify, colorbutton, colordialog, font, lineutils, richcombo, uicolor, divarea",
        removePlugins:
          "about, blockquote, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
      },
    };
  },
  computed: {
    dynamicLoop() {
      this.all_config_ck = [];
      var props = this.editaItem.propsData.rows;
      for (let i = 0; i < props.length; i++) {
        const element = props[i];
        this.all_config_ck.push({
          index: i,
          config: {
            uiColor: "#eeeeee",
            allowedContent: true,
            toolbar: [
              ["Bold", "Italic", "Strike"],
              ["TextColor", "BGColor"],
            ],
            extraPlugins:
              "justify, colorbutton, colordialog, font, lineutils, richcombo, uicolor, divarea",
            removePlugins:
              "about, blockquote, a11yhelp, resize, list, pastetext, pastefromgdocs, pastefromlibreoffice, pastefromword, showborders, tableselection, tabletools, pastetools, tableselection",
            on: {},
            delayIfDetached: true,
            delayIfDetached_interval: 50,
          },
        });
      }
      return props;
    },
  },
  methods: {
    obterProporcoesOriginais(image) {
      const img = new Image();
      img.onload = () => {
        const proporcoes = {
          largura: img.width,
          altura: img.height,
        };
        this.editaItem.propsData.rows[this.isOpen].image_ratio = proporcoes.altura / proporcoes.largura;
      };
      img.src = image
    },
    setImg(img) {
      this.editaItem.propsData.rows[this.isOpen].image = img;
      this.obterProporcoesOriginais(img)
    },
    themeCkEditor(index) {
      this.isBlack = !this.isBlack;
      var ckEditor = document.querySelector(`#resp${index}`);
      var textarea = ckEditor.querySelector(".cke_wysiwyg_div");
      textarea.style.background = this.isBlack
        ? "#000000"
        : this.editaItem.propsData.styles.background_question;
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalWidth(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        props.tablet.width = px;
        props.mobile.width = px;
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    paddingLinkDl(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPaddingDl) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dl_padding_top = value;
          props.desktop.dl_padding_right = value;
          props.desktop.dl_padding_bottom = value;
          props.desktop.dl_padding_left = value;
          props.tablet.dl_padding_top = value;
          props.tablet.dl_padding_right = value;
          props.tablet.dl_padding_bottom = value;
          props.tablet.dl_padding_left = value;
          props.mobile.dl_padding_top = value;
          props.mobile.dl_padding_right = value;
          props.mobile.dl_padding_bottom = value;
          props.mobile.dl_padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dl_padding_top = value;
            props.desktop.dl_padding_right = value;
            props.desktop.dl_padding_bottom = value;
            props.desktop.dl_padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dl_padding_top = value;
            props.tablet.dl_padding_right = value;
            props.tablet.dl_padding_bottom = value;
            props.tablet.dl_padding_left = value;
          } else {
            props.mobile.dl_padding_top = value;
            props.mobile.dl_padding_right = value;
            props.mobile.dl_padding_bottom = value;
            props.mobile.dl_padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dl_padding_top = value;
          props.mobile.dl_padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dl_padding_right = value;
          props.mobile.dl_padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dl_padding_bottom = value;
          props.mobile.dl_padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dl_padding_left = value;
          props.mobile.dl_padding_left = value;
        }
      }
    },
    attrLinkMarginDl(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMarginDl) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dl_margin_top = value;
          props.desktop.dl_margin_right = value;
          props.desktop.dl_margin_bottom = value;
          props.desktop.dl_margin_left = value;
          props.tablet.dl_margin_top = value;
          props.tablet.dl_margin_right = value;
          props.tablet.dl_margin_bottom = value;
          props.tablet.dl_margin_left = value;
          props.mobile.dl_margin_top = value;
          props.mobile.dl_margin_right = value;
          props.mobile.dl_margin_bottom = value;
          props.mobile.dl_margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dl_margin_top = value;
            props.desktop.dl_margin_right = value;
            props.desktop.dl_margin_bottom = value;
            props.desktop.dl_margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dl_margin_top = value;
            props.tablet.dl_margin_right = value;
            props.tablet.dl_margin_bottom = value;
            props.tablet.dl_margin_left = value;
          } else {
            props.mobile.dl_margin_top = value;
            props.mobile.dl_margin_right = value;
            props.mobile.dl_margin_bottom = value;
            props.mobile.dl_margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dl_margin_top = value;
          props.mobile.dl_margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dl_margin_right = value;
          props.mobile.dl_margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dl_margin_bottom = value;
          props.mobile.dl_margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dl_margin_left = value;
          props.mobile.dl_margin_left = value;
        }
      }
    },
    paddingLinkDd(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPaddingDd) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dd_padding_top = value;
          props.desktop.dd_padding_right = value;
          props.desktop.dd_padding_bottom = value;
          props.desktop.dd_padding_left = value;
          props.tablet.dd_padding_top = value;
          props.tablet.dd_padding_right = value;
          props.tablet.dd_padding_bottom = value;
          props.tablet.dd_padding_left = value;
          props.mobile.dd_padding_top = value;
          props.mobile.dd_padding_right = value;
          props.mobile.dd_padding_bottom = value;
          props.mobile.dd_padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dd_padding_top = value;
            props.desktop.dd_padding_right = value;
            props.desktop.dd_padding_bottom = value;
            props.desktop.dd_padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dd_padding_top = value;
            props.tablet.dd_padding_right = value;
            props.tablet.dd_padding_bottom = value;
            props.tablet.dd_padding_left = value;
          } else {
            props.mobile.dd_padding_top = value;
            props.mobile.dd_padding_right = value;
            props.mobile.dd_padding_bottom = value;
            props.mobile.dd_padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dd_padding_top = value;
          props.mobile.dd_padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dd_padding_right = value;
          props.mobile.dd_padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dd_padding_bottom = value;
          props.mobile.dd_padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dd_padding_left = value;
          props.mobile.dd_padding_left = value;
        }
      }
    },
    attrLinkMarginDd(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMarginDd) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dd_margin_top = value;
          props.desktop.dd_margin_right = value;
          props.desktop.dd_margin_bottom = value;
          props.desktop.dd_margin_left = value;
          props.tablet.dd_margin_top = value;
          props.tablet.dd_margin_right = value;
          props.tablet.dd_margin_bottom = value;
          props.tablet.dd_margin_left = value;
          props.mobile.dd_margin_top = value;
          props.mobile.dd_margin_right = value;
          props.mobile.dd_margin_bottom = value;
          props.mobile.dd_margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dd_margin_top = value;
            props.desktop.dd_margin_right = value;
            props.desktop.dd_margin_bottom = value;
            props.desktop.dd_margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dd_margin_top = value;
            props.tablet.dd_margin_right = value;
            props.tablet.dd_margin_bottom = value;
            props.tablet.dd_margin_left = value;
          } else {
            props.mobile.dd_margin_top = value;
            props.mobile.dd_margin_right = value;
            props.mobile.dd_margin_bottom = value;
            props.mobile.dd_margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dd_margin_top = value;
          props.mobile.dd_margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dd_margin_right = value;
          props.mobile.dd_margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dd_margin_bottom = value;
          props.mobile.dd_margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dd_margin_left = value;
          props.mobile.dd_margin_left = value;
        }
      }
    },
    paddingLinkDt(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPaddingDt) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dt_padding_top = value;
          props.desktop.dt_padding_right = value;
          props.desktop.dt_padding_bottom = value;
          props.desktop.dt_padding_left = value;
          props.tablet.dt_padding_top = value;
          props.tablet.dt_padding_right = value;
          props.tablet.dt_padding_bottom = value;
          props.tablet.dt_padding_left = value;
          props.mobile.dt_padding_top = value;
          props.mobile.dt_padding_right = value;
          props.mobile.dt_padding_bottom = value;
          props.mobile.dt_padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dt_padding_top = value;
            props.desktop.dt_padding_right = value;
            props.desktop.dt_padding_bottom = value;
            props.desktop.dt_padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dt_padding_top = value;
            props.tablet.dt_padding_right = value;
            props.tablet.dt_padding_bottom = value;
            props.tablet.dt_padding_left = value;
          } else {
            props.mobile.dt_padding_top = value;
            props.mobile.dt_padding_right = value;
            props.mobile.dt_padding_bottom = value;
            props.mobile.dt_padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dt_padding_top = value;
          props.mobile.dt_padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dt_padding_right = value;
          props.mobile.dt_padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dt_padding_bottom = value;
          props.mobile.dt_padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dt_padding_left = value;
          props.mobile.dt_padding_left = value;
        }
      }
    },
    attrLinkMarginDt(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMarginDt) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.dt_margin_top = value;
          props.desktop.dt_margin_right = value;
          props.desktop.dt_margin_bottom = value;
          props.desktop.dt_margin_left = value;
          props.tablet.dt_margin_top = value;
          props.tablet.dt_margin_right = value;
          props.tablet.dt_margin_bottom = value;
          props.tablet.dt_margin_left = value;
          props.mobile.dt_margin_top = value;
          props.mobile.dt_margin_right = value;
          props.mobile.dt_margin_bottom = value;
          props.mobile.dt_margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.dt_margin_top = value;
            props.desktop.dt_margin_right = value;
            props.desktop.dt_margin_bottom = value;
            props.desktop.dt_margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.dt_margin_top = value;
            props.tablet.dt_margin_right = value;
            props.tablet.dt_margin_bottom = value;
            props.tablet.dt_margin_left = value;
          } else {
            props.mobile.dt_margin_top = value;
            props.mobile.dt_margin_right = value;
            props.mobile.dt_margin_bottom = value;
            props.mobile.dt_margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.dt_margin_top = value;
          props.mobile.dt_margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.dt_margin_right = value;
          props.mobile.dt_margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.dt_margin_bottom = value;
          props.mobile.dt_margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.dt_margin_left = value;
          props.mobile.dt_margin_left = value;
        }
      }
    },
    // getGoogleFonts() {
    //   minhaVariavel.valor = this.fontOptions;
    // },
    addItems() {
      let index = this.editaItem.propsData.rows.length + 1
      this.editaItem.propsData.rows.push({
        question: `Pergunta ${index}`,
        resp: `Resposta ${index}`,
        show_image: false,
        image: "https://gdigital.s3.amazonaws.com/gdigital/1/resposta.webp",
        image_ratio: 25,
        image_width: {
          desktop: 100,
          tablet: 100,
          mobile: 100
        }
      });
    },
    removeItems(index) {
      this.editaItem.propsData.rows.splice(index, 1);
    },
    mostrarMensagem() {
      this.$bvToast.toast(`Necessário ao menos uma accordion List`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    next_und_width() {
      var props = this.editaItem.propsData.size[this.typeView];
      switch (props.width_min_und) {
        case "vh":
          props.width_min_und = "px";
          break;
        case "px":
          props.width_min_und = "%";
          break;
        case "%":
          props.width_min_und = "vh";
          break;
        default:
          props.width_min_und = "px";
      }
      if (this.global && this.typeView == "desktop") {
        this.editaItem.propsData.size.tablet =
          this.editaItem.propsData.size.desktop;
        this.editaItem.propsData.size.tablet =
          this.editaItem.propsData.size.mobile;
      }
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
  },
  mounted() {
    // this.getGoogleFonts();
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
.text-component .cke_toolbar_last {
  padding-right: 63px !important;
}
.button-expand {
  z-index: 9999999999;
  top: 7px !important;
  right: -775px !important;
}
// .ck-button-theme {
//   border: 1px solid #ccc !important;
//   color: #000;
//   transition: all 0.15s;
//   &:hover {
//     color: #000;
//   }
// }
// .button-black {
//   background: #000000 !important;
//   color: #fff;
//   &:hover {
//     color: #fff;
//   }
// }
// .button-white {
//   background: #fff !important;
// }
#SideRightTabs {
  position: relative;
}
.ck-theme-accordion {
  button {
    font-size: 12px;
    text-transform: uppercase;
    padding: 3px 10px 0px 10px;
    border-radius: 10px 10px 0 0;
    border-bottom: none !important;
  }
}
.cke_wysiwyg_div {
  padding-top: 1em !important;
}
textarea.cke_source {
  min-height: 100% !important;
  border: 0px solid #000000 !important;
}
.cke_source:hover {
  border: 0px solid #000000 !important;
}
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.container-mg {
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 20px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;

  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }

  .form-group {
    margin: 0 !important;
  }

  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &:hover {
    border: 0.5px solid var(--greenn);

    .borderx {
      border-right: 0.5px solid var(--greenn);
    }
  }
}

.cke_wysiwyg_div * {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  text-align: inherit;
  text-shadow: inherit;
  text-transform: inherit;
}

a.cke_button_off:hover,
a.cke_button_off:focus,
a.cke_button_off:active {
  background: #e5e5e5;
  padding: 4px 6px !important;
}

.cke_toolbar_start + .cke_combo_off a.cke_combo_button:hover,
.cke_toolbar_start + .cke_combo_off a.cke_combo_button:focus,
.cke_toolbar_start + .cke_combo_off a.cke_combo_button:active {
  padding: 1px !important;
  margin-left: 0px !important;
}

.text-component .cke_reset {
  min-height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

a.cke_button_expandable.cke_button_off:hover,
a.cke_button_expandable.cke_button_off:focus,
a.cke_button_expandable.cke_button_off:active {
  padding: 4px 5px !important;
}

.cke_combo_on a.cke_combo_button,
.cke_combo_off a.cke_combo_button:hover,
.cke_combo_off a.cke_combo_button:focus,
.cke_combo_off a.cke_combo_button:active {
  background: #e5e5e5;
  padding: 1px !important;
  margin-left: 0px !important;
}

.cke_resizer {
  display: none !important;
}

a.cke_dialog_ui_button {
  border-radius: 10px !important;
  display: inline-block;
  padding: 4px 1px;
  margin: 0;
  text-align: center;
  color: #484848;
  vertical-align: middle;
  cursor: pointer;
  border: 0px solid #bcbcbc !important;
  background: #f8f8f8;
  letter-spacing: 0.3px;
  line-height: 18px;
  box-sizing: border-box;
  transition: all 0.3s !important;
}

.cke_dialog_footer_buttons a.cke_dialog_ui_button span {
  color: inherit;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 0 12px;
  font-family: montserrat;
}

a.cke_button_on {
  background: rgb(255, 255, 255);
  padding: 4px 6px !important;
}
//
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.content-quill {
  padding: 0 35px;
}
.border-quill {
  border: 1px solid #ededf0 !important;
  border-radius: 10px;
}

.separador {
  width: 100%;
  height: 1px;
  background-color: #ededf0;
}
.contain-buttons {
  display: flex;
  gap: 15px;
  padding-left: 20px;
  margin-bottom: 8px;
}
.container-links {
  margin-bottom: 12px;
}

.container-mg {
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 0px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;
  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }
  .form-group {
    margin: 0 !important;
  }
  input,
  input:hover {
    border: 0.5px solid #ededf000 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
.button-remove-produto:hover {
  background: #f00 url("../../../../assets/icons/FunilSpeed/minus-hover.svg")
    no-repeat center center !important;
}
.button-remove-produto {
  content: "";
  background: url("../../../../assets/icons/FunilSpeed/minus.svg") no-repeat
    center center;
  border-color: #f00;
  border-radius: 2px;
}
.button-add-produto:hover {
  background: var(--greenn)
    url("../../../../assets/icons/FunilSpeed/add-hover.svg") no-repeat center
    center;
}
.button-add-produto {
  background: url("../../../../assets/icons/FunilSpeed/add.svg") no-repeat
    center center;
  border-color: var(--greenn);
  border-radius: 2px;
}
.btn {
  cursor: pointer;
  background-color: transparent;
  padding: 18px;
}
.und_form {
  position: relative;
  .und {
    position: absolute;
    right: 8px;
    top: 7px !important;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
  }
}

</style>
