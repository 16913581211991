<template>
  <div id="SideRightTabs">
    <b-tabs pills fill small style="width: 100%">
      <!-- Geral -->
      <b-tab title="Geral" active>
        <div class="b-tab-content">
          <div class="separador" />

          <!-- Conteúdo -->
          <div class="content">
            <div class="half">
              <label class="titulo">Ícones</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button class="btn button-add-produto" @click="addIcon"></button>
              </div>
            </div>
            <div v-for="(item, idx) in editaItem.propsData.icon" :key="idx">
              <div class="label-range">
                <label class="subtitulo">Ícone {{ idx + 1 }}</label>
                <button style="margin-top: -10px" class="btn button-remove-produto"
                  @click="editaItem.propsData.icon.length > 1 ? removeIconIndex(idx) : ''"></button>
              </div>
              <div style="padding: 0px 5px">
                <div class="select-image" v-if="editaItem.propsData.icon[idx].iconSelected.hasOwnProperty('name')">
                  <div @click="openModal('Modal-Icons-svg', idx)">
                    <span
                      class="material-icons icon-preview"
                      v-html="editaItem.propsData.icon[idx].iconSelected.code"
                      ></span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon[idx].iconSelected.name">
                    <span @click="openModal('Modal-Icons-svg', idx)">{{ editaItem.propsData.icon[idx].iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
                <div class="select-image" v-else>
                  <div @click="openModal('Modal-Icons-svg', idx)">
                    <span
                      class="material-icons icon-preview"
                      >{{ editaItem.propsData.icon[idx].iconSelected }}</span
                    >
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.icon[idx].iconSelected">
                    <span @click="openModal('Modal-Icons-svg', idx)">{{ editaItem.propsData.icon[idx].iconSelected ? 'Alterar ícone' : 'Selecionar ícone'}}</span>
                  </div>
                </div>
                <div class="space"></div>
                <input id="menu" placeholder="Link de redirecionamento (opcional)"
                  v-model="editaItem.propsData.icon[idx].link" />
                <div class="space"></div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
        </div>
      </b-tab>

      <!-- Estilo -->
      <b-tab title="Estilo">
        <div class="b-tab-content">
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Cor do ícone</label>
            <div class="space"></div>
            <label class="subtitulo"> Cor padrão</label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.iconColor" :colors="palette"></LvColorpicker>
            <div class="space"></div>
            <label class="subtitulo">Cor ao passar o mouse</label>
            <LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.styles.iconHover" :colors="palette"></LvColorpicker>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-range">
              <div class="label-icon">
                <label class="titulo">Tamanho</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input type="number" v-model="editaItem.propsData.styles[typeView].iconSize" @input="globalInput($event, 'iconSize')" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles[typeView].iconSize" @input="globalInput($event, 'iconSize')"
              type="range" min="0" max="300" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-range">
              <div class="label-icon">
                <label class="titulo">Espaço entre ícones</label>
                <div>
                  <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                  <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                    @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                  <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                    @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                  <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                    @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
                </div>
              </div>
              <input type="number" v-model="editaItem.propsData.styles[typeView].gap" @input="globalInput($event, 'gap')" />
            </div>
            <b-form-input v-model="editaItem.propsData.styles[typeView].gap" @input="globalInput($event, 'gap')"
              type="range" min="0" max="100" step="1"></b-form-input>
          </div>
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Posição</label>
              <div>
                <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="global" />
                <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
                  @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
                <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
                  @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
                <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
                  @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
              </div>
            </div>
            <div style="display: flex; width: 100%; gap: 10px">
              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-start' }"
                  @click="globalJustify('flex-start', 'justify')"><img
                      src="@/assets/editor/align-left-new.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'center' }"
                  @click="globalJustify('center', 'justify')"><img
                      src="@/assets/editor/align-center-horizontal.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify == 'flex-end' }"
                  @click="globalJustify('flex-end', 'justify')"><img
                      src="@/assets/editor/align-right-new.svg" /></button>
                </div>
              </div>

              <div style="display: flex; width: 50%; transition: all 0.3s">
                <div class="select-align">
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'start' }"
                    @click="globalJustify('start', 'justify_vertical')"><img
                      src="@/assets/editor/align-top.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'center' }"
                    @click="globalJustify('center', 'justify_vertical')"><img
                      src="@/assets/editor/align-center-vertical.svg" /></button>
                  <button :class="{ 'selected-align': editaItem.propsData.alignment[typeView].justify_vertical == 'end' }"
                    @click="globalJustify('end', 'justify_vertical')"><img
                      src="@/assets/editor/align-bottom.svg" /></button>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div>
              <label for="form-altura" class="sub">Altura mínima da coluna</label>
              <b-form-group id="form-altura" class="und_form">
                <div class="und_form">
                  <b-form-input
                    id="form-altura"
                    v-model="editaItem.propsColumn.height[typeView].height"
                    type="number"
                    min="0"
                  ></b-form-input>
                  <span
                    @click="next_und_height"
                    class="und text"
                    v-b-tooltip.hover
                    title="Altura em pixel"
                    >{{editaItem.propsColumn.height[typeView].height_und}}</span
                  >
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="separador"></div>
        </div>
        -
      </b-tab>

      <!-- Avançado -->
      <b-tab title="Avançado">
        <div class="separador"></div>

        <!-- Componente de Espaçamento -->
        <div class="content">
          <!-- Ícones -->
          <div class="label-icon">
            <label class="titulo">Espaçamento</label>
            <img src="@/assets/editor/globe.svg" alt="monitor" style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)" v-if="global" />
            <img src="@/assets/editor/monitor.svg" alt="monitor" style="filter: invert(50%) !important"
              @click="nextIcon('tablet')" v-if="typeView === 'desktop' && !global" />
            <img src="@/assets/editor/tablet.svg" alt="tablet" style="filter: invert(50%) !important"
              @click="nextIcon('mobile')" v-if="typeView === 'tablet' && !global" />
            <img src="@/assets/editor/phone.svg" alt="phone" style="filter: invert(50%) !important"
              @click="nextIcon('desktop'), (global = !global)" v-if="typeView === 'mobile' && !global" />
          </div>
          <!-- Inputs -->
          <div class="container-spacing" style="padding-bottom: 15px">
            <div class="border-dash">
              <span class="txt-mg">MARGEM EXTERNA</span>
              <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                :class="linkMargin ? '' : 'link-disabled'" @click="linkMargin = !linkMargin" />
              <div class="alinhamento">
                <!-- MARGIN LEFT -->
                <div class="item-1">
                  <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_left"
                    @input="attrLinkMargin($event, 'left')" min="0" max="100" />
                </div>
                <div class="item-2">
                  <div class="alinhamento-2">
                    <!-- MARGIN TOP -->
                    <div>
                      <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_top
                        " @input="attrLinkMargin($event, 'top')" min="0" max="100" />
                    </div>
                    <div>
                      <div class="contain-borders">
                        <span class="txt-mg">MARGEM INTERNA</span>
                        <img src="@/assets/editor/link-click.svg" alt="Link" class="links-advanced"
                          :class="linkPadding ? '' : 'link-disabled'" @click="linkPadding = !linkPadding" />
                        <div class="alinhamento">
                          <!-- PADDING LEFT -->
                          <div class="item-1">
                            <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_left
                              " @input="paddingLink($event, 'left')" min="0" max="100" />
                          </div>
                          <div class="item-2">
                            <div class="alinhamento-2">
                              <!-- PADDING TOP -->
                              <div>
                                <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                    .padding_top
                                  " @input="paddingLink($event, 'top')" min="0" max="100" />
                              </div>
                              <div>
                                <div class="border-final"></div>
                              </div>
                              <!-- PADDING BOTTOM -->
                              <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                  .padding_bottom
                                " @input="paddingLink($event, 'bottom')" min="0" max="100" />
                              <div></div>
                            </div>
                          </div>
                          <!-- PADDING RIGHT -->
                          <div class="item-3">
                            <input type="number" v-model="editaItem.propsData.spacing[typeView]
                                .padding_right
                              " @input="paddingLink($event, 'right')" min="0" max="100" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- MARGIN BOTTOM -->
                    <div>
                      <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_bottom
                        " @input="attrLinkMargin($event, 'bottom')" min="0" max="100" />
                    </div>
                  </div>
                </div>
                <!-- MARGIN RIGHT -->
                <div class="item-3">
                  <input type="number" v-model="editaItem.propsData.spacing[typeView].margin_right"
                    @input="attrLinkMargin($event, 'right')" min="0" max="100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Visilibilidade -->
        <div class="content">
          <label class="titulo"> Visibilidade </label>

          <!-- Dispositivos grandes -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos grandesﾠ
                <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                  title="Maiores que 768 pixels de largura, como laptops e computadores" />
              </label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.monitor" name="is_main" size="lg" @change="$emit('fixWidth')" switch>
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos médios -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos médiosﾠ
                <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                  title="Entre 480 a 768 pixels de largura, como tablets" />
              </label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.tablet" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos pequenosﾠ
                <img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon" v-b-tooltip.hover
                  title="Menores que 480 pixels de largura, como celulares" />
              </label>
              <b-form-checkbox style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.phone" name="is_main" size="lg" switch>
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Animação -->
        <div class="content">
          <label class="titulo">Animação</label>
          <div class="select-animation">
            <div class="animation-container">
              <div>
                <p>Nenhum</p>
                <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                  .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                  <div class="simulated-item simu1"></div>
                </div>
              </div>
              <div>
                <p>Zoom</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                  .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                  <div class="simulated-item simu2">
                    <img src="@/assets/editor/square-logo.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Up</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                  <div class="simulated-item simu3"> 
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Right</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                  <div class="simulated-item simu4">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Down</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                  <div class="simulated-item simu5">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
              <div>
                <p>Fade Left</p>
                <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                  .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                  .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                  <div class="simulated-item simu6">
                    <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                  </div>
                </div>
              </div>
            </div>
            <div class="space"></div>
            <div class="half-animation">
              <div>
                <div class="label-range">
                  <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                  <input
                    type="number"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    v-model="editaItem.propsColumn.delay"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.delay"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  type="range"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
              <div>
                <div class="label-range">
                  <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                  <input
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="number"
                    v-model="editaItem.propsColumn.duration"
                  />
                </div>
                <b-form-input
                  v-model="editaItem.propsColumn.duration"
                  type="range"
                  :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                  min="0"
                  max="10"
                  step="1"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Classe e ID -->
        <div class="content">
          <label class="titulo">Classe e ID</label>
          <label class="sub">IDﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
              v-b-tooltip.hover
              title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID" /></label>
          <input v-model="editaItem.propsData.custom.c_id" class="w-100 mb-3" type="text" name="text_1" id="text_1"
            placeholder="exemplo: id_unico" />
          <label class="sub">Classeﾠ<img width="17px" height="17px" src="@/assets/editor/help-circle.svg" alt="icon"
              v-b-tooltip.hover
              title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes." /></label>
          <input v-model="editaItem.propsData.custom.c_class" class="w-100 mb-3" type="text" name="text_1" id="text_1"
            placeholder="exemplo: classe_1 classe_2" />
        </div>
        <div class="separador"></div>

        <div class="separador"></div>
      </b-tab>
    </b-tabs>

    <ModalIcons @iconSelect="iconSelect"></ModalIcons>
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";
// import ModalIcons from "../../ModalSvg.vue";
import ModalIcons from "@/components/Editor/ModalSvg";

import { getGoogleFonts } from "@/components/Editor/GoogleFonts.js";

export default {
  props: ["editaItem", "typeView", "palette"],
  components: {
    LvColorpicker: LvColorpicker,
    Multiselect,
    ModalIcons,
  },
  data() {
    return {
      global: false,
      linkPadding: true,
      linkMargin: true,
      currentIndex: 0,
    };
  },
  async mounted() {
    this.fontOptions = await getGoogleFonts();
  },
  watch: {},
  methods: {
    next_und_height() {
      const props = this.editaItem.propsColumn.height;
      props[this.typeView].height_und = props[this.typeView].height_und == "px" ? "vh" : props[this.typeView].height_und == "vh" ? "vw" : "px";
      if (this.global && this.typeView == 'desktop') {
        props.tablet.height_und = props.desktop.height_und
        props.mobile.height_und = props.desktop.height_und
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment
      props[this.typeView][place] = value
      if (this.global && this.typeView == 'desktop') {
        props[this.typeView][place] = value
        props[this.typeView][place] = value
      }
    },
    removeIconIndex(index) {
      this.editaItem.propsData.icon.splice(index, 1);
    },
    addIcon() {
      this.editaItem.propsData.icon.push({
        iconSelected: "check",
        link: "",
      });
    },
    removeIcon() {
      this.editaItem.propsData.icon.pop();
    },
    iconSelect(item) {
      this.editaItem.propsData.icon[this.currentIndex].iconSelected = item;
    },
    openModal(data, idx) {
      this.$bvModal.show(data);
      this.currentIndex = idx
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalInput(px, place) {
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.styles;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>
