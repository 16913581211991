<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Controle de imagens -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Imagens</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button
                    class="btn button-add-produto"
                    @click="addImg"
                  ></button>
                </div>
              </div>
            </div>
            <div v-for="(slide, idx) in editaItem.propsData.slides" :key="idx">
              <div class="p-2" style="position: relative;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Mídia {{ idx + 1 }}</label>
              </div>
              <div class="select-type" v-if="slide.hasOwnProperty('isVideo')" @click="changeStatus(idx)" :class="!slide.isVideo ? 'left-focus' : 'right-focus'">
                <span>Imagem</span>
                <span>Vídeo</span>
              </div>
              <div style="display: flex">
                <div class="select-image" style="width: calc( 100% - 35px )">
                  <div @click="openModal('Upload-Editor', idx)">
                    <img :src="editaItem.propsData.slides[idx].imagem ? editaItem.propsData.slides[idx].imagem : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview">
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.slides[idx].imagem">
                    <div class="with-trash">
                      <span @click="openModal('Upload-Editor', idx)">{{ editaItem.propsData.slides[idx].isVideo ? 'Alterar thumbnail' : 'Alterar imagem'}}</span>
                      <img
                        v-show="editaItem.propsData.slides[idx].imagem && editaItem.propsData.slides[idx].isVideo"
                        title="Remover thumbnail"
                        @click="editaItem.propsData.slides[idx].imagem = ''"
                        class="trash"
                        src="@/assets/editor/trash-2.svg"
                      />
                    </div>
                  </div>
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow' : idx == 0}"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.slides.length}"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx + 1 == editaItem.propsData.slides.length ? '' : moveDown(idx)"
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <b-collapse v-model="slide.isVideo">
                <label class="subtitulo">Link do YouTube</label>
                <input
                  type="text"
                  v-model="slide.content"
                  @input="convertURL(slide.content, idx)"
                />
              </b-collapse>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>
          <div class="content">
            <div class="label-icon" v-if="inverted">
              <label class="titulo">Dimensões</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <label v-else class="titulo">Dimensões</label>
            <label class="subtitulo">Proporções</label>
            <multiselect
              :options="[
                'Paisagem (16:9)',
                'Vertical (9:16)',
                'Personalizado',
                'Quadrado (1:1)',
                'Retrato (4:5)',
                'Panorama (2:1)',
              ]"
              v-model="proportion"
              :searchable="false"
              :show-labels="false"
              placeholder=" "
              @input="proportions('altura'), dimensions()"
            ></multiselect>
            <b-collapse v-model="inverted">
              <div class="space"></div>
              <div class="half">
                <div>
                  <label
                    class="subtitulo"
                    >Altura</label
                  >
                  <input
                    type="number"
                    v-model="editaItem.propsData.size[typeView].height"
                    @input="proportions('altura')"
                  />
                </div>
                <div>
                  <label
                    class="subtitulo"
                    >Largura</label
                  >
                  <input
                    type="number"
                    v-model="editaItem.propsData.size[typeView].width"
                    @input="proportions('largura')"
                  />
                </div>
              </div>
              <div class="space"></div>
              <div>
                <label class="subtitulo">Posicionamento</label>
                <div style="display: flex; width: 100%; gap: 10px">
                  <div style="display: flex; width: 50%; transition: all 0.3s">
                    <div class="select-align">
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-start',
                        }"
                        @click="globalJustify('flex-start', 'justify')"
                      >
                        <img src="@/assets/editor/align-left-new.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'center',
                        }"
                        @click="globalJustify('center', 'justify')"
                      >
                        <img src="@/assets/editor/align-center-horizontal.svg" />
                      </button>
                      <button
                        :class="{
                          'selected-align':
                            editaItem.propsData.alignment[typeView].justify ==
                            'flex-end',
                        }"
                        @click="globalJustify('flex-end', 'justify')"
                      >
                        <img src="@/assets/editor/align-right-new.svg" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </b-collapse>
          </div>
          <div class="separador"></div>
          <div class="content">
            <label class="titulo">Botões</label>
            <label class="sub">Cor</label>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_btns"
              label="Cor dos Botões"
              :colors="palette"
            ></LvColorpicker>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Avançado">
          <!-- Espaçamento -->
          <div class="separador"></div>
          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Visibilidade -->
          <div class="separador"></div>
          <div class="content">
            <label class="titulo"> Visibilidade </label>
            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos grandesﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="space"></div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos médiosﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  @change="$emit('fixWidth')"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="space"></div>

            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos pequenosﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content columnAnimate">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                    .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                    .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                    <div class="simulated-item simu3"> 
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                    <input
                      type="number"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                    <input
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette"],

  components: {
    Upload,
    BaseButton,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      block_rotate_logo: 0,
      img_carousel_index: null,
      proportion: "Paisagem (16:9)",
      inverted: false,
    };
  },
  methods: {
    changeStatus(idx) {
      // foi necessário um splice ao invés de um simples slide.isVideo = !slide.isVideo 
      // por conta das limitações de reatividade na renderização de arrays do vue2,
      // as quais podem ser contornadas com um splice.
      let newItem = this.editaItem.propsData.slides[idx]
      newItem.isVideo = !newItem.isVideo
      this.editaItem.propsData.slides.splice(idx, 1, newItem)
    },
    convertURL(url, idx) {
      const regex = /https:\/\/(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|)([a-zA-Z0-9_-]+)/;
      const match = url.match(regex);
      var base_url = "https://www.youtube.com/embed/";
      if (match) {
        this.editaItem.propsData.slides[idx].content = base_url + match[4];
      } else {
        this.editaItem.propsData.slides[idx].content = "URL inválida";
      }
    },
    moveUp(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx + 1, 0, moving);
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalWidth(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet.width = px;
        props.mobile.width = px;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    globalJustify(value, place) {
      let props = this.editaItem.propsData.alignment;
      props[this.typeView][place] = value;
      if (this.global && this.typeView == "desktop") {
        props[this.typeView][place] = value;
        props[this.typeView][place] = value;
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    mostrarMensagem() {
      this.$bvToast.toast(`Necessário ao menos uma imagem`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    addImg() {
      this.editaItem.propsData.slides.push({
        imagem:
          "http://testespark.gpages.com.br/files/sites/3491/2019/06/gd-4.png",
      });
    },
    dimensions() {
      if (this.proportion.includes("Quadrado")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 100;
      }
      if (this.proportion.includes("Panorama")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 50;
      }
      if (this.proportion.includes("Paisagem")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 56.25;
      }
      if (this.proportion.includes("Vertical")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 177.8;
      }
      if (this.proportion.includes("Personalizado")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 0;
        this.editaItem.propsData.visibility.compleat = false
        this.inverted = true
      } else {
        this.editaItem.propsData.visibility.compleat = true
        this.inverted = false
      }
      if (this.proportion.includes("Retrato")) {
        this.editaItem.propsData.spacing.compleat_padding_bottom = 125;
      }
    },
    removeImgIndex(index) {
      this.editaItem.propsData.slides.splice(index, 1);
    },
    removeImg() {
      this.editaItem.propsData.slides.pop();
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.slides[this.img_carousel_index].imagem = img;
    },
    mg_logo(valor) {
      this.block_rotate_logo = valor;
    },
    proportions(item) {
      if (item == "largura") {
        if (this.proportion.includes("Quadrado")) {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width
          );
          return;
        }
        if (this.proportion.includes("Panorama")) {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width / 2
          );
          return;
        }
        if (this.proportion.includes("Paisagem")) {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 16) * 9
          );
          return;
        }
        if (this.proportion.includes("Vertical")) {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 16) * 9
          );
          return;
        }
        if (this.proportion.includes("Retrato")) {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 4) * 5
          );
          return;
        }
        return;
      }
      if (item == "altura") {
        if (this.proportion.includes("Quadrado")) {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          return;
        }
        if (this.proportion.includes("Panorama")) {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          return;
        }
        if (this.proportion.includes("Paisagem")) {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 9) * 16
          );
          return;
        }
        if (this.proportion.includes("Vertical")) {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 16) * 9
          );
          return;
        }
        if (this.proportion.includes("Retrato")) {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          return;
        }
        return;
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
    switch (this.editaItem.propsData.spacing.compleat_padding_bottom) {
      case 100:
        this.proportion = 'Quadrado (1:1)';
        break;
      case 50:
        this.proportion = 'Panorama (2:1)';
        break;
      case 56.25:
        this.proportion = 'Paisagem (16:9)';
        break;
      case 177.8:
        this.proportion = 'Vertical (9:16)';
        break;
      case 125:
        this.proportion = 'Retrato (4:5)';
        break;
      default:
        this.proportion = 'Personalizado';
    }

    this.inverted = !this.editaItem.propsData.visibility.compleat;
    // Atualiza os componentes antigos
    if (!this.editaItem.propsData.slides[0].hasOwnProperty('isVideo')) {
      for (let index = 0; index < this.editaItem.propsData.slides.length; index++) {
        this.editaItem.propsData.slides[index].isVideo = false
        this.editaItem.propsData.slides[index].content = ''
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.select-type {
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--greenn) !important;
  border-radius: 10px;
  padding: 5px 0;
  margin-bottom: 15px;
  overflow: hidden;
  span {
    text-align: center;
    color: var(--greenn) !important;
    transition: .3s;
    font-size: 14px;
    line-height: 1;
    position: relative;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: var(--greenn);
    transition: .3s;
    top: 0;
    opacity: .7;
  }
  &.left-focus {
    span:first-child {
      color: white !important;
    }
    &::before {
      left: 0%;
      border-radius: 10px 0 0 10px;
    }
  }
  &.right-focus {
    span:last-child {
      color: white !important;
    }
    &::before {
      left: 50%;
      border-radius: 0 10px 10px 0;
    }
  }
}
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}
.flex {
  display: flex;
  gap: 30px;
  align-items: center;
}
// PROVISORIO
.TOP {
  margin-top: 50px;
}
.LINKS {
  display: grid;
  grid-gap: 70px;
}
//
.contain-buttons {
  display: flex;
  gap: 15px;
}

.container-mg {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid #ededf0;
  display: flex;
  align-items: center;
  padding: 15px 20px !important;
  height: 45px !important;
  border-radius: 10px !important;
  gap: 35px;
  margin-bottom: 12px;
  .borderx {
    border-right: 0.5px solid #ededf0;
    padding-right: 15px;
  }
}
.button-remove-produto:hover {
  background: #f00 url("../../../../assets/icons/FunilSpeed/minus-hover.svg")
    no-repeat center center !important;
}
.button-remove-produto {
  content: "";
  background: url("../../../../assets/icons/FunilSpeed/minus.svg") no-repeat
    center center;
  border-color: #f00;
  border-radius: 2px;
}
.button-add-produto:hover {
  background: var(--greenn) url("../../../../assets/icons/FunilSpeed/add-hover.svg")
    no-repeat center center;
}
.button-add-produto {
  background: url("../../../../assets/icons/FunilSpeed/add.svg") no-repeat
    center center;
  border-color: var(--greenn);
  border-radius: 2px;
}
.btn {
  cursor: pointer;
  background-color: transparent;
  padding: 18px;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
</style>
