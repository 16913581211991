<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <b-tab title="Conteúdo" active>
          <!-- Controle de imagens -->
          <div class="separador"></div>
          <div class="content">
            <div class="container-links">
              <div class="half">
                <label class="titulo">Imagens</label>
                <div class="contain-buttons" style="margin-top: -15px">
                  <button
                    class="btn button-add-produto"
                    @click="addImg"
                  ></button>
                </div>
              </div>
            </div>
            <div v-for="(slide, idx) in editaItem.propsData.slides" :key="idx">
              <div class="p-2" style="position: relative;">
                <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
                <label class="subtitulo component-group-title">Imagem {{ idx + 1 }}</label>
              </div>
              <div style="display: flex">
                <div class="select-image" style="width: calc( 100% - 35px )">
                  <div @click="openModal('Upload-Editor', idx)">
                    <img :src="editaItem.propsData.slides[idx].imagem ? editaItem.propsData.slides[idx].imagem : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'" alt="Imagem preview">
                  </div>
                  <div>
                    <input type="text" v-model="editaItem.propsData.slides[idx].imagem">
                    <span @click="openModal('Upload-Editor', idx)">{{ editaItem.propsData.slides[idx].imagem ? 'Alterar imagem' : 'Selecionar imagem'}}</span>
                  </div>
                </div>
                <div class="image-control">
                  <div>
                    <img
                      :class="{ 'disabled-arrow' : idx == 0}"
                      class="first-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx == 0 ? '' : moveUp(idx)"
                      title="Mover para cima"
                    />
                    <button
                      class="button-remove-produto"
                      @click="removeImgIndex(idx)"
                    ></button>
                    <img
                      :class="{ 'disabled-arrow' : idx + 1 == editaItem.propsData.slides.length}"
                      class="last-arrow"
                      src="@/assets/editor/chevron-down.svg"
                      @click="idx + 1 == editaItem.propsData.slides.length ? '' : moveDown(idx)"
                      title="Mover para baixo"
                    />
                  </div>
                </div>
              </div>
              <div class="space"></div>
            </div>
          </div>
          <div class="separador"></div>
        </b-tab>

        <b-tab title="Estilo">
          <div class="separador"></div>

          <!-- Altura e largura -->
          <div class="content">
            <div class="label-icon">
              <label class="titulo">Dimensões das fotos</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div class="half">
              <div>
                <label class="subtitulo">Altura</label>
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      v-if="editaItem.propsData.size[typeView].und_height == 'px'"
                      type="number"
                      v-model="editaItem.propsData.size[typeView].height"
                      @input="globalWidth('height', $event)"
                    />
                    <input
                      v-else
                      type="number"
                      v-model="editaItem.propsData.size[typeView].smallerHeight"
                      @input="globalWidth('height', $event)"
                    />
                    <span
                      @click="next_und('und_height')"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_height }}</span
                    >
                  </section>
                </section>
              </div>

              <div>
                <label class="subtitulo">Largura</label>
                <section id="Altura mínima" class="und_form">
                  <section class="und_form">
                    <input
                      v-if="editaItem.propsData.size[typeView].und_width == 'px'"
                      type="number"
                      v-model="editaItem.propsData.size[typeView].width"
                      @input="globalWidth('width', $event)"
                    />
                    <input
                      v-else
                      type="number"
                      v-model="editaItem.propsData.size[typeView].smallerWidth"
                      @input="globalWidth('width', $event)"
                    />
                    <span
                      @click="next_und('und_width')"
                      class="und text validClick"
                      v-b-tooltip.hover
                      title="Largura em pixel"
                    >
                      {{ editaItem.propsData.size[typeView].und_width }}</span
                    >
                  </section>
                </section>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Foco de cor -->
          <div class="content">
            <!-- Terá ou não o foco de cor -->
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label class="titulo">Foco de cor</label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.styles.colorFocus"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>

            <!-- Posição do slide colorido -->
            <div class="label-icon">
              <label
                class="subtitulo"
                :class="
                  !editaItem.propsData.styles.colorFocus ? 'labelOff' : ''
                "
                >Posição do foco</label
              >
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important; margin-top: 7px"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <div class="focus-control">
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                "
                @click="
                  editaItem.propsData.size[typeView].colorIndex--,
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                -
              </button>
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                @click="
                  (editaItem.propsData.size[typeView].colorIndex = 0),
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                o
              </button>
              <button
                :disabled="!editaItem.propsData.styles.colorFocus"
                style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                "
                @click="
                  editaItem.propsData.size[typeView].colorIndex++,
                    globalMeasure(editaItem.propsData.size[typeView].colorIndex)
                "
              >
                +
              </button>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Tempo -->
          <div class="content">
            <label class="titulo">Temporarizador</label>
            <label class="subtitulo">Selecione o tempo em segundos</label>
            <div class="space"></div>
            <div class="focus-control">
              <button
                :disabled="editaItem.propsData.styles.timer <= 1"
                style="
                  border-radius: 10px 0 0 10px;
                  border-right: none !important;
                "
                @click="
                  editaItem.propsData.styles.timer--
                "
              >
                -
              </button>
              <input
                type="number"
                style="
                  height: 40px !important;
                  border-radius: 0px !important;
                  text-align: center;
                "
                min="1"
                @input="validTimer()"
                v-model="editaItem.propsData.styles.timer"
              />
              <button
                style="
                  border-radius: 0 10px 10px 0;
                  border-left: none !important;
                "
                @click="
                  editaItem.propsData.styles.timer++
                "
              >
                +
              </button>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Botões -->
          <!-- <div class="separador"></div>
          <div class="content">
            <label class="titulo">Botões</label>
            <label class="sub">Cor</label>
            <div class="space"></div>
            <LvColorpicker
              id="LvColorpicker"
              v-model="editaItem.propsData.styles.color_btns"
              label="Cor dos Botões"
              :colors="palette"
            ></LvColorpicker>
          </div> -->
        </b-tab>

        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Componente de Espaçamento -->
          <div class="content">
            <!-- Ícones -->
            <div class="label-icon">
              <label class="titulo">Espaçamento</label>
              <img
                src="@/assets/editor/globe.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="global"
              />
              <img
                src="@/assets/editor/monitor.svg"
                alt="monitor"
                style="filter: invert(50%) !important"
                @click="nextIcon('tablet')"
                v-if="typeView === 'desktop' && !global"
              />
              <img
                src="@/assets/editor/tablet.svg"
                alt="tablet"
                style="filter: invert(50%) !important"
                @click="nextIcon('mobile')"
                v-if="typeView === 'tablet' && !global"
              />
              <img
                src="@/assets/editor/phone.svg"
                alt="phone"
                style="filter: invert(50%) !important"
                @click="nextIcon('desktop'), (global = !global)"
                v-if="typeView === 'mobile' && !global"
              />
            </div>
            <!-- Inputs -->
            <div class="container-spacing" style="padding-bottom: 15px">
              <div class="border-dash">
                <span class="txt-mg">MARGEM EXTERNA</span>
                <img
                  src="@/assets/editor/link-click.svg"
                  alt="Link"
                  class="links-advanced"
                  :class="linkMargin ? '' : 'link-disabled'"
                  @click="linkMargin = !linkMargin"
                />
                <div class="alinhamento">
                  <!-- MARGIN LEFT -->
                  <div class="item-1">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_left
                      "
                      @input="attrLinkMargin($event, 'left')"
                      min="0"
                      max="100"
                    />
                  </div>
                  <div class="item-2">
                    <div class="alinhamento-2">
                      <!-- MARGIN TOP -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_top
                          "
                          @input="attrLinkMargin($event, 'top')"
                          min="0"
                          max="100"
                        />
                      </div>
                      <div>
                        <div class="contain-borders">
                          <span class="txt-mg">MARGEM INTERNA</span>
                          <img
                            src="@/assets/editor/link-click.svg"
                            alt="Link"
                            class="links-advanced"
                            :class="linkPadding ? '' : 'link-disabled'"
                            @click="linkPadding = !linkPadding"
                          />
                          <div class="alinhamento">
                            <!-- PADDING LEFT -->
                            <div class="item-1">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_left
                                "
                                @input="paddingLink($event, 'left')"
                                min="0"
                                max="100"
                              />
                            </div>
                            <div class="item-2">
                              <div class="alinhamento-2">
                                <!-- PADDING TOP -->
                                <div>
                                  <input
                                    type="number"
                                    v-model="
                                      editaItem.propsData.spacing[typeView]
                                        .padding_top
                                    "
                                    @input="paddingLink($event, 'top')"
                                    min="0"
                                    max="100"
                                  />
                                </div>
                                <div>
                                  <div class="border-final"></div>
                                </div>
                                <!-- PADDING BOTTOM -->
                                <input
                                  type="number"
                                  v-model="
                                    editaItem.propsData.spacing[typeView]
                                      .padding_bottom
                                  "
                                  @input="paddingLink($event, 'bottom')"
                                  min="0"
                                  max="100"
                                />
                                <div></div>
                              </div>
                            </div>
                            <!-- PADDING RIGHT -->
                            <div class="item-3">
                              <input
                                type="number"
                                v-model="
                                  editaItem.propsData.spacing[typeView]
                                    .padding_right
                                "
                                @input="paddingLink($event, 'right')"
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- MARGIN BOTTOM -->
                      <div>
                        <input
                          type="number"
                          v-model="
                            editaItem.propsData.spacing[typeView].margin_bottom
                          "
                          @input="attrLinkMargin($event, 'bottom')"
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- MARGIN RIGHT -->
                  <div class="item-3">
                    <input
                      type="number"
                      v-model="
                        editaItem.propsData.spacing[typeView].margin_right
                      "
                      @input="attrLinkMargin($event, 'right')"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>
            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos grandesﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.monitor"
                  @change="$emit('fixWidth')"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="space"></div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos médiosﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="space"></div>

            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label class="sub"> Dispositivos pequenosﾠ </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div class="animation-item" :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Nenhuma'}" @click="editaItem.propsColumn
                    .attribute_und = 'Nenhuma', editaItem.propsColumn.attribute_animation = ''">
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Gradual Increase'}" @click="editaItem.propsColumn
                    .attribute_und = 'Gradual Increase', editaItem.propsColumn.attribute_animation = 'gradual-increase'">
                    <div class="simulated-item simu2">
                      <img src="@/assets/editor/square-logo.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Up'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Up', editaItem.propsColumn.attribute_animation = 'top'">
                    <div class="simulated-item simu3"> 
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Right'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Right', editaItem.propsColumn.attribute_animation = 'right'">
                    <div class="simulated-item simu4">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Down'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Down', editaItem.propsColumn.attribute_animation = 'bottom'">
                    <div class="simulated-item simu5">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div class="animation-item"  :class="{ 'selected-animation' : editaItem.propsColumn
                    .attribute_und == 'Fade Left'}" @click="editaItem.propsColumn
                    .attribute_und = 'Fade Left', editaItem.propsColumn.attribute_animation = 'left'">
                    <div class="simulated-item simu6">
                      <img src="@/assets/editor/arrow-fat-lines-down.svg" alt="setinha">
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Atraso</label>
                    <input
                      type="number"
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label class="subtitulo" :class="{ 'labelOff' : editaItem.propsColumn.attribute_und == 'Nenhuma'}">Duração</label>
                    <input
                      :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
    <Upload @setImg="setImg" />
  </div>
</template>

<script>
import Upload from "../../Upload.vue";
import BaseButton from "@/components/BaseButton";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "palette"],

  components: {
    Upload,
    BaseButton,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      block_rotate_logo: 0,
      img_carousel_index: null,
      proportion: "Personalizado",
    };
  },
  methods: {
    moveUp(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.editaItem.propsData.slides[idx]
      this.editaItem.propsData.slides.splice(idx, 1);
      this.editaItem.propsData.slides.splice(idx + 1, 0, moving);
    },
    validTimer(){
      if (this.editaItem.propsData.styles.timer < 1) {
        this.editaItem.propsData.styles.timer = 1
      }
    },
    next_und(place) {
      var props = this.editaItem.propsData;
      if (props.size[this.typeView][place] == "px") {
        props.size[this.typeView][place] = "vh";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vh";
          props.size.mobile[place] = "vh";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vh") {
        props.size[this.typeView][place] = "vw";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "vw";
          props.size.mobile[place] = "vw";
        }
        return;
      }
      if (props.size[this.typeView][place] == "vw") {
        props.size[this.typeView][place] = "px";
        if (this.global && this.typeView == 'desktop') {
          props.size.tablet[place] = "px";
          props.size.mobile[place] = "px";
        }
        return;
      }
    },
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalMeasure(pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet.colorIndex = px;
        props.mobile.colorIndex = px;
      }
    },
    globalWidth(place, pixel) {
      var px = pixel.target ? pixel.target.value : pixel;
      if (this.global && this.typeView == 'desktop') {
        var props = this.editaItem.propsData.size;
        props.tablet[place] = px;
        props.mobile[place] = px;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == 'desktop') {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == 'desktop') {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    addImg() {
      this.editaItem.propsData.slides.push({
        imagem:
          "https://gdigital.s3.amazonaws.com/gdigital/1/imagem-vazia2.webp",
      });
      this.openModal('Upload-Editor', this.editaItem.propsData.slides.length - 1)
    },
    dimensions() {
      if (this.editaItem.propsData.visibility.compleat) {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.compleat_padding_bottom = 100;
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.compleat_padding_bottom = 50;
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.compleat_padding_bottom = 56.25;
          return;
        }
        if (this.proportion == "Personalizado") {
          this.editaItem.propsData.compleat_padding_bottom = 56.25;
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.compleat_padding_bottom = 125;
          return;
        }
      } else {
        this.editaItem.propsData.compleat_padding_bottom = 0;
      }
    },
    removeImgIndex(index) {
      this.editaItem.propsData.slides.splice(index, 1);
    },
    openModal(data, index) {
      this.img_carousel_index = index;
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.editaItem.propsData.slides[this.img_carousel_index].imagem = img;
    },
    mg_logo(valor) {
      this.block_rotate_logo = valor;
    },
    proportions(item) {
      if (item == "largura") {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width
          );
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            this.editaItem.propsData.size[this.typeView].width / 2
          );
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 1080) * 608
          );
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].height = Math.round(
            (this.editaItem.propsData.size[this.typeView].width / 4) * 5
          );
          return;
        }
        return;
      }
      if (item == "altura") {
        if (this.proportion == "Quadrado") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height
          );
          return;
        }
        if (this.proportion == "Panorama") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            this.editaItem.propsData.size[this.typeView].height * 2
          );
          return;
        }
        if (this.proportion == "Paisagem") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 608) * 1080
          );
          return;
        }
        if (this.proportion == "Retrato") {
          this.editaItem.propsData.size[this.typeView].width = Math.round(
            (this.editaItem.propsData.size[this.typeView].height / 5) * 4
          );
          return;
        }
        return;
      }
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss">
.focus-control {
  display: flex;
  button {
    height: 40px;
    width: 40px;
    background-color: #00000000;
    border: 1px solid #eee !important;
    font-size: 20px;
    transition: all 0.3s;
  }
  input {
    width: 40px !important;
    background-color: #00000000 !important;
    border: 1px solid #eee !important;
    font-size: 20px !important;
    padding: 15px 0px !important;
  }
}
</style>
