<template>
	<div id="SideRightTabs">
		<b-tabs pills fill small style="width: 100%">
			<!-- Geral -->
			<b-tab title="Geral" active>
				<div class="b-tab-content">
					<!-- Conteúdo -->
					<div class="separador" />
					<div class="content">
						<label class="titulo">Conteúdo</label>
						<label class="subtitulo">Texto</label>
						<textarea v-focus v-model="editaItem.propsData.redirection.text" rows="2" class="w-100"></textarea>
						<label class="subtitulo">Número</label>
            <input type="number"  v-model="editaItem.propsData.redirection.number">
					</div>
			
				</div>
			</b-tab>

			<!-- Estilo -->
			<b-tab title="Estilo">
				<div class="b-tab-content">
					<!-- Corpo -->
					<div class="separador"></div>
					<div class="content">
						<label class="titulo">Ícone</label>
						<label class="sub">Cor padrão</label>

						<!-- Seleção do tipo de fundo -->
						<multiselect v-model="editaItem.propsData.icon.back_selected" :options="['Cor sólida', 'Gradiente']"
							:searchable="false" :show-labels="false" placeholder="Tipo do fundo" 
							@input="iconCreate(), backgroundCreate()" :hide-selected="true" :allow-empty="false"></multiselect>
              <div class="space"></div>
						<!-- Cor sólida -->
						<div v-show="editaItem.propsData.icon.back_selected == 'Cor sólida'">
              <label class="subtitulo">Cor de fundo</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.backColor" :colors="palette" >
								</LvColorpicker>
							<div v-if="editaItem.propsData.icon.color">
								<div class="space"></div>

                <label class="subtitulo">Cor do Ícone</label>
							<LvColorpicker id="LvColorpicker" 
								v-model="editaItem.propsData.icon.color" :colors="palette"></LvColorpicker>
                <div class="space"></div>

								<label class="subtitulo">Cor ao passar o mouse no ícone</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.hoverColor" :colors="palette"
                @input="hoverCreate()">
								</LvColorpicker>
                <div class="space"></div>

                <label class="subtitulo">Cor ao passar o mouse no fundo</label>
								<LvColorpicker id="LvColorpicker" v-model="editaItem.propsData.icon.hoverBackColor" :colors="palette"
                @input="hoverBackCreate()">
								</LvColorpicker>
							</div>
						</div>
            
						<!-- Gradiente -->
            <div
              v-show="editaItem.propsData.icon.back_selected == 'Gradiente'"
            >
              <!-- Pickers -->
              <div class="pickers pb-2">
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradiente()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.icon.grad_color1"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
                <div class="picker">
                  <LvColorpicker
                    @input="backgroundGradiente()"
                    id="LvColorpicker"
                    v-model="editaItem.propsData.icon.grad_color2"
                    :colors="palette"
                  ></LvColorpicker>
                </div>
              </div>
              <div class="space"></div>

              <!-- Direção -->
              <div class="label-range">
                <label class="subtitulo">Direção do gradiente</label>
                <input
                  type="number"
                  @input="backgroundGradiente()"
                  v-model="editaItem.propsData.icon.grad_angle"
                />
              </div>
              <b-form-input
                @input="backgroundGradiente()"
                v-model="editaItem.propsData.icon.grad_angle"
                type="range"
                min="0"
                max="360"
                step="1"
              ></b-form-input>
            </div>

					</div>
				</div> 
			</b-tab>
      <b-tab title="Avançado">

        <div class="separador"></div>
        <!-- Visilibilidade -->
        <div class="content">
          <label class="titulo"> Visibilidade </label>

          <!-- Dispositivos grandes -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos grandesﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Maiores que 768 pixels de largura, como laptops e computadores"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.monitor"
                name="is_main"
                @change="$emit('fixWidth')"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos médios -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos médiosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Entre 480 a 768 pixels de largura, como tablets"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.tablet"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <!-- Dispositivos pequenos -->
          <div>
            <div style="display: flex; width: 100%; transition: all 0.3s">
              <label style="display: flex !important" class="sub">
                Dispositivos pequenosﾠ
                <img
                  width="17px"
                  height="17px"
                  src="@/assets/editor/help-circle.svg"
                  alt="icon"
                  v-b-tooltip.hover
                  title="Menores que 480 pixels de largura, como celulares"
                />
              </label>
              <b-form-checkbox
                style="margin-top: -5px; margin-left: auto; margin-right: 0"
                v-model="editaItem.propsData.visibility.phone"
                name="is_main"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="separador"></div>

        <!-- Classe e ID -->
        <div class="content">
          <label class="titulo">Classe e ID</label>
          <label class="sub"
            >IDﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_id"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: id_unico"
          />
          <label class="sub"
            >Classeﾠ<img
              width="17px"
              height="17px"
              src="@/assets/editor/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
          /></label>
          <input
            v-model="editaItem.propsData.custom.c_class"
            class="w-100 mb-3"
            type="text"
            name="text_1"
            id="text_1"
            placeholder="exemplo: classe_1 classe_2"
          />
        </div>
      </b-tab>
		</b-tabs>

	</div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
	props: ["editaItem", "typeView", "palette"],
	components: {
		LvColorpicker: LvColorpicker,
		Multiselect,
	},
	data() {
		return {
			lastColor: "",
			lastBackColor:"",
			lastHoverColor: "",
			lastHoverBackColor: "",
			content: true,
			gradientType: "linear-gradient",
		};
	},
	methods: {
		backgroundCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida") {
				if (this.lastBackColor) {
					this.editaItem.propsData.icon.backColor = this.lastBackColor;
				} else {
					this.editaItem.propsData.icon.backColor = '#25D366'; 
				}
				if (this.lastColor) {
					this.editaItem.propsData.icon.color = this.lastColor;
				}
			} else if (this.editaItem.propsData.icon.back_selected === "Gradiente") {
				this.lastBackColor = this.editaItem.propsData.icon.backColor;
				this.lastColor = this.editaItem.propsData.icon.color;
			}
		},
		iconCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida") {
				if (this.lastColor) {
					this.editaItem.propsData.icon.color = this.lastColor; 
				}
			} else {
				this.lastColor = this.editaItem.propsData.icon.color;
			}
		},
		hoverCreate() {
			if (this.editaItem.propsData.icon.back_selected === "Cor sólida" && this.editaItem.propsData.icon.color) {
				this.editaItem.propsData.icon.hoverColor = this.editaItem.propsData.icon.hoverColor || '';
				if (this.lastHoverColor) {
					this.editaItem.propsData.icon.hoverColor = this.lastHoverColor;
				}
			} else {
				this.lastHoverColor = this.editaItem.propsData.icon.hoverColor;
			}
		},
		hoverBackCreate(){
			if(this.editaItem.propsData.icon.back_selected === "Cor sólida" && this.editaItem.propsData.icon.color){
				this.editaItem.propsData.icon.hoverBackColor = this.editaItem.propsData.icon.hoverBackColor || '';
				if(this.lastHoverBackColor){
					this.editaItem.propsData.icon.hoverBackColor = this.lastHoverBackColor;
				}
			} else {
				this.lastHoverBackColor = this.editaItem.propsData.icon.hoverBackColor
			}
		},
		backgroundGradiente(){
			if (this.editaItem.propsData.icon.back_selected == "Gradiente") {
				this.editaItem.propsData.icon.back_type = "image";
				this.editaItem.propsData.icon.backColor = `${this.gradientType}(${this.editaItem.propsData.icon.grad_angle}deg,
				${this.editaItem.propsData.icon.grad_color2}, ${this.editaItem.propsData.icon.grad_color1})`;
			}
		}, 
	}
}
</script>
