<template>
  <div id="SideRightTabs">
    <b-card no-body>
      <b-tabs pills justified small style="width: 100%">
        <!-- Geral -->
        <b-tab
          title="Conteúdo"
          active
        >
          <div class="separador"></div>
          <div class="content">
            <div class="half">
              <label class="titulo">Links</label>
              <div class="contain-buttons" style="margin-top: -10px">
                <button
                  class="btn button-add-produto"
                  @click="addLink"
                ></button>
                <button
                  v-if="editaItem.propsData.links.length > 1"
                  class="btn button-remove-produto"
                  @click="removeItem"
                ></button>
                <button
                  v-else
                  class="btn button-remove-produto"
                  @click="mostrarMensagem"
                ></button>
              </div>
            </div>
            <div v-for="(link, idx) in editaItem.propsData.links" :key="idx">
              <div class="label-range">
                <label class="subtitulo" style="margin-top: 10px !important"
                  >Link {{ idx + 1 }}</label
                >
                <button
                  class="btn button-remove-produto"
                  @click="removeImgIndex(idx)"
                ></button>
              </div>
              <div style="padding: 0px 5px">
                <input
                  id="menu"
                  placeholder="Sobre nós"
                  v-model="editaItem.propsData.links[idx].name"
                />
                <div class="space"></div>
                <input
                  id="menu"
                  placeholder="https://seulink"
                  v-model="editaItem.propsData.links[idx].link"
                />
                <div class="space"></div>
                <div
                  class="half-menu"
                  v-if="editaItem.propsData.links[0].hasOwnProperty('blank')"
                >
                  <div class="child">
                    <multiselect
                      :options="optionsType"
                      v-model="editaItem.propsData.links[idx].type"
                      :searchable="false"
                      :show-labels="false"
                      :allow-empty="false"
                      placeholder="Tipo de link"
                      label="text"
                      track-by="value"
                    ></multiselect>
                  </div>
                  <div
                    class="child"
                    style="display: flex; width: 50%; transition: all 0.3s"
                  >
                    <label class="subtitulo" style="margin: 0 !important"
                      >Abrir em nova guia</label
                    >
                    <b-form-checkbox
                      style="
                        margin-top: -5px;
                        margin-left: auto;
                        margin-right: 0;
                        z-index: 0;
                      "
                      v-model="editaItem.propsData.links[idx].blank"
                      name="is_main"
                      size="lg"
                      switch
                    >
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>
          </div>
        </b-tab>

        <!-- Estilo -->
        <b-tab title="Estilo">
          <div>
            <div class="separador"></div>

            <!-- Cores -->
            <div class="content">
              <label class="titulo">Cores</label>
              <label class="sub">Fundo</label>
              <LvColorpicker
                id="LvColorpicker"
                @change="backgroundCreate()"
                v-model="editaItem.propsData.styles.background_header"
                :colors="palette"
              ></LvColorpicker>
              <div class="space"></div>
              <label class="sub">Links</label>
              <LvColorpicker
                id="LvColorpicker"
                v-model="editaItem.propsData.styles.color_itens"
                label="Cor dos Links"
                :colors="palette"
              ></LvColorpicker>
            </div>
            <div class="separador"></div>

            <div
              class="content max-multi"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            >
              <label class="titulo">Fonte</label>
              <multiselect
                v-model="editaItem.propsData.styles.font"
                :options="fontOptions"
                :searchable="true"
                :show-labels="false"
                placeholder="Fonte"
                :hide-selected="true"
                :allow-empty="false"
                @input="$emit('callCharge',$event)"
              ></multiselect>
            </div>
            <div
              class="separador"
              v-if="this.editaItem.propsData.styles.hasOwnProperty('font')"
            ></div>
          </div>
        </b-tab>

        <!-- Avançado -->
        <b-tab title="Avançado">
          <div class="separador"></div>

          <!-- Visibilidade -->
          <div class="content">
            <label class="titulo"> Visibilidade </label>

            <!-- Dispositivos grandes -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos grandesﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Maiores que 768 pixels de largura, como laptops e computadores"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  @change="$emit('fixWidth')"
                  v-model="editaItem.propsData.visibility.monitor"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos médios -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos médiosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Entre 480 a 768 pixels de largura, como tablets"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.tablet"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <!-- Dispositivos pequenos -->
            <div>
              <div style="display: flex; width: 100%; transition: all 0.3s">
                <label
                  style="font-size: 14px !important; display: flex !important"
                >
                  Dispositivos pequenosﾠ
                  <img
                    width="17px"
                    height="17px"
                    src="@/assets/editor/help-circle.svg"
                    alt="icon"
                    v-b-tooltip.hover
                    title="Menores que 480 pixels de largura, como celulares"
                  />
                </label>
                <b-form-checkbox
                  style="margin-top: -5px; margin-left: auto; margin-right: 0"
                  v-model="editaItem.propsData.visibility.phone"
                  name="is_main"
                  size="lg"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Animação -->
          <div class="content">
            <label class="titulo">Animação</label>
            <div class="select-animation">
              <div class="animation-container">
                <div>
                  <p>Nenhum</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Nenhuma',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Nenhuma'),
                        (editaItem.propsColumn.attribute_animation = '')
                    "
                  >
                    <div class="simulated-item simu1"></div>
                  </div>
                </div>
                <div>
                  <p>Zoom</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und ==
                        'Gradual Increase',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und =
                        'Gradual Increase'),
                        (editaItem.propsColumn.attribute_animation =
                          'gradual-increase')
                    "
                  >
                    <div class="simulated-item simu2">
                      <img
                        src="@/assets/editor/square-logo.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Up</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Up',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Up'),
                        (editaItem.propsColumn.attribute_animation = 'top')
                    "
                  >
                    <div class="simulated-item simu3">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Right</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Right',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Right'),
                        (editaItem.propsColumn.attribute_animation = 'right')
                    "
                  >
                    <div class="simulated-item simu4">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Down</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Down',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Down'),
                        (editaItem.propsColumn.attribute_animation = 'bottom')
                    "
                  >
                    <div class="simulated-item simu5">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p>Fade Left</p>
                  <div
                    class="animation-item"
                    :class="{
                      'selected-animation':
                        editaItem.propsColumn.attribute_und == 'Fade Left',
                    }"
                    @click="
                      (editaItem.propsColumn.attribute_und = 'Fade Left'),
                        (editaItem.propsColumn.attribute_animation = 'left')
                    "
                  >
                    <div class="simulated-item simu6">
                      <img
                        src="@/assets/editor/arrow-fat-lines-down.svg"
                        alt="setinha"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="half-animation">
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Atraso</label
                    >
                    <input
                      type="number"
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      v-model="editaItem.propsColumn.delay"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.delay"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
                <div>
                  <div class="label-range">
                    <label
                      class="subtitulo"
                      :class="{
                        labelOff:
                          editaItem.propsColumn.attribute_und == 'Nenhuma',
                      }"
                      >Duração</label
                    >
                    <input
                      :disabled="
                        editaItem.propsColumn.attribute_und == 'Nenhuma'
                      "
                      type="number"
                      v-model="editaItem.propsColumn.duration"
                    />
                  </div>
                  <b-form-input
                    v-model="editaItem.propsColumn.duration"
                    type="range"
                    :disabled="editaItem.propsColumn.attribute_und == 'Nenhuma'"
                    min="0"
                    max="10"
                    step="1"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>

          <!-- Classe e ID -->
          <div class="content">
            <label class="titulo">Classe e ID</label>
            <label class="sub"
              >IDﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Cada ID pode ser aplicado a somente um elemento . Um elemento pode ter apenas um ID"
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_id"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: id_unico"
            />
            <label class="sub"
              >Classeﾠ<img
                width="17px"
                height="17px"
                src="@/assets/editor/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover
                title="Uma classe pode ser aplicada a muitos elementos. Um elemento pode ter muitas classes."
            /></label>
            <input
              v-model="editaItem.propsData.custom.c_class"
              class="w-100 mb-3"
              type="text"
              name="text_1"
              id="text_1"
              placeholder="exemplo: classe_1 classe_2"
            />
          </div>
          <div class="separador"></div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LvInput from "lightvue/input";
import LvColorpicker from "lightvue/color-picker";
import Multiselect from "vue-multiselect";

export default {
  props: ["editaItem", "typeView", "fontOptions", "palette"],

  components: {
    LvInput,
    LvColorpicker: LvColorpicker,
    Multiselect,
  },
  data() {
    return {
      linkPadding: true,
      linkMargin: true,
      global: false,
      optionsType: [
        { value: "externo", text: "Externo" },
        { value: "interno", text: "Na própria página" },
      ],
    };
  },
  methods: {
    nextIcon(type) {
      this.$emit("attIconView", type);
    },
    globalInput(px, place) {
      if (this.global && this.typeView == "desktop") {
        var props = this.editaItem.propsData.size;
        var value = px.target.value;
        props.tablet[place] = value;
        props.mobile[place] = value;
      }
    },
    paddingLink(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkPadding) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.padding_top = value;
          props.desktop.padding_right = value;
          props.desktop.padding_bottom = value;
          props.desktop.padding_left = value;
          props.tablet.padding_top = value;
          props.tablet.padding_right = value;
          props.tablet.padding_bottom = value;
          props.tablet.padding_left = value;
          props.mobile.padding_top = value;
          props.mobile.padding_right = value;
          props.mobile.padding_bottom = value;
          props.mobile.padding_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.padding_top = value;
            props.desktop.padding_right = value;
            props.desktop.padding_bottom = value;
            props.desktop.padding_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.padding_top = value;
            props.tablet.padding_right = value;
            props.tablet.padding_bottom = value;
            props.tablet.padding_left = value;
          } else {
            props.mobile.padding_top = value;
            props.mobile.padding_right = value;
            props.mobile.padding_bottom = value;
            props.mobile.padding_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.padding_top = value;
          props.mobile.padding_top = value;
          return;
        } else if (position == "right") {
          props.tablet.padding_right = value;
          props.mobile.padding_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.padding_bottom = value;
          props.mobile.padding_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.padding_left = value;
          props.mobile.padding_left = value;
        }
      }
    },
    attrLinkMargin(px, position) {
      var props = this.editaItem.propsData.spacing;
      var value = px.target.value;
      if (this.linkMargin) {
        if (this.global && this.typeView == "desktop") {
          props.desktop.margin_top = value;
          props.desktop.margin_right = value;
          props.desktop.margin_bottom = value;
          props.desktop.margin_left = value;
          props.tablet.margin_top = value;
          props.tablet.margin_right = value;
          props.tablet.margin_bottom = value;
          props.tablet.margin_left = value;
          props.mobile.margin_top = value;
          props.mobile.margin_right = value;
          props.mobile.margin_bottom = value;
          props.mobile.margin_left = value;
        } else {
          if (this.typeView === "desktop") {
            props.desktop.margin_top = value;
            props.desktop.margin_right = value;
            props.desktop.margin_bottom = value;
            props.desktop.margin_left = value;
          } else if (this.typeView === "tablet") {
            props.tablet.margin_top = value;
            props.tablet.margin_right = value;
            props.tablet.margin_bottom = value;
            props.tablet.margin_left = value;
          } else {
            props.mobile.margin_top = value;
            props.mobile.margin_right = value;
            props.mobile.margin_bottom = value;
            props.mobile.margin_left = value;
          }
        }
      } else if (this.global && this.typeView == "desktop") {
        if (position == "top") {
          props.tablet.margin_top = value;
          props.mobile.margin_top = value;
          return;
        } else if (position == "right") {
          props.tablet.margin_right = value;
          props.mobile.margin_right = value;
          return;
        } else if (position == "bottom") {
          props.tablet.margin_bottom = value;
          props.mobile.margin_bottom = value;
          return;
        } else if (position == "left") {
          props.tablet.margin_left = value;
          props.mobile.margin_left = value;
        }
      }
    },
    setImg(img) {
      this.editaItem.propsData.image.img_logo = img;
    },
    mostrarMensagem() {
      this.$bvToast.toast(`Necessário ao menos um link`, {
        title: "Editor",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    removeImgIndex(index) {
      this.editaItem.propsData.links.splice(index, 1);
    },
    addLink() {
      this.editaItem.propsData.links.push({
        blank: false,
        link: "https://greenn.com.br/",
        name: "Novo link",
        type: {
          text: "Externo",
          value: "externo",
        },
      });
    },
    removeItem() {
      this.editaItem.propsData.links.pop();
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    mg_logo(valor) {
      this.block_rotate_logo = valor;
    },
  },
  created() {
    if (this.typeView == "desktop") {
      this.global = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.label-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 0 1em 0;
  }
}

.half-menu {
  display: flex;
  max-width: 100% !important;
  gap: 10px;
  .child {
    width: 50% !important;
    align-items: center;
    justify-content: center;
  }
}
</style>
